/**
 * @file   登录日志模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */

import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Button, Input, Table, DatePicker, Form } from 'antd';
import './index.scss';
import { queryLoginLogPageList } from '../../../service';

const { RangePicker } = DatePicker;

// 默认页面大小
const PAGE_SIZE = 10;

/**
 * @description 登录日志组件
 */
export const Log = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: PAGE_SIZE
    });

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        setLoading(true);
        queryLoginLogPageList(params).then(res => {
            const listData = res.data.data.records.map(item => {
                return {
                    loginTime: item.loginTime,
                    loginChannel: item.loginChannel,
                    logoutTime: item.logoutTime,
                    logoutChannel: item.logoutChannel,
                }
            });
            setPagination({
                current: params.pageNum,
                pageSize: params.pageSize,
                total: res.data.data.total,
            });
            setDataSource(listData);
            setLoading(false);

        })
        // setDataSource([
        //     {
        //         loginTime: '2020-03-25 18:58:11',
        //         loginMethod: 'web',
        //         operation: '绑定邮箱，手机号',
        //         logoutTime: '2020-03-25 19:58:11',
        //         logoutMethod: 'Logout',
        //         ip: '43.227.137.224'
        //     },
        //     {
        //         loginTime: '2020-03-25 18:58:11',
        //         loginMethod: 'web',
        //         operation: '绑定邮箱，手机号',
        //         logoutTime: '2020-03-25 19:58:11',
        //         logoutMethod: 'Logout',
        //         ip: '43.227.137.224'
        //     },
        //     {
        //         loginTime: '2020-03-25 18:58:11',
        //         loginMethod: 'web',
        //         operation: '绑定邮箱，手机号',
        //         logoutTime: '2020-03-25 19:58:11',
        //         logoutMethod: 'Logout',
        //         ip: '43.227.137.224'
        //     },
        //     {
        //         loginTime: '2020-03-25 18:58:11',
        //         loginMethod: 'web',
        //         operation: '绑定邮箱，手机号',
        //         logoutTime: '2020-03-25 19:58:11',
        //         logoutMethod: 'Logout',
        //         ip: '43.227.137.224'
        //     },
        //     {
        //         loginTime: '2020-03-25 18:58:11',
        //         loginMethod: 'web',
        //         operation: '绑定邮箱，手机号',
        //         logoutTime: '2020-03-25 19:58:11',
        //         logoutMethod: 'Logout',
        //         ip: '43.227.137.224'
        //     }
        // ]);
        // setLoading(false);
    }, []);

    useEffect(() => {
        fetchData({
            entity: {},
            param: {
                pageNum: 1,
                pageSize: PAGE_SIZE
            }
        });
    }, [fetchData]);

    const columns = [
        {
            title: '登录时间',
            dataIndex: 'loginTime'
        },
        {
            title: '登录方式',
            dataIndex: 'loginChannel'
        },
        {
            title: '退出时间',
            dataIndex: 'logoutTime'
        },
        {
            title: '退出方式',
            dataIndex: 'logoutChannel'
        },
    ];

    const handleTableChange = (pagination) => {
        const values = form.getFieldsValue();
        const timeRange = values.loginTimeQuery;
        fetchData({
            entity: {
                ...values,
                loginTimeQuery: timeRange && timeRange.map((t) => t.format('YYYY-MM-DD')).join('#')
            },
            param: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize
            }
        });
    };

    return (
        <>
            <div
                className="table-meta log-table-meta"
                // style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                {/* <div>
                    <Button type="primary" style={{ marginRight: 10 }}>
                        导出选中日志
                    </Button>
                    <Button type="primary">导出全部日志</Button>
                </div> */}
                <Form
                    style={{float: 'right', marginBottom: 10}}
                    form={form}
                    layout="inline"
                    onFinish={() => handleTableChange({ current: 1, pageSize: PAGE_SIZE })}
                >
                    {/* <Form.Item name="operator">
                        <Input placeholder="请输入查询操作人" />
                    </Form.Item>
                    <Form.Item name="loginName">
                        <Input placeholder="请输入查询登录名" />
                    </Form.Item> */}
                    <Form.Item name="loginTimeQuery">
                        <RangePicker />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        查询
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                            fetchData({
                                entity: {},
                                param: {
                                    pageNum: 1,
                                    pageSize: PAGE_SIZE
                                }
                            });
                        }}
                    >
                        重置
                    </Button>
                </Form>
            </div>
            <Table
                columns={columns}
                dataSource={dataSource}
                bordered
                pagination={{
                    ...pagination,
                    // showSizeChanger: true,
                    showTotal: (total) => {
                        return `共 ${total} 条数据`;
                    }
                }}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    );
};
