/**
 * @file   帐号申诉重置安全码模块
 * @create tianmu 2021/6/18
 * @update tianmu 2021/6/18
 */
 import React, { useState } from 'react';
 import { Button, Input, Layout, notification, Form } from 'antd';
 import { AppFooter } from '../../containers/AppFooter';
 import Logo from '../../assets/logo.png';
 import { resetSecurity } from '../../service';
 import { InfoBox, STATUS_ENUM } from './InfoBox';
 import './index.scss'
 import { useHistory } from 'react-router-dom';

 const { Content, Header } = Layout;


 // 帐号申诉组件
 export const AccountComplainReset = () => {
     const [security, setSecurity] = useState(false)
     const history = useHistory()
     const [form] = Form.useForm();

     return (
         <Layout className="account-complain">
             <Header className="layout-header">
                 <div className="logo-box">
                     <a className="logo" href="/">
                         <img src={Logo} alt="logo" />
                     </a>
                 </div>
             </Header>
             <Content className="account-complain-content">
                 <div className="page-header">
                     <div className="title">帐号申诉</div>
                 </div>
                 <div className="page-content">
                     {security ? (
                         <InfoBox
                             title="安全码重置成功！"
                             status={STATUS_ENUM.AUTHED}
                             extra={
                                 <Button
                                     onClick={() => {
                                         history.push('/accountComplain')
                                     }}
                                 >
                                    返回
                                 </Button>
                             }
                         />
                     ) : (
                         <div
                             style={{
                                 width: '400px',
                                 margin: '100px auto 0',
                             }}
                         >
                             <Form layout="vertical" form={form}>
                                <Form.Item
                                     name="appealPhone"
                                     label="申诉手机号"
                                     rules={[
                                         {
                                             required: true,
                                             message: '申诉手机号不能为空!'
                                         }
                                     ]}
                                 >
                                     <Input size="large" placeholder="请输入申诉手机号" />
                                 </Form.Item>
                                 <Form.Item
                                     name="phone"
                                     label="接收信息手机号"
                                     rules={[
                                         {
                                             required: true,
                                             message: '接收信息手机号不能为空!'
                                         }
                                     ]}
                                 >
                                     <Input size="large" placeholder="请输入接收信息手机号" />
                                 </Form.Item>
                             </Form>
                             <div>
                                 <Button
                                     type="primary"
                                     style={{marginRight: '20px'}}
                                     onClick={async () => {
                                         await form.validateFields()
                                         const values = form.getFieldsValue()
                                         resetSecurity(values).then(res => {
                                             if (res.data.code === 200) {
                                                setSecurity(res.data?.data)
                                                notification.success({
                                                    className: 'custom-antd-notification',
                                                    message: '提交成功！',
                                                    duration: 5,
                                                })
                                             } else {
                                                 notification.warn({
                                                     className: 'custom-antd-notification',
                                                     message: res.data.msg,
                                                     duration: 5,
                                                 })
                                             }
                                         })
                                     }}
                                 >
                                     提交
                                 </Button>
                                 <Button
                                    onClick={() => history.push('/accountComplain')}
                                >
                                    返回
                                </Button>
                             </div>
                         </div>
                     )}
                 </div>
             </Content>
             <AppFooter />
         </Layout>
     );
 };
