/**
 * @file   帐号申诉查询模块
 * @create tianmu 2021/6/10
 * @update tianmu 2021/6/10
 */
import React, { useState } from 'react';
import { Button, Input, Layout, notification, Form } from 'antd';
import { AppFooter } from '../../containers/AppFooter';
import Logo from '../../assets/logo.png';
import { updateAppeal } from '../../service';
import { InfoBox, STATUS_ENUM } from './InfoBox';
import './index.scss'
import { useHistory } from 'react-router-dom';

const CryptoJS = require('crypto-js');

const { Content, Header } = Layout;

// 密码强度
const STRONG_PWD = 2;
const MIDDLE_PWD = 1;
const WEAK_PWD = 0;
const NONE_PWD = 3;
const PWD_TIP = '请至少使用大小写字母、数字、符号两种类型组合的密码，长度至少为8位。';
const Tip = (status) => {
    switch (status) {
        case STRONG_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(153, 204, 153)' }}>当前密码强度：强</i>
                </span>
            );
        case MIDDLE_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(255, 180, 64)' }}>当前密码强度：中等</i>
                </span>
            );
        case WEAK_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(255, 43, 77)' }}>当前密码强度：弱</i>
                </span>
            );
        default:
            return PWD_TIP;
    }
};

// 验证密码强度
const validatePassword = (pwd) => {
    // 空输入框
    if (!pwd)
        return {
            status: NONE_PWD,
            msg: Tip(NONE_PWD)
        };

    // 密码为八位及以上并且大小写字母数字特殊字符三项都包括
    const strongRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/g;
    // 密码为八位及以上并且大小写字母、数字、特殊字符三项中有两项，强度是中等
    const mediumRegex = /^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*\W))|((?=.*[0-9])(?=.*\W))|((?=.*[A-Z])(?=.*\W))).*$/g;
    const enoughRegex = /(?=.{8,}).*/g;
    if (strongRegex.test(pwd)) {
        // console.log('强密码-----',value)
        return {
            status: STRONG_PWD,
            msg: Tip(STRONG_PWD)
        };
    } else if (mediumRegex.test(pwd)) {
        // console.log('中等密码-----',value)
        return {
            status: MIDDLE_PWD,
            msg: Tip(MIDDLE_PWD)
        };
    } else if (enoughRegex.test(pwd)) {
        // console.log('弱密码-----',value)
        return {
            status: WEAK_PWD,
            msg: Tip(WEAK_PWD)
        };
    } else {
        // console.log('密码-----',value)
        return {
            status: WEAK_PWD,
            msg: Tip(WEAK_PWD)
        };
    }
};


// 帐号申诉组件
export const AccountComplainChangePwd = () => {
    const [submited, setSubmited] = useState(false)
    const history = useHistory()
    const [form] = Form.useForm();
    const [pwdStatus, setPwdStatus] = useState({
        validateStatus: null,
        value: '',
        msg: PWD_TIP
    });

    const onPwdChange = (e) => {
        const validateInfo = validatePassword(e.target.value);
        const isOk = validateInfo.status !== WEAK_PWD && validateInfo.status !== NONE_PWD;
        // console.log("validate: ", validateInfo)
        setPwdStatus({
            validateStatus: isOk ? 'success' : 'error',
            value: validateInfo.value,
            msg: validateInfo.msg
        });
    };

    return (
        <Layout className="account-complain">
            <Header className="layout-header">
                <div className="logo-box">
                    <a className="logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                </div>
            </Header>
            <Content className="account-complain-content">
                <div className="page-header">
                    <div className="title">帐号申诉</div>
                </div>
                <div className="page-content">
                    {submited ? (
                        <InfoBox
                            title="登录密码修改成功！"
                            status={STATUS_ENUM.AUTHED}
                            extra={
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        history.push('/accountLogin')
                                    }}
                                >
                                    去登录
                                </Button>
                            }
                        />
                    ) : (
                        <div
                            style={{
                                width: '500px',
                                margin: '100px auto 0',
                            }}
                        >
                            <Form layout="vertical" form={form}>
                                <Form.Item
                                    name="phone"
                                    label="接收信息手机号"
                                    rules={[
                                        {
                                            required: true,
                                            message: '接收信息手机号不能为空!'
                                        }
                                    ]}
                                >
                                    <Input size="large" placeholder="请输入接收信息手机号" />
                                </Form.Item>
                                <Form.Item
                                    label="新密码"
                                    name="password"
                                    rules={[{ required: true, message: '请输入新密码' }]}
                                    validateStatus={pwdStatus.validateStatus}
                                    help={pwdStatus.msg}
                                >
                                    <Input.Password
                                        placeholder="新密码"
                                        size="large"
                                        value={pwdStatus.value}
                                        onChange={onPwdChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="确认新密码"
                                    name="confirm"
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请确认你的密码!'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                // 如果两次密码输入相同通过校验
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error('两次输入密码不同!'));
                                            }
                                        })
                                    ]}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="重新输入新密码"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="security"
                                    label="安全码"
                                    rules={[
                                        {
                                            required: true,
                                            message: '安全码不能为空!'
                                        }
                                    ]}
                                >
                                    <Input size="large" placeholder="请输入安全码" />
                                </Form.Item>

                            </Form>
                            <div>
                                <Button
                                    type="primary"
                                    style={{marginRight: '20px'}}
                                    onClick={async () => {
                                        await form.validateFields()
                                        const values = form.getFieldsValue()
                                        updateAppeal(values.phone, [
                                            CryptoJS.MD5(values.security).toString().toUpperCase(),
                                            CryptoJS.MD5(values.password).toString().toUpperCase(),
                                        ]).then(res => {
                                            if (res.data.code === 200) {
                                                setSubmited(true)
                                                notification.success({
                                                    className: 'custom-antd-notification',
                                                    message: '提交成功！',
                                                    duration: 5,
                                                })
                                            } else {
                                                notification.warn({
                                                    className: 'custom-antd-notification',
                                                    message: res.data.msg,
                                                    duration: 5,
                                                })
                                            }
                                        })
                                    }}
                                >
                                    提交修改
                                </Button>
                                <Button
                                    onClick={() => history.push('/accountComplain')}
                                >
                                    返回
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Content>
            <AppFooter />
        </Layout>
    );
};
