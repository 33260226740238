/**
 * @file   试用成功模块
 * @create tianmu 2021/5/6
 * @update tianmu 2021/5/6
 */
 import React, { useEffect, useMemo, useState } from 'react';
 import { Button } from 'antd';
 import { CheckCircleFilled } from '@ant-design/icons';
 import { useHistory, useLocation } from 'react-router';
 import { ProductLayout } from './components/ProductLayout';
 import './index.scss'
import { searchStrToObj } from '../../utils';
import { queryRefundByOrderId } from '../../service';

 // 订单退订成功组件
export const OrderUnsubscribeSuccess = () => {
    const history = useHistory();
    const location = useLocation();
    const [refundInfo, setRefundInfo] = useState(null);
    const o = useMemo(() => searchStrToObj(location.search), [location])
    useEffect(() => {
        queryRefundByOrderId(o?.orderId).then(res => {
            if (res.data.code === 200) {
                setRefundInfo(res.data.data?.[0]);
            }
        })
    }, [o])
    return (
        <ProductLayout
            title="申请退订成功"
        >
            <div className="orderinfobox-wrapper">
                <div className="orderinfobox">
                    <div className="head">
                        <div
                            style={{
                                display: 'inline-block',
                                width: '100px',
                                height: '100px',
                                lineHeight: '100px',
                                fontSize: '50px',
                                color: 'rgb(61, 188, 142)',
                            }}
                        >
                            <CheckCircleFilled />
                        </div>
                        <div className="infobox">
                            <h1>恭喜您，退订申请成功！</h1>
                            <p>
                                {/* 因退订的产品、服务涉及费用问题，需评估和协商，申请退订成功后，
                                24小时内会有工作人员联系您沟通费用相关问题，请保持手机畅通。费用沟通达成一致后，24小时之内费用会原路径退回。
                                注意如有效期小于24小时，将不予退钱。 */}
                                因退订的产品、服务涉及费用问题，需评估和协商，申请退订成功后，24小时内会有工作人员联系您沟通费用相关问题，请保持手机畅通。
                                费用沟通达成一致后，24小时之内费用会原路径退回。
                                注意：
                                1.【退单申请】提交至【退单申请审批】完成，该时间段内可以继续使用，其延时损失由运营方承担，但不计入产品有效期；
                                2.【退单申请】的退款时长计算，以1自然日(24小时)为最小计量单位，超过24小时则计入下个自然日；
                                3.【退单申请】的退款起始时间，以【退单申请】提交后满足最小计量单位(24小时)规则的时间点为准。
                            </p>
                        </div>
                    </div>
                    <div>
                        <fieldset className="common-fieldset">
                            <legend>退订信息</legend>
                            <div className="info-row">
                                <div className="info-label">退订单号：</div>
                                <div className="info-value">{refundInfo?.refundNo}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">退订产品：</div>
                                <div className="info-value">{refundInfo?.refundTitle}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">提交时间：</div>
                                <div className="info-value">{refundInfo?.createTime}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">预计退订总价格：</div>
                                <div className="info-value" style={{color: 'red'}}>￥{o?.refundCost}</div>
                            </div>

                        </fieldset>
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '20px'
                    }}
                >
                    <Button type="primary" style={{marginRight: '20px'}} onClick={() => history.push('/MyOrder')}>我的订单</Button>
                </div>
            </div>
        </ProductLayout>
    );
};
