/**
 * @file   初始帐号信息模块
 * @create tianmu 2020/12/8
 * @update tianmu 2020/12/8
 */
import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import { AppHeader } from '../../containers/AppHeader'
import { AppFooter } from '../../containers/AppFooter'
import { Link, useLocation } from 'react-router-dom'
import { searchStrToObj } from '../../utils'

const { Content } = Layout

/**
 * @description 找回密码页面
 */
export const InitAccount = () => {
    const location = useLocation();
    const [name, setName] = useState("");
    const [pwd, setPwd] = useState("");
    useEffect(() => {
        const s = location.search;
        const o = searchStrToObj(s);
        setName(o.username);
        setPwd(o.password);
    }, [location])
    return (
        <Layout className='app'>
            <AppHeader />
            <Content>
                <div className="init-account"
                    style={{
                        background: "white",
                        width: "50%",
                        margin: "70px auto",
                        padding: "100px",
                    }}
                >
                    <h2 className="init-account-title"
                        style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                        }}
                    >
                        初始账户
                    </h2>
                    <p style={{fontSize: 16, margin: 0}}>用户名：{name}</p>
                    <p style={{fontSize: 16, margin: 0}}>密码：{pwd}</p>
                    <div style={{marginTop: 20}}>
                        <Link to="/accountLogin" className="filled-theme-btn" style={{
                            fontSize: "16px",
                            textAlign: "center",
                            width: "90px",
                            height: "35px",
                            lineHeight: "35px",
                            display: "inline-block",
                            borderRadius: "5px",
                        }}>立即登录</Link>
                        <Link to="/changePwd" className="filled-theme-btn" style={{
                            fontSize: "16px",
                            textAlign: "center",
                            width: "90px",
                            height: "35px",
                            lineHeight: "35px",
                            display: "inline-block",
                            borderRadius: "5px",
                            marginLeft: "20px",
                        }}>修改密码</Link>
                    </div>
                </div>
            </Content>
            <AppFooter />
        </Layout>
    )
}
