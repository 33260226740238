
/**
 * @file   帮助中心
 * @create macaiqi 2021/07/08
 * @update
 */
import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cls from 'classnames'
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import './style.scss'

export const HelpCenter = () => {


    return (<>
        <IntroHeader small={true} />
        <div className="helpcenter-new">
            <div className="banner">
                {/* <span>帮助中心</span> */}
                <div className="big">
                    <span>
                        帮助中心
                    </span>
                    <p className="txt">
                        欢迎使用瑞鹿云
                    </p>
                </div>
            </div>
            <div className="section section1">
                <p className="title">
                    瑞鹿云使用帮助
                </p>
                <div className="box b1">
                    <div className="item">
                        <Link to='/GettingStarted' className="circle">
                            <i className='iconfont iconxinshourumen' />
                        </Link>
                        <span>新手入门</span>
                    </div>
                    <div className="item">
                        <div className="circle">
                            <i className='iconfont iconpeizhiyonghu' />
                        </div>
                        <span>基础配置</span>
                    </div>
                    <div className="item">
                        <Link to='/CommonProblem' className="circle">
                            <i className='iconfont iconchangjianwenti' />
                        </Link>
                        <span>常见问题</span>
                    </div>
                </div>

            </div>
            <div className="section section2">
                <p className="title t1">
                    产品使用帮助
                </p>
                <div className="allcenter">
                    <div className="s1">
                        <div className="title-small">
                            智慧服务
                        </div>
                        <div className="boxlist fw">
                            <div className="item ">
                                <span>参观预约服务</span>
                            </div>
                            <div className="item">
                                <span>活动预约服务</span>
                            </div>
                            <div className="item">
                                <span>特展预约管理</span>
                            </div>
                        </div>
                    </div>
                    <div className="s1">
                        <div className="title-small">
                            智慧管理
                        </div>
                        <div className="boxlist gl">
                            <div className="item gl">
                                <span>数字资产管理</span>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </div>
        <IntroFooter />
    </>
    )
}
