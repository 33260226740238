/**
 * @file   发票管理模块
 * @create tianmu 2021/3/29
 * @update tianmu 2021/3/29
 */
import React from 'react';
import { Layout } from 'antd';
import { InvoiceTitleTable } from './InvoiceTitleTable';
import './index.scss';
import { searchStrToObj } from '../../utils';
import { useLocation } from 'react-router';

// 发票管理页面组件
export const ManageInvoice = (props) => {
    const { invoiceType } = props;
    const location = useLocation();
    return (
        <>
            <div className="page-header">
                <div className="title">发票管理</div>
            </div>
            <Layout className="page-content manage-invoice">

                <InvoiceTitleTable
                    invoiceType={invoiceType}
                    orderId={searchStrToObj(location.search)?.orderId}
                />
                {/* <Tabs defaultActiveKey="1" tabBarExtraContent={<Link to={`/MyOrder/ApplyInvoice?orderId=${searchStrToObj(location.search)?.orderId}`}>&lt; 返回</Link>}>
                    <Tabs.TabPane tab="发票信息" key="1" >
                        <InvoiceTitleTable invoiceType={invoiceType} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="地址管理" key="2">
                        <AddressTable invoiceType={invoiceType} />
                    </Tabs.TabPane>
                </Tabs> */}
            </Layout>

        </>
    );
};
