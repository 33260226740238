/**
 * @file   常用功能模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/23
 */

/**
 * @description 表单验证器
 */
export const validator = function () {
    return {
        numberRegex: /^\d+$/,
        phoneNumRegex: /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[01356789]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|6[567]\d{2}|4[579]\d{2})\d{6}$/, // 中国大陆手机号匹配
        phoneTelNumRegex: /^(0\d{2,3}-?\d{7,8})|((?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[01356789]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|6[567]\d{2}|4[579]\d{2})\d{6})$/,
        userNameRegex: /^[a-zA-Z0-9_]{1,17}$/, // 用户名
        pwdRegex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}$/, // 6-16位  字母数字下划线
        fullNameRegex: /^[\u4e00-\u9fa5]{2,5}(?:·[\u4e00-\u9fa5]+)?$/, // 汉字姓名正则
        // idcardRegex: (IdNum) => idcard.verify(IdNum), //身份证
        contentRegex: /^(<\s*\/?\s*[a-zA-z_]([^>]*?["][^"]*["])*[^>"]*>)/g, // 留言内容
        chineseRegex: /[\u4e00-\u9fa5]/, // 中文
        sCEURegex: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/ // 中文，英文字母和数字及下划线
    };
};

/**
 * @description 提取url中的搜索字符串
 * @param {String} searchStr url中的搜索字符串
 * @returns {Object} 搜索键值对
 */
export const searchStrToObj = (searchStr) => {
    if (!searchStr) return null;
    if (Object.prototype.toString.call(searchStr) !== '[object String]') return null;
    const o = {};
    let s = searchStr;
    if (s.indexOf('?') === 0) {
        s = s.substr(1);
    }
    const pairs = s.split('&');
    pairs.forEach((p) => {
        const [k, v] = p.split('=');
        o[k] = v;
    });
    return o;
};

/**
 * @description 生成全局唯一uuid
 * @param {Number} len uuid长度
 * @param {Number} radix uuid基数
 */
export function uuid(len, radix) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz'.split('');
    const uuid = [];
    let i;
    let rad = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * rad)];
    } else {
        // rfc4122, version 4 form
        let r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | (Math.random() * 16);
                uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}

export function validateIdcard(id) {
    if (!id) return { status: 0, msg: '身份证号为空' };
    // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
    const format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
    // 号码规则校验
    if (!format.test(id)) {
        return { status: 0, msg: '身份证号码不合规' };
    }
    // 区位码校验
    // 出生年月日校验  前正则限制起始年份为1900;
    const year = id.substr(6, 4); // 身份证年
    const month = id.substr(10, 2); // 身份证月
    const date = id.substr(12, 2); // 身份证日
    const time = Date.parse(month + '-' + date + '-' + year); // 身份证日期时间戳date
    const now_time = Date.parse(new Date()); // 当前时间戳
    const dates = new Date(year, month, 0).getDate(); // 身份证当月天数
    if (time > now_time || date > dates) {
        return { status: 0, msg: '出生日期不合规' };
    }
    // 校验码判断
    const c = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 系数
    const b = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验码对照表
    const id_array = id.split('');
    let sum = 0;
    for (let k = 0; k < 17; k++) {
        sum += parseInt(id_array[k], 10) * parseInt(c[k], 10);
    }
    if (id_array[17].toUpperCase() !== b[sum % 11].toUpperCase()) {
        return { status: 0, msg: '身份证校验码不合规' };
    }
    return { status: 1, msg: '校验通过' };
}

export function dateFormat(fmt, date) {
    if (date === undefined || date === null) return;
    let d = date;
    if (typeof date === 'string') d = new Date(date);
    let ret;
    const opt = {
        'Y+': d.getFullYear().toString(), // 年
        'm+': (d.getMonth() + 1).toString(), // 月
        'd+': d.getDate().toString(), // 日
        'H+': d.getHours().toString(), // 时
        'M+': d.getMinutes().toString(), // 分
        'S+': d.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    // eslint-disable-next-line guard-for-in
    for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt);
        if (ret) {
            // eslint-disable-next-line no-param-reassign
            fmt = fmt.replace(
                ret[1],
                ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
            );
        }
    }
    return fmt;
}

function isNegative0(x) {
    if (x!==0) return false;
    const obj = Object.freeze({z:-0});
    try {
       Object.defineProperty(obj,'z',{value:x});
    } catch (e) {return false};
    return true;
 }

/**
 * @description 精确到小数位
 * @param {Number} number 数字
 * @param {Number} cnt 精确到的位数
 */
export function toFixedNumStr(num, cnt = 2) {
    if (num === null || num === undefined) return "";
    if (typeof(num) !== 'number') return "";
    const fixedCntStr = num.toFixed(cnt);
    // 处理负0的hack
    if (isNegative0(parseFloat(fixedCntStr))) {
        return "0.00"
    }
    return fixedCntStr;
    // const numStr = num.toString();
    // const index = numStr.indexOf('.')
    // const len = numStr.length;
    // let result = ""
    // if (index > 0) {
    //     const fixedCnt = len - index - 1;
    //     result = numStr.slice(0, index + cnt + 1)
    //     // 当前小数位数小于指定的位数
    //     if (fixedCnt < cnt) {
    //         result += "".padEnd(cnt - fixedCnt, "0")
    //     }
    // } else {
    //     result = numStr + "." + "".padEnd(cnt, "0")
    // }
    // return result;
}

/**
 * @description 测试是否为正整数或零
 * @param {Number} v 数字或字符串
 */
export function testPositiveIntOrZero(v) {
    if (v === null || v === undefined || v === "") return false;
    if (typeof v === 'string' && !(/^[0-9]\d*$/g).test(v)) return false;
    // 非整数或0
    if (typeof v === 'number' && !(v % 1 === 0)) return false;
    // 负数
    if (typeof v === 'number' && v < 0) return false;
    return true;
}

/**
 * @description 判断是否是对象或数组
 * @param {Object} o 任意对象
 * @returns {Boolean} 是否是对象或数组
 */
function isObject(obj) {
    return typeof obj === 'object' && obj !== null && obj !== undefined
}

/**
 * @description 判断两个对象或数组是否完全相等
 * @param {Object} obj1 任意对象
 * @param {Object} obj2 任意对象
 * @returns {Boolean} 两个对象或数组是否完全相等
 */
export function isEqual(obj1,obj2) {
  // 两个数据有任何一个不是对象或数组
  if (!isObject(obj1) || !isObject(obj2)) {
    // 值类型(注意：参与equal的一般不会是函数)
    return obj1 === obj2
  }
  // 如果传的两个参数都是同一个对象或数组
  if (obj1 === obj2) {
    return true
  }

  // 两个都是对象或数组，而且不相等
  // 1.先比较obj1和obj2的key的个数，是否一样
  const obj1Keys = Object.keys(obj1)
  const obj2Keys = Object.keys(obj2)
  if (obj1Keys.length !== obj2Keys.length) {
    return false
  }

  // 如果key的个数相等,就是第二步
  // 2.以obj1为基准，和obj2依次递归比较
  // eslint-disable-next-line guard-for-in
  for (let key in obj1) {
    // 比较当前key的value  --- 递归
    const res = isEqual(obj1[key], obj2[key])
    if (!res) {
      return false
    }
  }

  // 3.全相等
  return true
}

const transform = (obj, predicate) => {
    return Object.keys(obj).reduce((memo, key) => {
    if(predicate(obj[key], key)) {
        memo[key] = obj[key]
    }
    return memo
    }, {})
}

export const omit = (obj, items) => transform(obj, (value, key) => !items.includes(key))

export const pick = (obj, items) => transform(obj, (value, key) => items.includes(key))
