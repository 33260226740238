/**
 * @file   我的工单模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React from 'react';
import { Layout } from 'antd';
import TODO from '../../assets/todo.png'

// 我的工单页面组件
export const MyWorksheet = () => {

    return (
        <Layout
            className="myworksheet"
        >
            <img
                style={{
                    // width: 550,
                    height: 400,
                    margin: '100px auto'
                }}
                src={TODO}
                alt=""
            />
        </Layout>
    );
};
