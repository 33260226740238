/**
 * @file   我的积分模块
 * @create tianmu 2021/7/2
 * @update tianmu 2021/7/2
 */
import React, { useCallback, useState, useEffect } from 'react';
import { Tabs, Table, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { queryChangePoints, queryPointRecord, queryPoints } from '../../service';
import { ScoreLayout } from './components/ScoreLayout';
import './index.scss';


const ScoreTable = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    });

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        setLoading(true);
        return queryPointRecord(params).then((res) => {
            const listData = res.data.data.records.map((item) => {
                return {
                    key: 1,
                    usage: item.name,
                    income: item.points <= 0 ? item.points : '+' + item.points,
                    time: item.recordTime,
                    extra: ''
                };
            });
            setPagination({
                current: params.pageNum,
                pageSize: params.pageSize,
                total: res.data.data.total
            });
            setDataSource(listData);
            setLoading(false);
        });
    }, []);

    //  useEffect(() => {
    //      fetchScore();
    //      fetchData({
    //          // recordType: 1,
    //          // settmentTimeQuery: '2021-01-01#2021-12-31',
    //          pageNum: 1,
    //          pageSize: 10,
    //      });
    //  }, [fetchScore, fetchData]);

    const columns = [
        {
            title: '来源/用途',
            dataIndex: 'usage'
        },
        {
            title: '收入/支出',
            dataIndex: 'income'
        },
        {
            title: '时间',
            dataIndex: 'time'
        },
    ];

    // 表格分页或筛选参数变化
    const handleTableChange = (pagination, filter = {}) => {
        return fetchData({
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...filter
        });
    };

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={{
                ...pagination,
                // showSizeChanger: true,
                showTotal: (total) => {
                    return `共 ${total} 条数据`;
                }
            }}
            loading={loading}
            onChange={handleTableChange}
        />
    )

}

const ExchangeTable = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    });

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        setLoading(true);
        return queryPointRecord(params).then((res) => {
            const listData = res.data.data.records.map((item) => {
                return {
                    key: 1,
                    usage: item.name,
                    income: item.points <= 0 ? item.points : '+' + item.points,
                    time: item.recordTime,
                    extra: ''
                };
            });
            setPagination({
                current: params.pageNum,
                pageSize: params.pageSize,
                total: res.data.data.total
            });
            setDataSource(listData);
            setLoading(false);
        });
    }, []);

    //  useEffect(() => {
    //      fetchScore();
    //      fetchData({
    //          // recordType: 1,
    //          // settmentTimeQuery: '2021-01-01#2021-12-31',
    //          pageNum: 1,
    //          pageSize: 10,
    //      });
    //  }, [fetchScore, fetchData]);

    const columns = [
        {
            title: '来源/用途',
            dataIndex: 'usage'
        },
        {
            title: '收入/支出',
            dataIndex: 'income'
        },
        {
            title: '时间',
            dataIndex: 'time'
        },
    ];

    // 表格分页或筛选参数变化
    const handleTableChange = (pagination, filter = {}) => {
        return fetchData({
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...filter
        });
    };

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={{
                ...pagination,
                // showSizeChanger: true,
                showTotal: (total) => {
                    return `共 ${total} 条数据`;
                }
            }}
            loading={loading}
            onChange={handleTableChange}
        />
    )

}

const AddressTable = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    });

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        setLoading(true);
        return queryPointRecord(params).then((res) => {
            const listData = res.data.data.records.map((item) => {
                return {
                    key: 1,
                    usage: item.name,
                    income: item.points <= 0 ? item.points : '+' + item.points,
                    time: item.recordTime,
                    extra: ''
                };
            });
            setPagination({
                current: params.pageNum,
                pageSize: params.pageSize,
                total: res.data.data.total
            });
            setDataSource(listData);
            setLoading(false);
        });
    }, []);

    //  useEffect(() => {
    //      fetchScore();
    //      fetchData({
    //          // recordType: 1,
    //          // settmentTimeQuery: '2021-01-01#2021-12-31',
    //          pageNum: 1,
    //          pageSize: 10,
    //      });
    //  }, [fetchScore, fetchData]);

    const columns = [
        {
            title: '来源/用途',
            dataIndex: 'usage'
        },
        {
            title: '收入/支出',
            dataIndex: 'income'
        },
        {
            title: '时间',
            dataIndex: 'time'
        },
    ];

    // 表格分页或筛选参数变化
    const handleTableChange = (pagination, filter = {}) => {
        return fetchData({
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...filter
        });
    };

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={{
                ...pagination,
                // showSizeChanger: true,
                showTotal: (total) => {
                    return `共 ${total} 条数据`;
                }
            }}
            loading={loading}
            onChange={handleTableChange}
        />
    )

}

// 我的积分组件
export const MyScore = () => {

    const [points, setPoints] = useState(0);
    const [inPoints, setInPoints] = useState(0);
    const [outPoints, setOutPoints] = useState(0);

    // 获取积分数据
    const fetchScore = useCallback(() => {
        queryPoints().then((res) => {
            if (res.data.code === 200) {
                const d = res.data.data;
                const p = d?.points ?? 0;
                const f = d?.frozenPoints ?? 0;
                setPoints(p - f);
            } else {
                setPoints(0);
            }
        });
        queryChangePoints().then((res) => {
            if (res.data.code === 200) {
                const d = res.data.data;
                const p = d?.payPoints ?? 0;
                const i = d?.incomePoints ?? 0;
                setInPoints(i);
                setOutPoints(p);
            } else {
                setInPoints(0);
                setOutPoints(0);
            }
        });
    }, []);

    useEffect(() => {
        fetchScore();
    }, [fetchScore])


    return (
        <ScoreLayout>
            <div className="myscore-board">
                <div className="myscore-touse">
                    <div className="myscore-inner">
                        <p>可用积分</p>
                        <p>{points}</p>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: '0 0 49.8%',
                        alignItems: 'center'
                    }}
                >
                    <div className="myscore-inner" style={{ borderLeft: '1px solid #ddd' }}>
                        <p>收入积分</p>
                        <p>{inPoints}</p>
                    </div>
                    <div className="myscore-inner">
                        <p>支出积分</p>
                        <p>0</p>
                    </div>
                    <div className="myscore-inner" style={{ borderRight: '1px solid #ddd' }}>
                        <p>消费积分</p>
                        <p>{outPoints}</p>
                    </div>
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: '40px 0'
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        <Button>签到领积分</Button>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '5px'
                        }}
                    >
                        <Link
                            to="/ScoreRule"
                            style={{
                                fontSize: 14,
                                height: '100%',
                                paddingTop: 10
                            }}
                        >
                            <span
                                style={{
                                    display: 'inline-block',
                                    color: '#3dbc8e',
                                    marginRight: 5
                                }}
                            >
                                <QuestionCircleOutlined />
                            </span>
                            积分规则
                        </Link>
                    </div>
                </div>
            </div>
            <Tabs defaultActiveKey="1" onChange={() => {}}>
                <Tabs.TabPane tab="积分明细" key="1">
                    <ScoreTable />
                </Tabs.TabPane>
                <Tabs.TabPane tab="兑换记录" key="2">
                    <ExchangeTable />
                </Tabs.TabPane>
                <Tabs.TabPane tab="收货地址" key="3">
                    <AddressTable />
                </Tabs.TabPane>
            </Tabs>
        </ScoreLayout>
    );
};
