/**
 * @file   手机版讲解员管理系统介绍页面
 * @create macaiqi 2021/07/29
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import cls from 'classnames'
import './style.scss'

/**
 * @description 讲解员管理系统
 */

export const WapExplainSystem = () => {
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    const settings = {
        arrows: false,
        dots: false,
        // infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "3rem",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    const sec3 = [
        {
            name: '讲解员管理与服务全过程覆盖',
            des: '实现从讲解预约、任务发布、指派跟踪、任务执行、评价考核等全过程闭环管理。',
            icon: 'iconyizhanshijiejuefangan',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '基于移动终端的便捷式管理',
            des: '支持微信小程序作为移动终端，快捷、高效地实现讲解员工作与服务管理。',
            icon: 'iconshouji21',
            duration: '1s',
            delay: '0.7s'
        },

    ]
    // 功能组成
    const gongneng = [
        {
            name: "讲解员管理端",
            tab: ['档案管理', '排班管理', '任务管理', '讲解任务记录', '考核管理', '数据统计', '基础配置'],
            des: '提供讲解员档案信息的统一集中管理，主要功能包括新增、冻结、激活、修改、查看、查询。&&（1） 新增&&支持新增讲解员档案信息，主要包括讲解员姓名、手机号、证件号码、头像照片、专长等信息。',
            icon: 'iconshezhi'
        },
        {
            name: "讲解员小程序端",
            tab: [' 面向讲解员', '面向讲解员管理者'],
            des: '支持讲解员查看自己的讲解任务清单，跟踪各讲解任务状态，并根据状态提供一键开始讲解、结束讲解功能，主要功能包括任务查询、查看、开始讲解、结束讲解、查看讲解消息通知。',
            icon: 'iconxiaochengxu'
        },
        {
            name: "讲解预约小程序",
            tab: ['预约讲解', '讲解评价'],
            des: '支持观众在微信小程序端进行讲解预约。系统获取登记参观时间、参观人数、参观展厅、预约方式、联系人、联系方式、讲解员等信息后，发起讲解预约申请，等待管理员审核。',
            icon: 'iconhuodongyuyueguanli'
        },
        {
            name: "讲解员管理端",
            tab: ['档案管理', '排班管理', '任务管理', '讲解任务记录', '考核管理', '数据统计', '基础配置'],
            des: '提供讲解员档案信息的统一集中管理，主要功能包括新增、冻结、激活、修改、查看、查询。&&（1） 新增&&支持新增讲解员档案信息，主要包括讲解员姓名、手机号、证件号码、头像照片、专长等信息。',
            icon: 'iconshezhi'
        },
        {
            name: "讲解员小程序端",
            tab: [' 面向讲解员', '面向讲解员管理者'],
            des: '支持讲解员查看自己的讲解任务清单，跟踪各讲解任务状态，并根据状态提供一键开始讲解、结束讲解功能，主要功能包括任务查询、查看、开始讲解、结束讲解、查看讲解消息通知。',
            icon: 'iconxiaochengxu'
        },
        {
            name: "讲解预约小程序",
            tab: ['预约讲解', '讲解评价'],
            des: '支持观众在微信小程序端进行讲解预约。系统获取登记参观时间、参观人数、参观展厅、预约方式、联系人、联系方式、讲解员等信息后，发起讲解预约申请，等待管理员审核。',
            icon: 'iconhuodongyuyueguanli'
        }
    ]
    const [down, setDown] = useState()
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        if (anchorName || anchorName === 0) {
            const height = document.getElementById(anchorName).offsetTop - 40; // 计算需要向上移动的距离
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        }
    };
    const banner = useRef()
    const activeref = useRef()
    const { search } = useLocation()
    useEffect(() => {

        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // 滚动超过banner后nav吸顶
            if (scrollT >= banner.current.clientHeight + banner.current.offsetTop) {
                activeref.current?.classList.add('active');
            } else {
                activeref.current?.classList.remove('active');
            }
            // 滚动到对应点，对应nav高亮
            for (let i = 0; i < tab.length; i++) {

                if (scrollT >= document.getElementById(`item${i}`).offsetTop - 40) {
                    document.getElementById(`link${i}`).classList.add('active');
                    for (let j = 0; j < tab.length; j++) {
                        if (i !== j) {
                            document.getElementById(`link${j}`).classList.remove('active');
                        }
                    }
                }

            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(`item${search.split('?')[1]}`)
        }
    }, [tab.length, search]);

    return (<>
        <IntroHeader />
        <div className="mobile-content explain" ref={activeref}>
            <div className="banner" ref={banner}>
                <div className="content">
                    <p className="big">讲解员管理系统</p>
                    <div className="cont">
                        建立讲解员的个人档案，制定并发布讲解任务，通过自动提醒让讲解员实时接收讲解任务，并一键完成签到/签退，实现讲解任务的便捷管理；自动汇总讲解时长、收集观众评价数据、记录领导定期考核结果，实现讲解员的绩效考核管理。通过讲解预约、任务发布、执行、评价的闭环管理，全面实现讲解任务管理的标准化和自动化。
                    </div>
                    <div className="btnbox">
                        {/* <div className="btn btn1">
                             观看演示
                         </div> */}
                        {/* <div className=" btn btn2">
                             免费体验
                         </div> */}
                    </div>

                </div>
            </div>
            <div className="tab" >
                <ul>
                    {tab.map((item, index) => {
                        return (
                            <li key={index} id={`link${index}`} onClick={() => {
                                scrollToAnchor(`item${index}`)
                            }}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="people" id='item0'>
                <p className="title" >用户群体</p>
                <p className="txt">本产品适用于博物馆、美术馆、图书馆、文化馆、科技馆、规划馆、校史馆、档案馆等各类公共文化场馆及其他有讲解员管理需求的企事业单位。</p>
            </div>
            <div className="section2" id='item1' >
                <p className="title" >功能组成</p>
                <div className="functionof">
                    <Slider {...settings} key={Math.random()} >
                        {gongneng.map((item, index) => {
                            return (
                                <div className="itembox" key={index}>
                                    <div className="item">
                                        <i className={cls('iconfont', item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        {item.tab.map((item1, i1) => {
                                            return (<p className="txt" key={i1}>{item1}</p>)
                                        })}

                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div className="section3" id='item2'>
                <p className="title">产品特点</p>
                {
                    sec3.map((item, index) => {
                        return (
                            <div className={cls("item")} key={index} >
                                <div className="box">
                                    <div className="l">
                                        <i className={cls('iconfont', item.icon)} />
                                        {item.name}
                                    </div>
                                    <div className="down" />
                                </div>
                                <div className="text">
                                    {item.des}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="price" id='item3'>
                <p className="title">产品价格</p>
                <div className="num">
                    4800
                    <span>元/年</span>
                </div>
            </div>
            <div className="update" id='item4'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }} />
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
