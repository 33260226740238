
/**
 * @file   产品介绍欢迎页面
 * @create macaiqi 2021/07/28
 * @update
 */
import React, { useRef } from 'react'
import Slider from 'react-slick';
import { Link } from 'react-router-dom'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { IntroHeader } from './header';
import { IntroFooter } from './footer';
import './index.scss'
import slide1 from '../../assets/mobile/mobile-homebanner.jpg'
// import slide2 from '../../assets/mobile/mobile-slide2.png'
// import slide3 from '../../assets/mobile/mobile-slide3.png'
// import slide4 from '../../assets/mobile/mobile-slide4.png'
// import LEFT_ARROW from '../../assets/left-arrow.png'
import RIGHT_ARROW from '../../assets/right-arrow.png'

/**
 * @description 产品介绍欢迎组件
 */
export const WapIntroduction = () => {
    const settings = {
        customPaging: function (i) {
            return <span className="carousel-dot" data-idx={i} />;
        },
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const pics = [{ picture: slide1 },
        // { picture: slide2, link: '/mobile/VisitAppointment' },
        // { picture: slide3, link: '/mobile/ActivityAppointment' },
        // { picture: slide4, link: '/mobile/VisualAssetManagement' },
    ]
    return (
        <>
            <IntroHeader />
            <div className='mobile-cont'>
                <Slider {...settings} key={Math.random()} >
                    {pics?.map((item, index) => {
                        return (item.link ?
                            <Link to={item.link} key={index} className="carousel-item">
                                <figure className='portal-picture' >
                                    <img src={item.picture} alt="" />
                                </figure>
                            </Link>
                            : <div key={index} className="carousel-item">
                                <figure className='portal-picture' >
                                    <img src={item.picture} alt="" />
                                </figure>
                            </div>
                        );
                    })}
                </Slider>

            </div>
            <IntroFooter />
        </>
    )
}
