/**
 * @file    产品订购表单模块
 * @create tianmu 2021/3/23
 * @update tianmu 2021/3/23
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row, Col, notification, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { preSubmit, updateOrderState } from '../../store';
import { OrderSumInfo } from '../OrderSumInfo';
import { testPositiveIntOrZero } from '../../../../utils';
import { PriceCounter } from './PriceCounter';
import './index.scss';
import { queryDiscountInfo, queryResourceById } from '../../../../service';
import UnderCarriageIcon from '../../../../assets/notice/5.png'

// 单个服务订购表单组件
export const ResourceOrderForm = (props) => {
    const { finish, id } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { orderState } = useSelector((state) => state.productReducer);
    const { resourceList } = orderState;
    const [discInfos, setDiscInfos] = useState([]);
    const [underCarriage, setUndercarriage] = useState(false);

    // 表单提交成功
    const onSubmitClick = async () => {
        const hasResource = Array.isArray(resourceList)
                            && resourceList.filter(i => i.value !== 0)
                            .length > 0;
        if (!(hasResource)) {
            notification.warn({
                className: 'custom-antd-notification',
                message: '下单失败',
                description:
                '请选择要购买的资源',
                duration: 5,
            });
            return;
        }
        setLoading(true);
        const success = await dispatch(preSubmit());
        setLoading(false);
        if (success) {
            finish();
        }
    };

    const fetchResourceList = useCallback(async () => {
        const res = await queryResourceById(id);
        if (res.data.code === 200) {
            const p = res.data.data;
            if (!p || !p.price) return [];
            return[{
                ...p,
                // 购买数量
                value: p.price?.quota,
                // 总价
                sum: p.price?.price,
            }]
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
            return [];
        }
    }, [id]);

    const onResourceValueChange = useCallback(
        (v, idx) => {
            if (!testPositiveIntOrZero(v)) return;
            const newLst = resourceList.map((i, num) => {
                const price = i?.price?.price;
                const quota = i?.price?.quota;
                const value = v;
                if (price === null) return null;
                const sum = price * (value / quota);

                return num === idx
                    ? {
                          ...i,
                          value: v,
                          sum
                      }
                    : i;
            });
            dispatch(
                updateOrderState({
                    resourceList: newLst
                })
            );
        },
        [dispatch, resourceList]
    );

    useEffect(() => {
        if (!id) {
            history.push('/MyProduct');
        }
        const fetchData = async () => {
                const rLst = await fetchResourceList();
                const discInfoPromises = rLst.map((p) => queryDiscountInfo({ type: 3, id: p.resourceId }))
                // type=1套件2 产品 3资源 4服务
                Promise.all(
                    discInfoPromises
                ).then((rs) => {
                    setDiscInfos(rs.map(i =>
                        (Array.isArray(i?.data?.data) && i?.data?.data.length>0) ?
                        i.data.data[0] :
                        null
                    ).filter(i => !!i))
                });
                // 如果资源下架，则禁用页面交互，显示下架
                if (rLst.length > 0 && rLst[0].status !== 1) {
                    setUndercarriage(true)
                }

                // 初始化订单状态
                dispatch(updateOrderState({
                    kit: null,
                    productList: [],
                    serviceList: [],
                    resourceList: rLst,
                }))
        }
        fetchData()
    }, [history, dispatch, id, fetchResourceList]);

    return (
        <>
            <div className="product-order-form">
                {underCarriage && (
                    <div className="notice-img-icon">
                        <img src={UnderCarriageIcon} alt="" />
                    </div>
                )}
                {resourceList.length > 0 &&
                    resourceList.map((resource, idx) => (
                        <div key={resource.serviceId} className="order-product-item">
                            <Row gutter={[0, 20]}>
                                <Col className="order-product-title" span={2}>
                                    资源名称：
                                </Col>
                                <Col className="order-product-title" span={22}>
                                    {resource?.resourceName}
                                    <Tooltip
                                        placement="topLeft"
                                        color="#fff"
                                        title={
                                            <div style={{color: '#000'}}>
                                                资源一旦订购成功后，不支持退订！
                                            </div>
                                        }
                                    >
                                        <span
                                            style={{
                                                fontSize: 16,
                                                color: '#559f80',
                                                marginLeft: '5px'
                                            }}
                                        >
                                            <ExclamationCircleOutlined />
                                        </span>
                                    </Tooltip>

                                </Col>
                            </Row>
                            <Row gutter={[0, 20]}>
                                <Col className="order-product-desc" span={2}>
                                    资源介绍：
                                </Col>
                                <Col className="order-product-desc" span={22}>
                                    {resource?.description}
                                </Col>
                            </Row>
                            <Row gutter={[0, 20]}>
                                <Col className="order-product-desc" span={2}>
                                    购买数量：
                                </Col>
                                <Col span={22}>
                                    <PriceCounter
                                        disabled={underCarriage}
                                        product={resource}
                                        onValueChange={onResourceValueChange}
                                        idx={idx}
                                        optionNum={3}
                                    />

                                </Col>
                            </Row>
                        </div>
                    ))}
            </div>
            <div
                className="order-form-footer"
            >
                <Button
                    className="order-form-footer-btns"
                    onClick={() => history.goBack()}
                >
                    返回
                </Button>
                <Button
                    loading={loading}
                    className="order-form-footer-btns"
                    type="primary"
                    disabled={underCarriage}
                    onClick={onSubmitClick}
                >
                    立即下单
                </Button>
                <OrderSumInfo discInfos={discInfos} />
            </div>
        </>
    );
};
