/**
 * @file   通用目录模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20
 */
import React, { useState, useEffect } from 'react'
import { Menu, Modal } from 'antd'
import { Link, useHistory, withRouter } from 'react-router-dom'

// 处理 pathname
const getOpenKeys = string => {
    let newStr = '';
    const newArr = [];
    const arr = string.split('/').map(i => '/' + i)
    for (let i = 1; i < arr.length - 1; i++) {
        newStr += arr[i]
        newArr.push(newStr)
    }
    return newArr
}

// 菜单组件
export const CustomMenu = withRouter(props => {
    const history = useHistory();
    const [state, setstate] = useState({
        openKeys: [],
        selectedKeys: []
    })

    let { openKeys, selectedKeys } = state

    // 页面刷新的时候可以定位到 menu 显示
    useEffect(() => {
        let { pathname } = props.location
        // console.log("pathname", pathname)
        const keys = pathname.split('/').map(i => '/' + i)
        console.log('keys: ', keys)
        setstate(prevState => {
            return {
                ...prevState,
                // 当前项目特殊需求，只需要看路径的第一个
                selectedKeys: [keys.length > 1 ? keys[1] : '/'],
                // selectedKeys: [...keys],
                openKeys: getOpenKeys(pathname)
            }
        })
    }, [props])

    // 只展开一个 SubMenu
    const onOpenChange = openKeys => {
        setstate(prevState => {
            if (openKeys.length === 0 || openKeys.length === 1) {
                return { ...prevState, openKeys }
            }
            const latestOpenKey = openKeys[openKeys.length - 1]

            // 这里与定义的路由规则有关
            if (latestOpenKey.includes(openKeys[0])) {
                return { ...prevState, openKeys }
            } else {
                return { ...prevState, openKeys: [latestOpenKey] }
            }
        })
    }

    const renderMenuItem = ({ key, icon, title }) => (
        <Menu.Item key={key}>
            <Link to={key}>
                {icon}
                <span>{title}</span>
            </Link>
        </Menu.Item>
    )

    // 循环遍历数组中的子项 subs ，生成子级 menu
    const renderSubMenu = ({ key, icon, title, subs }) => {
        return (
            <Menu.SubMenu
                key={key}
                title={
                    <span>
                        {icon}
                        <span>{title}</span>
                    </span>
                }>
                {subs &&
                    subs.map(item => {
                        return item.subs && item.subs.length > 0 ? renderSubMenu(item) : renderMenuItem(item)
                    })}
            </Menu.SubMenu>
        )
    }

    return (
        <Menu
            mode='inline'
            // theme='dark'
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onClick={({ key }) => {
                if (selectedKeys.includes(key)) {
                    history.push(key);
                }
                setstate(prevState => ({ ...prevState, selectedKeys: [key] }))
            }}
            onOpenChange={onOpenChange}>
            {props.menu &&
                props.menu.map(item => {
                    return item.subs && item.subs.length > 0 ? renderSubMenu(item) : renderMenuItem(item)
                })}
        </Menu>
    )
});

