/* eslint-disable complexity */
/**
 * @file   产品项模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React from 'react';
import { Button, notification, Popover, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToTrialList } from '../../store';
import { CornerTag, EnumTag } from '../CornerTag';
import { toFixedNumStr } from '../../../../utils';
import { doAfterOrgAuth } from '../../../../utils/orgAuthUtils';
import { displayQuota, unitStr } from '../../../../utils/productUtils';
import { queryProductInUseList } from '../../../../service';
import { getUserDetail } from '../../../login/store';
import { showOrgAuthTip, showEditNameTip } from '../../../../store/globStore';

// const FTP_PREVIEW = process.env.ENV_FTP_PREVIEW
const FTP_PREVIEW = window.FTP_PREVIEW;


const renderCorner = ({ typeStr, data, opts = {} }) => {
    if (!data) return;
    const { route } = opts;
    const { isOrdered, isTrial, diff, expireTime } = data;

    // 已购买（覆盖之前的试用）
    const hasBuy = isOrdered && !!isTrial === false;
    // 已试用
    const hasTrial = isOrdered && !!isTrial;
    // 是否到期
    const isExpire = expireTime && (new Date() - new Date(expireTime)) >= 0;
    // const isExpire = diff < 0;

    // 业务产品
    const isBusinessProduct = typeStr === 'product' && data.type === 1;
    // 基础产品
    // const isBasicProduct = typeStr === 'product' && data.type === 2;
    // 试用产品
    const isTrialProduct = typeStr === 'product' && data.type === 3;

    // 业务套件
    const isBusinessKit = typeStr === 'kit' && data.type === 1;
    // 基础套件
    // const isBasicKit = typeStr === 'kit' && data.type === 2;
    // 试用套件
    // const isTrialKit = typeStr === 'kit' && data.type === 3;
    // 资源
    const isResource = typeStr === 'resource';
    // 服务
    const isService = typeStr === 'service';
    // 服务已使用
    const isComplete = !!data.isComplete;

    let tag = null;
    // 产品商城面向大众，不需要这些状态
    if (route === 'ProductShop') return null;

    if (isBusinessProduct) {
        // 业务产品
        if (hasTrial) {
            // 试用
            if (isExpire) {
                // 产品到期
                tag = EnumTag.TRIALEXPIRE;
            } else {
                tag = EnumTag.ONTRIAL;
            }
        } else if (hasBuy) {
            // 购买
            if (isExpire) {
                tag = EnumTag.EXPIRE;
            } else {
                tag = EnumTag.INUSE;
            }
        }
    } else if (isTrialProduct) {
        // 试用产品
        if (hasTrial) {
            if (isExpire) {
                tag = EnumTag.TRIALEXPIRE;
            } else {
                tag = EnumTag.ONTRIAL;
            }
        } else if (hasBuy) {
            // TODO：这块逻辑可能会更改
            // 已购买的业务产品可能会下架后改为试用产品
            if (isExpire) {
                tag = EnumTag.EXPIRE;
            } else {
                tag = EnumTag.ORDERED;
            }
        }
    } else if (isBusinessKit) {
        // 业务套件
        if (hasBuy) {
            if (isExpire) {
                tag = EnumTag.EXPIRE;
            } else {
                tag = EnumTag.INUSE;
            }
        }
    } else if (isResource) {
        // 资源
        // 2021.5.6 注释下面代码 无论是试用或购买附赠的资源还是直接购买的资源，不再显示标签
        // if (hasBuy) {
        //     tag = EnumTag.ORDERED;
        // } else {
        //     // 试用申请产品或者套件时会有些基础用户数或者空间数
        //     // 状态显示 赠送，一旦购买后，状态显示已订购
        //     tag = EnumTag.GIFT;
        // }
    } else if (isService) {
        // 服务
        if (isComplete) {
            tag = EnumTag.USED;
        } else {
            tag = EnumTag.UNUSED;
        }
    }

    return <CornerTag tag={tag} />;
};

/* 产品标题，点击进入详情 */
const renderHeader = ({ typeStr, data, operations }) => {
    if (!data) return null;
    return (
        <Link
            className={`product-title ${typeStr}`}
            style={{
                cursor: 'pointer'
            }}
            to={`/ProductIntro?type=${typeStr}&id=${data[typeStr + 'Id']}`}
        >
            {data[typeStr + 'Name']}
        </Link>
    );
};

const renderContent = ({ typeStr, data, opts = {} }) => {
    if (!data) return null;
    const { route } = opts;
    const lenLimit = 30;

    return (
        <div className={`product-content ${typeStr}`}>
            <div className="product-content-inner">
                {data.description && data.description.length > lenLimit ? (
                    <Popover
                        placement="topLeft"
                        title={data[typeStr + 'Name']}
                        content={<p style={{ maxWidth: 300 }}>{data.description}</p>}
                        arrowPointAtCenter
                    >
                        <p style={{ margin: '10px 0' }} className="ellipsis2">
                            {data.description?.substr(0, 100)}
                        </p>
                    </Popover>
                ) : (data.description &&
                    <p style={{ margin: '10px 0' }} className="ellipsis2">
                        {data.description}
                    </p>
                )}
                {typeStr === 'kit' && (
                    <p>
                        <b>套件内容：</b>
                        {Array.isArray(data.details) &&
                            data.details.map((i) => i.name + i.quota + unitStr(i.unit)).join(', ')}
                    </p>
                )}

                {/* 产品商城中显示价格 */}
                {route === 'ProductShop'
                    ? (typeStr === 'product' || typeStr === 'kit')
                        /* 产品商城中非基础、试用的产品或套件显示价格 */
                        ? data.type === 1
                            ? (
                                <p>
                                    价格：
                                    <span style={{
                                        color: 'rgb(211, 60, 60)',
                                        fontSize: 18,
                                    }}>
                                        ￥
                                    </span>
                                    <span style={{
                                        color: 'rgb(211, 60, 60)',
                                        fontSize: 22,
                                    }}>
                                        {toFixedNumStr(data.price?.price, 2)}
                                    </span>
                                    {/* 套件没有单位 */}
                                    {<>
                                        /{displayQuota(data.price?.quota)}
                                        {typeStr === 'kit' ? '套' : unitStr(data.price?.unit)}
                                    </>}

                                </p>
                            ) : null
                        : (
                            <p>
                                价格：
                                <span style={{
                                    color: 'rgb(211, 60, 60)',
                                    fontSize: 18,
                                }}>
                                    ￥
                                </span>
                                <span style={{
                                    color: 'rgb(211, 60, 60)',
                                    fontSize: 22,
                                }}>
                                    {toFixedNumStr(data.price?.price, 2)}
                                </span>
                                /{displayQuota(data.price?.quota)}
                                {unitStr(data.price?.unit)}
                            </p>
                        )
                    : null}
                {route === 'MyProduct' && data.isOrdered && data?.expireTime && (
                    <p>
                        <b>到期时间：</b>
                        {data.expireTime}
                    </p>
                )}
                {route === 'MyProduct' && (typeStr === 'service' && data?.completeTime) && (
                    <p>
                        <b>完成时间：</b>
                        {data.completeTime}
                    </p>
                )}
                {/* 已购资源显示数量 */}
                {route === 'MyProduct' && (typeStr === 'resource' && data?.count) ?
                    (data?.used !== undefined && data?.used !== null) ?
                        <p>
                            已使用：{data.used} / {data.count}
                            {unitStr(data.price?.unit)}
                        </p>
                        : (
                            <p>
                                数量：{data.count}
                                {unitStr(data.price?.unit)}
                            </p>
                        ) : null}
            </div>
        </div>
    );
};

const renderFooter = ({ typeStr, data, operations, opts = {} }) => {
    if (!data) return null;
    const { tryHandler, buyHandler, showNotAuthed, showEditName } = operations;
    const { isOrdered, isTrial, diff, expireTime } = data;
    const { route } = opts;
    const cacheUser = getUserDetail()
    const TryBtn = (
        <Button
        type="primary"
            onClick={
                () =>{
                    if (cacheUser.randomName) {
                        showEditName()
                    } else {
                        doAfterOrgAuth(tryHandler, showNotAuthed)
                    }

                }
            }
        >
            免费试用
        </Button>
    );
    const BuyBtn = (
        <Button
        type="primary"
            onClick={() => {
                if (cacheUser.randomName) {
                    showEditName()
                } else {
                    doAfterOrgAuth(buyHandler, showNotAuthed)
                }
            }}
        >
            立即订购
        </Button>
    );
    // TODO: 退订产品
    const UnsubscribeBtn = <Button onClick={() => {}}>退订</Button>;
    const SubscribeBtn = (
        <Button
        type="primary"
            onClick={() => {
                if (cacheUser.randomName) {
                    showEditName()
                } else {
                    doAfterOrgAuth(buyHandler, showNotAuthed)
                }
            }}
        >
            续订
        </Button>
    );

    // 已购买（覆盖之前的试用）
    const hasBuy = isOrdered && !!isTrial === false;
    // 已试用
    const hasTrial = isOrdered && !!isTrial;
    // 是否到期
    const isExpire = expireTime && (new Date() - new Date(expireTime)) >= 0;
    // const isExpire = diff < 0;

    // 业务产品
    const isBusinessProduct = typeStr === 'product' && data.type === 1;
    // 基础产品
    const isBasicProduct = typeStr === 'product' && data.type === 2;
    // 试用产品
    const isTrialProduct = typeStr === 'product' && data.type === 3;

    // 业务套件
    const isBusinessKit = typeStr === 'kit' && data.type === 1;
    // 基础套件
    const isBasicKit = typeStr === 'kit' && data.type === 2;
    // 试用套件
    const isTrialKit = typeStr === 'kit' && data.type === 3;
    // 资源
    const isResource = typeStr === 'resource';
    // 服务
    const isService = typeStr === 'service';
    // 服务已使用
    const isComplete = !!data.isComplete;
    let footer = null;

    if (route === 'ProductShop') {
        // 产品商城中显示的按钮与是否申请试用、购买无关，只显示产品本身常规按钮
        footer = (
            <div className={`product-operations left ${typeStr}`}>
                {isBusinessProduct && (
                    <>
                        {TryBtn}
                        {BuyBtn}
                    </>
                )}
                {isTrialProduct && TryBtn}
                {isBusinessKit && BuyBtn}
                {isService && BuyBtn}
                {isResource && BuyBtn}
            </div>
        );
    } else {
        footer = (
            <div className={`product-operations left ${typeStr}`}>
                {/* 业务产品试用中 */}
                {isBusinessProduct && hasTrial && !isExpire && BuyBtn}
                {/* 业务产品试用到期 */}
                {isBusinessProduct && hasTrial && isExpire && (
                    <>
                        {TryBtn}
                        {BuyBtn}
                    </>
                )}
                {/* 业务产品已订购且未到期 */}
                {isBusinessProduct && hasBuy && !isExpire && (
                    <>
                        {SubscribeBtn}
                        {/* 2021.5.8 退订功能改到订单中，不再显示退订按钮 */}
                        {/* {UnsubscribeBtn} */}
                    </>
                )}
                {/* 业务产品已订购但已到期 */}
                {isBusinessProduct && hasBuy && isExpire && SubscribeBtn}
                {/* 试用产品  试用到期后可再次试用，未到期不可试用 */}
                {isTrialProduct && hasTrial && isExpire && TryBtn}
                {/* 基础产品暂不支持申请试用、购买，购买或者申请试用产品审核通过后自动生成相关脚本 */}
                {isBasicProduct && null}

                {/* 业务套件购买未到期 */}
                {isBusinessKit && hasBuy && !isExpire && (
                    <>
                        {SubscribeBtn}
                        {/* 套件不能退订，故不显示退订按钮，每次仅能购买一套 */}
                        {/* {UnsubscribeBtn} */}
                    </>
                )}
                {/* 业务套件购买已到期 */}
                {isBusinessKit && hasBuy && isExpire && SubscribeBtn}
                {isBasicKit && null}
                {/* 没有试用套件，套件现不可试用 */}
                {isTrialKit && null}

                {isResource && hasBuy && SubscribeBtn}
                {isResource && !hasBuy && BuyBtn}

                {isService && hasBuy && !isComplete && (
                    <>
                        {/* 服务订购且未使用时不能续订 */}
                        {/* 2021.5.8 退订功能改到订单中，不再显示退订按钮 */}
                        {/* {UnsubscribeBtn} */}
                    </>
                )}
                {isService && hasBuy && isComplete && SubscribeBtn}
            </div>
        );
    }

    return footer;
};

// 产品、服务、资源、套件的一个项目
export const ProductItem = ({ typeStr, data, opts }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const location = useLocation();
    const { hasOrderedProduct } = opts || {};
    // const { isOrdered, isTrial, diff } = data;
    // 添加试用
    const tryHandler = () => {
        history.push(`/ProductOrder?type=trial&id=${data[typeStr + "Id"]}`)
        // // 已购买（覆盖之前的试用）
        // const hasBuy = isOrdered && !!isTrial === false;
        // // 已试用
        // const hasTrial = isOrdered && !!isTrial;
        // // 是否到期
        // // const isExpire = nowTime && expireTime && new Date(nowTime) - new Date(expireTime) > 0;
        // const isExpire = diff < 0;
        // // 购买过或试用未到期时，不能添加试用
        // if (hasBuy) {
        //     notification.warn({
        //         className: 'custom-antd-notification',
        //         message: '试用失败',
        //         description: '您已购买过该产品！',
        //         duration: 5
        //     })
        // }
        // if (hasTrial && !isExpire) {
        //     notification.warn({
        //         className: 'custom-antd-notification',
        //         message: '试用失败',
        //         description: '您正在试用该产品！',
        //         duration: 5
        //     })
        // }

        // dispatch(addToTrialList(data));
        // // 当前位置不在产品页面时，跳转到产品页面
        // if (
        //     !location.pathname.includes('MyProduct') &&
        //     !location.pathname.includes('ProductShop')
        // ) {
        //     history.push('/ProductShop');
        // }
    };

    // 订购
    const buyHandler = async () => {
        if (typeStr === 'service' || typeStr === 'resource') {
            // const res = await queryProductInUseList({
            //     customerId: getUserDetail().customerId
            // })
            // // 查询是否所有购买的产品都过期了
            // if (Array.isArray(res.data?.data) && res.data.data.length === 0 ) {
            //     Modal.confirm({
            //         title: '您购买的产品均已到期，是否继续购买?',
            //         icon: <ExclamationCircleOutlined />,
            //         // content: data[typeStr + "Name"],
            //         onOk() {
            //             history.push(`/ProductOrder?type=${typeStr}&id=${data[typeStr + "Id"]}`);
            //         },
            //     });
            //     return;
            // }
            // 是否没有购买过产品
            if (!hasOrderedProduct) {
                notification.warn({
                    className: 'custom-antd-notification',
                    description: '请先购买或试用产品再购买资源和服务',
                    duration: 5
                });
                return;
            }

        }
        if (typeStr === 'kit') {
            const { details } = data;
            if (!(Array.isArray(details) && details.length > 0)) return;
            // 套件中不包含产品，而且没有购买过产品
            if (!details.some(i => i.type === 2) && !hasOrderedProduct) {
                // type=1套件2 产品 3资源 4服务
                notification.warn({
                    className: 'custom-antd-notification',
                    message: '订购失败',
                    description: '请先购买或试用产品再购买该套件',
                    duration: 5
                });
                return;
            }
        }
        history.push(`/ProductOrder?type=${typeStr}&id=${data[typeStr + "Id"]}`);
    };

    // 退订
    // const unsubscribeHandler = () => {
    //     dispatch(setProductToBuy(data));
    //     history.push('/MyProduct/ProductUnsubscribe');
    // }

    const showNotAuthed = () => {
        dispatch(showOrgAuthTip())
    }
    const showEditName = () => {
        dispatch(showEditNameTip())
    }

    return data ? (
        <div
            className={`product-item ${typeStr}`}
            style={typeStr === 'product' ? {
                background: `no-repeat center/cover url(${FTP_PREVIEW}${
                    data.icon?.indexOf('/') === 0 ?
                    data.icon.substr(1) :
                    data.icon
                })`
            } : null}
        >
            {renderCorner({ typeStr, data, opts })}
            {renderHeader({
                typeStr,
                data,
            })}

            {/* 产品信息 */}
            {renderContent({ typeStr, data, opts })}

            {/* 产品能够进行的操作 */}
            {renderFooter({
                typeStr,
                data,
                operations: {
                    tryHandler,
                    buyHandler,
                    showNotAuthed,
                    showEditName
                },
                opts
            })}
        </div>
    ) : null;
};
