/**
 * @file   以登陆用户修改密码模块
 * @create tianmu 2020/10/16
 * @update tianmu 2020/10/16
 */

import React from 'react'
import { ChangePwdForm } from '../../../pages/changePwd/ChangePwdForm'

/**
 * @description 用户修改密码组件
 */
export const UserChangePwd = () => {
  return (
    <div
        className="page-content"
        style={{
            paddingTop: 60,
        }}
    >
        <div className="user-change-pwd">
            <ChangePwdForm isCustomer={true} />
        </div>
    </div>
  );
};
