/**
 * @file   帐号申诉模块
 * @create tianmu 2021/6/8
 * @update tianmu 2021/6/8
 */
 import React from 'react';
 import OrgauthAuthingIcon from '../../assets/orgauth-authing.png';
 import OrgauthAuthedIcon from '../../assets/orgauth-authed.png';
 import OrgauthRejectIcon from '../../assets/orgauth-reject.png';

 import './index.scss';

export const STATUS_ENUM = Object.freeze({
    AUTHED: 1,
    AUTHING: 2,
    REJECTED: 3,
})

export const InfoBox = (props) => {
    const { title, description, status, extra } = props;
    return (<div className="infoboxWrapper" >
        <div className="head">
            {(status && status === STATUS_ENUM.AUTHING) && (<img src={OrgauthAuthingIcon} alt="" />)}
            {(status && status === STATUS_ENUM.AUTHED) && (<img src={OrgauthAuthedIcon} alt="" />)}
            {(status && status === STATUS_ENUM.REJECTED) && (<img src={OrgauthRejectIcon} alt="" />)}
            <div className="infobox">
                <h1>{title}</h1>
                <div className="infobox-description">
                    {description}
                </div>
            </div>
        </div>
        <div className="infobox-extra">
            {extra}
        </div>
    </div>)
}
