/**
 * @file   订单退订模块
 * @create tianmu 2021/3/9
 * @update tianmu 2021/3/9
 */
import React, { useEffect } from 'react';
import { Layout, Row, Col, Alert } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormFooter } from '../../../../containers/FormFooter';
import { setDetailItem } from '../../store';

// 订单退订组件
export const ProductUnsubscribe = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { itemToUnsubscribe } = useSelector((state) => state.productReducer);

    useEffect(() => {
        // if (!itemToUnsubscribe) {
        //     history.push("/MyProduct");
        // }
    });

    return (
        <>
            <div className="page-header">
                <div className="title">申请退订</div>
            </div>
            <Alert
                message="因退订的产品、服务、资源涉及费用问题，需评估和协商，申请退订成功后，24小时内会有工作人员联系您沟通费用相关问题，请保持手机畅通。费用沟通达成一致后，24小时之内费用会原路径退回。"
                type="info"
                showIcon
                style={{
                    marginBottom: 10
                }}
            />
            <Layout className="page-content">
                {itemToUnsubscribe && (
                    <div
                        style={{
                            padding: '15px 20px',
                            background: '#fff'
                        }}
                    >
                        <Row gutter={[0, 20]}>
                            <Col span={2}>产品名称：</Col>
                            <Col span={22}>
                                <a
                                    href="/#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(setDetailItem(itemToUnsubscribe));
                                        history.push('/MyProduct/ProductIntro');
                                    }}
                                >
                                    {itemToUnsubscribe?.productName}
                                </a>
                            </Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col span={2}>简&sp;&sp;介：</Col>
                            <Col span={22}>{itemToUnsubscribe?.description}</Col>
                        </Row>
                    </div>
                )}
            </Layout>
            <FormFooter
                okText="确认退订"
                onOk={() => {}}
                cancelText="返回"
                onCance={() => {
                    history.goBack();
                }}
            />
        </>
    );
};
