/**
 * @file   参观预约系统介绍页面
 * @create macaiqi 2021/07/29
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import cls from 'classnames'
import './style.scss'

/**
 * @description 参观预约系统组件
 */

export const WapVisitAppointment = () => {
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    const settings = {
        arrows: false,
        dots: false,
        // infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "3rem",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    const sec3 = [
        { title: '灵活的预约规则配置', txt: '提供开放时段、预约天数、预约票数、预约须知等预约规则的灵活配置，适应多类型公共场所的开放需求。' },
        { title: '全面的预约终端支持', txt: '提供微信小程序和Web端预约入口，让公众预约更加便捷高效。' },
        { title: '快捷多样的验票方式', txt: '支持扫码核销、自助核销等多种验票方式，方便观众快速通过检票口。' },
        { title: '多维度观众统计分析', txt: '建立观众画像，提供观众预约和入馆入园的多维度统计分析，为观众服务和运营管理提供决策支持。' },
    ]
    const [down, setDown] = useState()
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        if (anchorName || anchorName === 0) {
            const height = document.getElementById(anchorName).offsetTop - 40; // 计算需要向上移动的距离
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        }
    };
    const banner = useRef()
    const activeref = useRef()
    const { search } = useLocation()
    useEffect(() => {

        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // 滚动超过banner后nav吸顶
            if (scrollT >= banner.current.clientHeight + banner.current.offsetTop) {
                activeref.current?.classList.add('active');
            } else {
                activeref.current?.classList.remove('active');
            }
            // 滚动到对应点，对应nav高亮
            for (let i = 0; i < tab.length; i++) {

                if (scrollT >= document.getElementById(`item${i}`).offsetTop - 40) {
                    document.getElementById(`link${i}`).classList.add('active');
                    for (let j = 0; j < tab.length; j++) {
                        if (i !== j) {
                            document.getElementById(`link${j}`).classList.remove('active');
                        }
                    }
                }

            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(`item${search.split('?')[1]}`)
        }
    }, [tab.length, search]);

    return (<>
        <IntroHeader />
        <div className="mobile-content" ref={activeref}>
            <div className="banner visit" ref={banner}>
                <div className="content">
                    <p className="big">参观预约系统</p>
                    <div className="cont">
                        以满足观众便捷入馆、入园参观体验为核心，为管理人员提供灵活的预约规则配置，让观众通过多种终端完成在线预约，并在入馆入园时快速通过核验，实现参观预约全过程的标准化、数字化、智慧化服务与管理。
                    </div>
                    <div className="btnbox">
                        {/* <div className="btn btn1">
                            观看演示
                        </div> */}
                        {/* <div className=" btn btn2">
                            免费体验
                        </div> */}
                    </div>

                </div>
            </div>
            <div className="tab" >
                <ul>
                    {tab.map((item, index) => {
                        return (
                            <li key={index} id={`link${index}`} onClick={() => {
                                scrollToAnchor(`item${index}`)
                            }}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="people" id='item0'>
                <p className="title" >用户群体</p>
                <p className="txt">本产品适用于对观众参观预约有需求的公共文化场馆、文旅景区的社教、宣传部门及相关工作人员。</p>
            </div>
            <div className="section2" id='item1' >
                <p className="title" >功能组成</p>
                <div className="functionof">
                    <Slider {...settings} key={Math.random()} >

                        <div className="itembox">
                            <div className="item">
                                <i className='i2' />
                                <p className='tip'>票务预约</p>
                                <p className="txt">微信小程序预约</p>
                                <p className="txt">Web端预约</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i3' />
                                <p className='tip'>票务核验</p>
                                <p className="txt">扫码核销</p>
                                <p className="txt">自助核销</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i1' />
                                <p className='tip'>票务管理</p>
                                <p className="txt">预约规则配置</p>
                                <p className="txt">预约观众档案管理</p>
                                <p className="txt">数据统计</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i2' />
                                <p className='tip'>票务预约</p>
                                <p className="txt">微信小程序预约</p>
                                <p className="txt">Web端预约</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i3' />
                                <p className='tip'>票务核验</p>
                                <p className="txt">扫码核销</p>
                                <p className="txt">自助核销</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i1' />
                                <p className='tip'>票务管理</p>
                                <p className="txt">预约规则配置</p>
                                <p className="txt">预约观众档案管理</p>
                                <p className="txt">数据统计</p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className="section3" id='item2'>
                <p className="title">产品特点</p>
                {
                    sec3.map((item, index) => {
                        return (
                            <div className={cls("item")} key={index} >
                                <div className="box">
                                    <div className="l">
                                        <i className={index === 0 ? `icon9` : `icon${index + 1}`} />
                                        {item.title}
                                    </div>
                                    <div className="down" />
                                </div>
                                <div className="text">
                                    {item.txt}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="price" id='item3'>
                <p className="title">产品价格</p>
                <div className="num">
                    2800
                    <span>元/年</span>
                </div>
            </div>
            <div className="update" id='item4'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span>
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
