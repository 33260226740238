/**
 * @file   修改密码模块
 * @create tianmu 2020/10/16
 * @update tianmu 2020/10/16
 */
import React from 'react';
import { Layout } from 'antd';
import { AppFooter } from '../../containers/AppFooter';
import { ChangePwdForm } from './ChangePwdForm.jsx';
import Logo from '../../assets/logo.png';
import './index.scss';

const { Content, Header } = Layout;

/**
 * @description 找回密码页面
 */
export const ChangePwd = () => {
    return (
        <Layout className="change-pwd">
            <Header className="layout-header">
                <div className="logo-box">
                    <a className="logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                </div>
            </Header>
            <Content>
                <h2 className="change-pwd-title">找回密码</h2>
                <ChangePwdForm />
            </Content>
            <AppFooter />
        </Layout>
    );
};
