/**
 * @file  全局状态模块
 * @create tianmu 2021/9/27
 * @update tianmu 2021/9/27
 */
import { createSlice } from '@reduxjs/toolkit';

// 用户状态
const initialState = {
    showNotAuthedTip: false,
    showEditNameTip: false,
    showEditPwdTip: false,
    unReadMsgs: [],
};

// 全局状态Slice
const globSlice = createSlice({
    name: 'glob',
    initialState,
    reducers: {
        setShowNotAuthedTip(state, action) {
            state.showNotAuthedTip = action.payload;
        },
        setShowEditNameTip(state, action) {
            state.showEditNameTip = action.payload;
        },
        setShowEditPwdTip(state, action) {
            state.showEditPwdTip = action.payload;
        },
        setUnReadMsgs(state, action) {
            state.unReadMsgs = action.payload;
        }
    }
});

// 全局状态actions
export const { setShowNotAuthedTip, setShowEditNameTip, setShowEditPwdTip, setUnReadMsgs } = globSlice.actions;

export const showOrgAuthTip = () => async (dispatch) => {
    dispatch(setShowNotAuthedTip(true));
};
export const hideOrgAuthTip = () => async (dispatch) => {
    dispatch(setShowNotAuthedTip(false));
};

export const showEditNameTip = () => async (dispatch) => {
    dispatch(setShowEditNameTip(true))
}

export const hideEditNameTip = () => async (dispatch) => {
    dispatch(setShowEditNameTip(false))
}

export const showEditPwdTip = (payload) => async (dispatch) => {
    dispatch(setShowEditPwdTip(payload))
}

export const hideEditPwdTip = () => async (dispatch) => {
    dispatch(setShowEditPwdTip(false))
}

export default globSlice.reducer;
