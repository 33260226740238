/**
 * @file   自定义文件上传模块
 * @create tianmu 2020/10/20
 * @update tianmu 2020/10/20
 *         tianmu 2020/10/22 手动构造请求上传文件
 */
import React  from 'react';
import { Upload } from 'antd';
import {fileUpload} from '../../service'

// 自定义文件上传组件
export const CustomUpload = (props) => {
    const uploadProps = {
        name: 'trackdata',
    }

    const customRequest = (opts) => {
        const {onError, onSuccess, filename, file } = opts;
        const f = new FormData()
        f.append(filename, file);
        fileUpload(f).then(res => {
            // 请求图片响应成功后回调onSuccess给uploader
            if (res.data.code === 200) {
                onSuccess(res.data, file);
            } else {
                onError("error")
            }
        })
    }

    return (
        <Upload {...uploadProps} {...props} customRequest={customRequest}>
            {props.children}
        </Upload>
    )
}
