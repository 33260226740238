/**
 * @file   产品列表模块
 * @create tianmu 2021/4/19
 * @update tianmu 2021/4/19
 */
import React, { useEffect, useState, useCallback } from 'react';
import {
    queryKitList,
    queryMallKitList,
    queryMallProductList,
    queryMallResourceList,
    queryMallServiceList,
    queryProductList,
    queryResourceList,
    queryServiceList
} from '../../../../service';
import { ProductItem } from '../ProductItem';
import ProductIcon from '../../../../assets/product-icon.png';
import ServiceIcon from '../../../../assets/service-icon.png';
import ResourceIcon from '../../../../assets/resource-icon.png';
import KitIcon from '../../../../assets/kit-icon.png';
import { NoData } from '../../../../components/NoData';
import { Row, Col } from 'antd';
import { fetchUnExpireProducts } from '../../../../utils/productUtils';
import { Link } from 'react-router-dom';
export const ProductList = (props) => {
    const { route, size } = props;
    const [productList, setProductList] = useState([]);
    const [buyProductList, setBuyProductList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [kitList, setKitList] = useState([]);
    const [resourceList, setResourceList] = useState([]);

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback(() => {
        if (route === 'ProductShop') {
            const defaultParams = {
                searchKey: 'status',
                searchValue: 1,
                pageNum: 1,
                pageSize: 100,
            };
            fetchUnExpireProducts().then((lst) => {
                // console.log("unexpire lst", lst)
                setBuyProductList(lst || []);
            });
            queryMallProductList(defaultParams).then((res) => {
                const lst = res.data.data.list;
                setProductList(lst || []);
            });
            queryMallKitList(defaultParams).then((res) => {
                const lst = res.data.data.list;
                setKitList(lst || []);
            });
            queryMallServiceList(defaultParams).then((res) => {
                const lst = res.data.data.list;
                setServiceList(lst || []);
            });
            queryMallResourceList(defaultParams).then((res) => {
                const lst = res.data.data.list;
                setResourceList(lst || []);
            });
        } else {
            fetchUnExpireProducts().then((lst) => {
                setBuyProductList(lst || []);
            });
            // MyProduct
            queryProductList().then((res) => {
                const lst = res.data.data;
                setProductList(lst || []);
            });
            queryServiceList().then((res) => {
                const lst = res.data.data;
                setServiceList(lst || []);
            });
            queryKitList().then((res) => {
                const lst = res.data.data;
                setKitList(lst || []);
            });
            queryResourceList().then((res) => {
                const lst = res.data.data;
                setResourceList(lst || []);
            });
        }
    }, [route]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // MyProduct 我的产品列表都是已订购的产品
    const hasOrderedProduct = buyProductList.length > 0;

    return (
        <>
            <div className="product-panel">
                {/* <h2 className="product-type-title">
                    <img src={ProductIcon} alt="" />
                    产品
                </h2> */}
                {productList.length > 0 ? (
                    <div className="product-list">
                        <Row gutter={[16, 16]}>
                            {productList.map((i) => (
                                <Col

                                    span={
                                        route === 'ProductShop' ?
                                        12 :
                                        route === 'MyProduct' ?
                                        8 :
                                        12
                                    }
                                    key={i.productId}
                                >
                                    <ProductItem
                                        typeStr="product"
                                        data={i}
                                        opts={{
                                            hasOrderedProduct,
                                            route,
                                            size
                                        }}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                ) : (
                    <NoData
                        extra={route === 'MyProduct' ? <>，<Link to="/productShop">去订购</Link></> : null}
                    />
                )}
            </div>

            {/* 2021/5/17 我的产品中不再显示产品套件，只在产品商城中进行套件的购买 */}
            {/*
            {route === 'ProductShop' && (
                <div className="product-panel">
                    <h2 className="product-type-title">
                        <img src={KitIcon} alt="" />
                        产品套件
                    </h2>
                    {kitList.length > 0 ? (
                        <div className="product-list">
                            {kitList.map((i) => (
                                <ProductItem
                                    key={i.kitId}
                                    typeStr="kit"
                                    data={i}
                                    opts={{
                                        hasOrderedProduct,
                                        route
                                    }}
                                />
                            ))}
                        </div>
                    ) : (
                        <NoData />
                    )}
                </div>
            )}
            */}


            {/* <div className="product-panel">
                <h2 className="product-type-title">
                    <img src={ServiceIcon} alt="" />
                    产品服务
                </h2>
                {serviceList.length > 0 ? (
                    <div className="product-list">
                        {serviceList.map((i, idx) => (
                            <ProductItem
                                key={i.serviceId}
                                typeStr="service"
                                data={i}
                                opts={{
                                    hasOrderedProduct,
                                    route
                                }}
                            />
                        ))}
                    </div>
                ) : (
                    <NoData />
                )}
            </div>

            <div className="product-panel">
                <h2 className="product-type-title">
                    <img src={ResourceIcon} alt="" />
                    产品资源
                </h2>
                {resourceList.length > 0 ? (
                    <div className="product-list">
                        {resourceList.map((i, idx) => (
                            <ProductItem
                                key={i.resourceId}
                                typeStr="resource"
                                data={i}
                                opts={{
                                    hasOrderedProduct,
                                    route
                                }}
                            />
                        ))}
                    </div>
                ) : (
                    <NoData />
                )}
            </div> */}
        </>
    );
};
