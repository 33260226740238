
/**
 * @file   客流管理系统
 * @create macaiqi 2022/01/12
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import { localDS } from '../../../storage';
import ReactWOW from 'react-wow'
import cls from 'classnames'
import './style.scss'
import { handleFreeTrial } from '../../../utils/productUtils';

/**
 * @description 客流管理系统组件
 */
export const TrafficManagement = () => {
    const [index, setIndex] = useState(0)
    const [tabindex, setTabindex] = useState(1)
    const oDiv = useRef();
    const gnzc = useRef()
    const cptd = useRef()
    const { search } = useLocation()
    const [showTrialTip, setShowTrialTip] = useState(false);
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        setTabindex(anchorName)
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        }
    }
    useEffect(() => {
        // tab吸顶
        let divT = oDiv.current.offsetTop
        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // if (scrollT < gnzc.current.offsetTop - 10) {
            //     setTabindex(1)
            // }
            // else if (scrollT >= gnzc.current.offsetTop - 10 && document.documentElement.scrollHeight - scrollT !== document.documentElement.clientHeight) {
            //     setTabindex(2)
            // }
            // else if (scrollT >= 1200) {
            //     setTabindex(3)
            // }

            if (scrollT >= divT) {
                if (window.navigator.userAgent.indexOf('MSIE 6.0') != -1) {
                    // 兼容IE6代码
                    // body[0].style.paddingTop = '50px'
                    oDiv.current.style.position = 'absolute';
                    oDiv.current.style.top = scrollT + 'px';
                    oDiv.current.style.left = 0 + 'px';
                } else {
                    // 正常浏览器代码
                    oDiv.current.style.position = 'fixed';
                    oDiv.current.style.top = 0;
                    oDiv.current.style.left = 0;
                }
                document.getElementById('content').style.paddingTop = 50 + 'px'
                for (let i = 0; i < tab.length; i++) {
                    if (scrollT >= document.getElementById(`${i + 1}`)?.offsetTop) {
                        document.getElementById(`link${i + 1}`).classList.add('active');
                        for (let j = 0; j < tab.length; j++) {
                            if (i !== j) {
                                document.getElementById(`link${j + 1}`).classList.remove('active');
                            }
                        }
                    }

                }
            } else {
                oDiv.current.style.position = '';
                document.getElementById('content').style.paddingTop = 0 + 'px'
                for (let j = 0; j < tab.length; j++) {
                    document.getElementById(`link${j + 1}`).classList.remove('active');
                }
            }

        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(search.split('?')[1])
        }
    }, [cptd, gnzc, oDiv, search]);
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    // 功能组成
    const gongneng = [
        {
            name: "客流监控",
            tab: ['缩略图模式', '平面图模式'],
            des: '提供全馆/景区客流摄像头的视频监控画面，方便管理者在线观察各楼层不同区域的客流摄像头实时运行情况及画面。支持以缩略图和平面图两种模式进行监控画面的呈现。',
            icon: 'icona-404jiankongshexiangtou-xianxing'
        },
        {
            name: "客流统计",
            tab: ['全馆、园区客流量统计', '展厅客流量统计', '区域时段客流量统计'],
            des: '对全馆/园区及各展厅的客流进行汇总统计，主要包括全馆客流量统计、展厅客流量统计、区域时段客流量统计。',
            icon: 'iconshujutongji1'
        },
        {
            name: "客流分析",
            tab: ['观众聚集分析', '观展热度分析', '观众客流预测'],
            des: '结合全馆/园区的楼层平面图，根据不同展厅当前的客流量，以热力图的形式直观呈现当前各展厅的密集程度（密集程度根据预设的客流承载量最大阈值进行计算）。当全馆/园区/展厅的实时客流超过承载量最大阈值时，在全馆/园区的楼层平面图上标记预警，并同时给相关负责人发送预警消息。',
            icon: 'icona-fenxi1'
        },
        {
            name: "基础配置",
            tab: ['楼层信息管理', '客流设备管理', '展厅设备绑定', '展览位置绑定', '预警消息提醒'],
            des: '提供客流相关的基础数据/参数配置，主要包括客流设备管理、展厅及客流承载量、展厅进出客流规则、展厅关联的临展、预警消息提醒等的配置管理。',
            icon: 'iconpeizhi'
        },
        {
            name: "客流展示",
            tab: ['客流数据展示'],
            des: '向用户直观展示整个全馆/园区及各个展厅的实时客流数据，主要包括当前在馆/在园/在展厅总人数、当日入馆/入园/入展厅总人数，并支持在不同终端上呈现（如手机端、大屏），可配置实时客流数据的更新机制（如每隔一分钟更新一次）。',
            icon: 'iconzhanshi'
        },
    ]
    // 产品特点
    const tedian = [
        {
            name: '灵活的客流管理规则配置',
            des: '提供展厅、展览、进出客流等规则的灵活配置，适应多类型场馆、园区、展厅的客流统计需求。',
            icon: 'iconpeizhiguanli',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '动态实时的客流监测预警',
            des: '通过客流实时监控和超限预警，保障良好的参观体验，为维护展厅秩序提供决策信息支撑。',
            icon: 'icona-03-yujingyucefenxi',
            duration: '1s',
            delay: '0.7s'
        },
        {
            name: '多维度的客流统计与对比分析',
            des: '从范围、周期等多维度进行客流走势统计和对比，全面掌握全馆/园区/展厅客流情况。',
            icon: 'iconjiageyuce',
            duration: '1s',
            delay: '0.9s'
        },
        {
            name: '基于深度学习的客流预测',
            des: '基于深度学习方法建立客流预测模型，准确预测未来入馆/入园客流量，辅助管理与决策。',
            icon: 'icon503tongji_bingtu',
            duration: '1s',
            delay: '1.1s'
        },
    ]

    return (<>
        <IntroHeader />
        <div className="activity-appointment traffic" id='content'>
            <div className="banner">
                <ReactWOW animation="fadeInLeft" duration="1.2s" delay="0.1s">
                    <div className="content">
                        <p className="big">客流管理系统</p>
                        <div className="cont">以观众为中心，结合公共文化场馆、文旅景区的客流监控数据，实现客流数据的实时显示和管理，合理控制入馆、入园的观众数量。通过客流数据实时统计、历史同期对比、客流预测等，为管理决策提供数据支持，提高服务水平。</div>
                        <div className="btn">
                            {/* <div className="btn1" onClick={() => {
                                const token = localDS.get('saasToken')
                                if (!token) {
                                    window.location.href = "/accountLogin"
                                    return
                                }
                                let a = document.createElement("a"); // 创建一个<a></a>标签
                                a.href = `${window.location.origin}/pdf/traffic.pdf`; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
                                a.download = "产品白皮书.pdf"; // 设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
                                a.style.display = "none"; // 障眼法藏起来a标签
                                document.body.appendChild(a); // 将a标签追加到文档对象中
                                a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                                a.remove(); // 一次性的，用完就删除a标签
                            }}>
                                产品白皮书
                            </div> */}
                            {/* <div className="btn1">
                                观看演示
                            </div> */}
                            <div
                                className="btn2"
                                onClick={handleFreeTrial}
                                onMouseOver={() => { setShowTrialTip(true) }}
                                onMouseLeave={() => { setShowTrialTip(false) }}
                            >
                                免费试用
                                {showTrialTip && <div className='hov'>! 注册并申请机构实名认证后即可免费试用</div>}
                            </div>
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="tab" ref={oDiv}>
                <ul>
                    {tab?.map((item, index) => {
                        return (
                            <li key={index}>
                                <span onClick={() => scrollToAnchor(index + 1)} id={`link${index + 1}`}>{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <div className="people" id='1'>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title" >用户群体</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <p className="txt">本产品适用于有客流管理需求的公共文化场馆、文旅景区。</p>
                </ReactWOW>
            </div>
            <div className="section2" id='2' ref={gnzc}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1.1s">
                    <p className="title" id='two'>功能组成</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1s">
                    <div className="functionof">
                        {gongneng.map((item, i) => {
                            return (
                                <div className={cls('item', { active: index === i })} onClick={() => { setIndex(i) }} key={i}>
                                    <div className="moren">
                                        <i className={cls('iconfont', item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        {item.tab.map((item1, i1) => {
                                            return (<p className="txt" key={i1}>{item1}</p>)
                                        })}
                                    </div>
                                    <div className="activeitem">
                                        <p className='top'>
                                            <i className={cls('iconfont', item.icon)} />
                                            <span className='tip'>{item.name}</span>
                                        </p>
                                        <div className="cont">
                                            {item.des}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </ReactWOW>
            </div>
            <div className="section3" id='3' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.5s">
                    <p className="title">产品特点</p>
                </ReactWOW>
                <div className="section3-box">
                    {tedian.map((item, index) => {
                        return (
                            <ReactWOW animation="fadeInUp-little" key={index} duration={item.duration} delay={item.delay}>
                                <div className="item">
                                    <div className="itembox">
                                        <div className="l">
                                            <i className={cls(item.icon, 'iconfont')} />
                                        </div>
                                        <div className="r">
                                            <p className="tip">{item.name}</p>
                                            <p className='txt'>{item.des}</p>
                                        </div>
                                    </div>
                                </div>
                            </ReactWOW>
                        )
                    })}
                </div>

            </div>
            <div className="section4" id='4' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title">产品价格</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <div className="box">
                        <div className="price">
                            <div className="l">
                                <div className="red">
                                    <i className='iconfont icona-31-jiage1' />
                                    <span>4800</span>
                                    元/年
                                </div>
                                {/* <span>首年免费</span> */}

                            </div>
                            {/* <div className="buy">立即购买</div> */}
                        </div>

                    </div>
                </ReactWOW>
            </div>
            <div className="update" id='5'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    {/* <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span> */}
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
