import React, { useState } from 'react';
import Viewer from 'react-viewer';
import { Link } from 'react-router-dom';
import { Button, notification, Space } from 'antd';
import { revokeAuthCompany } from '../../service';
import OrgauthAuthedIcon from '../../assets/orgauth-authed.png';
import OrgauthAuthingIcon from '../../assets/orgauth-authing.png';
import OrgauthRejectIcon from '../../assets/orgauth-reject.png';

import OrgStatusIcon1 from '../../assets/notice/1.png'
import OrgStatusIcon2 from '../../assets/notice/2.png'
import OrgStatusIcon3 from '../../assets/notice/3.png'


// TODO: 目前暂时只有营业执照
const licenseTypeStr = (type) => {
    switch (type) {
        case 1:
            return '机构营业执照';
        case 2:
            return '组织机构代码证';
        case 3:
            return '事业单位法人证书';
        case 4:
            return '社会团体法人证书';
        case 5:
            return '行政执法主体资格证';
        case 6:
            return '其他'
        default:
            return '';
    }
};

const InfoCard = (props) => {
    const { title, info, extra } = props;
    const [imageVisible, setImageVisible] = useState(false);
    return (info ?
        <div>
            <fieldset className="common-fieldset">
                <legend>{title}</legend>
                {extra && (
                    <div className="extra-operations">
                        {extra}
                    </div>
                )}

                <div className="info-row">
                    <div className="info-label">您的身份：</div>
                    <div className="info-value">{
                        info.identity === 1 ? '法人'
                        : info.identity === 2 ? '被授权人'
                        : ""}
                    </div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构名称：</div>
                    <div className="info-value">{info.companyName}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构编号：</div>
                    <div className="info-value">{info.companyCode}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">法人姓名：</div>
                    <div className="info-value">{info.contact}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">法定代表人身份证号：</div>
                    <div className="info-value">{info.identityCard.replace(/^(.{6})(?:\d+)(.{4})$/,  "$1****$2")}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构证件类型：</div>
                    <div className="info-value">{licenseTypeStr(info.licenseType)}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构证件附件：</div>
                    {/* <div className="info-value">{props.identfile}</div> */}
                    <div className="info-value">
                        <img
                            onClick={() => setImageVisible(true)}
                            src={info.thumb}
                            alt="certificate"
                        />
                        <Viewer
                            visible={imageVisible}
                            onClose={() => {
                                setImageVisible(false);
                            }}
                            images={[{ src: info.thumb, alt: '' }]}
                        />
                    </div>
                </div>
            </fieldset>
        </div> : null
    )
}

// eslint-disable-next-line complexity
export const OrgAuthInfo = (props) => {
    const { infos, queryInfo, setForceRender } = props;

    const cancelHandler = () => {
        revokeAuthCompany(infos[0].authCompanyId).then(res => {
            if (res.data.code === 200) {
                notification.success({
                    className: 'custom-antd-notification',
                    message: '撤销成功！',
                    duration: 5
                });
                queryInfo && queryInfo();
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: res.data.msg,
                    duration: 5,
                });
            }
        })
    }
    /**
     * infos 是一个数组
     * 只有一个对象的情况下表示只提交过一次申请
     * 有两个对象时第一个对象表示当前变更申请，第二个表示之前的变更申请
     *
     * approveStatus：
     * 1 审核中
     * 2 通过
     * 3 拒绝
     * 4 撤销
     */
    if (!infos) return null;
    const firstSubmit = infos.length === 1;
    const hasSubmited = infos.length === 2;
    // 第一次申请，审核中
    const firstAuthing = firstSubmit && infos[0].approveStatus === 1;
    // 第一次申请，审核通过
    const firstAuthed = firstSubmit && infos[0].approveStatus === 2;
    // 第一次申请，审核拒绝
    const firstReject = firstSubmit && infos[0].approveStatus === 3;
    // 第一次申请，撤销了
    const firstCancel = firstSubmit && infos[0].approveStatus === 4;

    // 当前申请审核中，前一次审核通过
    const authingAuthed = hasSubmited && infos[0].approveStatus === 1 && infos[1].approveStatus === 2;
    // 当前申请审核通过，前一次也审核通过
    const authedAuthed = hasSubmited && infos[0].approveStatus === 2 && infos[1].approveStatus === 2;
    // 当前申请审核拒绝，前一次审核通过
    const rejectAuthed = hasSubmited && infos[0].approveStatus === 3 && infos[1].approveStatus === 2;
    // 当前申请撤销，前一次审核通过
    const cancelAuthed = hasSubmited && infos[0].approveStatus === 4 && infos[1].approveStatus === 2;

    // 当前申请审核中，前一次审核拒绝
    const authingReject = hasSubmited && infos[0].approveStatus === 1 && infos[1].approveStatus === 3;
    // 当前申请审核通过，前一次审核拒绝
    const authedReject = hasSubmited && infos[0].approveStatus === 2 && infos[1].approveStatus === 3;
    // 当前申请审核拒绝，前一次审核拒绝
    const rejectReject = hasSubmited && infos[0].approveStatus === 3 && infos[1].approveStatus === 3;
    // 当前申请撤销，前一次审核拒绝
    const cancelReject = hasSubmited && infos[0].approveStatus === 4 && infos[1].approveStatus === 3;

    return (
        <div className="orgauthinfobox">
            <div className="notice-img-icon">
                {(firstAuthing || authingReject || authingAuthed) && <img src={OrgStatusIcon1} alt="" />}
                {(firstAuthed || authedAuthed || authedReject || rejectAuthed || cancelAuthed) && <img src={OrgStatusIcon2} alt="" />}
                {(firstReject || rejectReject || cancelReject) && <img src={OrgStatusIcon3} alt="" />}
            </div>

            {(firstAuthing || authingReject) && (<>
                <div className="head">
                    <img src={OrgauthAuthingIcon} alt="" />
                    <div className="infobox">
                        <h1>您申请的机构实名认证已提交成功，待审核</h1>
                    </div>
                </div>

                <InfoCard
                    title="审核中的认证信息"
                    info={infos[0]}
                    extra={
                        <Button
                            onClick={cancelHandler}
                        >
                            撤销
                        </Button>
                    }
                />
            </>)}
            {(firstAuthed || authedAuthed || authedReject) && (<>
                <div className="head">
                    <img src={OrgauthAuthedIcon} alt="" />
                    <div className="infobox">
                        <h1>恭喜！您已完成机构实名认证</h1>
                        <p>
                            实名认证变更，需要核实原有实名认证的身份，并完成变更后的实名认证。
                            <Link to={`/Organization/Change?id=${infos[0].authCompanyId}`}>更换机构认证&gt;&gt;</Link>
                        </p>
                    </div>
                </div>
                <InfoCard
                    title="认证信息"
                    info={infos[0]}
                />
            </>)}
            {firstReject && (<>
                <div className="head">
                    <img src={OrgauthRejectIcon} alt="" />
                    <div className="infobox">
                        <h1>您申请的机构认证信息被拒绝！</h1>
                    </div>
                </div>
                <h3
                    style={{
                        margin: '20px 0'
                    }}
                >
                    拒绝原因：{infos[0].approveOpinion}
                </h3>
                <InfoCard
                    title="拒绝的认证信息"
                    info={infos[0]}
                    extra={
                        <Button
                        type="primary"
                            onClick={() => {
                                setForceRender && setForceRender({
                                    infoIdx: 0,
                                    renderForm: true,
                                })
                            }}
                        >
                            修改
                        </Button>
                    }
                />
            </>)}
            {/* 这个状态不会进入该模块 */}
            {firstCancel && null}

            {authingAuthed && (<>
                <div className="head">
                    <img src={OrgauthAuthingIcon} alt="" />
                    <div className="infobox">
                        <h1>您申请的机构认证信息变更正在审核中，请耐心等待！</h1>
                    </div>
                </div>
                <Space
                    direction="vertical"
                    style={{
                        width: '100%'
                    }}
                >
                    <InfoCard
                        title="变更中认证信息"
                        info={infos[0]}
                        extra={
                            <Button
                                onClick={cancelHandler}
                            >
                                撤销
                            </Button>
                        }
                    />
                    <InfoCard
                        title="变更前认证信息"
                        info={infos[1]}
                    />
                </Space>
            </>)}

            {/* 当前被拒绝或撤销，但已经认证过，显示之前认证的信息 */}
            {(rejectAuthed || cancelAuthed) && (<>
                <div className="head">
                    <img src={OrgauthAuthedIcon} alt="" />
                    <div className="infobox">
                        <h1>恭喜！您已完成机构实名认证</h1>
                        <p>
                            实名认证变更，需要核实原有实名认证的身份，并完成变更后的实名认证。
                            <Link to={`/Organization/Change?id=${infos[1].authCompanyId}`}>更换机构认证&gt;&gt;</Link>
                        </p>
                    </div>
                </div>
                <InfoCard
                    title="认证信息"
                    info={infos[1]}
                />
            </>)}

            {rejectReject && (<>
                <div className="head">
                    <img src={OrgauthRejectIcon} alt="" />
                    <div className="infobox">
                        <h1>您申请的机构认证信息被拒绝！</h1>
                    </div>
                </div>
                <h3
                    style={{
                        margin: '20px 0'
                    }}
                >
                    拒绝原因：{infos[0].approveOpinion}
                </h3>
                <InfoCard
                    title="拒绝的认证信息"
                    info={infos[0]}
                    extra={
                        <Button
                        type="primary"
                            onClick={() => {
                                setForceRender && setForceRender({
                                    infoIdx: 0,
                                    renderForm: true,
                                })
                            }}
                        >
                            修改
                        </Button>
                    }
                />
            </>)}

            {cancelReject && (<>
                <div className="head">
                    <img src={OrgauthRejectIcon} alt="" />
                    <div className="infobox">
                        <h1>您申请的机构认证信息被拒绝！</h1>
                    </div>
                </div>
                <h3
                    style={{
                        margin: '20px 0'
                    }}
                >
                    拒绝原因：{infos[1].approveOpinion}
                </h3>
                <InfoCard
                    title="拒绝的认证信息"
                    info={infos[1]}
                    extra={
                        <Button
                        type="primary"
                            onClick={() => {
                                setForceRender && setForceRender({
                                    infoIdx: 1,
                                    renderForm: true,
                                })
                            }}
                        >
                            修改
                        </Button>
                    }
                />
            </>)}
        </div>
    );
};
