/**
 * @file   积分商城商品订单模块
 * @create tianmu 2021/7/1
 * @update tianmu 2021/7/1
 */
import React from 'react';
import { ScoreLayout } from './components/ScoreLayout';
import { Button } from 'antd';
import './index.scss';

import TEMP_PIC from '../../assets/temp.png';
import { useHistory } from 'react-router-dom';

// 积分商城商品订单组件
export const ScoreProductOrder = () => {
    const history = useHistory()
    return (
        <ScoreLayout>
            <div className="score-product-order">
                <div className="order-info">
                    <div className="order-cover">
                        <img src={TEMP_PIC} alt="" />
                    </div>
                    <div className="order-desc">
                        <div className="order-title">洁玉 质素毛巾单条装礼盒</div>
                        <div className="order-attrs">
                            <div className="line">
                                <div className="p-key">消耗积分</div>
                                <div className="p-value">4500积分</div>
                            </div>
                            <div className="line">
                                <div className="p-key">商品规格</div>
                                <div className="p-value">粉色</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-address">
                    <div className="head">
                        <div className="title">收件人信息</div>
                        <div className="add-address">新增收货地址</div>
                    </div>
                    <div className="address-box">
                        <div className="address-item">
                            <div className="address-ops">
                                <span>编辑</span>
                                <span>删除</span>
                            </div>
                            <div className="address-info">
                            张磊   13270706565   湖北省武汉市武汉市东湖新技术开发区高新大道999号未来科技城海外人才大楼A座18楼277室
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-address-more">更多地址</div>
                <div className="btn">
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push('/ScoreProductOrderSuccess')
                        }}
                    >
                        提交订单
                    </Button>
                </div>
            </div>
        </ScoreLayout>
    );
};
