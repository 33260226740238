
/**
 * @file   常见问题
 * @create macaiqi 2021/07/08
 * @update
 */
import React, { useRef, useEffect, useState, useCallback } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cls from 'classnames'
import Loading from '../../../components/Loading'
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import { queryChannel, queryArchivesByNo } from '../../../service/index'

import './style.scss'

export const CommonProblem = () => {
    const navref = useRef()
    const [firsttab, setFirsttab] = useState();
    const [data, setData] = useState(''); // 文章数据
    const [loading, setLoading] = useState('');
    const [index, setindex] = useState(0)  // 数据加载到第几个，避免后面数据先加载出来打乱顺序
    // 或者左边栏目数据
    useEffect(() => {
        queryChannel('CommonProblem').then(res => {
            setFirsttab(res?.data?.data?.records)
        })
    }, [])
    //    获取全部文章数据
    const getdata = useCallback(() => {
        if (firsttab) {
            const lasttab = firsttab?.[firsttab?.length - 1]?.subChannel // 最后一项数据的数字
            for (let i = 0; i < lasttab?.[lasttab?.length - 1]?.channelNo?.replace(/[^\d]/g, ""); i++) {
                // eslint-disable-next-line no-loop-func
                if (index === i) {
                    setLoading(true)
                    queryArchivesByNo(`commonlink${i + 1}`).then(res => {
                        console.log(i)
                        if (i === 0) {
                            localStorage.setItem('data', `<div id=${`content${i + 1}`}>${res?.data?.data?.content}</div>`)
                        } else {
                            localStorage.setItem('data', (localStorage.getItem('data') || '') + `<div id=${`content${i + 1}`}>${res?.data?.data?.content}</div>`)

                        }
                        setindex(i + 1)
                        if (i === lasttab?.[lasttab?.length - 1]?.channelNo?.replace(/[^\d]/g, "") - 1) {
                            setData(localStorage.getItem('data'))
                            setLoading(false)
                        }
                    })

                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firsttab, index])
    useEffect(() => {
        getdata()
    }, [getdata])
    // 滚动到锚点
    const scrollToAnchor = (index) => {
        // setTabindex(index)
        if (index) {
            // 找到锚点
            let anchorElement = document.getElementById(index);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        }
    }
    const mark = window.location.href.split('mark=')[1]
    useEffect(() => {
        setTimeout(() => {
            scrollToAnchor(mark)
        }, 100)
        document.documentElement.scrollTop = 0
        // 加载完成前不能滚动，会闪屏
        if (loading) {
            document.body.style.overflow = 'hidden'
        }
        window.onscroll = function () {
            // // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            let scrollH = document.documentElement.scrollHeight || document.body.scrollHeight; // 滚动条总高度
            let windowH = document.documentElement.clientHeight || document.body.clientHeight; // 可视区高度
            let scrollBottom = scrollH - scrollT - windowH // 滚动条到底部距离
            // console.log(scrollBottom)

            if (scrollT > 70) {
                navref.current.classList.add('fixed')
                navref.current.style.height = `100vh`

            } else {
                navref.current.classList.remove('fixed')
                navref.current.style.height = 'unset'
            }
            if (scrollBottom <= 200) {
                navref.current.style.height = `calc(100vh - ${210 - scrollBottom}px)`
            }

            //   滚动的时候对应的高亮
            if (scrollT >= document.getElementById('content1')?.offsetTop) {
                for (let i = 1; i <= index; i++) {
                    // 10的误差
                    if (scrollT >= document.getElementById(`content${i}`)?.offsetTop) {
                        document.getElementById(`link${i}`)?.classList?.add('link');
                        for (let j = 1; j <= index; j++) {
                            // console.log(i, index, j)
                            if (i !== j) {
                                document.getElementById(`link${j}`)?.classList?.remove('link');
                            }
                        }
                    }

                }
            } else {
                for (let j = 1; j <= index + 1; j++) {
                    document.getElementById(`link${j}`)?.classList?.remove('link');
                }
            }

        }

    }, [mark, index, loading]);

    return (<div className='help-page'>
        <IntroHeader small={true} />
        <div className="helpcenter">
            <div className="allcenter">
                <div className="l" ref={navref}>
                    <div className="title">常见问题</div>
                    <ul className="nav-l">
                        {
                            firsttab?.map((item1, index1) => {
                                return (
                                    <li className="first-li" id={`link${item1.id}`} mark={item1.id} key={index1} onClick={() => scrollToAnchor(item1.id)}>
                                        <i className={cls("iconfont", { 'iconhuodong': index1 === 0, 'iconvisit': index1 === 1, 'iconrenzheng': index1 === 2, 'icontianmaoshiyongfuwu': index1 === 3, 'icondinggou': index1 === 4, 'iconshensu': index1 === 5 })} />{item1.channelName}
                                        {item1?.subChannel?.map((item2, index2) => {
                                            return (
                                                <div className="sec-ul" key={index2}>
                                                    <span className="" id={`link${item2.channelNo.replace(/[^\d]/g, "")}`} mark={item2.channelNo} onClick={(e) => {
                                                        e.stopPropagation();
                                                        scrollToAnchor(`content${item2.channelNo.replace(/[^\d]/g, "")}`)
                                                    }}>
                                                        <span className="line">-</span>{item2.channelName}
                                                    </span>
                                                </div>
                                            )
                                        })}

                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
                <div className="r">
                    {loading ? <Loading /> :
                        <div className="contbox" id='contbox' dangerouslySetInnerHTML={{ __html: data || '' }} />
                    }

                </div>
            </div>
        </div>

        <IntroFooter />
    </div>
    )
}


