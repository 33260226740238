/**
 * @file   瑞鹿云用户服务协议
 * @create macaiqi 2021/09/07
 * @update
 */
import React, { useEffect, useState, useCallback } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import Loading from '../../../components/Loading'
import { queryChannel, queryArchivesByNo, queryChannelInfo } from '../../../service/index'
import './style.scss'


export const WapServiceItems = () => {
    const [firsttab, setFirsttab] = useState();
    const [data, setData] = useState(''); // 文章数据
    const [loading, setLoading] = useState('');
    const [index, setindex] = useState(0)  // 数据加载到第几个，避免后面数据先加载出来打乱顺序
    const [topdata, setTopdata] = useState() // 顶部数据，栏目标题和描述
    // 获取左边栏目数据
    useEffect(() => {
        queryChannel('ServiceItems').then(res => {
            setFirsttab(res?.data?.data?.records)
        })
        queryChannelInfo('ServiceItems').then(res => {
            setTopdata(res?.data?.data)
        })
    }, [])
    //    获取全部文章数据
    const getdata = useCallback(() => {
        if (firsttab) {
            const lasttab = firsttab?.[firsttab?.length - 1]?.channelNo?.replace(/[^\d]/g, "") // 最后一项数字
            for (let i = 0; i < lasttab; i++) {
                // eslint-disable-next-line no-loop-func
                if (index === i) {
                    setLoading(true)
                    queryArchivesByNo(`service${i + 1}`).then(res => {
                        console.log(i)
                        if (i === 0) {
                            localStorage.setItem('data', `<h3 id=${`content${i + 1}`}>${res?.data?.data?.title}</h3>${res?.data?.data?.content}`)
                        } else {
                            localStorage.setItem('data', (localStorage.getItem('data') || '') + `<h3 id=${`content${i + 1}`}>${res?.data?.data?.title}</h3>${res?.data?.data?.content}`)

                        }
                        setindex(i + 1)
                        if (i === lasttab - 1) {
                            setData(localStorage.getItem('data'))
                            setLoading(false)
                        }
                    })

                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firsttab, index])
    useEffect(() => {
        getdata()
    }, [getdata])





    return (<>
        {/* <IntroHeader small={true} /> */}
        <div className="mobile-serviceitem">
            <div className="text">
                <h2>
                    {topdata?.channelName}
                </h2>
                <div className="contbox" id='contbox' dangerouslySetInnerHTML={{ __html: topdata?.description || '' }} />

                {loading ? <Loading /> :
                    <div className="contbox" id='contbox' dangerouslySetInnerHTML={{ __html: data || '' }} />
                }
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
