/**
 * @file   产品试用表单模块
 * @create tianmu 2021/3/23
 * @update tianmu 2021/3/23
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row, Col, notification, Input, Modal, Alert } from 'antd';
import {
    buyProducts,
    queryProductById
} from '../../../../service';
import { useHistory } from 'react-router';
import UnderCarriageIcon from '../../../../assets/notice/5.png'
import './index.scss';

 // 试用订单
 export const TrialOrderForm = (props) => {
    const { id } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [productList, setProductList] = useState([]);
    const [underCarriage, setUndercarriage] = useState(false);

    // 试用弹窗相关状态
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [remark, setRemark] = useState("");

    // 表单提交成功
    const onSubmitClick = async () => {
        setLoading(true);
        buyProducts({
            remark: remark,
            trialDetailList: [{
                // 试用类型，1:套件，2:产品，3:资源，4:服务
                trialType: 2,
                prodId: id,
                prodName: productList?.[0]?.productName || ""
            }]
        })
        .then(res => {
            if (res.data.code === 200) {
                notification.success({
                    className: 'custom-antd-notification',
                    message: "成功提交试用申请！",
                    duration: 5,
                })
                setRemark("");
                history.push(`/TrialOrderSuccess?trialId=${res.data.data}`)
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: res.data.msg,
                    duration: 5,
                })
            }
            setLoading(false);
            setShowBuyModal(false);
        })
    };

    // 取消试用表单
    const handleCancel = () => {
        setRemark("");
        setLoading(false);
        setShowBuyModal(false);
    };

    const fetchProductList = useCallback(async () => {
        const res = await queryProductById(id);
        if (res.data.code === 200) {
            const p = res.data.data;
            if (!p || !p.price) return [];
            return [
                {
                    ...p,
                }
            ];
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return [];
        }
    }, [id]);

    useEffect(() => {
        // console.log("use effect")
        const fetchData = async () => {
            const pLst = await fetchProductList();
            // const rLst = await fetchResourceList();
            // const sLst = await fetchServiceList();
            // 如果产品下架，则禁用页面交互，显示下架
            if (pLst.length > 0 && pLst[0].status !== 1) {
                setUndercarriage(true)
            }
            setProductList(pLst);
            // setResourceList(rLst);
            // setServiceList(sLst);
        };
        fetchData();
    }, [fetchProductList]);

    return (<>
        <Alert
            message={<>
                <span>试用时长：30天</span>
                <br/>
                <span>试用说明：用户在试用期间可体验和试用{productList?.[0]?.productName}的全部功能。</span>
            </>}
            type="info"
            showIcon
            style={{
                marginBottom: 10
            }}
        />
        <div className="product-order-form">
            {underCarriage && (
                <div className="notice-img-icon">
                    <img src={UnderCarriageIcon} alt="" />
                </div>
            )}
            {productList.map((product, idx) => (
                <div className="order-product-item" key={product.productId}>
                    <Row gutter={[0, 20]}>
                        <Col className="order-product-title" span={2}>
                            产品名称：
                        </Col>
                        <Col className="order-product-title" span={22}>
                            {product?.productName}
                        </Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col className="order-product-desc" span={2}>
                            产品介绍：
                        </Col>
                        <Col className="order-product-desc" span={22}>
                            {product?.description}
                        </Col>
                    </Row>
                </div>
            ))}
            {/* {serviceList?.length > 0} */}
            <div className="order-form-footer">
                <Button
                    className="order-form-footer-btns"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    返回
                </Button>
                <Button
                    className="order-form-footer-btns"
                    type="primary"
                    onClick={() => {
                        const { isOrdered, isTrial, diff, expireTime } = productList[0];
                        // 已购买（覆盖之前的试用）
                        const hasBuy = isOrdered && !!isTrial === false;
                        // 已试用
                        const hasTrial = isOrdered && !!isTrial;
                        // 是否到期
                        const isExpire = expireTime && (new Date() - new Date(expireTime)) >= 0;
                        // const isExpire = diff < 0;
                        // 购买过或试用未到期时，不能添加试用
                        if (hasBuy) {
                            notification.warn({
                                className: 'custom-antd-notification',
                                message: '试用失败',
                                description: '试用申请提交失败，您已购买过该产品！',
                                duration: 5
                            })
                            return;
                        }
                        if (hasTrial && !isExpire) {
                            notification.warn({
                                className: 'custom-antd-notification',
                                description: '试用申请提交失败，您正在试用该产品！',
                                duration: 5
                            })
                            return;
                        }
                        setShowBuyModal(true);
                    }}
                    disabled={underCarriage}
                >
                    立即试用
                </Button>
            </div>
            <Modal
                title="试用申请"
                centered
                width={700}
                visible={showBuyModal}
                confirmLoading={loading}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={onSubmitClick}>
                        确定
                    </Button>
                ]}
            >
                <Input.TextArea
                    value={remark}
                    placeholder="请输入申请试用"
                    onChange={(e) => {
                        setRemark(e.target.value);
                    }}
                />
            </Modal>
        </div>
    </>);
 };
