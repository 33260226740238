/**
 * @file   产品介绍模块
 * @create tianmu 2021/3/10
 * @update tianmu 2021/3/10
 */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Layout, notification, Modal, } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToTrialList } from './store';
import {
    queryKitById,
    queryMallResourceList,
    queryMallServiceList,
    queryProductById,
    queryProductInUseList,
    queryProductList,
    queryResourceById,
    queryServiceById
} from '../../service';
import './index.scss';
import { ProductLayout } from './components/ProductLayout';
import { doAfterOrgAuth } from '../../utils/orgAuthUtils';
import { searchStrToObj } from '../../utils';
import UnderCarriageIcon from '../../assets/notice/5.png'
import { showEditNameTip, showOrgAuthTip } from '../../store/globStore';
import { getUserDetail } from '../login/store'

// eslint-disable-next-line complexity
const renderOperations = ({ typeStr, data, status, operations }) => {
    if (!data) return null;
    const { tryHandler, buyHandler, buyServiceHandler, buyResourceHandler, showNotAuthed, showEditName } = operations;
    const { isOrdered, isTrial, diff, expireTime } = data;
    const { hasServices, hasResources } = status;
    const cacheUser = getUserDetail();

    // 已购买（覆盖之前的试用）
    const hasBuy = isOrdered && !!isTrial === false;
    // 已试用
    const hasTrial = isOrdered && !!isTrial;
    // 未购买 未试用
    const unBuyUnTrial = !!isOrdered === false && !!isTrial === false;
    // 是否到期
    const isExpire = expireTime && (new Date() - new Date(expireTime)) >= 0;
    // const isExpire = diff < 0;

    // 业务产品
    const isBusinessProduct = typeStr === 'product' && data.type === 1;
    // 基础产品
    const isBasicProduct = typeStr === 'product' && data.type === 2;
    // 试用产品
    const isTrialProduct = typeStr === 'product' && data.type === 3;

    // 业务套件
    const isBusinessKit = typeStr === 'kit' && data.type === 1;
    // 基础套件
    const isBasicKit = typeStr === 'kit' && data.type === 2;
    // 试用套件
    const isTrialKit = typeStr === 'kit' && data.type === 3;
    // 资源
    const isResource = typeStr === 'resource';
    // 服务
    const isService = typeStr === 'service';
    // 服务已使用
    const isComplete = !!data.isComplete;

    // 产品已下架
    const isUndercarriage = data.status === 0;

    const TryBtn = (
        <Button
            type="primary"
            onClick={() => {
                if (cacheUser.randomName) {
                    showEditName()
                } else {
                    doAfterOrgAuth(tryHandler, showNotAuthed)
                }

            }}
        >
            免费试用
        </Button>
    );
    const BuyBtn = (
        <Button
        type="primary"
            onClick={() => {
                if (cacheUser.randomName) {
                    showEditName()
                } else {
                    doAfterOrgAuth(buyHandler, showNotAuthed)
                }

            }}
        >
            立即订购
        </Button>
    );
    const BuyServiceBtn = (
        <Button
        type="primary"
            onClick={() => {
                if (cacheUser.randomName) {
                    showEditName()
                } else {
                    doAfterOrgAuth(buyServiceHandler, showNotAuthed)
                }

            }}
        >
            购买服务
        </Button>
    );
    const BuyResourceBtn = (
        <Button
        type="primary"
            onClick={() => {
                if (cacheUser.randomName) {
                    showEditName()
                } else {
                    doAfterOrgAuth(buyResourceHandler, showNotAuthed)
                }
            }}
        >
            购买资源
        </Button>
    );
    // TODO: 退订产品
    // const UnsubscribeBtn = <Button type="primary" onClick={() => {}}>退订</Button>;
    const SubscribeBtn = (
        <Button
        type="primary"
            onClick={() => {
                if (cacheUser.randomName) {
                    showEditName()
                } else {
                    doAfterOrgAuth(buyHandler, showNotAuthed)
                }
            }}
        >
            续订
        </Button>
    );

    /**
     * 产品上架状态运营平台放，下架是我的产品里面点击续订时如果那个产品已经下架，就显示下架，立即订购那些按钮都不显示了，如果没有下架就是订购的那个逻辑
     * 主要是针对之前购买过或者试用过的产品，我的产品列表按钮的逻辑不管是否下架都一样，说明下架的产品还可以点击续订或者订购按钮，这时候在续订或订购页面给出相应提示
     */
    if (isUndercarriage) {
        return (
            <div className="notice-img-icon">
                <img src={UnderCarriageIcon} alt="" />
            </div>
        );
    }
    return (
        <div
            className='product-operations right'
        >
            {/* 业务产品未购买也未试用 */}
            {isBusinessProduct && unBuyUnTrial && (<>
                {TryBtn}
                {BuyBtn}
            </>)}
            {/* 业务产品试用中 */}
            {isBusinessProduct && hasTrial && !isExpire && BuyBtn}
            {/* 业务产品试用到期 */}
            {isBusinessProduct && hasTrial && isExpire && (<>
                {TryBtn}
                {BuyBtn}
            </>)}
            {/* 业务产品已订购且未到期 */}
            {isBusinessProduct && hasBuy && !isExpire && (<>
                {hasServices && BuyServiceBtn}
                {hasResources && BuyResourceBtn}
                {SubscribeBtn}
                {/* 2021.5.8 退订功能改到订单中，不再显示退订按钮 */}
                {/* {UnsubscribeBtn} */}
            </>)}
            {/* 业务产品已订购但已到期 */}
            {isBusinessProduct && hasBuy && isExpire && SubscribeBtn}
            {/* 试用产品  试用到期后可再次试用，未到期不可试用 */}
            {isTrialProduct && hasTrial && isExpire && TryBtn}
            {isTrialProduct && !hasTrial && TryBtn}
            {/* 基础产品暂不支持申请试用、购买，购买或者申请试用产品审核通过后自动生成相关脚本 */}
            {isBasicProduct && null}

            {/* 业务套件未购买未试用 */}
            {isBusinessKit && unBuyUnTrial && BuyBtn}
            {/* 业务套件购买未到期 */}
            {isBusinessKit && hasBuy && !isExpire && (<>
                {SubscribeBtn}
                {/* 套件不能退订，故不显示退订按钮，每次仅能购买一套 */}
                {/* {UnsubscribeBtn} */}
                {/* 4.49：套件现在只有立即订购、续订，不单独购买服务、资源了 */}
            </>)}
            {/* 业务套件购买已到期 */}
            {isBusinessKit && hasBuy && isExpire && SubscribeBtn}
            {isBasicKit && null}
            {/* 没有试用套件，套件现不可试用 */}
            {isTrialKit && null}

            {isResource && hasBuy && SubscribeBtn}
            {isResource && !hasBuy && BuyBtn}

            {isService && hasBuy && !isComplete && (<>
                {/* 服务订购过且未使用时不能再续订 */}
                {/* 2021.5.8 退订功能改到订单中，不再显示退订按钮 */}
                {/* {UnsubscribeBtn} */}
            </>)}
            {isService && hasBuy && isComplete && SubscribeBtn}
            {isService && !hasBuy && BuyBtn}
        </div>
    );
};

// 产品介绍组件
export const ProductIntro = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const [productList, setProductList] = useState([]);
    const [hasServices, setHasServices] = useState(false);
    const [hasResources, setHasResources] = useState(false);
    const [detail, setDetail] = useState(null);
    const [typeStr, setTypeStr] = useState('');
    const hasOrderedProduct = productList?.length > 0 && productList.filter(i =>
        // i.diff >= 0
        (new Date() - new Date(i.expireTime)) < 0

    );

    const searchItems = useMemo(() => {
        return searchStrToObj(location.search) || {};
    }, [location.search])

    const fetchProductDetail = async (id) => {
        if (!id) return;
        const res = await queryProductById(id);
        const relevantServiceRes = await queryMallServiceList({
            searchKey: "productId",
            searchValue: id,
            searchMap: {
                // 只显示已上架的资源（未报价的不会上架）
                // equalsMap里的是json string格式
                // equalsMap: "{\"status\": 1}"
                // 2021/8/11 改成了键值对map形式
                equalsMap: {
                    status: 1
                }
            },
            pageNum: 1,
            pageSize: 100
        });
        const relevantResourceRes = await queryMallResourceList({
            searchKey: "productId",
            searchValue: id,
            searchMap: {
                // 只显示已上架的资源（未报价的不会上架）
                // equalsMap里的是json string格式
                // equalsMap: "{\"status\": 1}"
                // 2021/8/11 改成了键值对map形式
                equalsMap: {
                    status: 1
                }
            },
            pageNum: 1,
            pageSize: 100
        });
        setHasServices(Array.isArray(relevantServiceRes.data?.data?.list)
                        && relevantServiceRes.data?.data?.list.length > 0);
        setHasResources(Array.isArray(relevantResourceRes.data?.data?.list)
                        && relevantResourceRes.data?.data?.list.length > 0);
        setDetail(res?.data?.data)
    }

    const fetchKitDetail = async (id) => {
        if (!id) return;
        const res = await queryKitById(id);
        setDetail(res?.data?.data)
    }

    const fetchServiceDetail = async (id) => {
        if (!id) return;
        const res = await queryServiceById(id);
        setDetail(res?.data?.data)
    }

    const fetchResourceDetail = async (id) => {
        if (!id) return;
        const res = await queryResourceById(id);
        setDetail(res?.data?.data)
    }

    // 当机构认证未通过是显示提示
    const showNotAuthed = () => {
        dispatch(showOrgAuthTip())
    }

    // 当帐号名未修改时显示提示
    const showEditName = () => {
        dispatch(showEditNameTip())
    }

    useEffect(() => {
        const { id, type } = searchItems;
        if (!(type && id)) {
            history.push('/MyProduct');
            return;
        }
        // 设置当前显示的是什么的详情，根据不同类型，渲染的数据也不同
        setTypeStr(type)
        // 获取当前是否购买过产品
        queryProductList().then((res) => {
            const lst = res?.data?.data;
            setProductList(lst || []);
        });
        if (type === 'product') {
            fetchProductDetail(id);
        } else if (type === 'kit') {
            fetchKitDetail(id);
        } else if (type === 'service') {
            fetchServiceDetail(id);
        } else if (type === 'resource') {
            fetchResourceDetail(id);
        }
    }, [history, searchItems]);

    // 添加试用
    const tryHandler = () => {
        history.push(`/ProductOrder?type=trial&id=${detail[typeStr + "Id"]}`)
    };

    // 订购
    const buyHandler = async () => {
        const { id, type } = searchItems;
        if (type === 'service' || type === 'resource') {
            // const res = await queryProductInUseList()
            // // 查询是否所有购买的产品都过期了
            // if (Array.isArray(res.data?.data) && res.data.data.length === 0 ) {
            //     Modal.confirm({
            //         title: '您购买的产品均已到期，是否继续购买?',
            //         icon: <ExclamationCircleOutlined />,
            //         onOk() {
            //             history.push(`/ProductOrder?type=${type}&id=${id}`);
            //         },
            //     });
            //     return;
            // }

            // console.log('inner hasordered: ', hasOrderedProduct)
            if (!hasOrderedProduct) {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: '订购失败',
                    description: '请先购买或试用产品再购买资源和服务',
                    duration: 5
                });
                return;
            }
        }
        if (typeStr === 'kit') {
            const { details } = detail;
            if (!(Array.isArray(details) && details.length > 0)) return;
            // 套件中不包含产品，而且没有购买过产品
            if (!details.some(i => i.type === 2) && !hasOrderedProduct) {
                // type=1套件2 产品 3资源 4服务
                notification.warn({
                    className: 'custom-antd-notification',
                    message: '订购失败',
                    description: '请先购买或试用产品再购买该套件',
                    duration: 5
                });
                return;
            }
        }
        history.push(`/ProductOrder?type=${type}&id=${id}`);
    };

    // 购买服务
    const buyServiceHandler = () => {
        const { id } = searchItems;
        // 需要根据当前产品id查询出关联的服务再进行购买
        if (hasOrderedProduct) {
            history.push(`/ProductOrder?type=applyService&id=${id}`);
        } else {
            const args = {
                className: 'custom-antd-notification',
                message: '订购失败',
                description: '请先购买或试用产品再购买资源和服务',
                duration: 5
            };
            notification.warn(args);
        }
    };

    // 购买资源
    const buyResourceHandler = () => {
        const { id } = searchItems;
        // 需要根据当前产品id查询出关联的资源再进行购买
        if (hasOrderedProduct) {
            history.push(`/ProductOrder?type=applyResource&id=${id}`);
        } else {
            const args = {
                className: 'custom-antd-notification',
                message: '订购失败',
                description: '请先购买或试用产品再购买资源和服务',
                duration: 5
            };
            notification.warn(args);
        }
    };

    return (
        <ProductLayout title={
            typeStr === 'product' ?
            '产品介绍' :
            typeStr === 'service' ?
            '服务介绍' :
            typeStr === 'resource' ?
            '资源介绍' :
            typeStr === 'kit' ?
            '套件介绍' :
            ''
        }>
            <div
                style={{
                    position: 'relative',
                    marginBottom: '10px',
                    padding: '30px 30px 60px 30px',
                    background: '#fff',
                    borderRadius: '5px'
                }}
            >
                <h3
                    style={{
                        color: 'rgb(59, 145, 109)',
                        fontSize: '16px',
                        marginBottom: '10px',
                        fontWeight: 'bold'
                    }}
                >
                    {detail?.[typeStr + 'Name']}
                    <span
                        style={{
                            float: 'right',
                            fontSize: 14,
                            cursor: 'pointer'
                        }}
                        onClick={() => history.goBack()}
                    >
                        &lt; 返回
                    </span>
                </h3>
                <p>{detail?.description}</p>
                {detail?.url && (
                    <p style={{marginTop: 15}}>详细介绍：<a href={detail.url} target="_blank" rel="noopener noreferrer">{detail.url}</a></p>
                )}
                <div>
                    {renderOperations({
                        typeStr,
                        data: detail,
                        status: {
                            hasResources,
                            hasServices
                        },
                        operations: {
                            tryHandler,
                            buyHandler,
                            buyServiceHandler,
                            buyResourceHandler,
                            showNotAuthed,
                            showEditName
                        }
                    })}
                </div>
            </div>

            <Layout
                className="page-content"
                style={{
                    minHeight: 'calc(100vh - 160px - 64px - 2 *20px - 50px)'
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: detail?.introduce }} />
            </Layout>
        </ProductLayout>
    );
};
