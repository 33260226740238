/**
 * @file   活动预约系统介绍页面手机版
 * @create macaiqi 2021/07/29
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import cls from 'classnames'
import './style.scss'

/**
 * @description 活动预约系统组件
 */
export const WapActivityAppointment = () => {
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    const settings = {
        arrows: false,
        dots: false,
        // infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "3rem",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    const sec3 = [
        { title: '适应多类型活动管理', txt: '可适应学术讲座、研学交流及社教课程等不同类型活动的全流程管理。' },
        { title: '全面的预约终端支持', txt: '在移动端（微信小程序）和Web端均提供入口，让公众预约便捷高效。' },
        { title: '快捷多样的验票方式', txt: '支持扫码签到、自助签到等多种验票方式，方便观众快速签到并参加活动。' },
        { title: '多维度观众统计分析', txt: '建立观众画像，提供活动预约、签到、营收等多维度统计分析，为提升活动质量提供决策依据。' },
    ]
    const [down, setDown] = useState()
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        if (anchorName || anchorName === 0) {
            const height = document.getElementById(anchorName).offsetTop - 40; // 计算需要向上移动的距离
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        }
    };
    const banner = useRef()
    const activeref = useRef()
    const { search } = useLocation()
    useEffect(() => {
        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // 滚动超过banner后nav吸顶
            if (scrollT >= banner.current.clientHeight + banner.current.offsetTop) {
                activeref.current?.classList.add('active');
            } else {
                activeref.current?.classList.remove('active');
            }
            // 滚动到对应点，对应nav高亮
            for (let i = 0; i < tab.length; i++) {

                if (scrollT >= document.getElementById(`item${i}`).offsetTop - 40) {
                    document.getElementById(`link${i}`).classList.add('active');
                    for (let j = 0; j < tab.length; j++) {
                        if (i !== j) {
                            document.getElementById(`link${j}`).classList.remove('active');
                        }
                    }
                }

            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(`item${search.split('?')[1]}`)
        }
    }, [tab.length, search]);

    return (<>
        <IntroHeader />
        <div className="mobile-content" ref={activeref}>
            <div className="banner active" ref={banner}>
                <div className="content">
                    <p className="big">活动预约系统</p>
                    <div className="cont">以学术讲座、研学交流及社教课程等活动管理业务流程为主线，实现活动发布、预约报名、活动签到、数据统计等全流程的标准化、数字化、智慧化管理。</div>
                    <div className="btnbox">
                        {/* <div className="btn btn1">
                            观看演示
                        </div> */}
                        {/* <div className=" btn btn2">
                            免费体验
                        </div> */}
                    </div>

                </div>
            </div>
            <div className="tab" >
                <ul>
                    {tab.map((item, index) => {
                        return (
                            <li key={index} id={`link${index}`} onClick={() => {
                                scrollToAnchor(`item${index}`)
                            }}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="people" id='item0'>
                <p className="title" >用户群体</p>
                <p className="txt">本产品适用于对学术讲座、研学交流及社教课程等活动预约有需求的公共文化场馆、文旅景区的社教、宣传部门及相关工作人员。</p>
            </div>
            <div className="section2" id='item1' >
                <p className="title" >功能组成</p>
                <div className="functionof">
                    <Slider {...settings} key={Math.random()} >

                        <div className="itembox">
                            <div className="item">
                                <i className='i2' />
                                <p className='tip'>活动预约</p>
                                <p className="txt">微信小程序预约</p>
                                <p className="txt">Web端预约</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i3' />
                                <p className='tip'>活动签到</p>
                                <p className="txt">扫码签到</p>
                                <p className="txt">自助签到</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i1' />
                                <p className='tip'>活动管理</p>
                                <p className="txt">活动发布</p>
                                <p className="txt">预约观众档案管理</p>
                                <p className="txt">数据统计</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i2' />
                                <p className='tip'>活动预约</p>
                                <p className="txt">微信小程序预约</p>
                                <p className="txt">Web端预约</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i3' />
                                <p className='tip'>活动签到</p>
                                <p className="txt">扫码签到</p>
                                <p className="txt">自助签到</p>
                            </div>
                        </div>
                        <div className="itembox">
                            <div className="item">
                                <i className='i1' />
                                <p className='tip'>活动管理</p>
                                <p className="txt">活动发布</p>
                                <p className="txt">预约观众档案管理</p>
                                <p className="txt">数据统计</p>
                            </div>
                        </div>
                    </Slider>
                    <div className="cont">
                        为观众提供便捷的活动预约服务，支持在微信小程序端、Web端进行预约， 最大化地为观众参与活动提供方便，让预约省时、省力、更省心。主要功能包括微信小程序预约和Web端预约。
                    </div>
                </div>
            </div>
            <div className="section3" id='item2'>
                <p className="title">产品特点</p>
                {
                    sec3.map((item, index) => {
                        return (
                            <div className={cls("item")} key={index}>
                                <div className="box">
                                    <div className="l">
                                        <i className={`icon${index + 1}`} />
                                        {item.title}
                                    </div>
                                    <div className="down" />
                                </div>
                                <div className="text">
                                    {item.txt}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="price" id='item3'>
                <p className="title">产品价格</p>
                <div className="num">
                    3800
                    <span>元/年</span>
                </div>
            </div>
            <div className="update" id='item4'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span>
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
