/**
 * @file   我的订单模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Button, Input, Table, Form, DatePicker, Modal, notification } from 'antd';
import {
    ExclamationCircleOutlined
} from '@ant-design/icons'
import { cancelOrder, cancelRefund, queryCustomerOrderById, queryCustomerOrderList, queryRefundByOrderId } from '../../service';
import { searchStrToObj, toFixedNumStr } from '../../utils';
import './index.scss';

// 默认页面大小
const PAGE_SIZE = 10;
const { RangePicker } = DatePicker;

const statusStr = (data) => {
    const { status, initId } = data;
    switch (status) {
        case 0:
            return <span className="state-waring">待支付</span>;
        case 1:
            return <span className="state-info">支付中</span>;
        case 2:
            return <span className="state-cancel">已取消</span>;
        case 3:
            if (initId) {
                // 审核通过 + 初始化成功
                return <span className="state-success">已支付</span>;
            } else {
                return <span className="state-waring">审批中</span>;
            }
        case 4:
            return <span className="state-waring">退单中</span>;
        case 5:
            return <span className="state-cancel">已退单</span>;
        default:
            return '';
    }
};


// 我的订单页面组件
export const MyOrder = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const location = useLocation();
    const o = useMemo(() => searchStrToObj(location.search), [location.search])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: PAGE_SIZE
    });
    const [form] = Form.useForm();
    const [dataEx, setDataEx] = useState({});
    const [statusFilter, setStatusFilter] = useState(
        o?.status ? parseInt(o.status, 10) : 9
    );

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        setLoading(true);
        return queryCustomerOrderList(params).then((res) => {
            const listData = res.data.data.records.map((item) => {
                return {
                    key: item.orderId,
                    orderNo: item,
                    orderTitle: item.orderTitle,
                    orderCost: toFixedNumStr(item.orderCost, 2),
                    createTime: item.createTime,
                    status: item,
                    operation: item
                };
            });
            setPagination({
                current: params.pageNum,
                pageSize: params.pageSize
                // total: res.data.data.total,
            });
            setDataSource(listData);
            setLoading(false);
        });
    }, []);

    const handleCancelUnsubscribe = useCallback(async (orderId) => {
        const res = await queryRefundByOrderId(orderId)
        const refundRecords = res?.data?.data;
        let rec = null;
        if (Array.isArray(refundRecords) && refundRecords.length > 0) {
            refundRecords.forEach(i => {
                const curTime = new Date(i.createTime);
                if (rec) {
                    const latestTime = new Date(rec.createTime);
                    if (curTime > latestTime) {
                        rec = i;
                    }
                } else {
                    rec = i;
                }
            })
        }
        // if (orderDetail?.kit) {
        //     notification.warn({
        //         className: 'custom-antd-notification',
        //         message:'套件不能取消退单！',
        //         duration: 5,
        //     })
        // }
        // 退单状态（0待审核；1审核中；2取消；3通过；4拒绝）
        if (rec && rec?.status === 0) {
            const res2 = await cancelRefund(rec?.refundId);
            if (res2.data.code === 200) {
                notification.success({
                    className: 'custom-antd-notification',
                    message:'取消退单成功！',
                    duration: 5,
                })

                fetchData({
                    entity: {
                        status: statusFilter !== 9 ? statusFilter : undefined // 不是显示全部时，传递对应的status数字
                    },
                    param: {
                        pageNum: 1,
                        pageSize: PAGE_SIZE
                    }
                });
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message:res.data.msg,
                    duration: 5,
                })
            }
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: "退单申请审核中！",
                duration: 5,
            })
        }
    }, [fetchData, statusFilter])

    const handleCancelOrder = useCallback(async (orderId) => {
        const res = await cancelOrder({orderId})
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message:'取消订单成功！',
                duration: 5,
            })

            fetchData({
                entity: {
                    status: statusFilter !== 9 ? statusFilter : undefined // 不是显示全部时，传递对应的status数字
                },
                param: {
                    pageNum: 1,
                    pageSize: PAGE_SIZE
                }
            });
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message:res.data.msg,
                duration: 5,
            })
        }
    }, [fetchData, statusFilter])
    /**
     * @description 确认取消订单弹框
     * @param {String} options 待取消订单的信息
     */
    const confirmCancel = useCallback((options) => {
        // 未支付时可以取消订单
        const canCancelOrder = options.status === 0 || options.status === 1
        // 可以撤销退单申请
        const canCancelUnsubscribe = options.status === 4;

        Modal.confirm({
            title: canCancelOrder
                ? '确认取消该订单吗？'
                : canCancelUnsubscribe
                ? '确认取消该退单申请吗？' : '',
            icon: <ExclamationCircleOutlined />,
            content: "产品：" + options.orderTitle,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => {
                if (canCancelUnsubscribe) {
                    handleCancelUnsubscribe(options.orderId)
                } else if (canCancelOrder) {
                    handleCancelOrder(options.orderId)
                }
            },
        });
    }, [handleCancelOrder, handleCancelUnsubscribe])


    useEffect(() => {
        fetchData({
            entity: {
                status: statusFilter !== 9 ? statusFilter : undefined // 不是显示全部时，传递对应的status数字
            },
            param: {
                pageNum: 1,
                pageSize: PAGE_SIZE
            }
        });
    }, [fetchData, statusFilter]);

    const columns = [
        {
            title: '订单号',
            dataIndex: 'orderNo',
            render: (o) => (
                o.orderNo
            )
        },
        {
            title: '订单名',
            dataIndex: 'orderTitle',
            // render: (p) => <Link to="/MyProduct">{p}</Link>
        },
        {
            title: '金额（元）',
            dataIndex: 'orderCost'
        },
        {
            title: '下单时间',
            dataIndex: 'createTime'
        },
        {
            title: '支付状态',
            dataIndex: 'status',
            render: (data) => statusStr(data)
        },
        {
            title: '操作',
            dataIndex: 'operation',
            render: (data) => {
                // 未申请发票的情况下
                // 1. 支付完成且初始化完成时，可以申请发票
                // 2. 退单 且退单金额小于订单金额，可以申请发票
                const canApplyInvoice = !data.isInvoiced &&
                                        (
                                            (data.status === 3 && data.initId) ||
                                            (
                                                // (data.status === 5 || data.status === 4)
                                                data.status === 5
                                                && data.refundCost < data.orderCost
                                            )
                                        )
                // 未支付时可以取消订单
                const canCancelOrder = data.status === 0 || data.status === 1
                // 支付完成且初始化完成时可以选择退订
                const canUnsubscribe = data.status === 3 &&
                                        !data.expired && // 产品过期后不能再退订订单
                                        data.initId && // 产品初始化过后才能退订
                                        !!data.canRefund // 后台判断可退订
                // 可以撤销退单申请
                const canCancelUnsubscribe = data.status === 4;
                return (<>
                    {canApplyInvoice && (
                        <Link
                            to={`/MyOrder/ApplyInvoice?orderId=${data.orderId}`}
                            style={{
                                marginRight: 10,
                            }}
                        >
                            申请发票
                        </Link>
                    )}
                    <Link
                        to={`/MyOrder/OrderInvoiceHistory?orderId=${data.orderId}`}
                        style={{
                            marginRight: 10,
                        }}
                    >
                        查看明细
                    </Link>
                    {canCancelOrder && (
                        <a
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault();
                                confirmCancel(data);
                            }}
                        >
                            取消订单
                        </a>
                    )}
                    {canCancelUnsubscribe && (
                        <a
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault();
                                confirmCancel(data);
                            }}
                        >
                            取消退单
                        </a>
                    )}

                    {canUnsubscribe && (
                        <Link
                            to={`/MyOrder/Unsubscribe?orderId=${data.orderId}`}
                        >
                            退订
                        </Link>
                    )}
                </>);
            }
        }
    ];

    const columnsEx = [
        {
            title: '产品名称',
            dataIndex: 'name',
        },
        {
            title: '生效日期',
            dataIndex: 'effectDate',
        },
        {
            title: '失效日期',
            dataIndex: 'expireDate',
        },

    ]

    const handleTableChange = (pagination) => {
        const values = form.getFieldsValue();
        const timeRange = values.createTimeQuery;
        fetchData({
            entity: {
                ...values,
                status: statusFilter !== 9 ? statusFilter : undefined,
                createTimeQuery: timeRange && timeRange.map((t) => t.format('YYYY-MM-DD')).join('#')
            },
            param: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize
            }
        });
    };

    return (
        <>
            <div className="page-header">
                <div className="title">我的订单</div>
            </div>
            <Layout className="page-content myorder">
                <div
                    className="table-meta myorder-table-meta"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div className="myorder-table-filter">
                        <span
                            onClick={() => setStatusFilter(0)}
                            className={`myorder-table-filteritem ${
                                statusFilter === 0 ? 'active' : ''
                            }`}
                        >
                            待支付
                        </span>
                        <span
                            onClick={() => setStatusFilter(2)}
                            className={`myorder-table-filteritem ${
                                statusFilter === 2 ? 'active' : ''
                            }`}
                        >
                            已取消
                        </span>
                        <span
                            onClick={() => setStatusFilter(3)}
                            className={`myorder-table-filteritem ${
                                statusFilter === 3 ? 'active' : ''
                            }`}
                        >
                            已支付
                        </span>
                        <span
                            onClick={() => setStatusFilter(4)}
                            className={`myorder-table-filteritem ${
                                statusFilter === 4 ? 'active' : ''
                            }`}
                        >
                            退单中
                        </span>
                        <span
                            onClick={() => setStatusFilter(5)}
                            className={`myorder-table-filteritem ${
                                statusFilter === 5 ? 'active' : ''
                            }`}
                        >
                            已退单
                        </span>

                        <span
                            onClick={() => setStatusFilter(9)}
                            className={`myorder-table-filteritem ${
                                statusFilter === 9 ? 'active' : ''
                            }`}
                        >
                            全部
                        </span>
                    </div>
                    <Form
                        layout="inline"
                        form={form}
                        onFinish={() => handleTableChange({ current: 1, pageSize: PAGE_SIZE })}
                    >
                        <Form.Item name="orderNo">
                            <Input placeholder="请输入订单号" />
                        </Form.Item>
                        <Form.Item name="orderTitle">
                            <Input placeholder="请输入订单名" />
                        </Form.Item>
                        <Form.Item name="createTimeQuery">
                            <RangePicker />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            查询
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                fetchData({
                                    entity: {
                                        status: statusFilter !== 9 ? statusFilter : undefined // 不是显示全部时，传递对应的status数字
                                    },
                                    param: {
                                        pageNum: 1,
                                        pageSize: PAGE_SIZE
                                    }
                                });
                            }}
                        >
                            重置
                        </Button>
                    </Form>
                </div>

                <Table
                    columns={columns}
                    dataSource={dataSource}
                    bordered
                    expandable={{
                        expandedRowRender: (record) => {
                            return (
                                <Table
                                    columns={columnsEx}
                                    dataSource={dataEx[record.key]}
                                    pagination={false}
                                />
                            )
                        },
                        onExpand: (expanded, record) => {
                            queryCustomerOrderById(record.key).then(res => {
                                let lst = []
                                if (res.data.code === 200) {
                                    const d = res.data.data;
                                    // 套件订单直接显示套件中的明细 2021.5.14
                                    // if (d?.kit) {
                                    //     lst.push({
                                    //         name: d.kit.kitName,
                                    //         effectDate: d.kit.effectDate,
                                    //         expireDate: d.kit.expireDate,
                                    //     })
                                    // } else {
                                        if (Array.isArray(d?.productList) && d?.productList.length > 0) {
                                            lst = lst.concat(d?.productList.map(i => ({
                                                name: i.productName,
                                                effectDate: i.effectDate,
                                                expireDate: i.expireDate,
                                            })))
                                        }
                                        if (Array.isArray(d?.serviceList) && d?.serviceList.length > 0) {
                                            lst = lst.concat(d?.serviceList.map(i => ({
                                                name: i.serviceName,
                                                effectDate: i.effectDate,
                                                expireDate: i.expireDate,
                                            })))
                                        }
                                        if (Array.isArray(d?.resourceList) && d?.resourceList.length > 0) {
                                            lst = lst.concat(d?.resourceList.map(i => ({
                                                name: i.resourceName,
                                                effectDate: i.effectDate,
                                                expireDate: i.expireDate,
                                            })))
                                        }
                                    // }
                                }
                                setDataEx({
                                    ...dataEx,
                                    [record.key]: lst,
                                })
                            })
                        }
                    }}
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => {
                            return `共 ${total} 条数据`;
                        }
                    }}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Layout>
        </>
    );
};
