/**
 * @file   首页模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/16
 */
import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Modal, Tag } from 'antd';
import { ProfileCard } from './ProfileCard.jsx';
import './index.scss';
import { PlusOutlined, createFromIconfontCN } from '@ant-design/icons';
import { queryUserInfo, queryProductList, queryMallProductList, queryBulletinPage } from '../../service/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../pages/login/store.js';
import { Link, useHistory } from 'react-router-dom';
import { ProductItem } from '../../pages/productShop/components/ProductItem/index.jsx';
import { ProductList } from '../../pages/productShop/components/ProductList/index.jsx';

const IconFont = createFromIconfontCN({
    scriptUrl: window.ICONFONT_URL
});

// 首页页面组件
export const Index = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.loginReducer);
    const history = useHistory();
    const [buyProducts, setBuyProducts] = useState([]);
    const [recommendProducts, setRecommendProducts] = useState([]);
    const [bulletinData, setBulletinData] = useState([]);

    useEffect(() => {
        // 获取用户数据
        const fetchUserData = async () => {
            const userRes = await queryUserInfo();
            if (userRes.data.code === 200) {
                // 如果响应成功返回，设置当前user为响应结果
                dispatch(setUser(userRes.data.data));
            }
        };
        const fetchProductData = async () => {
            // 我的产品里的产品
            const res1 = await queryProductList();
            // 产品商城里的产品
            const defaultParams = {
                searchKey: 'status',
                searchValue: 1
            };
            const res2 = await queryMallProductList(defaultParams);
            if (res1.data.code === 200 && res2.data.code === 200) {
                const buyPds = res1.data?.data || [];
                const recommendPds = res2.data?.data?.list || [];
                setBuyProducts(buyPds.slice(0, 2));
                setRecommendProducts(recommendPds.slice(0, 3));
            }
        };
        const fetchBulletinData = async () => {
            const res = await queryBulletinPage({
                entity: {},
                params: {
                    pageNum: 1,
                    pageSize: 5
                }
            })
            if (res.data.code === 200) {
                const arr = res.data.data?.records || []
                // 需要置顶的公告
                const topStack = []
                // 剩余的公告
                const rest = []
                let finalData = arr;
                if (arr.length > 1) {
                    arr.forEach(a => {
                        if (a.flag) {
                            topStack.push(a)
                        } else {
                            rest.push(a)
                        }
                    })
                    finalData = [].concat(topStack, rest)
                }
                setBulletinData(finalData)
            }
        }
        fetchUserData();
        fetchProductData();
        fetchBulletinData();
    }, [dispatch]);

    const hasOrderedProduct = ProductList?.length > 0 && buyProducts.filter(i =>
        // i.diff >= 0
        (new Date() - new Date(i.expireTime)) < 0

    );

    return (
        <Layout className="layout-index">
            <Row gutter={[16, 16]}>
                <Col span={18}>
                    <Card title="已购产品" extra={buyProducts.length > 0 ? <Link to="/MyProduct">查看全部已购产品</Link> : ""}>
                        <div className="product-list" style={{ height: 200, overflow: 'hidden' }}>
                            <Row gutter={[16, 16]}>
                                <Col xl={{ span: 12 }} xxl={{ span: 8 }}>
                                    <div className="product-item product-item-add">
                                        <Link className="" to="/ProductShop">
                                            <PlusOutlined />
                                            <p>订购更多产品</p>
                                        </Link>
                                    </div>
                                </Col>
                                {buyProducts.map((i, idx) => (
                                    <Col xl={{ span: 12 }} xxl={{ span: 8 }} key={idx}>
                                        <ProductItem
                                            data={i}
                                            typeStr={
                                                i.productId
                                                    ? 'product'
                                                    : i.serviceId
                                                        ? 'service'
                                                        : i.resourceId
                                                            ? 'resource'
                                                            : i.kitId
                                                                ? 'kit'
                                                                : ''
                                            }
                                            opts={{
                                                hasOrderedProduct,
                                                route: 'MyProduct'
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <ProfileCard user={user} />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={18}>
                    <Card title="推荐产品" extra={<Link to="/ProductShop">更多产品</Link>} style={{ marginBottom: 10 }}>
                        <div className="product-list" style={{ height: 200, overflow: 'hidden' }}>
                            <Row gutter={[16, 16]}>
                                {recommendProducts.map((i, idx) => (
                                    <Col xl={{ span: 12 }} xxl={{ span: 8 }} key={idx}>
                                        <ProductItem
                                            typeStr="product"
                                            data={i}
                                            opts={{
                                                hasOrderedProduct,
                                                route: 'ProductShop'
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Card>
                    <Card title="公告" extra={bulletinData?.length > 0 ? <Link to="/anounceList">更多</Link> : ""}>
                        <div className="article-list" style={{ height: 220, overflow: 'hidden' }}>
                            {bulletinData.map(i => (
                                <div className={`article-listitem ${i.flag ? 'active' : ''}`} key={i.bulletinId}>
                                    <span className="article-title" onClick={() => {
                                        history.push(`/AnounceDetail?id=${i.bulletinId}`)
                                    }}>
                                        {i.title}
                                        {i.flag === 1 && (
                                            <Tag color="#ffa500" className="common-set-top">顶</Tag>
                                        )}
                                    </span>
                                    <span className="article-time">{i.time}</span>
                                </div>
                            ))}
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="新手入门"
                        style={{
                            minWidth: '340px'
                        }}
                        extra={
                            <Link to='/GettingStarted' target="_blank">更多</Link>
                        }
                    >
                        <div className="article-list" style={{ height: 536, overflow: 'hidden' }}>
                            <div className="article-listitem">
                                <Link to='/GettingStarted?mark=1' className="article-title" target="_blank">注册登录</Link>
                            </div>
                            <div className="article-listitem">
                                <Link to='/GettingStarted?mark=5' className="article-title" target="_blank">机构认证</Link>
                            </div>
                            <div className="article-listitem">
                                <Link to='/GettingStarted?mark=9' className="article-title" target="_blank">产品试用</Link>
                            </div>
                            <div className="article-listitem">
                                <Link to='/GettingStarted?mark=11' className="article-title" target="_blank">产品订购</Link>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};
