
/**
 * @file   手机版产品介绍欢迎页面footer组件
 * @create macaiqi 2021/07/28
 * @update
 */
import React from 'react'
import { Link } from 'react-router-dom';

/**
 * @description 产品介绍欢迎footer组件
 */
export const IntroFooter = () => {
    const year = new Date().getFullYear()
    return (
        <footer className="mobile-footer" >
            {/* <ul>
                <li>
                    <a href="/helpcenter/index.html" target='_blank'>帮助中心</a>
                </li>
                <li>
                    <a href='/legalnotices.html' target='_blank'>法律声明 </a>
                </li>
                <li>
                    <Link to='/PrivacyPolicy'>隐私政策</Link>
                </li>
            </ul> */}
            <div className="phone">
                <span>
                    联系电话
                </span>
                <i />
                <div className="number">181-6272-2753</div>
            </div>
            <div className="txt">
                <p>  版权所有 © 武汉瑞鹿文化科技有限公司 {year}  保留一切权利  </p>
                <p><a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">鄂ICP备2021006883号-1</a>  鄂B2-20130048号</p>
                <p>
                    <i />
                    鄂公网安备 32011402010636号

                    <span id='cnzz_stat_icon_1280104732' className='tj' >
                        <img border="0" hspace="0" vspace="0" src="https://icon.cnzz.com/img/pic.gif" alt='' />
                    </span>
                    <script src='https://v1.cnzz.com/z_stat.php?id=1280104732&amp;show=pic' type='text/javascript' />
                    {/* <span id="cnzz_stat_icon_1280104732" className='tj'><a href="https://www.cnzz.com/stat/website.php?web_id=1280104732" target="_blank" rel="noreferrer" title="站长统计"><img border="0" hspace="0" vspace="0" src="https://icon.cnzz.com/img/pic.gif" alt='' /></a></span>
                    <script src="https://v1.cnzz.com/z_stat.php?id=1280104732&amp;show=pic" type="text/javascript" />
                    <script src="https://c.cnzz.com/core.php?web_id=1280104732&amp;show=pic&amp;t=z" charset="utf-8" type="text/javascript" /> */}
                </p>
            </div>
        </footer>
    )
}
