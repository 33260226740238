/**
 * @file   产品相关状态模块
 * @create tianmu 2021/3/18
 * @update tianmu 2021/3/18
 */
import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { preSubmitOrder, queryOrderCost, submitOrder } from '../../service';

// 产品状态
const initialState = {
    // 添加到试用清单的待购产品列表
    trialList: [],

    // 订购订单的产品、资源、服务以及价格等实时状态
    orderState: {
        kit: null,
        productList: [], // 产品详情
        serviceList: [], // 服务列表
        resourceList: [], // 资源列表
        cost: null,
    },
    // 订购的订单对象
    order: null,
};

// 判断两个试用是不是相同的产品（或套件）
const isSameTrial = (p1, p2) => {
    // 可能是产品或者套件添加了试用
    return p1.kitName ?
    p1.kitId === p2.kitId :
    p1.productName ?
    p1.productId === p2.productId : false
}

// 产品状态Slice
const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setOrder(state, action) {
            state.order = action.payload;
        },
        setOrderState(state, action) {
            if (!action.payload) {
                // 重置为初始状态
                state.orderState = {
                    kit: null,
                    productList: [],
                    serviceList: [],
                    resourceList: [],
                    cost: null,
                }
            } else {
                state.orderState = {
                    ...state.orderState,
                    ...action.payload,
                };
            }
        },

        // 设置试用清单
        setTrialList(state, action) {
            const { payload } = action;
            if (Array.isArray(payload) && payload.length > 0) {
                state.trialList = payload.map(i => ({ ...i, isChecked: false}))
            } else {
                state.trialList = [];
            }
        },
        // 添加产品到试用清单
        addToTrialList(state, action) {
            const { payload } = action;
            if (!payload) return;
            const exist =
                state.trialList.some(i => isSameTrial(i, payload))

            if (!exist) {
                state.trialList = [...state.trialList, { ...payload, isChecked: false}];
                notification.success({
                    message: '产品已成功加入产品试用清单中！',
                    duration: 5,
                    className: 'custom-antd-notification',
                })
            } else {
                notification.warn({
                    message: '产品已成功加入产品试用清单中！',
                    duration: 5,
                    className: 'custom-antd-notification',
                })
            }
        },
        // 从产品清单移除试用产品
        removeFromTrialList(state, action) {
            const { payload } = action;
            if (!payload) return;
            state.trialList = [
                ...state.trialList.filter(i => !isSameTrial(i, payload))
            ];
        },
        // 勾选试用清单的一个试用
        checkTrial(state, action) {
            const { payload } = action;
            if (!payload) return;
            state.trialList = state.trialList.map(i => {
                if (isSameTrial(i, payload)) {
                    return {
                        ...i,
                        isChecked: true,
                    }
                } else {
                    return {
                        ...i,
                    };
                }
            })
        },
        // 取消勾选试用清单的一个试用
        unCheckTrial(state, action) {
            const { payload } = action;
            if (!payload) return;
            state.trialList = state.trialList.map(i => {
                if (isSameTrial(i, payload)) {
                    return {
                        ...i,
                        isChecked: false,
                    }
                } else {
                    return {
                        ...i,
                    };
                }
            })
        },
        // 全选试用清单的试用
        checkAllTrial(state) {
            state.trialList = state.trialList.map(i => ({...i, isChecked: true}))
        },
        // 全部取消选择试用清单
        unCheckAllTrial(state) {
            state.trialList = state.trialList.map(i => ({...i, isChecked: false}))
        },

    }
});

export const {
    setProductToBuy,
    setServiceToBuy,
    setResourceToBuy,
    setKitToBuy,

    setOrder,
    setOrderState,

    setTrialList,
    addToTrialList,
    removeFromTrialList,
    checkTrial,
    unCheckTrial,
    checkAllTrial,
    unCheckAllTrial,
} = productSlice.actions;


/**
 * @description 获取最新的订单查询、提交的接口的参数对象
 * @returns {Object} 参数对象
 */
export const getOrderParams = ({
    kit,
    productList,
    serviceList,
    resourceList,
}) => {
    const chosenProducts = productList
                            ?.filter((i) => i.value !== 0)
                            ?.map((i) => ({
                                productId: i.productId,
                                productName: i.productName,
                                productType: i.price?.unit,
                                productValue: i.value,
                            }))
    const chosenServices = serviceList
                            ?.filter((i) => i.value !== 0)
                            ?.map((i) => ({
                                serviceId: i.serviceId,
                                serviceName: i.serviceName,
                                serviceType: i.price?.unit,
                                serviceValue: i.value,
                            }))
    const chosenResources = resourceList
                            ?.filter((i) => i.value !== 0)
                            ?.map((i) => ({
                                resourceId: i.resourceId,
                                resourceName: i.resourceName,
                                resourceType: i.price?.unit,
                                resourceValue: i.value,
                            }))
    return {
        kit: kit
        ? {
                kitId: kit.kitId,
                kitName: kit.kitName,
                kitType: kit.price?.unit,
                kitValue: kit.value,
          }
        : null,
        productList: Array.isArray(chosenProducts) && chosenProducts.length > 0
                    ? chosenProducts
                    : null,
        serviceList:
            Array.isArray(chosenServices) && chosenServices.length > 0
                ? chosenServices
                : null,
        resourceList:
            Array.isArray(chosenResources) && chosenResources.length > 0
                ? chosenResources
                : null
    }
}

/**
 * @description 实时查询和更新订单价格
 * @returns {Boolean} 订单是否预提交成功
 */
 export const updateOrderState = (payload) => async (dispatch, getState) => {
    const currState = getState().productReducer;
    const { orderState } = currState;
    let newOrderState = orderState;
    if (payload) {
        newOrderState = {
            ...orderState,
            ...payload,
        }
    }
    // 还没有更新渲染到页面上的最新状态
    const {
        kit,
        productList,
        serviceList,
        resourceList,
    } = newOrderState;

    // 试用最新状态去查询价格
    const params = getOrderParams({
        kit,
        productList,
        serviceList,
        resourceList,
    })
    // 获取实时价格
    const res = await queryOrderCost(params);
    if (res.data.code === 200) {
        dispatch(setOrderState({
            kit,
            productList,
            serviceList,
            resourceList,
            cost: res.data?.data,
        }))
    } else {
        notification.warn({
            className: 'custom-antd-notification',
            message: res.data.msg,
            duration: 5,
        })

    }
};



/**
 * @description 预提交订单, 计算价格和明细
 * @returns {Boolean} 订单是否预提交成功
 */
export const preSubmit = () => async (dispatch, getState) => {
    const currState = getState().productReducer;
    const { orderState } = currState;
    const {
        kit,
        productList,
        serviceList,
        resourceList,
    } = orderState;

    const params = getOrderParams({
        kit,
        productList,
        serviceList,
        resourceList,
    })

    return preSubmitOrder(params).then((res) => {
        if (res.data.code === 200) {
            dispatch(setOrder(res.data.data));
            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })

            return false;
        }
    });
};

/**
 * @description 提交订单，根据返回数据更新订单状态
 * @returns {Boolean} 订单是否提交成功
 */
export const submit = () => async (dispatch) => {
    return submitOrder({}).then((res) => {
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '订单提交成功！',
                duration: 5,
            })
            dispatch(setOrder(res.data.data));
            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
            return false;
        }
    });
};

export default productSlice.reducer;
