/**
 * @file   路由配置模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */

import { Index } from '../views/Index';
import { OrgAuth } from '../views/OrgAuth';
import { OrgAuthTips } from '../views/OrgAuth/OrgAuthTips';
import { MyProduct } from '../views/MyProduct';
import { MyOrder } from '../views/MyOrder';
import { MyBill } from '../views/MyBill';
import { MyTrial } from '../views/MyTrial';
import { MyWorksheet } from '../views/MyWorksheet';
import { Statistic } from '../views/Statistic';
import { ApplyInvoice } from '../views/ApplyInvoice';
import { ChangeOrgAuth } from '../views/ChangeOrgAuth';
import { OrderInvoiceHistory } from '../views/MyOrder/OrderInvoiceHistory';
import { UserCenter } from '../views/UserCenter';
import { ManageInvoice } from '../views/ApplyInvoice/ManageInvoice';
import { InvoiceDetail } from '../views/MyBill/InvoiceDetail';
import { TrialDetail } from '../views/MyTrial/TrialDetail';
import { OrderUnsubscribe } from '../views/MyOrder/OrderUnsubscribe'
import { OrderUnsubscribeSuccess } from '../pages/productShop/OrderUnsubscribeSuccess';
import { CustomerChangePhone } from '../views/CustomerChangePhone';
import { CustomerValidatePhone } from '../views/CustomerValidatePhone';
import { MyScore } from '../views/MyScore';
import { Feedback } from '../views/Feedback';
// 后台页面路由列表
const routes = [
    { path: '/index', exact: true, name: '', component: Index },

    { path: '/Organization', exact: true, name: '机构认证', component: OrgAuth},
    { path: '/Organization/Tips', exact: true, name: '机构认证提示', component: OrgAuthTips},
    { path: '/Organization/Change', exact: true, name: '变更机构认证', component: ChangeOrgAuth},
    { path: '/MyProduct', exact: true, name: '我的产品', component: MyProduct},
    { path: '/MyOrder', exact: true, name: '我的订单', component: MyOrder},
    { path: '/MyBill', exact: true, name: '我的发票', component: MyBill},
    { path: '/MyTrial', exact: true, name: '我的试用', component: MyTrial},
    { path: '/MyTrial/TrialDetail', exact: true, name: '试用详情', component: TrialDetail},
    { path: '/MyWorksheet', exact: true, name: '我的工单', component: MyWorksheet},
    { path: '/Statistic', exact: true, name: '数据统计', component: Statistic},
    { path: '/Feedback', exact: true, name: '问题反馈', component: Feedback},

    // 帐号中心
    { path: '/UserCenter', exact: true, name: '帐号中心', component: UserCenter },
    { path: '/validatePhone', exact: true, name: '验证手机号', component: CustomerValidatePhone},
    { path: '/changePhone', exact: true, name: '修改手机号', component: CustomerChangePhone},

    // { path: '/UserAuth', exact: true, name: '用户审核', component: UserAuth},


    { path: '/MyOrder/ApplyInvoice', exact: true, name: '申请发票', component: ApplyInvoice},
    { path: '/MyOrder/ManageInvoice', exact: true, name: '管理发票', component: ManageInvoice},
    { path: '/MyOrder/OrderInvoiceHistory', exact: true, name: '订单发票历史', component: OrderInvoiceHistory},
    { path: '/MyOrder/Unsubscribe', exact: true, name: '订单退订', component: OrderUnsubscribe},
    { path: '/MyOrder/UnsubscribeSuccess', exact: true, name: '订单退订', component: OrderUnsubscribeSuccess},
    { path: '/MyBill/InvoiceDetail', exact: true, name: '消息订单发票详情', component: InvoiceDetail },

    // 消息提醒的
    { path: '/OrderInvoiceHistory', exact: true, name: '订单发票历史', component: OrderInvoiceHistory },
    { path: '/InvoiceDetail', exact: true, name: '消息订单发票详情', component: InvoiceDetail },
    { path: '/TrialDetail', exact: true, name: '试用详情', component: TrialDetail },

    // 会员积分
    { path: '/MyScore', exact: true, name: '会员积分', component: MyScore }
]

export default routes
