/* eslint-disable no-implicit-coercion */
/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from "react";
import { Button, Input } from "antd";

/**
 * Input Spinner
 * @author Thiago Rodrigues based on InputSpinner from Marco Cesarato <cesarato.developer@gmail.com>
 */
export class InputSpinner extends Component {

	constructor(props) {
		super(props);
		if (!this.props.type) throw new Error("Bootstrap-input-number-spinner prop type is required");

		let spinnerStep = this.getSpinnerStep(this.props.step);
		this.state = {
            disabled: this.props.disabled,
			min: this.parseNum(this.props.min),
			max: this.parseNum(this.props.max),
			value: this.parseNum(this.props.value),
			step: spinnerStep,
			buttonPress: null,
			lastEmittedValue: undefined,
		};
	}

	componentDidUpdate(prevProps) {
        if (this.props.disabled !== prevProps.disabled) {
            this.setState({ disabled: this.props.disabled });
        }
		if (this.props.min !== prevProps.min) {
			this.setState({ min: this.parseNum(this.props.min) });
		}
		if (this.props.max !== prevProps.max) {
			this.setState({ max: this.parseNum(this.props.max) });
		}
		if (this.props.step !== prevProps.step) {
			let spinnerStep = this.getSpinnerStep(this.props.step);
			this.setState({ step: spinnerStep });
		}
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.parseNum(this.props.value) });
        }
	}

	getSpinnerStep(step) {
		let spinnerStep = this.parseNum(step);
		if (!this.isTypeDecimal() && spinnerStep < 1) {
			spinnerStep = 1;
		}
		if (spinnerStep === '') {
			if (this.isTypeDecimal()) {
				spinnerStep = 0.1;
			} else {
				spinnerStep = 1;
			}
		}
		return spinnerStep;
	}

	onChange(num, event) {
		if (this.props.disabled) return;
		if (event === undefined || event === null) event = 'none'

		this.setState({ value: num });
		let currentValue = this.getValue(num);

		if (this.isTypeDecimal()) {
			if (this.realMatch(String(currentValue))) {
				if (this.state.min > 0 && Number(currentValue) === 0 && (event === 'none'))
					return;
				// Ex: min=2 -> if was typed '2.' it will be  emitted '2' but the input keepes '2.' as value
				if (Number(currentValue) === this.state.min && (event === 'none')) {
					if ((Number(currentValue) !== Number(this.state.lastEmittedValue)) && this.props.onChange) {
						this.emitChange(Number(currentValue));
					}
					return;
				}
				if (this.state.min > 9) {
					if ((Number(currentValue) < this.state.min) && (event === 'none')) {
						return;
					}
				}
				currentValue = Number(currentValue);
				if (!this.minReached(currentValue)) {
					if (this.maxReached(currentValue)) {
						currentValue = this.state.max;
						if (this.props.onMax) {
							this.props.onMax(this.state.max);
						}
						this.setState({ value: currentValue });
					}
				} else {
					if (this.props.onMin) {
						this.props.onMin(this.state.min)
					}
					currentValue = this.state.min;
					this.setState({ value: currentValue });
				}

				if (event === 'blur')
					this.setState({ value: currentValue });

				if (currentValue !== Number(this.state.lastEmittedValue) && this.props.onChange) {
					this.emitChange(currentValue);
				}
			}
		} else {
			if (this.intMatch("" + currentValue)) {
				currentValue = Number(currentValue);
				if (!this.minReached(currentValue)) {
					if (this.maxReached(currentValue)) {
						currentValue = this.state.max;
						if (this.props.onMax) {
							this.props.onMax(this.state.max);
						}
						this.setState({ value: currentValue });
					}
				} else {
					if (this.props.onMin) {
						this.props.onMin(this.state.min)
					}
					currentValue = this.state.min;
					this.setState({ value: currentValue });
				}
				if (event === 'blur')
					this.setState({ value: currentValue });

				if (currentValue !== Number(this.state.lastEmittedValue) && this.props.onChange) {
					this.emitChange(currentValue);
				}
			}
		}
	}

	emitChange(value) {
		this.props.onChange(value);
		this.setState({ lastEmittedValue: value });
	}

	onBlur() {
		let currentValue = this.getValue(undefined);
		if (this.isTypeDecimal()) {
			if (this.realMatch("" + currentValue)) {
				this.onChange(currentValue, 'blur');
			} else {
				this.onChange(this.state.min, undefined);
			}
		} else {
			if (this.intMatch("" + currentValue)) {
				this.onChange(currentValue, 'blur');
			} else {
				this.onChange(this.state.min, undefined);
			}
		}
	}

	realMatch = (value) => value && value.match(/-?\d+(\.(\d+)?)?/) && value.match(/-?\d+(\.(\d+)?)?/)[0] === value.match(/-?\d+(\.(\d+)?)?/).input;
	intMatch = (value) => value && value.match(/-?\d+/) && value.match(/-?\d+/)[0] === value.match(/-?\d+/).input;

	parseNum(num) {
		if (this.isTypeDecimal()) {
			if (num === '.')
				return '0.';
			if (this.realMatch("" + num)) {
				let numSplit = ("" + num).split(".");
				if (
					(numSplit.length > 1 && numSplit[1].length > 0 && numSplit[1].endsWith('0')) ||
					(numSplit.length > 1 && numSplit[1].length === 0)
				) {
					return num;
				}
				num = parseFloat(num);
			} else {
				num = parseFloat(num);
			}
		} else {
			num = parseInt(num, 10);
		}
		if (isNaN(num)) {
			num = '';
		}
		return num;
	}

	getValue(num) {
		let value = num === undefined ? this.state.value : num;

		if (this.isTypeDecimal()) {
			value = this.parseNum(value);
			if (typeof value == 'number') {
				if (this.countDecimals(value) > this.props.precision)
					value = value.toFixed(this.props.precision);
			} else if (this.realMatch(value)) {
				if (this.countDecimalsFromString(value) > this.props.precision)
					value = Number(value).toFixed(this.props.precision);
			}
		}
		if (typeof value == 'number') {
			return String(this.parseNum(value));
		} else {
			if (this.realMatch(value)) {
				if (this.countDecimalsFromString(value) === this.props.precision && value.endsWith('0')) {
					value = Number(value);
				}
			}
			return String(value);
		}
	}

	countDecimals(value) {
		if (Math.floor(value) === value) return 0;
		return value.toString().split(".")[1].length || 0;
	}

	countDecimalsFromString(value) {
		let numSplit = ("" + value).split(".");
		return numSplit.length > 1 ? numSplit[1].length : 0;
	}

	getType() {
		return String(this.props.type).toLowerCase();
	}

	isTypeDecimal() {
		let type = this.getType();
		return (
			type === "float" ||
			type === "double" ||
			type === "decimal" ||
			type === "real"
		);
	}

	increase() {
		if (this.isDisabledButtonRight()) {
			return;
		}
		let num = this.parseNum(this.state.value) + this.parseNum(this.state.step);
		if (this.props.onIncrease) {
			let increased_num = num;
			if (this.maxReached(num)) {
				increased_num = this.state.max;
			}
			this.props.onIncrease(increased_num);
		}

		this.onChange(num, 'inc');
	}

	decrease() {
		if (this.isDisabledButtonLeft()) {
			return;
		}
		let num = this.parseNum(this.state.value) - this.parseNum(this.state.step);
		if (this.props.onDecrease) {
			let decreased_num = num;
			if (this.minReached(num)) {
				decreased_num = this.state.min;
			}
			this.props.onDecrease(decreased_num);
		}
		this.onChange(num, 'dec');
	}

	maxReached(num = null) {
		if (num === null) {
			return this.state.value >= this.state.max
		}
		return num >= this.state.max;
	}

	minReached(num = null) {
		if (num === null) {
			return this.state.value <= this.state.min;
		}
		return num <= this.state.min;
	}

	isEditable() {
		return !this.props.disabled && this.props.editable;
	}

	isDisabledButtonLeft() {
		return this.props.disabled || this.minReached(this.parseNum(this.state.value));
	}

	isDisabledButtonRight() {
		return this.props.disabled || this.maxReached(this.parseNum(this.state.value));
	}

	renderLeftButtonElement() {
		return this.props.arrows === true ? "<" : "-";
	}

	renderRightButtonElement() {
		return this.props.arrows === true ? ">" : "+";
	}

	renderLeftButton() {
		return (
			<Button
				className="input-spinner-left-button"
				// variant={this.props.variant}
				disabled={this.isDisabledButtonLeft() || this.state.disabled}
				onClick={() => this.decrease()}>
				{this.renderLeftButtonElement()}
			</Button>
		);
	}

	renderRightButton() {
		return (
			<Button
				className="input-spinner-right-button"
				// variant={this.props.variant}
				disabled={this.isDisabledButtonRight() || this.state.disabled}
				onClick={() => this.increase()}>
				{this.renderRightButtonElement()}
			</Button>
		);
	}

	render() {
		return (
			<div
                style={{
                    display: 'inline-flex'
                }}
            >
				<div>
					{this.renderLeftButton()}
				</div>
				{this.props.prepend}
				<Input
                    className="input-spinner-input"
					value={this.getValue(undefined)}
                    disabled={this.state.disabled}
					// readOnly={!this.isEditable()}
					onChange={event => this.onChange(event.target.value, undefined)}
					onBlur={this.onBlur.bind(this)}
				/>
				{this.props.children}
				{this.props.append}
				<div>
					{this.renderRightButton()}
				</div>
			</div>
		);
	}
}

export default InputSpinner;
