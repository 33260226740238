/**
 * @file   页面底部布局模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */
import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

// 页脚组件
export const AppFooter = () => {
    const year = new Date().getFullYear()
    return (
    <Footer
        className="app-footer"
        style={{
            background: 'transparent',
            color: 'hsl(217, 5%, 71%)',
            fontSize: 14,
            textAlign: 'center'
        }}
    >
        版权所有 © 武汉瑞鹿文化科技有限公司 {year}  保留一切权利  <a style={{color: 'hsl(217, 5%, 71%)'}} href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">鄂ICP备2021006883号-1</a>  鄂B2-20130048号
        <i />
        鄂公网安备 32011402010636号
        {/* 版权所有 © 2019-2025 瑞鹿科技 建议浏览器：chrome/360极速浏览器 */}
    </Footer>
)};
