/**
 * @file   应用组件模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20
 */
import React from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import { Modal, Button, notification } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
// import loadable from './utils/loadable'
import { DefaultLayout } from './containers/DefaultLayout.jsx';
import { Login } from './pages/login';
import { Register } from './pages/register';
import { ChangePwd } from './pages/changePwd';
import { Introduction } from './pages/introduction/index.jsx';
import { WapIntroduction } from './pages/WapIntroduction/index.jsx';
import { ActivityAppointment } from './pages/introduction/System/ActivityAppointment';
import { VisitAppointment } from './pages/introduction/System/VisitAppointment';
import { TrafficManagement } from './pages/introduction/System/TrafficManagement';
import { ShowBooking } from './pages/introduction/System/ShowBooking';
import { ExplainSystem } from './pages/introduction/System/ExplainSystem';
import { VolunteersSystem } from './pages/introduction/System/VolunteersSystem';
import { CollectionSystem } from './pages/introduction/System/CollectionSystem';
import { WapVisitAppointment } from './pages/WapIntroduction/System/WapVisitAppointment';
import { WapActivityAppointment } from './pages/WapIntroduction/System/ActivityAppointment';
import { WapVisualAssetManagement } from './pages/WapIntroduction/System/VisualAssetManagement';
import { WapTrafficManagement } from './pages/WapIntroduction/System/TrafficManagement';
import { WapExplainSystem } from './pages/WapIntroduction/System/WapExplainSystem';
import { WapVolunteersSystem } from './pages/WapIntroduction/System/WapVolunteersSystem';
import { WapShowBooking } from './pages/WapIntroduction/System/WapShowBooking';
import { WapCollectionSystem } from './pages/WapIntroduction/System/CollectionSystem';
import { WapPrivacyPolicy } from './pages/WapIntroduction/components/PrivacyPolicy';
import { WapServiceItems } from './pages/WapIntroduction/components/ServiceItems';
import { WapSystemAll } from './pages/WapIntroduction/systemall';
import { VisualAssetManagement } from './pages/introduction/System/VisualAssetManagement';
import { PrivacyPolicy } from './pages/introduction/components/PrivacyPolicy';
import { Legalnotices } from './pages/introduction/components/Legalnotices';
import { HelpCenter } from './pages/introduction/components/HelpCenter';
import { GettingStarted } from './pages/introduction/components/GettingStarted';
import { CommonProblem } from './pages/introduction/components/CommonProblem';
import { ServiceItems } from './pages/introduction/components/ServiceItems';
import { Join } from './pages/join/index.jsx';
import { JoinInfo } from './pages/joinInfo/index.jsx';
import { InviteJoin } from './pages/inviteJoin/index.jsx';
import { ProductShop } from './pages/productShop/index.jsx';
import { ProductIntro } from './pages/productShop/ProductIntro.jsx';
import { ProductOrder } from './pages/productShop/ProductOrder.jsx';
import { ProductOrderInfo } from './pages/productShop/ProductOrderInfo';
import { ProductOrderSuccess } from './pages/productShop/ProductOrderSuccess.jsx';
import { ProductUnsubscribe } from './pages/productShop/components/OrderForms/ProductUnsubscribe.jsx';
import './modalModify';
import './styles/App.scss'
import { OrgNoticeInfo } from './pages/OrgNoticeInfo/index.jsx';
import { NoticeList } from './pages/NoticeList/index.jsx';
import { CommonNotice } from './pages/CommonNotice/index.jsx';
import { TrialOrderSuccess } from './pages/productShop/TrialOrderSuccess.jsx';
import { AccountComplain } from './pages/accountComplain/index.jsx';
import { AccountComplainQuery } from './pages/accountComplain/accountComplainQuery.jsx';
import { AccountComplainChangePwd } from './pages/accountComplain/accountComplainChangePwd.jsx';
import { AccountComplainReset } from './pages/accountComplain/accountComplainReset.jsx';
import { ScoreShop } from './pages/scoreShop/index.jsx';
import { ScoreProductDetail } from './pages/scoreShop/ScoreProductDetail.jsx';
import { ScoreProductOrder } from './pages/scoreShop/ScoreProductOrder.jsx';
import { ScoreProductOrderSuccess } from './pages/scoreShop/ScoreProductOrderSuccess.jsx';
import { MyScore } from './pages/scoreShop/MyScore.jsx';
import { ScoreRule } from './pages/scoreShop/ScoreRule.jsx';
import { AnounceList } from './pages/AnounceList/index.jsx';
import { AnounceDetail } from './pages/AnounceList/AnounceDetail.jsx';
import { WechatLogin } from './pages/wechatLogin/index.jsx';
import { WechatBind } from './pages/wechatBind/index.jsx';
import { InitAccount } from './pages/initAccount/index.jsx';
import { InConstruction } from './pages/Inconstruction/index.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { cacheUserDetail } from './pages/login/store'
import { hideOrgAuthTip, hideEditNameTip, hideEditPwdTip } from './store/globStore.js';
import { queryUserInfo, updateName } from './service';
import { WechatAuthPhone } from './pages/wechatLogin/WechatAuthPhone.jsx';
import { WechatLoginSuccess } from './pages/wechatLogin/wechatLoginSuccess.jsx';

const IconFont = createFromIconfontCN({
    scriptUrl: window.ICONFONT_URL
});

const OrgAuthTip = () => {
    const { showNotAuthedTip } = useSelector(state => state.globReducer)
    const dispatch = useDispatch();
    return (
        <div className="not-authed-layout">
            <Modal
                title={null}
                footer={null}
                closable={false}
                maskClosable={false}
                keyboard={false}
                // getContainer=".not-authed-layout"
                centered
                visible={showNotAuthedTip}
                width={600}
                wrapClassName="orgwarmtips"
            >
                <div className="head">
                    <p>温馨提示</p>
                    <h2>您还没有完成机构实名认证哦</h2>
                </div>
                <div className="cont">
                    <p>实名认证后</p>
                    <ul>
                        <li>
                            <IconFont type="iconshenqingshiyong" />
                            <p>产品试用</p>
                        </li>
                        <li>
                            <IconFont type="icongoumai" />
                            <p>产品购买</p>
                        </li>
                        <li>
                            <IconFont type="icondingdan" />
                            <p>订单查询</p>
                        </li>
                        <li>
                            <IconFont type="iconfapiaochaxun" />
                            <p>发票查询</p>
                        </li>
                    </ul>
                </div>

                <Button
                    type="primary"
                    onClick={() => {
                        dispatch(hideOrgAuthTip())
                        window.location.href = '/Organization';
                    }}
                    size="large"
                >
                    立即认证
                </Button>
            </Modal>
        </div>
    )
}

const EditNameTip = () => {
    const { showEditNameTip } = useSelector(state => state.globReducer)
    const dispatch = useDispatch();

    return (
        <Modal
            title="确认帐号名"
            centered
            width={450}
            visible={showEditNameTip}
            onCancel={() => { dispatch(hideEditNameTip()) }}
            maskClosable={false}
            keyboard={false}
            footer={[
                <Button key="ok" type="primary" onClick={() => {
                    dispatch(hideEditNameTip())
                    window.location.href = '/UserCenter?key=1'
                }} >
                    去修改帐号名
                </Button>,
                <Button key="back" type="primary" onClick={() => {
                    updateName({ isRetain: true }).then(res => {
                        if (res.data.code === 200) {
                            dispatch(hideEditNameTip())
                            queryUserInfo().then(res => {
                                const user = res.data.data;
                                cacheUserDetail(user);
                                notification.success({
                                    className: 'custom-antd-notification',
                                    description: '用户名保存成功！',
                                    duration: 5
                                });
                                setTimeout(() => {
                                    // 刷新当前页面的 user 实例对象
                                    window.location.reload()
                                }, 100);
                            })

                        } else {
                            notification.warn({
                                className: 'custom-antd-notification',
                                description: res.data.msg,
                                duration: 5
                            });
                        }
                    })
                }}>
                    保存当前帐号名
                </Button>,
            ]}
        >
            <div style={{ height: '100px', fontSize: '16px' }}>
                您的帐号名为初始状态，为了方便后续的登录操作，建议修改您的帐号名。如无需修改，则使用当前帐号名。<br />
                <span style={{ color: 'red' }}>初始状态的帐号名只允许修改一次</span>
            </div>
        </Modal>
    )
}

const EditPwdTip = () => {
    const { showEditPwdTip } = useSelector(state => state.globReducer)
    const dispatch = useDispatch();
    return (
        <Modal
            title="确认密码"
            centered
            width={450}
            visible={showEditPwdTip?.pwd}
            onCancel={() => { dispatch(hideEditPwdTip()) }}
            maskClosable={false}
            keyboard={false}
            footer={[
                <Button key="ok" type="primary" onClick={() => {
                    dispatch(hideEditPwdTip())
                    window.location.href = '/UserCenter?key=2'
                }} >
                    去修改密码
                </Button>,
            ]}
        >
            <div style={{ height: '100px', fontSize: '16px' }}>
                您的初始密码为 {showEditPwdTip?.pwd}，为了方便后续的登录操作，建议修改密码。如无需修改，则使用当前密码。<br />
            </div>
        </Modal>
    )
}

/**
 * @description App组价
 */
const App = () => (<>
    {/* <Router basename="/uc"> */}
    <Router>
        <Switch>
            {/* <Route path='/' exact render={() => <Redirect to='/index' />} /> */}

            {/* 正在建设中的首页 */}
            {/* <Route path='/' exact component={InConstruction} /> */}
            <Route path='/' exact component={Introduction} />

            <Route path='/ActivityAppointment' exact component={ActivityAppointment} />
            <Route path='/VisitAppointment' exact component={VisitAppointment} />
            <Route path='/TrafficManagement' exact component={TrafficManagement} />
            <Route path='/ShowBooking' exact component={ShowBooking} />
            <Route path='/ExplainSystem' exact component={ExplainSystem} />
            <Route path='/VolunteersSystem' exact component={VolunteersSystem} />
            <Route path='/CollectionSystem' exact component={CollectionSystem} />
            <Route path='/VisualAssetManagement' exact component={VisualAssetManagement} />
            <Route path='/PrivacyPolicy' exact component={PrivacyPolicy} />
            <Route path='/HelpCenter' exact component={HelpCenter} />
            <Route path='/GettingStarted' exact component={GettingStarted} />
            <Route path='/CommonProblem' exact component={CommonProblem} />
            <Route path='/Legalnotices' exact component={Legalnotices} />
            <Route path='/ServiceItems' exact component={ServiceItems} />
            {/* 手机版 */}
            <Route path='/mobile' exact component={WapIntroduction} />
            <Route path='/mobile/VisitAppointment' exact component={WapVisitAppointment} />
            <Route path='/mobile/ActivityAppointment' exact component={WapActivityAppointment} />
            <Route path='/mobile/VisualAssetManagement' exact component={WapVisualAssetManagement} />
            <Route path='/mobile/TrafficManagement' exact component={WapTrafficManagement} />
            <Route path='/mobile/ExplainSystem' exact component={WapExplainSystem} />
            <Route path='/mobile/VolunteersSystem' exact component={WapVolunteersSystem} />
            <Route path='/mobile/ShowBooking' exact component={WapShowBooking} />
            <Route path='/mobile/CollectionSystem' exact component={WapCollectionSystem} />
            <Route path='/mobile/PrivacyPolicy' exact component={WapPrivacyPolicy} />
            <Route path='/mobile/ServiceItems' exact component={WapServiceItems} />
            <Route path='/mobile/WapSystemAll' exact component={WapSystemAll} />


            <Route path='/accountLogin' component={Login} />
            <Route path='/phoneLogin' component={Login} />
            <Route path='/register' component={Register} />
            <Route path='/changePwd' component={ChangePwd} />
            <Route path='/accountComplain' component={AccountComplain} />
            <Route path='/accountComplainQuery' component={AccountComplainQuery} />
            <Route path='/accountComplainChangePwd' component={AccountComplainChangePwd} />
            <Route path='/accountComplainReset' component={AccountComplainReset} />

            <Route path='/join' component={Join} />
            <Route path='/joinInfo' component={JoinInfo} />
            <Route path='/inviteJoin' component={InviteJoin} />
            <Route path='/OrgNoticeInfo' component={OrgNoticeInfo} />
            <Route path='/NoticeList' component={NoticeList} />
            <Route path="/CommonNotice" component={CommonNotice} />
            <Route path="/anounceList" component={AnounceList} />
            <Route path="/AnounceDetail" component={AnounceDetail} />

            <Route path='/ProductShop' component={ProductShop} />
            <Route path='/ProductIntro' component={ProductIntro} />
            <Route path='/ProductOrder' component={ProductOrder} />
            <Route path='/ProductUnsubscribe' component={ProductUnsubscribe} />
            <Route path='/ProductOrderInfo' component={ProductOrderInfo} />
            <Route path='/ProductOrderSuccess' component={ProductOrderSuccess} />
            <Route path='/TrialOrderSuccess' component={TrialOrderSuccess} />

            <Route path='/scoreShop' component={ScoreShop} />
            <Route path='/ScoreProductDetail' component={ScoreProductDetail} />
            <Route path='/ScoreProductOrder' component={ScoreProductOrder} />
            <Route path='/ScoreProductOrderSuccess' component={ScoreProductOrderSuccess} />
            <Route path='/MyScore' component={MyScore} />
            <Route path='/ScoreRule' component={ScoreRule} />

            <Route path='/wechatLogin' component={WechatLogin} />
            <Route path='/wechatAuthPhone' component={WechatAuthPhone} />
            <Route path='/wechatLoginSuccess' component={WechatLoginSuccess} />
            <Route path='/wechatBind' component={WechatBind} />
            <Route path='/initAccount' component={InitAccount} />

            {/* <Route path='/500' component={View500} />
            <Route path='/404' component={View404} /> */}
            <Route component={DefaultLayout} />
        </Switch>
    </Router>
    <OrgAuthTip />
    <EditNameTip />
    <EditPwdTip />
</>);

export default App;
