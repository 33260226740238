/**
 * @file    订购底部价格汇总模块
 * @create tianmu 2021/3/23
 * @update tianmu 2021/3/23
 */
import React, { useMemo } from 'react';
import { Space, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { toFixedNumStr } from '../../../../utils';
import { unitStr } from '../../../../utils/productUtils';

// 订购底部价格汇总组件
export const OrderSumInfo = (props) => {
    const { orderState } = useSelector((state) => state.productReducer);
    const { kit, productList, serviceList, resourceList, cost } = orderState;
    const { discInfos = [] } = props;

    // 总价格计算结果
    const allCost = cost ? cost.cost : 0;
    // console.log('cost: ', cost);
    // 总价格折扣结果
    const allDiff = useMemo(() => {
        if (cost?.cost === null || cost?.cost === undefined) return 0;
        // 返回了就直接用返回的
        if (cost?.originalCost !== null && cost?.originalCost !== undefined)
            return (cost.originalCost - cost.cost) < 0 ? 0 : (cost.originalCost - cost.cost);
        // 没返回就自己算
        const products = Array.isArray(productList) && productList.filter((i) => i.value !== 0);
        const services = Array.isArray(serviceList) && serviceList.filter((i) => i.value !== 0);
        const resources = Array.isArray(resourceList) && resourceList.filter((i) => i.value !== 0);
        const kitDiff = kit ? kit.sum - cost[kit.kitId] : 0;
        const productDiff = products
            ? products.reduce((ds, i) => ds + i.sum - cost[i.productId], 0)
            : 0;

        const serviceDiff = services
            ? services.reduce((ds, i) => ds + i.sum - cost[i.serviceId], 0)
            : 0;
        const resourceDiff = resources
            ? resources.reduce((ds, i) => ds + i.sum - cost[i.resourceId], 0)
            : 0;

        return kitDiff + productDiff + serviceDiff + resourceDiff;
    }, [cost, kit, productList, resourceList, serviceList]);

    // 折扣的描述
    const discountDescriptions = useMemo(() => {
        const products = Array.isArray(productList) && productList.filter((i) => i.value !== 0);
        const services = Array.isArray(serviceList) && serviceList.filter((i) => i.value !== 0);
        const resources = Array.isArray(resourceList) && resourceList.filter((i) => i.value !== 0);
        const prodsToDescriptions = (prods, discSelector) => {
            console.log("prods: ", prods);
            return prods.map((p, idx) => {
                const info = discInfos.find(
                    d => d[discSelector + 'Name'] && d[discSelector + 'Id'] === p[discSelector + 'Id']
                );
                console.log(info)
                const rates = info?.rates;
                let selectedRate = null;
                if (Array.isArray(rates) && rates.length > 0) {
                    selectedRate = rates.find(rt => {
                        if (p.value < rt.min) return false;
                        // 没有上界限，且通过了上面的测试，则通过
                        if (rt.max === null || rt.max === undefined) return true;
                        if (p.value > rt.max) return false;
                        return true;
                    })
                }
                if (!selectedRate) return null;
                // 不打折
                if (selectedRate.rate === 100) return null;
                const unit = info.unitType;
                const name = info.productName || info.serviceName || info.resourceName || info.kitName;
                return (
                    <div key={idx} style={{ textAlign: 'center' }}>
                        {name || ''} 享 {selectedRate.min}
                        {selectedRate.max ? '~' + selectedRate.max : ''}
                        {'\u00A0'}{unitStr(unit)}
                        {!selectedRate.max && '以上'}
                        {'\u00A0'}{(selectedRate.rate / 100) * 10}{'\u00A0'}
                        折{selectedRate.adjust > 0 && '再加' + selectedRate.adjust}
                        {selectedRate.adjust < 0 && '再减' + (-selectedRate.adjust)}
                    </div>
                );
            })
        }
        const descs = prodsToDescriptions(
            products,
            'product'
        ).concat(prodsToDescriptions(
            services,
            'service'
        )).concat(prodsToDescriptions(
            resources,
            'resource'
        ))
        console.log(descs)
        return descs.filter(i => !!i);
    }, [discInfos, productList, resourceList, serviceList])

    return (
        <div
            style={{
                float: 'right',
                marginRight: 30,
                marginTop: 20,
            }}
        >
            <div
                style={{
                    height: '30px',
                    lineHeight: '30px'
                }}
            >
                <span>总费用：</span>
                <span
                    style={{
                        color: 'rgb(211, 60, 60)',
                        fontSize: 18
                    }}
                >
                    ￥
                </span>
                <span
                    style={{
                        color: 'rgb(211, 60, 60)',
                        fontSize: 24
                    }}
                >
                    {toFixedNumStr(allCost, 2)}
                </span>

                <Tooltip
                    color="#fff"
                    title={
                        <div>
                            {kit && (<div>
                                {Array.isArray(cost?.detail) && cost.detail.map(i => {
                                    const found = kit.details.find(d => d.id === i.id);
                                    if (!found) return null;
                                    return (
                                        <div key={i.id} style={{ color: 'rgb(112, 112, 112)' }}>
                                            {found.name}
                                            <Space>
                                                <span style={{ color: 'rgb(204, 28, 28)' }}>
                                                    ￥{toFixedNumStr(i.originalPrice, 2)}
                                                </span>
                                                {/* 套件整体算，不在单个上计算省钱 */}
                                            </Space>
                                        </div>
                                    )
                                })}
                                <span style={{ color: 'rgb(112, 112, 112)' }}>共省 </span>
                                <span style={{ color: 'rgb(99, 170, 136)' }}>
                                    ￥{toFixedNumStr(allDiff, 2)}
                                </span>
                            </div>)}
                            {Array.isArray(productList) &&
                                productList
                                    .filter((i) => i.value !== 0)
                                    .map((s) =>
                                        s ? (
                                            <div
                                                key={s.productId}
                                                style={{ color: 'rgb(112, 112, 112)' }}
                                            >
                                                {s.productName}：
                                                <Space>
                                                    <span style={{ color: 'rgb(204, 28, 28)' }}>
                                                        ￥{toFixedNumStr(s.sum, 2)}
                                                    </span>
                                                    省
                                                    <span style={{ color: 'rgb(99, 170, 136)' }}>
                                                        ￥
                                                        {toFixedNumStr(
                                                            s.sum - cost[s.productId],
                                                            2
                                                        )}
                                                    </span>
                                                </Space>
                                            </div>
                                        ) : null
                                    )}

                            {Array.isArray(serviceList) &&
                                serviceList
                                    .filter((i) => i.value !== 0)
                                    .map((s) =>
                                        s ? (
                                            <div
                                                key={s.serviceId}
                                                style={{ color: 'rgb(112, 112, 112)' }}
                                            >
                                                {s.serviceName}：
                                                <Space>
                                                    <span style={{ color: 'rgb(204, 28, 28)' }}>
                                                        ￥{toFixedNumStr(s.sum, 2)}
                                                    </span>
                                                    省
                                                    <span style={{ color: 'rgb(99, 170, 136)' }}>
                                                        ￥
                                                        {toFixedNumStr(
                                                            s.sum - cost[s.serviceId],
                                                            2
                                                        )}
                                                    </span>
                                                </Space>
                                            </div>
                                        ) : null
                                    )}
                            {Array.isArray(resourceList) &&
                                resourceList
                                    .filter((i) => i.value !== 0)
                                    .map((r) =>
                                        r ? (
                                            <div
                                                key={r.resourceId}
                                                style={{ color: 'rgb(112, 112, 112)' }}
                                            >
                                                {r.resourceName}：
                                                <Space>
                                                    <span style={{ color: 'rgb(204, 28, 28)' }}>
                                                        ￥{toFixedNumStr(r.sum, 2)}
                                                    </span>
                                                    省
                                                    <span style={{ color: 'rgb(99, 170, 136)' }}>
                                                        ￥
                                                        {toFixedNumStr(
                                                            r.sum - cost[r.resourceId],
                                                            2
                                                        )}
                                                    </span>
                                                </Space>
                                            </div>
                                        ) : null
                                    )}
                        </div>
                    }
                >
                    <span
                        style={{
                            fontSize: 16,
                            color: '#ccc',
                            marginLeft: '5px'
                        }}
                    >
                        <QuestionCircleOutlined />
                    </span>
                </Tooltip>
            </div>
            <div
                style={{
                    height: '30px',
                    lineHeight: '30px'
                }}
            >
                {/* <Tooltip
                    color="#fff"
                    title={
                        <div
                            style={{
                                color: 'rgb(112, 112, 112)'
                            }}
                        >
                            {discountDescriptions.filter((i) => !!i).length > 0
                                ? discountDescriptions
                                : '暂无优惠'}
                        </div>
                    }
                >
                    <span
                        style={{
                            color: 'rgb(99, 170, 136)'
                        }}
                    >
                        查看优惠
                    </span>
                </Tooltip> */}

                <span>
                    共{'\u00A0\u00A0\u00A0\u00A0'}省：
                    <span
                        style={{
                            color: 'rgb(85, 192, 153)',
                            fontSize: 18,
                            fontWeight: 'bold'
                        }}
                    >
                        ￥
                    </span>
                    <span
                        style={{
                            color: 'rgb(85, 192, 153)',
                            fontSize: 24
                        }}
                    >
                        {toFixedNumStr(allDiff, 2)}
                    </span>
                </span>
            </div>
        </div>
    );
};
