/**
 * @file   机构认证用户提示模块
 * @create tianmu 2021/4/20
 * @update tianmu 2021/4/20
 */
import React from 'react';
import { Alert, Button } from 'antd';
import { createFromIconfontCN } from '@ant-design/icons';
import './index.scss';

const IconFont = createFromIconfontCN({
    scriptUrl: window.ICONFONT_URL
});

// // 机构认证用户提示组件
export const OrgAuthTips = (props) => {
    const { toAuth } = props;
    //     // 获取机构验证信息
    //     const queryAuthInfo = useCallback(async () => {
    //         let res = await queryAuthCompanyByCustomerId();
    //         let t = res.data?.data;
    //         let authed;
    //         if (Array.isArray(t)) {
    //             if (t?.[0] === 2) {
    //                 authed = true;
    //             } else {
    //                 authed = false;
    //             }
    //         } else {
    //             authed = false;
    //         }
    //         if (authed) history.push('/Organization')
    //     }, [history])

    //     useEffect(() => {
    //         queryAuthInfo();
    //     }, [queryAuthInfo]);

    return (
        <div className="orgauth-tips">
            <div className="page-header">
                <div className="title">机构实名认证</div>
            </div>
            <Alert
                message="您还未进行机构实名认证，可能会影响您申请试用或购买产品！"
                type="info"
                showIcon
            />
            <div className="page-block">
                <div className="page-block-header">
                    <h2 className="page-block-title">
                        <IconFont type="iconqiyerenzheng" />
                        机构认证
                    </h2>
                </div>
                <p>
                    <span className="lighttext">适用条件：</span>
                    瑞鹿云主要服务于博物馆、美术馆、文化馆、纪念馆、图书馆等各类公共文化场馆和文旅景区，上述单位可通过机构认证成为瑞鹿云认证用户。
                </p>
                <p>
                    <span className="lighttext">所需资料：</span>
                    机构证件附件（机构营业执照、组织机构代码证、事业单位法人证书、社会团体法人证书、行政执法主体资格证或其他机构证件）、法人姓名及法人身份证号等。
                </p>
                <Button type="primary" onClick={() => toAuth()}>
                    立即认证
                </Button>
            </div>
            <div className="page-block">
                <div className="page-block-header">
                    <h2 className="page-block-title">
                        <IconFont type="iconquanyi" />
                        获得权益
                    </h2>
                    <span className="page-block-extra">实名认证后，您将获得的权益</span>
                </div>
                <div className="page-block-cards">
                    <div className="page-block-card">
                        <IconFont type="icontishenggongxinli" />
                        <div className="page-block-card-content">
                            <h3>提升公信力</h3>
                            <p>增加真实性及主办方品牌信任度</p>
                        </div>
                    </div>
                    <div className="page-block-card">
                        <IconFont type="iconshenqingshiyong" />
                        <div className="page-block-card-content">
                            <h3>申请试用</h3>
                            <p>申请免费试用多个产品，先试用再购买</p>
                        </div>
                    </div>
                    <div className="page-block-card">
                        <IconFont type="icondinggouchanpin" />
                        <div className="page-block-card-content">
                            <h3>订购产品</h3>
                            <p>购买产品、套件、服务、资源</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-block">
                <div className="page-block-header">
                    <h2 className="page-block-title">
                        <IconFont type="iconshenhefeiyong" />
                        审核及费用
                    </h2>
                </div>
                <div className="page-block-list">
                    <div className="page-block-listitem">
                        <h3>实名认证审核需要多久？</h3>
                        <p>
                            资料提交后，大约2-7个工作日可完成审核，届时会进行电话核实，请注意保持通讯流畅。
                        </p>
                    </div>
                    <div className="page-block-listitem">
                        <h3>实名认证审核需要缴费吗？</h3>
                        <p>实名认证审核无需缴费，认证后长期有效。</p>
                    </div>
                    <div className="page-block-listitem">
                        <h3>一个帐号可以进行多个机构认证吗？</h3>
                        <p>不可以，一个帐号只能进行一个机构的认证。</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
