/**
 * @file   页面头部布局模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, Layout, Avatar, Badge, Button, Divider, notification, List } from 'antd';

import { createFromIconfontCN, BellOutlined, UserOutlined } from '@ant-design/icons';
import Logo from '../assets/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetail, logout } from '../pages/login/store';
import { Link, useHistory } from 'react-router-dom';
import {
    customerLogin,
    queryNotice,
    querySystems,
    queryUserInfo,
    updateDefaultSystem,
    updateNoticeStatus
} from '../service';
import { localDS } from '../storage';
import './index.scss';
import { setUnReadMsgs } from '../store/globStore';

const { Header } = Layout;

// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;

const IconFont = createFromIconfontCN({
    scriptUrl: window.ICONFONT_URL
});

// 页面头部组件
export const AppHeader = (props) => {
    const { activeMenuName } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [systems, setSystems] = useState([]);
    const [messages, setMessages] = useState([]);
    const [msgCnt, setMsgCnt] = useState(0);
    const { unReadMsgs } = useSelector(state => state.globReducer);
    const user = getUserDetail();

    const fetchInfo = useCallback(async () => {
        // const userRes = await queryUserInfo();
        const sysRes = await querySystems();
        const noticeRes = await queryNotice({
            entity: {
                isRead: 0
            }
        });

        if (sysRes.data.code === 200) {
            setSystems(sysRes.data.data || []);
        }
        if (noticeRes.data.code === 200) {
            const msgs = noticeRes?.data?.data?.records || [];
            dispatch(setUnReadMsgs(msgs))
            // setMessages(noticeRes.data.data.records);
            // setMsgCnt(Array.isArray(msgs) ? msgs.length : 0);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchInfo();
    }, [fetchInfo]);

    const DropdownCard = (
        <div className="user-dropdown">
            <div className="user-dropdown-info">
                <Avatar icon={<UserOutlined />} alt="avatar" />
                <span className="name">{user?.customerName}</span>
            </div>
            <div className="user-dropdown-flex">
                <Link to="/UserCenter?key=1" className="user-dropdown-flexitem">
                    基本信息
                </Link>
                <Link to="/Organization" className="user-dropdown-flexitem">
                    机构认证
                </Link>
                <Link to="/UserCenter?key=3" className="user-dropdown-flexitem">
                    安全设置
                </Link>
            </div>
            <div className="user-dropdown-list">
                <Link to="/MyOrder" className="user-dropdown-listitem">
                    {/* <img src={OrderIcon} alt="" /> */}
                    <IconFont type="iconwodedingdan" />
                    <span>我的订单</span>
                </Link>
                <Link to="/UserCenter?key=4" className="user-dropdown-listitem">
                    <IconFont type="iconbangdingshezhi" />
                    <span>绑定设置</span>
                </Link>
                <Link to="/UserCenter?key=5" className="user-dropdown-listitem">
                    <IconFont type="icondenglurizhi" />
                    <span>登录日志</span>
                </Link>
                <Link to="/UserCenter?key=2" className="user-dropdown-listitem">
                    <IconFont type="iconxiugaimima" />
                    <span>修改密码</span>
                </Link>
            </div>
            <Divider />
            <div className="user-dropdown-extra">
                {systems && systems.length > 0 && (
                    <>
                        <div>
                            所属机构
                            <span
                                style={{
                                    fontSize: '12px',
                                    color: 'rgb(206, 203, 202)'
                                }}
                            >
                                （您可以点击机构直接跳转管理后台，也可以加入新机构）
                            </span>
                        </div>
                        {systems.map((i) => (
                            <div
                                key={i.systemId}
                                className="user-dropdown-extraline"
                                onClick={() => {
                                    const token = localDS.get('saasToken')
                                    if (!token) {
                                        notification.warn({
                                            className: 'custom-antd-notification',
                                            description: '已退出登录或登录超时，请重新登录',
                                            duration: 5
                                        });
                                        window.location.href = "/accountLogin"
                                        return
                                    }
                                    customerLogin(i.systemId).then((res) => {
                                        // 请求错误直接返回
                                        if (res.data.code !== 200) {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                // description:
                                                // '请先购买或试用产品再进入管理后台',
                                                description: res.data.msg,
                                                duration: 5
                                            });
                                            return;
                                        }
                                        const d = res.data.data;
                                        if (d?.authorizationu) {
                                            let loc = '';
                                            if (i.systemId === '0') {
                                                loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${i.systemId}&customerId=${user?.customerId}`;
                                            } else {
                                                loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${i.systemId}&customerId=${user?.customerId}`;
                                            }
                                            localDS.set('gatewayToken', d?.authorizationu);
                                            localDS.set('systemId', i.systemId)
                                            window.open(loc);
                                        }
                                    });
                                }}
                            >
                                <span className="left">{i.systemName}</span>
                                {i.isHome ? (
                                    <span
                                        className="right isdefault-btn"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    >
                                        默认
                                    </span>
                                ) : (
                                    <span
                                        className="right notdefault-btn"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            updateDefaultSystem(i.systemId).then((res) => {
                                                if (res.data.code === 200) {
                                                    notification.success({
                                                        className: 'custom-antd-notification',
                                                        message: '设置默认机构成功',
                                                        duration: 5
                                                    });
                                                    fetchInfo();
                                                } else {
                                                    notification.warn({
                                                        className: 'custom-antd-notification',
                                                        message: '设置默认机构失败',
                                                        description: res.data.msg,
                                                        duration: 5
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        设置默认
                                    </span>
                                )}
                                <span className="right tag">机构</span>
                            </div>
                        ))}
                    </>
                )}
                <div
                    className="user-dropdown-extrabtn"
                    onClick={() => {
                        history.push('/Join');
                    }}
                >
                    + 加入新机构
                </div>
            </div>
            <Button
                block
                className="user-dropdown-exit"
                onClick={() => {
                    dispatch(logout());
                    history.push('/accountLogin');
                }}
            >
                退出登录
            </Button>
        </div>
    );

    const MessagesDropdown = (
        <div className="user-dropdown">
            <List
                header={
                    <div className="messages-info">
                        你有
                        <span
                            style={{
                                color: '#fff',
                                padding: '0 5px',
                                margin: '0 5px',
                                borderRadius: '3px',
                                background: 'rgb(40, 167, 69)'
                            }}
                        >
                            {/* {msgCnt} */}
                            {unReadMsgs.length}
                        </span>
                        条通知
                    </div>
                }
                footer={
                    <Link
                        to="/NoticeList"
                        // target="_blank"
                        className="messages-all"
                    >
                        查看所有通知
                    </Link>
                }
                bordered
                // dataSource={messages}
                dataSource={unReadMsgs}
                renderItem={(item) => (
                    <List.Item>
                        <a
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault()
                                updateNoticeStatus(item.noticeId)
                                // window.open(`/#${item.url}?content=${encodeURIComponent(
                                //     item.content
                                // )}&businessId=${item.businessId}&noticeId=${item.noticeId}`)
                                history.push(`${item.url}?content=${encodeURIComponent(
                                    item.content
                                )}&businessId=${item.businessId}&noticeId=${item.noticeId}`)
                            }}
                        >
                            {item.title}
                        </a>
                    </List.Item>
                )}
            />
        </div>
    );

    const headerMenus = [
        {
            name: '控制台',
            to: '/Index',
        },
        {
            name: '产品商城',
            to: '/productShop',
        },
        // {
        //     name: '积分商城',
        //     to: '/scoreShop'
        // }
    ]

    return (
        <Header className="layout-header">
            <div className="logo-box">
                <a className="logo" href="/">
                    <img src={Logo} alt="logo" />
                </a>
                {/* <h5
                    onClick={() => {
                        history.push('/Index');
                    }}
                    style={{
                        cursor: 'pointer'
                    }}
                >
                    控制台
                </h5> */}
            </div>
            <div className="header-menu">
                {headerMenus.map((m, idx) => (
                    <div
                        key={idx}
                        style={{
                            display: 'inline-block',
                            marginRight: 20,
                            color: m.name === activeMenuName ? '#3dbc8e' : '#aaa',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            history.push(m.to)
                        }}
                    >
                        {m.name}
                    </div>
                ))}
                {/* <Link to="/scoreShop" style={{marginLeft: 20}}>积分商城</Link> */}
            </div>
            <div className="right">
                {user ? (
                    <>
                        {systems && systems.length > 0 && (
                            <a
                                href="/#"
                                className="console-entry"
                                onClick={(e) => {
                                    e.preventDefault()
                                    const token = localDS.get('saasToken')
                                    if (!token) {
                                        notification.warn({
                                            className: 'custom-antd-notification',
                                            description: '已退出登录或登录超时，请重新登录',
                                            duration: 5
                                        });
                                        window.location.href = "/accountLogin"
                                        return
                                    }
                                    customerLogin().then((res) => {
                                        // 请求错误直接返回
                                        if (res.data.code !== 200) {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                // description:
                                                // '请先购买或试用产品再进入管理后台',
                                                description: res.data.msg,
                                                duration: 5
                                            });
                                            return;
                                        }
                                        const d = res.data.data;
                                        if (d?.authorizationu && d?.systemId) {
                                            let loc = '';
                                            if (d?.systemId === '0') {
                                                loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`;
                                            } else {
                                                loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`;
                                            }
                                            localDS.set('gatewayToken', d?.authorizationu);
                                            localDS.set('systemId', d?.systemId)
                                            window.open(loc);
                                        }
                                    });
                                }}
                            >
                                进入工作门户
                            </a>
                        )}
                        <Dropdown overlay={MessagesDropdown}>
                            <a
                                href="/#"
                                style={{ marginRight: 20, marginTop: 10 }}
                                className="tips"
                                onClick={(e) => e.preventDefault()}
                            >
                                <Badge
                                    // count={msgCnt}
                                    count={unReadMsgs.length}
                                    style={{
                                        marginTop: '25px'
                                    }}
                                >
                                    <BellOutlined style={{ fontSize: '25px', color: '#aaa' }} />
                                </Badge>
                            </a>
                        </Dropdown>
                        <Dropdown overlay={DropdownCard}>
                            <div className="dropdown-link">
                                <Avatar icon={<UserOutlined />} alt="avatar" />
                                <span className="name">{user?.customerName}</span>
                            </div>
                        </Dropdown>
                    </>
                ) : null}
            </div>
        </Header>
    );
};
