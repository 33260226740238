/**
 * @file   手机登陆表单模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/23
 */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Input, Form, Button, notification, Checkbox } from 'antd';
import { UserOutlined, MobileOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { querySmsVCode, queryVCode } from '../../service';
import { login } from './store';
import { validator } from '../../utils';
import { localDS, sessionDS } from '../../storage';
// import WechatLoginICON from '../../assets/wechat-login-icon.png';
import WechatCornerIcon from '../../assets/wechat-corner.svg';
import WechatScanLogin from '../../assets/wechatscan-login.png';
import CloseIcon from '../../assets/close-icon.svg';

// 发送cd时间
const TIME_LIMIT = 60;
const MACHINE_AUTH_VID = process.env.ENV_MACHINE_AUTH_VID
// const WECHAT_LOGIN_APPID = process.env.ENV_WECHAT_LOGIN_APPID;
// const WECHAT_LOGIN_ADDR = process.env.ENV_WECHAT_LOGIN_ADDR;
const WECHAT_LOGIN_APPID = window.WECHAT_LOGIN_APPID;
const WECHAT_LOGIN_ADDR = window.WECHAT_LOGIN_ADDR;

/**
 * @description 手机登录表单
 * @param {Object} props 父组件传递对象
 */
export const PhoneForm = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isSending, setSending] = useState(false);
    // const [isAuth, setAuth] = useState(false);
    const [time, setTime] = useState(TIME_LIMIT);
    const ref = useRef(null);
    const [form] = Form.useForm();
    const [wechatScan, setWechatScan] = useState(false);
    const [keepLogin, setKeepLogin] = useState(false);

    // const [validateCode, setValidateCode] = useState(null)

    // const fetchValidateCode = async () => {
    //     const res = await queryVCode();
    //     if (res.data.code === 200) {
    //         setValidateCode(res.data.data)
    //     }
    // }

    // const init = useCallback(async () => {
    //     // const info = getLoginMetaInfo();
    //     // const isNeed = info && info.failCount > FAIL_LIMIT
    //     if (needVCode) {
    //         fetchValidateCode()
    //     }
    // }, [needVCode])

    // 获取手机验证码
    const query = useCallback(async (phone) => {
        const res = await querySmsVCode(phone, 1);
        // 根据返回的错误代码显示错误信息
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '验证码发送成功，请注意查收！',
                duration: 5
            });
            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return false;
        }
    }, []);

    // 处理点击获取验证码按钮事件
    const handleClick = useCallback(
        async (phone) => {
            if (isSending) return;
            setSending(true);
            const success = await query(phone);
            // 如果请求验证码不成功直接返回
            if (!success) {
                setSending(false);
                return;
            }
            ref.current = setInterval(() => {
                setTime((time) => {
                    const left = time - 1;
                    // 点击验证码之后TIME_LIMIT秒
                    if (left === 0) {
                        setTime(TIME_LIMIT);
                        window.clearInterval(ref.current);
                        setSending(left > 0);
                    }
                    return left;
                });
            }, 1000);
        },
        [query, isSending]
    );

    useEffect(() => {
        // 2021/10/15 取消了vaptcha验证
        // window
        //     .vaptcha({
        //         vid: MACHINE_AUTH_VID, // 验证单元id
        //         type: 'click', // 显示类型 点击式
        //         scene: 0, // 场景值 默认0
        //         container: '#vaptchaContainer', // 容器，可为Element 或者 selector
        //         offline_server: '' // 离线模式服务端地址，若尚未配置离线模式，请填写任意地址即可。
        //         // 可选参数
        //         // lang: 'auto', // 语言 默认auto,可选值auto,zh-CN,en,zh-TW,jp
        //         // https: true, // 使用https 默认 true
        //         // style: 'dark' //按钮样式 默认dark，可选值 dark,light
        //         // color: '#57ABFF' //按钮颜色 默认值#57ABFF
        //     })
        //     .then(function (vaptchaObj) {
        //         // const obj = vaptchaObj; // 将VAPTCHA验证实例保存到局部变量中
        //         vaptchaObj.render(); // 调用验证实例 vpObj 的 render 方法加载验证按钮
        //         // 获取token的方式一：
        //         // vaptchaObj.renderTokenInput('.login-form')//以form的方式提交数据时，使用此函数向表单添加token值
        //         // 获取token的方式二：
        //         vaptchaObj.listen('pass', function () {
        //             // 验证成功进行后续操作
        //             //   const data = {
        //             //     // 表单数据
        //             //     token: vaptchaObj.getToken(),
        //             //   };
        //             setAuth(true);
        //             // eslint-disable-next-line max-nested-callbacks
        //             setTimeout(() => {
        //                 // vaptcha验证 3 分钟后失效
        //                 setAuth(false)
        //             }, 1000 * 60 * 3)
        //         });
        //         // 关闭验证弹窗时触发
        //         vaptchaObj.listen('close', function () {
        //             // 验证弹窗关闭触发
        //         });
        //     });

        // return () => {
        //     // 销毁组件时如果存在定时器求删除它
        //     if (ref.current) {
        //         window.clearInterval(ref.current);
        //     }
        // };
    }, []);
    // 表单提交成功
    const onFinish = async (values) => {
        setLoading(true);
        // sessionDS.set('saasLogin', true)
        localDS.set('saasKeepLogin', keepLogin)
        const res = await dispatch(
            login({
                ...values
            })
        );
        if (res) {
            const searchStr = location.search;
            const o = {};
            if (searchStr) {
                let s = searchStr.substr(1);
                s.split('&').forEach((p) => {
                    const [k, v] = p.split('=');
                    o[k] = v;
                });
            }
            if (o.redirect) {
                const u = decodeURIComponent(o.redirect);
                const targetLink = u + `?token=${localDS.get('gatewayToken')}`;
                // console.log("targetLink: ", targetLink)
                window.location.href = targetLink;
            } else {
                history.replace('/');
            }
            // const redirectUrl =
            // (location?.state)?.from?.pathname || "/index";
            // history.go(redirectUrl);
        }
        setLoading(false);
    };
    // 表单提交失败
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="login-panel">
            <h3 className="login-title">手机验证码登录</h3>
            <a
                className="wechat-corner"
                href={`https://open.weixin.qq.com/connect/qrconnect?appid=${WECHAT_LOGIN_APPID
                }&redirect_uri=${encodeURIComponent(WECHAT_LOGIN_ADDR)
                }&response_type=code&scope=snsapi_login&state=123#wechat_redirect`}
            >
                <img src={WechatCornerIcon} alt="" />
            </a>
            {/* <a
                className="wechat-corner"
                href="/#"
                onClick={(ev) => {
                    ev.preventDefault();
                    setWechatScan(true);
                }}
            >
                <img src={WechatCornerIcon} alt="" />
            </a> */}

            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            required: true,
                            pattern: validator().phoneNumRegex,
                            message: '请输入正确的手机号!'
                        }
                    ]}
                    wrapperCol={{ offset: 4, span: 16 }}
                >
                    <Input
                        placeholder="手机号"
                        size="large"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>
                {/* <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <div id="vaptchaContainer" style={{ width: '100%', height: 40 }}>
                        <div className="vaptcha-init-main">
                            <div
                                className="vaptcha-init-loading"
                                style={{ textAlign: 'center', lineHeight: '40px' }}
                            >
                                <img
                                    src="https://r.vaptcha.net/public/img/vaptcha-loading.gif"
                                    alt="vaptcha"
                                />

                                <span className="vaptcha-text" style={{ paddingLeft: '5px' }}>
                                    正在生成验证...
                                </span>
                            </div>
                        </div>
                    </div>
                </Form.Item> */}
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Form.Item
                        name="svCode"
                        rules={[
                            {
                                required: true,
                                message: '验证码不能为空!'
                            },
                            {
                                len: 6,
                                message: '验证码长度为6位！'
                            }
                        ]}
                        noStyle
                    >
                        <Input
                            placeholder="验证码"
                            size="large"
                            className="sendcodeinput"
                            prefix={<MobileOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>
                    <Button
                        size="large"
                        className={`sendcodebtn ${isSending
                            // || !isAuth
                            ? '' : 'active'
                        }`}
                        disabled={isSending
                            // || !isAuth
                        }
                        style={isSending ? null : {
                            background: '#00a2fe',
                            border: 'none',
                        }}
                        onClick={() => {
                            console.log('click');
                            const phone = form.getFieldValue('phone');
                            // 手机号不为空时处理验证码获取事件
                            if (phone) {
                                handleClick(phone);
                            } else {
                                notification.info({
                                    className: 'custom-antd-notification',
                                    message: '请先输入手机号！',
                                    duration: 5
                                });
                            }
                        }}
                    >
                        {isSending ? `${time}秒后重新发送` : '发送验证码'}
                    </Button>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Checkbox
                        onChange={(e) => {
                            setKeepLogin(e.target.checked)
                        }}
                    >
                        保持登录
                    </Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button
                        style={{
                            background: '#00a2fe',
                            border: 'none',
                        }}
                        type="primary"
                        block
                        size="large"
                        htmlType="submit"
                        loading={loading}
                    >
                        登录
                    </Button>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <div className="login-type">
                        <div>
                            <Link style={{color: '#00a2fe'}} to="/accountLogin">帐号登录</Link>
                            <a style={{color: '#00a2fe'}} href={`https://open.weixin.qq.com/connect/qrconnect?appid=${
                                WECHAT_LOGIN_APPID
                            }&redirect_uri=${
                                encodeURIComponent(WECHAT_LOGIN_ADDR)
                            }&response_type=code&scope=snsapi_login&state=123#wechat_redirect`}>微信授权登录</a>
                        </div>
                        <div>
                            <Link to="/changePwd">忘记密码</Link>
                            <Link to="/accountComplain" >帐号申诉</Link>
                            <Link to="/register">免费注册</Link>
                        </div>
                    </div>
                </Form.Item>
            </Form>
            <div
                style={{
                    textAlign: 'center',
                    fontSize: 14,
                    color: '#aaa'
                }}
            >
                登录即同意瑞鹿云 <Link style={{color: '#00a2fe'}} to='/PrivacyPolicy' target='_blank'>《隐私政策声明》</Link>
            </div>
            {/* </>)} */}
            {wechatScan && (
                <div className="wechatscan-login">
                    <h3 className="login-title">微信登录</h3>
                    <img style={{ margin: 'auto' }} src={WechatScanLogin} alt="" />
                    <p>请使用微信扫描二维码登录</p>
                    <a
                        href="/#"
                        className="close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setWechatScan(false);
                        }}
                    >
                        <img src={CloseIcon} alt="" />
                    </a>
                </div>
            )}
        </div>
    );
};
