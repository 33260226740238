/**
 * @file   机构认证模块
 * @create tianmu 2021/2/26
 * @update tianmu 2021/2/26
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Form, message, notification, Spin } from 'antd';
import { OrgAuthForm } from './OrgAuthForm';
import { OrgAuthInfo } from './OrgAuthInfo';
import {
    getFileById,
    queryAuthCompanyByCustomerId,
    saveAuthCompany
} from '../../service';
import { FormFooter } from '../../containers/FormFooter';
import './index.scss';
import { useLocation } from 'react-router';
import { OrgAuthTips } from './OrgAuthTips';
import { searchStrToObj, omit } from '../../utils';

// 图片预览
// const filePreviewPrefix = process.env.ENV_FILE_PREVIEW;
const filePreviewPrefix = window.FILE_PREVIEW;

// 单位注册组件
export const OrgAuth = () => {
    const location = useLocation();
    const [pageLoading, setPageLoading] = useState(false);
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false);
    const [infos, setInfos] = useState(null);
    const [form] = Form.useForm();

    // 强制渲染机构认证的表单
    const [forceRender, setForceRender] = useState({
        // 是否强制渲染表单
        renderForm: false,
        // 渲染查询到的第几个info对象
        infoIdx: 0,
    });

    // 获取机构验证信息
    const queryInfo = useCallback(async () => {
        setPageLoading(true)
        let res = await queryAuthCompanyByCustomerId();
        let t = res?.data?.data;
        if (t) {
            for (let i = 0; i < t.length; i++) {
                if (t[i].license) {
                    res = await getFileById(t[i].license);
                    const file = res.data.data;
                    if (file && file.filePath && file.fileName) {
                        t[i].thumb = filePreviewPrefix + file.filePath + file.fileName;
                    }
                }
            }
        }
        setInfos(t);
        setPageLoading(false);
    }, [])

    useEffect(() => {
        const o = searchStrToObj(location.search);
        if (o?.renderForm && o?.infoIdx) {
            setForceRender({
                ...o,
            })
        }
        queryInfo();
    }, [location.search, queryInfo]);

    // 验证机构信息
    const authorize = useCallback((values) => {
        return saveAuthCompany({
            companyName: values.companyName,
            companyCode: values.companyCode,
            contact: values.contact,
            identity: values.identity,
            identityCard: values.identityCard,
            licenseType: values.licenseType,
            license: values.license
        });
    }, [])

    // 处理提交机构认证的事件
    const handleSubmit = useCallback(async () => {
        await form.validateFields()
        let values = form.getFieldsValue();
        const l = values.license;
        if (!values.agree) {
            notification.info({
                className: 'custom-antd-notification',
                message: '请先确认协议内容！',
                duration: 5,
            })
            return;
        }
        if (!l) {
            notification.warn({
                className: 'custom-antd-notification',
                message: '请上传机构证件附件！',
                duration: 5,
            })
            return;
        }
        const isNewLicense = Object.prototype.toString.call(l) !== '[object String]';
        if (isNewLicense
            && Array.isArray(l.fileList)
            && values.license.fileList.length === 0) {
            // 此时图片被删除，应该提示重新上传
            notification.warn({
                className: 'custom-antd-notification',
                message: '请上传机构证件附件！',
                duration: 5,
            })
            return;
        }

        if (values.editIdentMode === 1) {
            // 修改表单，但不修改法人身份信息，需要将法人身份证号设置为原来的真实身份证号（而不是加密后的）
            values = omit({
                ...values,
                identityCard: values.realIdentityCard,
            }, ['realIdentityCard', 'editIdentMode'])
        } else if (values.editIdentMode === 2) {
            // 修改表单，同时修改法人身份信息
            values = omit(values, ['realIdentityCard', 'editIdentMode'])
        } else {
            // 不上传这个字段
            values = omit(values, ['realIdentityCard', 'editIdentMode'])
        }
        setLoading(true);
        const res = await authorize({
            ...values,
            license: isNewLicense
                ? values.license.fileList[0].response.data.fileInfoId // 上传新文件后返回的对象中取出license
                : values.license // 没有更改之前的license
        });

        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '提交成功！',
                duration: 5,
            })
            // 提交成功后重置信息
            setForceRender({
                renderForm: false,
                infoIdx: 0,
            });
            setStep(1);
            form.resetFields()
            // 机构认证提交成功，这里就应该读取得到info信息了
            // 此时页面跳转到authingState状态
            queryInfo();
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
        }
        setLoading(false);
    }, [authorize, form, queryInfo])

    // 没有提交过认证
    const noAuthState = !infos;
    // 第一次提交申请且已经撤销
    const canceledAuthState = infos && infos.length === 1 && infos?.[0].approveStatus === 4;
    const notAuthed = noAuthState || canceledAuthState;

    return pageLoading ?
    <Spin size="large" /> :
    forceRender.renderForm ? (<>
        <div className="page-header">
            <div className="title">我的机构</div>
        </div>
        <div className="page-content orgauth">
            <Alert
                message="机构证件信息仅用于进行实名认证，瑞鹿云不会泄露您的任何证件信息。"
                type="info"
                showIcon
            />
            <OrgAuthForm
                form={form}
                info={infos[forceRender.infoIdx]}
            />
            <FormFooter
                okText='提交'
                cancelText='取消'
                onOk={handleSubmit}
                onCancel={() => {
                    form.resetFields();
                    setForceRender({
                        renderForm: false,
                        infoIdx: 0,
                    })
                    setStep(1)
                    // setFileList([]);
                    // setLoading(false);
                    // cancel && cancel();
                }}
                loading={loading}
                btnAlign="center"
            />
        </div>
    </>) : (<>
        {/* 没有认证，先显示提示用户认证相关的信息 */}
        {(notAuthed && step === 1) && <OrgAuthTips toAuth={() => setStep(2)} />}
        {/* 用户点击立即认证进行认证，进入第二步 */}
        {(notAuthed && step === 2) && (<>
            <div className="page-header">
                <div className="title">我的机构</div>
            </div>
            <div className="page-content orgauth">
                <Alert
                    message="机构证件信息仅用于进行实名认证，瑞鹿云不会泄露您的任何证件信息。"
                    type="info"
                    showIcon
                />

                <OrgAuthForm
                    form={form}
                    info={null}
                />
                <FormFooter
                    okText='提交'
                    cancelText='取消'
                    onOk={handleSubmit}
                    onCancel={() => {
                        form.resetFields();
                        setForceRender({
                            renderForm: false,
                            infoIdx: 0,
                        })
                        setStep(1)
                        // setFileList([]);
                        // setLoading(false);
                        // cancel && cancel();
                    }}
                    loading={loading}
                    btnAlign="center"
                />
            </div>
        </>)}
        {!notAuthed && (<>
            <div className="page-header">
                <div className="title">我的机构</div>
            </div>
            <div className="page-content orgauth">
                <OrgAuthInfo
                    infos={infos}
                    setForceRender={setForceRender}
                    queryInfo={queryInfo}
                />
            </div>
        </>)}
    </>)
};
