/**
 * @file   申请加入机构页面
 * @create tianmu 2021/4/7
 * @update tianmu 2020/4/7
 */
import React, { useState } from 'react';
import { Alert, Button, Input, Layout, Modal, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppFooter } from '../../containers/AppFooter';
import './index.scss';
import { querySystemId, saveAuthCustomer, queryBySystemId, updateName } from '../../service';
import { getUserDetail } from '../login/store';
import { AppHeader } from '../../containers/AppHeader';
import { CheckCircleFilled } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux';
import { showEditNameTip } from '../../store/globStore';

const { Content } = Layout;

/**
 * @description 申请加入机构组件
 */
export const Join = () => {
    const history = useHistory()
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [systemCode, setSystemCode] = useState("");
    const [orgInfo, setOrgInfo] = useState(null);
    const dispatch = useDispatch();
    const cacheUser = getUserDetail();

    // 提交机构申请
    const handlePreSubmit = async () => {
        if (cacheUser?.randomName) {
            dispatch(showEditNameTip())
            return;
        }
        setLoading(true);
        if (!systemCode || systemCode.trim() === "") {
            notification.warn({
                className: 'custom-antd-notification',
                message: '请填写机构申请码',
                duration: 5
            });
            setLoading(false);
            return;
        }
        // console.log('values: ', values);
        const res = await querySystemId(systemCode)
        if (res.data.code === 200 && res.data.data) {
            const res1 = await queryBySystemId(res.data.data);
            setOrgInfo(res1?.data?.data);
            setStep(2)
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: '申请失败',
                description: '请输入正确的机构申请码',
                duration: 5
            });
        }
        setLoading(false)
    };

    const handleSubmit =  async () => {
        if (cacheUser?.randomName) {
            dispatch(showEditNameTip())
            return;
        }
        setLoading(true);
        const res = await querySystemId(systemCode)
        if (res.data.code === 200 && res.data.data) {
            const res2 = await saveAuthCustomer({ applyType: 1, systemId: res.data.data });
            if (res2.data.code === 200) {
                notification.success({
                    className: 'custom-antd-notification',
                    message: '申请成功',
                    duration: 5
                });
                setStep(3)
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: '申请失败',
                    description: res2.data.msg,
                    duration: 5
                });
            }
        }
        setLoading(false);
    }

    return (
        <Layout
            className="app"
            style={{
                background: 'rgb(241, 242, 246)'
            }}
        >
            {/* <Header className="layout-header" style={{ position: 'relative' }}>
                <div className="logo-box">
                    <a className="logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                </div>
            </Header> */}
            <AppHeader />
            <Content
                style={{
                    position: 'relative',
                    // width: '1190px',
                    width: '100%',
                    margin: 'auto',
                    height: '100%'
                }}
            >
                {(step === 1 && !cacheUser) && (<>
                    <div
                        style={{
                            margin: '80px 30% 60px',
                        }}
                    >
                        <h1>申请加入机构</h1>
                        <p>欢迎申请加入机构，请填写机构申请码</p>
                    </div>

                    <div
                        style={{
                            width: '800px',
                            // height: '300px',
                            padding: '100px',
                            margin: 'auto',
                            background: '#fff',
                        }}
                    >
                        <Button
                            type="primary"
                            style={{
                                display: 'block',
                                width: '200px',
                                margin: '0 auto 10px',
                            }}
                            onClick={() => history.push('/accountLogin')}
                        >
                            登录
                        </Button>
                        <Button
                            style={{
                                display: 'block',
                                width: '200px',
                                margin: '0 auto 30px',
                                // background: 'rgb(238, 238, 238)',
                                // color: 'rgb(141, 141, 141)',
                            }}
                            onClick={() => history.push('/register')}
                        >
                            使用其他帐号注册
                        </Button>
                        <Alert
                            showIcon
                            message="已存在瑞鹿云帐号，请直接登录并输入组织申请码，申请加入组织"
                        />
                    </div>
                </>)}
                {(step === 1 && cacheUser) && (<>
                    <div
                        style={{
                            margin: '80px 30% 60px',
                        }}
                    >
                        <h1>申请加入机构</h1>
                        <p>欢迎申请加入机构，请填写机构申请码</p>
                    </div>
                    <div
                        style={{
                            width: '800px',
                            // height: '300px',
                            padding: '80px 50px',
                            margin: 'auto',
                            background: '#fff',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: '50px',
                                height: '32px',
                                lineHeight: '32px',
                            }}
                        >
                            <span
                                style={{
                                    flex: '1 0 auto',
                                }}
                            >
                                <i style={{color: 'red'}}> * </i>
                                机构申请码：
                            </span>
                            <Input
                                onChange={(e) => { setSystemCode(e.target.value)}}
                                value={systemCode}
                                style={{
                                    margin: '0 10px'
                                }}
                                placeholder="请输入机构申请码"
                            />
                            <Button
                                loading={loading}
                                type="primary"
                                onClick={handlePreSubmit}
                            >
                                申请加入
                            </Button>
                        </div>
                        <Alert
                            showIcon
                            message="请输入机构申请码并申请加入，如不知道机构申请码，请联系申请加入机构的管理员索要。"
                        />
                    </div>
                </>)}
                {step === 2 && (<>
                    <div
                        style={{
                            margin: '80px 30% 60px',
                        }}
                    >
                        <h1>申请加入机构</h1>
                        <p>欢迎申请加入机构，请填写机构申请码</p>
                    </div>
                    <div
                        style={{
                            width: '800px',
                            // height: '300px',
                            padding: '80px 50px',
                            margin: 'auto',
                            background: '#fff',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: '50px',
                                height: '32px',
                                lineHeight: '32px',
                            }}
                        >
                            <span
                                style={{
                                    flex: '1 0 auto',
                                }}
                            >
                                <i style={{color: 'red'}}> * </i>
                                机构申请码：
                            </span>
                            <Input
                                disabled
                                value={systemCode}
                                style={{
                                    margin: '0 10px'
                                }}
                                placeholder="请输入机构申请码"
                            />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                background: 'rgb(246, 251, 255)',
                                padding: '30px',
                            }}
                        >
                            {orgInfo
                                ? <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        您申请加入的机构为
                                        <span
                                            style={{
                                                fontSize: '18px',
                                                color: 'rgb(61, 188, 143)',
                                            }}
                                        >
                                            {orgInfo?.companyName || ""}
                                        </span>
                                        机构联系人为
                                        <span
                                            style={{
                                                fontSize: '18px',
                                                color: 'rgb(61, 188, 143)',
                                            }}
                                        >
                                            {orgInfo?.contact || ""}
                                        </span>
                                    </div>
                                : <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        marginBottom: '20px',
                                    }}>
                                    您申请加入的机构为
                                    <span style={{color: 'rgb(61, 188, 143)'}}>瑞鹿云运营平台</span>
                                </div>}
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Button
                                    loading={loading}
                                    type="primary"
                                    style={{marginRight: 10}}
                                    onClick={handleSubmit}
                                >
                                    确认加入
                                </Button>
                                <Button
                                    onClick={() => history.push('/index')}
                                >
                                    返回控制台
                                </Button>
                            </div>
                        </div>
                    </div>
                </>)}
                {step === 3 && (<>
                    <div
                        style={{
                            margin: '80px 30% 60px',
                        }}
                    >
                        <h1>申请加入机构</h1>
                        <p>欢迎申请加入机构</p>
                    </div>
                    <div
                        style={{
                            width: '800px',
                            margin: '20px auto',
                            padding: '80px 50px',
                            background: '#fff',
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <div>
                                <CheckCircleFilled
                                    style={{
                                        color: 'rgb(82, 196, 27)',
                                        fontSize: '40px'
                                    }}
                                />
                                <h1 style={{fontWeight: 'bold'}}>提交成功</h1>
                            </div>
                            <p
                                style={{
                                    textAlign: 'center',
                                    width: '80%',
                                    margin: '20px auto',
                                }}
                            >
                                {orgInfo ?
                                    <>
                                        您已申请加入
                                        <span style={{color: 'rgb(61, 188, 143)'}}>
                                            {orgInfo.companyName}
                                        </span>，
                                        请耐心等待
                                        {orgInfo?.companyName}
                                        管理员审核，审核通过后，即可登录进入
                                        {orgInfo?.companyName}
                                        团队办公空间。
                                    </> : <>
                                        您已申请加入
                                        <span style={{color: 'rgb(61, 188, 143)'}}>瑞鹿云运营平台</span>，
                                        请耐心等待管理员审核，审核通过后，即可登录进入瑞鹿云运营平台。
                                    </>}
                            </p>
                            {/* <Button
                                type="primary"
                                style={{marginRight: 10}}
                                onClick={() => setStep(1)}
                            >
                                上一页
                            </Button> */}
                            <Button onClick={() => history.push('/index')}>返回控制台</Button>
                        </div>
                    </div>
                </>)}

            </Content>

            <AppFooter />
        </Layout>
    );
};
