/**
 * @file   我的产品模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React from 'react';
import { ProductList } from '../../pages/productShop/components/ProductList';

// 我的产品页面组件
export const MyProduct = () => {
    return (
        <>
            <div className="page-header">
                <div className="title">我的产品</div>
            </div>
            {/* 我的产品中为已订购产品 */}
            <ProductList route="MyProduct" />
        </>
    );
};
