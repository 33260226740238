/**
 * @file   登陆状态模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/23
 */
import { createSlice } from '@reduxjs/toolkit';
import { accountLogIn, phoneLogin, queryUserInfo, saasLogout } from '../../service';

import { notification } from 'antd';
import { localDS } from '../../storage';
import { showEditPwdTip } from '../../store/globStore'
// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;

// 用户状态
const initialState = {
    user: null,
    backLink: "",
};

// 登陆状态Slice
const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
            // 登录用户数据存储到本地
            localDS.set('saasUser', JSON.stringify(state.user));
        },
        clearUser(state) {
            state.user = initialState.user;
            localDS.remove('saasUser');
        },
        setBackLink(state, action) {
            state.backLink = action.payload;
        }
    }
});

// 登陆状态actions
export const { setUser, clearUser } = loginSlice.actions;

/**
 * @description 微信用户扫码直接登录
 * @param {Object} payload 登陆参数
 * @returns {Boolean} 是否登录成功
 */
 export const wechatUserLogin = () => async (dispatch) => {
    try {
        const userRes = await queryUserInfo();
        dispatch(setUser(userRes.data?.data));
        if (userRes.data?.data?.phoneInvalid) {
            notification.warn({
                className: 'custom-antd-notification',
                message: "您的手机号已失效，请确认后及时进行手机号验证！",
                duration: 5,
            })
        } else {
            notification.success({
                className: 'custom-antd-notification',
                message: "登录成功！",
                duration: 5,
            })
        }

        return true;
    } catch (error) {
        return false;
    }
}

// const loginFail = () => {
//     const infoStr = localDS.get('loginMetaInfo');
//     if (!infoStr) {
//         localDS.set('loginMetaInfo', JSON.stringify({
//             failCount: 1,
//         }));
//         return;
//     }
//     const info = JSON.parse(infoStr);
//     info.failCount += 1;
//     localDS.set('loginMetaInfo', JSON.stringify(info));
// }

// const loginSuccess = () => {
//     const infoStr = localDS.get('loginMetaInfo');
//     if (!infoStr) {
//         localDS.set('loginMetaInfo', JSON.stringify({
//             failCount: 0,
//         }));
//         return;
//     }
//     const info = JSON.parse(infoStr);
//     info.failCount = 0;
//     localDS.set('loginMetaInfo', JSON.stringify(info));
// }
/**
 * @description 用户登陆
 * @param {payload}} payload 登陆参数
 */
export const login = (payload) => async (dispatch) => {
    let loginRes;
    console.log('login')
    try {
        if (payload.phone) {
            loginRes = await phoneLogin(payload);
        } else {
            loginRes = await accountLogIn(payload);
        }
        if (loginRes.data.code !== 200) {
            // loginFail()
            notification.warn({
                className: 'custom-antd-notification',
                message: loginRes.data.msg,
                duration: 5,
            })

            return false;
        }
        // loginSuccess()
        const authc = loginRes.data?.data?.authorizationc
        /* eslint no-debugger: "warn" */
        debugger;
        if (!authc) {
            return false
        }

        await dispatch(afterLogin(loginRes));
        return true;
    } catch (error) {

        notification.warn({
            className: 'custom-antd-notification',
            message: "登录失败！",
            duration: 5,
        })
        return false;
    }
};

export const afterLogin = (loginRes) => async (dispatch) => {
    const authc = loginRes.data?.data?.authorizationc || ""
    const authu = loginRes.data?.data?.authorizationu || ""
    const systemId = loginRes.data?.data?.systemId || ""
    const hasDefaultPassword = loginRes.data?.data?.defaultPassword
    const defaultPassword = loginRes.data?.data?.pwd
    /* eslint no-debugger: "warn" */
    // debugger;

    localDS.set('saasToken', authc);
    localDS.set('gatewayToken', authu);
    localDS.set('systemId', systemId)
    const userRes = await queryUserInfo();
    dispatch(setUser(userRes.data?.data));

    // TODO
    if (hasDefaultPassword) {
        dispatch(showEditPwdTip({ pwd: defaultPassword }))
        return;
    }

    if (authu && systemId) {
        let targetLink;
        if (systemId === '0') {
            targetLink = platformAddr + `?token=${authu}&systemId=${systemId}`;
        } else {
            targetLink = customerAddr + `?token=${authu}&systemId=${systemId}`;
        }
        console.log("targetLink: ", targetLink)
        window.location.href = targetLink;
    }
    if (userRes.data?.data?.phoneInvalid) {
        notification.warn({
            className: 'custom-antd-notification',
            message: "您的手机号已失效，请确认后及时进行手机号验证！",
            duration: 5,
        })
    } else {
        notification.success({
            className: 'custom-antd-notification',
            message: "登录成功！",
            duration: 5,
        })
    }
}

/**
 * @description 获取用户详情
 * @returns {Object} user
 */
export const getUserDetail = () => {
    const u = localDS.get('saasUser');
    if (u) {
        return JSON.parse(u);
    } else {
        return null;
    }
}

/**
 * @description 缓存用户详情
 * @returns {Object} user
 */
 export const cacheUserDetail = (user) => {
    localDS.set('saasUser', JSON.stringify(user))
}

/**
 * @description 获取登录的相关信息
 * @returns {Object} login info object
 */
export const getLoginMetaInfo = () => {
    const i = localDS.get('loginMetaInfo');
    if (i) {
        return JSON.parse(i);
    } else {
        return null;
    }
}

/**
 * 退出登录
 */
export const logout = () => async (dispatch) => {
    saasLogout().then(() => {
        localDS.remove('saasToken');
        localDS.remove('gatewayToken');
        localDS.remove('systemId')
        dispatch(clearUser());
    })
};

export default loginSlice.reducer;
