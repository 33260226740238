/**
 * @file   积分商城订单成功模块
 * @create tianmu 2021/7/2
 * @update tianmu 2021/7/2
 */
import React from 'react';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { toFixedNumStr } from '../../utils';
import { ScoreLayout } from './components/ScoreLayout';
import './index.scss';

// 积分商城订单成功组件
export const ScoreProductOrderSuccess = () => {
    const order = {};
    return (
        <ScoreLayout>
            <div className="orderinfobox-wrapper">
                <div className="orderinfobox">
                    <div className="head">
                        <div
                            style={{
                                display: 'inline-block',
                                width: '70px',
                                height: '80px',
                                lineHeight: '100px',
                                fontSize: '50px',
                                color: 'rgb(61, 188, 142)'
                            }}
                        >
                            <CheckCircleFilled />
                        </div>
                        <div className="infobox">
                            <h1>订单提交成功，请您尽快完成付款！</h1>
                        </div>
                    </div>
                    <div>
                        <fieldset className="common-fieldset">
                            <legend>订单信息</legend>
                            <div className="info-row">
                                <div className="info-label">订单号：</div>
                                <div className="info-value">{order?.orderNo}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">商品名称：</div>
                                <div className="info-value">{order?.orderTitle}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">商品规格：</div>
                                <div className="info-value">{order?.createTime}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">应付金额：</div>
                                <div className="info-value">
                                    ￥{toFixedNumStr(order?.orderCost, 2)}
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '20px'
                    }}
                >
                    <Button type="primary" style={{ marginRight: '20px' }}>
                        立即支付
                    </Button>
                </div>
                <p style={{width: '100%', textAlign: 'center', marginTop: 20}}>请尽快支付完成交易。30分钟内未完成，订单将自动取消。</p>
            </div>
        </ScoreLayout>
    );
};
