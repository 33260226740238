/**
 * @file   积分商城布局模块
 * @create tianmu 2021/7/1
 * @update tianmu 2021/7/1
 */
import React from 'react';
import { BackTop, Layout } from 'antd';
import { AppHeader } from '../../../../containers/AppHeader';

const { Content } = Layout;

// 积分商城页面布局组件
export const ScoreLayout = (props) => {
    const { title, header, children } = props;
    return (
        <Layout className="app">
            <BackTop />
            <AppHeader />
            {header}
            <Content
                style={{
                    width: 1200,
                    margin: `${header ? '0' : '80px'} auto 0`
                }}
            >
                {title && (
                    <div className="page-header">
                        <div className="title">{title}</div>
                    </div>
                )}
                {children}
            </Content>
        </Layout>
    );
};
