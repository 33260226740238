/**
 * @file   会员积分规则页面
 * @create tianmu 2021/7/2
 * @update tianmu 2021/7/2
 */
import React from 'react';
import { Alert } from 'antd';
import { ScoreLayout } from './components/ScoreLayout';
import './index.scss';

/**
 * @description 会员积分规则组件
 */
export const ScoreRule = () => {
    return (
        <ScoreLayout>
            <div className="scorerule">
                <Alert
                    message="平台用户积分制度，本制度于2020年6月1日正式实施，用户须在使用前仔细阅读并在使用中严格遵照执行"
                    type="info"
                    showIcon
                />
                <h4 style={{ marginTop: 20 }}>总体说明</h4>
                <p>
                    本平台面向社会免费开放，设置积分是对用户关注本平台和参与本平台项目的一种鼓励，获得的积分可在云平台积分商城中兑换提供的各类福利项目或参与抢购平台优质活动入场票等。
                </p>
                <h4>积分获取说明</h4>
                <li>1.用户完成注册+10分。由好友推荐并成功注册的，推荐人+5分；</li>
                <li>2.用户实名认证+15分。由好友推荐并成功认证的，推荐人+5分；</li>
                <li>3.户在云平台成功预约活动并现场成功签到+10分/次；</li>
                <li>4.用户在云平台成功预约场馆并成功签到+10分/次；</li>
                <li>
                    5.用户在云平台成功报名慕课培训的+1分/课程，每月不超过15分。当月累计观看鉴赏资源每超过5分钟+1分，上限10分；
                </li>
                <li>
                    6.用户在云平台观看（收听）鉴赏资源的+1分/课程，每月不超过15分。当月累计观看鉴赏资源每超过5分钟+1分，上限10分；
                </li>
                <li>
                    7.用户在云平台观看网络直播（回顾）的+1分/节目，每月不超过15分。当月累计观看直播（回顾）时长每超过5分钟+1分，上限10分；
                </li>
                <li>8.用户在云平台观看全景展厅的+1分/个，每月不超过15分；</li>
                <li>
                    9.用户参与文化活动、使用场馆之后，对活动、场馆服务等作出评价并成功通过审核的+1分/条；
                </li>
                <li>10.用户申请成为志愿者并通过审核的+10分；</li>
                <li>11.用户报名并签到参与志愿服务的+10分/次；</li>
                <li>
                    12.成功邀请好友关注“江苏公共文化云”微信公众号并在1个月内未取消关注的，邀请人+2分/人；
                </li>
                <li>
                    13.通过分享链接成功邀请好友报名活动、预约场馆、观看资源、观看直播的，邀请人+1分/人。
                </li>
                <h4>积分扣除说明</h4>
                <li>
                    1.用户在预约活动后至活动开始前24小时取消预约不扣分，在活动开始前24小时内取消或未取消也未参加活动的-10分；
                </li>
                <li>2.用户成功预约场馆48小时前取消不扣分，48小时内取消-10分；</li>
                <li>
                    3.用户报名志愿服务活动至活动开始前48小时取消预约不扣分，在活动开始前48小时内取消或未取消也未参加活动的-10分；
                </li>
                <li>4.违反国家法律法规的行为，发现一次将扣除全部积分。</li>
                <h4>积分兑换说明</h4>
                <li>1.积分可用于兑换福利票，通过抵扣相应的积分获取演出票、入场票等；</li>
                <li>
                    2.部分热门活动入场票只有达到一定积分数量的用户才可参与抢票，积分不足的用户可通过好友赠送获得足够的积分；
                </li>
                <li>3.积分商城中的福利项目，仅可通过积分交易兑换获取；</li>
                <li>4.部分福利项目每人每月仅可兑换1次。</li>

            </div>
        </ScoreLayout>
    );
};
