/**
 * @file   邀请加入机构页面
 * @create tianmu 2021/4/7
 * @update tianmu 2020/4/7
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Layout, Modal, notification, Spin } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { AppFooter } from '../../containers/AppFooter';
import { approveAuthInvite, customerLogin, queryAuthInviteById, updateName } from '../../service';
import { searchStrToObj } from '../../utils';
import { localDS } from '../../storage';
import { AppHeader } from '../../containers/AppHeader';
import { getUserDetail } from '../login/store';
import { useDispatch } from 'react-redux';
import { showEditNameTip } from '../../store/globStore';

// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;

const { Content } = Layout;

/**
 * @description 邀请加入机构组件
 */
export const InviteJoin = () => {
    const location = useLocation();
    const history = useHistory();
    const [inviteContent, setInviteContent] = useState("");
    const [authInviteInfo, setAuthInviteInfo] = useState(null);
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();

    const cacheUser = getUserDetail();

    // 正在获取验证信息
    const [authLoading, setAuthLoading] = useState(false);
    // 提交机构申请
    const handleSubmit = async (status) => {
        if (cacheUser?.randomName) {
            dispatch(showEditNameTip())
            return;
        }

        const o = searchStrToObj(location.search);
        if (!o?.businessId) return;
        const res = await approveAuthInvite({
            inviteId: o.businessId,
            // 同意邀请
            approveStatus: status
        });
        if (res.data.code === 200) {
            await fetchAuthInviteInfo();
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
        }
    };

    const fetchAuthInviteInfo = useCallback(async () => {
        const o = searchStrToObj(location.search)
        if (!o?.businessId) return;
        setAuthLoading(true)
        const res = await queryAuthInviteById(o.businessId)
        if (res.data.code === 200) {
            const d = res.data.data;
            if (d.approveStatus === 1) {
                setInviteContent(o?.content ?? "");
                setStep(1);
            } else if (d.approveStatus === 2) {
                setAuthInviteInfo(d);
                setStep(2);
            } else if (d.approveStatus === 3) {
                setAuthInviteInfo(d);
                setStep(3);
            }
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
        }
        setAuthLoading(false)
    }, [location.search])

    useEffect(() => {
        fetchAuthInviteInfo()
    }, [fetchAuthInviteInfo]);

    return (
        <Layout
            className="app"
            style={{
                background: 'rgb(241, 242, 246)'
            }}
        >
            {/* <Header className="layout-header" style={{ position: 'relative' }}>
                <div className="logo-box">
                    <a className="logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                </div>
            </Header> */}
            <AppHeader />
            {authLoading ? <Spin /> : (
                <Content
                    style={{
                        position: 'relative',
                        // width: '1190px',
                        width: '100%',
                        margin: 'auto',
                        height: '100%'
                    }}
                >
                    <div
                        style={{
                            margin: '80px 30% 60px',
                        }}
                    >
                        <h1>邀请加入</h1>
                        <p>欢迎加入瑞鹿云产品团队！</p>
                    </div>
                    {step === 1 && (
                        <div
                            style={{
                                width: '800px',
                                padding: '80px 50px',
                                margin: 'auto',
                                background: '#fff',
                            }}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <p
                                    style={{
                                        textAlign: 'center',
                                        width: '80%',
                                        margin: '20px auto',
                                    }}
                                >
                                    {decodeURIComponent(inviteContent)}
                                </p>
                                <Button
                                    type="primary"
                                    style={{marginRight: 10}}
                                    onClick={() => { handleSubmit(2) }}
                                >
                                    同意
                                </Button>
                                <Button
                                    onClick={() => { handleSubmit(3) }}
                                >
                                    拒绝
                                </Button>
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div
                            style={{
                                width: '800px',
                                padding: '80px 50px',
                                margin: 'auto',
                                background: '#fff',
                            }}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <h1
                                    style={{
                                        textAlign: 'center',
                                        width: '80%',
                                        margin: '20px auto',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    您已同意加入【{authInviteInfo?.systemName}】云产品团队！
                                </h1>
                                <p
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    待【{authInviteInfo?.systemName}】管理员分配权限后，您就可以点击
                                    <a
                                        href="/#"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            const token = localDS.get('saasToken')
                                            if (!token) {
                                                notification.warn({
                                                    className: 'custom-antd-notification',
                                                    description: '已退出登录或登录超时，请重新登录',
                                                    duration: 5
                                                });
                                                window.location.href = "/accountLogin"
                                                return
                                            }
                                            const systemId = authInviteInfo?.systemId
                                            customerLogin(systemId).then((res) => {
                                                // 请求错误直接返回
                                                if (res.data.code !== 200) {
                                                    notification.warn({
                                                        className: 'custom-antd-notification',
                                                        // description:
                                                        // '请先购买或试用产品再进入管理后台',
                                                        description: res.data.msg,
                                                        duration: 5,
                                                    })
                                                    return;
                                                }
                                                const d = res.data.data
                                                if (d?.authorizationu) {
                                                    let loc = ""
                                                    if (systemId === "0") {
                                                        loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${systemId}`;
                                                    } else {
                                                        loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${systemId}`;
                                                    }
                                                    localDS.set('gatewayToken', d?.authorizationu);
                                                    localDS.set('systemId', systemId)
                                                    window.open(loc);
                                                }
                                            });

                                        }}
                                    >
                                    【{authInviteInfo?.systemName}】
                                    </a>
                                    进入云产品系统
                                </p>
                            </div>
                        </div>
                    )}
                    {step === 3 && (
                        <div
                            style={{
                                width: '800px',
                                padding: '80px 50px',
                                margin: 'auto',
                                background: '#fff',
                            }}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <h1
                                    style={{
                                        textAlign: 'center',
                                        width: '80%',
                                        margin: '20px auto',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    您已拒绝加入【{authInviteInfo?.systemName}】云产品团队！
                                </h1>
                            </div>
                        </div>
                    )}

                </Content>
            )}
            <AppFooter />
        </Layout>
    );
};
