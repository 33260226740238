/**
 * @file   帐号密码登陆模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/23
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Input, Form, Button, Checkbox, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { queryNeedVCode, queryVCode } from '../../service';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { getLoginMetaInfo, login } from './store';
import { localDS, sessionDS } from '../../storage';
import WechatLoginICON from '../../assets/wechat-login-icon.png';
import WechatCornerIcon from '../../assets/wechat-corner.svg';
import WechatScanLogin from '../../assets/wechatscan-login.png';
import CloseIcon from '../../assets/close-icon.svg';

const FAIL_LIMIT = 2
// const WECHAT_LOGIN_APPID = process.env.ENV_WECHAT_LOGIN_APPID;
// const WECHAT_LOGIN_ADDR = process.env.ENV_WECHAT_LOGIN_ADDR;
const WECHAT_LOGIN_APPID = window.WECHAT_LOGIN_APPID;
const WECHAT_LOGIN_ADDR = window.WECHAT_LOGIN_ADDR;

// 帐号密码登陆表单组件
export const AccountForm = (props) => {
    // const nav = useNavigate();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [keepLogin, setKeepLogin] = useState(false);
    const [wechatScan, setWechatScan] = useState(false);
    const [validateCode, setValidateCode] = useState(null)

    const fetchNeedValidate = async () => {
        const res = await queryNeedVCode()
        return res.data?.data
    }

    const fetchValidateCode = async () => {
        const res = await queryVCode();
        if (res.data.code === 200) {
            setValidateCode(res.data.data)
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
        }
    }

    const init = useCallback(async () => {
        // const info = getLoginMetaInfo();
        // const isNeed = info && info.failCount > FAIL_LIMIT
        const isNeed = await fetchNeedValidate()
        if (isNeed) {
            fetchValidateCode()
        }
    }, [])

    useEffect(() => {
        init()
    }, [init])

    // 表单提交成功
    const onFinish = async (values) => {
        setLoading(true);
        let vid;
        if (validateCode) {
            vid = validateCode.vid
        }
        else {
            const vcodeRes = await queryVCode();
            if (vcodeRes?.data?.code === 200) {
                vid = vcodeRes.data?.data?.vid;
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: vcodeRes?.data?.msg,
                    duration: 5
                });
            }
        }
        localDS.set('saasKeepLogin', keepLogin)
        const res = await dispatch(
            login({
                ...values,
                vid
            })
        );
        if (res) {
            // 登录成功
            const searchStr = location.search;
            const o = {};
            if (searchStr) {
                let s = searchStr.substr(1);
                s.split('&').forEach((p) => {
                    const [k, v] = p.split('=');
                    o[k] = v;
                });
            }
            if (o.redirect) {
                const u = decodeURIComponent(o.redirect);
                const targetLink = u + `?token=${localDS.get('gatewayToken')}`;
                // console.log("targetLink: ", targetLink)
                window.location.href = targetLink;
            } else {
                history.replace('/');
            }
            // const redirectUrl =
            // (location?.state)?.from?.pathname || "/index";
            // history.go(redirectUrl);
        } else {
            // 重新验证
            // fetchValidateCode()
            init()
        }
        setLoading(false);
    };
    // 表单提交失败
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="login-panel">
            <h3 className="login-title">帐号登录</h3>
            <a
                className="wechat-corner"
                href={`https://open.weixin.qq.com/connect/qrconnect?appid=${WECHAT_LOGIN_APPID
                }&redirect_uri=${encodeURIComponent(WECHAT_LOGIN_ADDR)
                }&response_type=code&scope=snsapi_login&state=123#wechat_redirect`}
            >
                <img src={WechatCornerIcon} alt="" />
            </a>
            {/* <a
                className="wechat-corner"
                href="/#"
                onClick={(ev) => {
                    ev.preventDefault();
                    setWechatScan(true);
                }}
            >
                <img src={WechatCornerIcon} alt="" />
            </a> */}
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: '帐号不能为空!' }]}
                    wrapperCol={{ offset: 4, span: 16 }}
                >
                    <Input
                        placeholder="请输入用户名或手机号"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '密码不能为空!' }]}
                    wrapperCol={{ offset: 4, span: 16 }}
                >
                    <Input.Password
                        placeholder="请输入密码"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        size="large"
                    />
                </Form.Item>
                {validateCode && (
                    <Form.Item
                        name="vcode"
                        rules={[
                            {
                                required: true,
                                message: '验证码不能为空!'
                            },
                        ]}
                        wrapperCol={{ offset: 4, span: 16 }}
                    >
                        <Input
                            placeholder="验证码"
                            size="large"
                            suffix={
                                <img
                                    style={{
                                        width: '100px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        fetchValidateCode()
                                    }}
                                    src={"data:image/png;base64," + validateCode?.image}
                                    alt="暂无图片"
                                />
                            }
                        />
                    </Form.Item>
                )}
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Checkbox
                        className='login-checkbox'
                        onChange={(e) => {
                            setKeepLogin(e.target.checked)
                        }}
                    >
                        保持登录
                    </Checkbox>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button
                        style={{
                            background: '#00a2fe',
                            border: 'none',
                            color: '#fff'
                        }}
                        block
                        size="large"
                        htmlType="submit"
                        loading={loading}
                    >
                        登录
                    </Button>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <div className="login-type">
                        <div>
                            <Link style={{color: '#00a2fe'}} to="/phoneLogin">验证码登录</Link>
                            <a style={{color: '#00a2fe'}} href={`https://open.weixin.qq.com/connect/qrconnect?appid=${WECHAT_LOGIN_APPID
                                }&redirect_uri=${encodeURIComponent(WECHAT_LOGIN_ADDR)
                                }&response_type=code&scope=snsapi_login&state=123#wechat_redirect`}>微信授权登录</a>
                        </div>

                        <div>
                            <Link to="/changePwd">忘记密码</Link>
                            <Link to="/accountComplain" >帐号申诉</Link>
                            <Link to="/register">免费注册</Link>
                        </div>
                    </div>
                </Form.Item>
            </Form>
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                登录即同意瑞鹿云 <Link style={{color: '#00a2fe'}} to='/PrivacyPolicy' target='_blank'>《隐私政策声明》</Link>
            </div>
            {/* </>)} */}
            {wechatScan && (
                <div className="wechatscan-login">
                    <h3 className="login-title">微信登录</h3>
                    <img style={{ margin: 'auto' }} src={WechatScanLogin} alt="" />
                    <p>请使用微信扫描二维码登录</p>
                    <a
                        href="/#"
                        className="close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setWechatScan(false);
                        }}
                    >
                        <img src={CloseIcon} alt="" />
                    </a>
                </div>
            )}
        </div>
    );
};
