/**
 * @file   帐号申诉模块
 * @create tianmu 2021/6/8
 * @update tianmu 2021/6/8
 */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MobileOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, notification, Radio, Row, Select, Steps, Space, Modal } from 'antd';
import { queryCustomerByPhone, querySmsVCode, submitAppeal } from '../../service';
import { AppFooter } from '../../containers/AppFooter';
import Logo from '../../assets/logo.png';
import { Link, useHistory } from 'react-router-dom';
import { InfoBox, STATUS_ENUM } from './InfoBox';
import './index.scss';
import { validator } from '../../utils';

const CryptoJS = require('crypto-js');

const { Content, Header } = Layout;

const Steppers = (props) => {
    const { stepNum } = props;
    return (
        <Steps labelPlacement="vertical" current={stepNum - 1}>
            <Steps.Step title="填写申诉材料" />
            <Steps.Step title="申诉提交完成" />
        </Steps>
    );
};

const Step0 = (props) => {
    const { setStepNum } = props;

    return (
        <div className="account-complain-stepbox step0">
            <h1>申诉帐号说明</h1>
            <h3>申诉适用范围：</h3>
            <p>1.忘记帐号密码(原手机号失效)，或者帐号被盗(手机号和密码均被修改)。</p>
            <p>2.注册的时候，手机号被他人占用而无法注册。</p>
            <h3>申诉提交信息：</h3>
            <p>需要你提供帐号历史信息、联系人信息、新的手机号等，资料越详细，申诉越容易通过。</p>
            <h3>申诉所需时间：</h3>
            <p>预计审核时间：1-3个工作日，申诉结果会在审核后，立即通过短信发送至您的手机。</p>

            <div className="account-complain-confirmbox">
                <p className="account-complain-hint">开始申诉即表示您已同意以上条款，请仔细阅读!</p>
                <Button
                    type="primary"
                    className="account-complain-confirmbtn"
                    onClick={() => {
                        setStepNum(1);
                    }}
                >
                    开始申诉
                </Button>
                <div style={{ textAlign: 'right', marginTop: 10 }}>
                    <Link style={{marginRight: 20}} to="/accountComplainReset">重置安全码</Link>
                    <Link to="/accountComplainQuery">申诉进度查询</Link>
                </div>
            </div>
        </div>
    );
};
// 发送cd时间
const TIME_LIMIT = 60;
const MACHINE_AUTH_VID = process.env.ENV_MACHINE_AUTH_VID

const Step1 = (props) => {
    const { setStepNum, setComplainState } = props;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [isSending, setSending] = useState(false);
    const [time, setTime] = useState(TIME_LIMIT);
    const ref = useRef(null);
    const [rowsDataSource, setRowsDataSource] = useState([
        {
            type: 1,
            value: '',
        }
    ])
    const [appealReason, setAppealReason] = useState(1)
    const [validating, setValidating] = useState(false)
    // const [isAuth, setAuth] = useState(false);
    const [users, setUsers] = useState([]);
    const [currUser, setCurrUser] = useState(null);

    useEffect(() => {
        // window
        //     .vaptcha({
        //         vid: MACHINE_AUTH_VID, // 验证单元id
        //         type: 'click', // 显示类型 点击式
        //         scene: 0, // 场景值 默认0
        //         container: '#vaptchaContainer', // 容器，可为Element 或者 selector
        //         offline_server: '' // 离线模式服务端地址，若尚未配置离线模式，请填写任意地址即可。
        //         // 可选参数
        //         // lang: 'auto', // 语言 默认auto,可选值auto,zh-CN,en,zh-TW,jp
        //         // https: true, // 使用https 默认 true
        //         // style: 'dark' //按钮样式 默认dark，可选值 dark,light
        //         // color: '#57ABFF' //按钮颜色 默认值#57ABFF
        //     })
        //     .then(function (vaptchaObj) {
        //         // const obj = vaptchaObj; // 将VAPTCHA验证实例保存到局部变量中
        //         vaptchaObj.render(); // 调用验证实例 vpObj 的 render 方法加载验证按钮
        //         // 获取token的方式一：
        //         // vaptchaObj.renderTokenInput('.login-form')//以form的方式提交数据时，使用此函数向表单添加token值
        //         // 获取token的方式二：
        //         vaptchaObj.listen('pass', function () {
        //             // 验证成功进行后续操作
        //             //   const data = {
        //             //     // 表单数据
        //             //     token: vaptchaObj.getToken(),
        //             //   };
        //             setAuth(true);
        //             // eslint-disable-next-line max-nested-callbacks
        //             setTimeout(() => {
        //                 // vaptcha验证 3 分钟后失效
        //                 setAuth(false)
        //             }, 1000 * 60 * 3)
        //         });
        //         // 关闭验证弹窗时触发
        //         vaptchaObj.listen('close', function () {
        //             // 验证弹窗关闭触发
        //         });
        //     });

        // return () => {
        //     // 销毁组件时如果存在定时器求删除它
        //     if (ref.current) {
        //         window.clearInterval(ref.current);
        //     }
        // };
    }, []);

    // 获取手机验证码
    const query = useCallback(async (phone) => {
        const res = await querySmsVCode(phone, 3);
        // 根据返回的错误代码显示错误信息
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '验证码发送成功，请注意查收！',
                duration: 5
            });

            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return false;
        }
    }, []);

    // 处理点击获取验证码按钮事件
    const handleClick = useCallback(
        async (phone) => {
            if (isSending) return;
            setSending(true);
            const success = await query(phone);
            // 如果请求验证码不成功直接返回
            if (!success) {
                setSending(false);
                return;
            }
            ref.current = setInterval(() => {
                setTime((time) => {
                    const left = time - 1;
                    // 点击验证码之后TIME_LIMIT秒
                    if (left === 0) {
                        setTime(TIME_LIMIT);
                        window.clearInterval(ref.current);
                        setSending(left > 0);
                    }
                    return left;
                });
            }, 1000);
        },
        [query, isSending]
    );


    const handleSubmit = async () => {
        // 开启展示验证结果到Input底部
        setValidating(true)
        // 验证Form中的字段
        await form.validateFields()
        // 验证动态添加的字段(不在Form中)不为空
        if (rowsDataSource.some(d => !d.value)) return
        // 验证通过，关闭展示验证结果
        setValidating(false)
        setLoading(true);
        const values = form.getFieldsValue();
        submitAppeal(
            values.vCode,
            {
                appealPhone: values.appealPhone,
                appealCustomerId: currUser?.value,
                appealReason: values.appealReason,
                phone: values.phone,
                details: rowsDataSource.map(
                    s => {
                        let v = s.value
                        if (s.type === 1) {
                            v = CryptoJS.MD5(v).toString().toUpperCase()
                        }
                        return {
                            content: v,
                            type: s.type,
                        }
                    }
                )
            }
        ).then(res => {
            if (res.data.code === 200) {
                notification.success({
                    className: 'custom-antd-notification',
                    message: '提交成功！',
                    duration: 5,
                })
                setComplainState(res.data?.data)
                setStepNum(2)
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: res.data.msg,
                    duration: 5,
                })
            }
            setLoading(false);
        })
    }

    return (
        <div className="account-complain-stepbox step1">
            <Form layout="horizontal" form={form} initialValues={{appealReason, appealPhone: '', phone: ''}} >
                <Row>
                    <Col span={[4]} className="account-complain-formlabel">
                        申诉原因：
                    </Col>
                    <Col span={[18]}>
                        <Form.Item
                            name="appealReason"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择您的申诉原因!'
                                }
                            ]}
                        >
                            <Radio.Group
                                onChange={(e) => {
                                    const v = e.target.value
                                    form.setFieldsValue({
                                        appealReason: v,
                                    })
                                    setAppealReason(v)
                                    if (v === 1) {
                                        setRowsDataSource([{
                                            type: 1,
                                            value: '',
                                        }])
                                    } else {
                                        setRowsDataSource([{
                                            type: 9,
                                            value: '',
                                        }])
                                    }
                                }
                            }>
                                <Radio value={1}>注册手机号失效</Radio>
                                <Radio value={2}>手机号被占用</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={[4]} className="account-complain-formlabel">
                        申诉帐号手机号：
                    </Col>
                    <Col span={[20]}>
                        <Form.Item
                            name="appealPhone"
                            rules={[
                                () => ({
                                    validator: async (rule, value) => {
                                        if (!value) {
                                            return Promise.reject(new Error('接收信息手机号不能为空!'));
                                        }
                                        if (!validator().phoneNumRegex.test(value)) {
                                            return Promise.reject(new Error('请输入正确的手机号!'));
                                        }
                                        // 此时没有点击提交，要去去查询手机对应的账户供用户选择
                                        const res = await queryCustomerByPhone(value);
                                        if (res.data.code === 200) {
                                            const users = res.data.data || []
                                            if (users.length === 0) {
                                                return Promise.reject(new Error("请输入正确的申诉帐号手机号!"))
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                        return Promise.reject(new Error("请输入正确的申诉帐号手机号!"))
                                    }
                                })
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="请输入申诉帐号注册手机号"
                                onChange={async (ev) => {
                                    const v = ev.target.value;
                                    if (!validator().phoneNumRegex.test(v)) return;
                                    const res = await queryCustomerByPhone(v);
                                    if (res.data.code === 200) {
                                        const users = res.data.data || []
                                        if (users.length === 0) {
                                            setCurrUser(null);
                                            setUsers([]);
                                        } else if (users.length === 1) {
                                            setCurrUser(users[0]);
                                            setUsers([]);
                                        } else {
                                            setCurrUser(users[0]);
                                            setUsers(users);
                                        }
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {currUser && (
                    <Row style={{marginBottom: 20}}>
                        <Col span={[4]} className="account-complain-formlabel">
                            申诉帐号：
                        </Col>
                        <Col span={[20]} style={{textAlign: 'left', paddingTop: '7px'}}>
                            {currUser.label}
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col span={[4]} className="account-complain-formlabel">
                        接收信息手机号：
                    </Col>
                    <Col span={[20]}>
                        <Form.Item
                            name="phone"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value) {
                                            return Promise.reject(new Error('接收信息手机号不能为空!'));
                                        }
                                        if (!validator().phoneNumRegex.test(value)) {
                                            return Promise.reject(new Error('请输入正确的手机号!'));
                                        }
                                        if (appealReason === 1) {
                                            if (!value || getFieldValue('appealPhone') !== value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('申诉帐号手机号和接收信息手机号应不同!'));
                                        } else {
                                            if (!value || getFieldValue('appealPhone') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('申诉帐号手机号和接收信息手机号应相同!'));
                                        }
                                    }
                                })
                            ]}
                        >
                            <Input size="large" placeholder="请输入接收信息手机号" />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
                    <div id="vaptchaContainer" style={{ width: '100%', height: 40 }}>
                        <div className="vaptcha-init-main">
                            <div
                                className="vaptcha-init-loading"
                                style={{ textAlign: 'center', lineHeight: '40px' }}
                            >
                                <img
                                    src="https://r.vaptcha.net/public/img/vaptcha-loading.gif"
                                    alt="vaptcha"
                                />

                                <span className="vaptcha-text" style={{ paddingLeft: '5px' }}>
                                    正在生成验证...
                                </span>
                            </div>
                        </div>
                    </div>
                </Form.Item> */}
                <Row>
                    <Col span={[4]} className="account-complain-formlabel">
                        验证码：
                    </Col>
                    <Col span={[16]}>
                        <Form.Item
                            name="vCode"
                            rules={[
                                {
                                    required: true,
                                    message: '验证码不能为空!'
                                },
                                {
                                    len: 6,
                                    message: '验证码长度为6位！'
                                }
                            ]}
                        >
                            <Input
                                placeholder="验证码"
                                className="sendcodeinput"
                                prefix={<MobileOutlined className="site-form-item-icon" />}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={[4]} style={{ textAlign: 'right' }}>
                        <Button
                            size="large"
                            className={`sendcodebtn ${isSending
                                // || !isAuth
                                ? '' : 'active'}`}
                            disabled={isSending
                                // || !isAuth
                            }
                            onClick={() => {
                                // console.log("click");
                                const phone = form.getFieldValue('phone');
                                // 手机号不为空时处理验证码获取事件
                                if (phone) {
                                    handleClick(phone);
                                } else {
                                    notification.info({
                                        className: 'custom-antd-notification',
                                        message: '请先输入接收信息手机号',
                                        duration: 5
                                    });
                                }
                            }}
                        >
                            {isSending ? `${time}秒后重新发送` : '发送验证码'}
                        </Button>
                    </Col>
                </Row>
                <p style={{marginBottom: 10}}>
                    为了帐号安全，请尽量多提供帐号使用资料以帮助我们判断你是号码主人，每种类型可填写多个，而非盗号者，即使你对某些答案不确定，也可提供你认为正确的答案。
                </p>
                <div className="account-complain-inputlist">
                    {rowsDataSource.map((r, idx) => (
                        <Row
                            key={idx}
                            className="account-complain-inputrow"
                            style={{position: 'relative', height: 60}}
                        >
                            <Col span={[6]}>
                                {/* 1:历史密码 2:历史手机号 3:联系人姓名 4:联系人手机号 5:联系人证件号 9:其他 */}
                                <Select
                                    value={r.type}
                                    style={{ width: '100%' }}
                                    onChange={(v) => {
                                        setRowsDataSource(
                                            rowsDataSource.map((d, i) =>
                                                i === idx ? { ...d, type: v } : d
                                            )
                                        );
                                    }}
                                >
                                    {appealReason === 1 && (<>
                                        <Select.Option key={1} value={1}>
                                            帐号历史密码
                                        </Select.Option>
                                        <Select.Option key={2} value={2}>
                                            历史手机号
                                        </Select.Option>
                                        <Select.Option key={3} value={3}>
                                            联系人姓名
                                        </Select.Option>
                                        <Select.Option key={4} value={4}>
                                            联系人手机号
                                        </Select.Option>
                                        <Select.Option key={5} value={5}>
                                            联系人证件号
                                        </Select.Option>
                                    </>)}
                                    <Select.Option key={9} value={9}>
                                        其它
                                    </Select.Option>
                                </Select>
                            </Col>
                            <Col span={[1]}>
                                <span>:</span>
                            </Col>
                            <Col span={[17]}>
                                <Form.Item
                                    validateStatus={validating && !rowsDataSource[idx].value ? 'error' : 'success'}
                                    help={validating && !rowsDataSource[idx].value ? '内容不能为空' : ''}
                                >
                                    <Input
                                        value={r.value}
                                        placeholder=""
                                        onChange={(e) => {
                                            setRowsDataSource(
                                                rowsDataSource.map((d, i) =>
                                                    i === idx ? { ...d, value: e.target.value } : d
                                                )
                                            )
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {/* 至少有一个原因和内容，因此第一个不能删除 */}
                            {idx !== 0 && (
                                <span
                                    style={{
                                        position: 'absolute',
                                        width: 32,
                                        height: 32,
                                        lineHeight: '26px',
                                        right: -40,
                                        top: 0,
                                        fontSize: 20,
                                        borderRadius: '50%',
                                        border: '2px solid rgb(97, 201, 160)',
                                        color: 'rgb(97, 201, 160)',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setRowsDataSource(
                                            rowsDataSource.filter((v, i) => i !== idx)
                                        )
                                    }}
                                >
                                    <DeleteOutlined />
                                </span>
                            )}
                        </Row>
                    ))}
                </div>
                <div
                    className="accont-complain-addrow"
                    onClick={() => {
                        const r = form.getFieldValue('appealReason');
                        setRowsDataSource([
                            ...rowsDataSource,
                            {
                                type: r === 1 ? 1 : 9,
                                value: '',
                            },
                        ]);
                    }}
                >
                    <PlusCircleOutlined style={{ fontSize: 28 }} />
                </div>
                <div className="account-complain-confirmbox">
                    <Button
                        // disabled={!isAuth}
                        type="primary"
                        className="account-complain-confirmbtn"
                        loading={loading}
                        onClick={handleSubmit}
                    >
                        下一步
                    </Button>
                </div>
            </Form>
            <Modal
                title="请选择申诉帐号"
                centered
                width={700}
                closable={false}
                onCancel={() => { setUsers([]) }}
                footer={<Button type="primary" onClick={() => setUsers([]) }>确定</Button>}
                visible={Array.isArray(users) && users.length > 1}
            >
                <div className="selectUserWrap">
                <Radio.Group
                    onChange={(e) => setCurrUser(users.find(u => u.value === e.target.value))}
                    value={currUser?.value}
                >
                    <Space direction="vertical">
                        {users.map((u,i) => (
                            <Radio key={i} value={u.value}>{u.label}</Radio>
                        ))}
                    </Space>
                </Radio.Group>

                </div>
            </Modal>
        </div>
    );
};

const Step2 = (props) => {
    const { complainState } = props;
    const history = useHistory()
    return (
        <div className="account-complain-stepbox infobox">
            <InfoBox
                title="申诉提交成功！"
                description="工作人员会在1-3个工作日内完成审核，审核后，申诉结果会立即通过短信发送至您的手机，请关注手机短信"
                status={STATUS_ENUM.AUTHED}
                extra={<>
                        <div style={{fontSize: 20, color: '#3dbc8e'}}>
                            安全码：{complainState?.security}
                        </div>
                        <div style={{textAlign: 'center', marginTop: 20}}>
                            <Button
                                onClick={() => {
                                    history.push('/')
                                }}
                            >
                                返回首页
                            </Button>
                        </div>
                </>}
            />
        </div>
    );
};


// 帐号申诉组件
export const AccountComplain = () => {
    const [stepNum, setStepNum] = useState(0);
    const [complainState, setComplainState] = useState(null)
    return (
        <Layout className="account-complain">
            <Header className="layout-header">
                <div className="logo-box">
                    <a className="logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                </div>
            </Header>
            <Content className="account-complain-content">
                <div className="page-header">
                    <div className="title">帐号申诉</div>
                </div>
                <div className="page-content">
                    {stepNum > 0 && (
                        <div className="account-complain-steppers">
                            <Steppers stepNum={stepNum} />
                        </div>
                    )}
                    {stepNum === 0 && <Step0 setStepNum={setStepNum} />}
                    {stepNum === 1 && <Step1 setStepNum={setStepNum} complainState={complainState} setComplainState={setComplainState} />}
                    {stepNum === 2 && <Step2 setStepNum={setStepNum} complainState={complainState} setComplainState={setComplainState} />}
                </div>
            </Content>
            <AppFooter />
        </Layout>
    );
};
