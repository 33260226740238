/**
 * @file   注册表单模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/23
 */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Input, Form, Button, Checkbox, notification } from 'antd';
import { UserOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons';
import { querySmsVCode, register } from '../../service';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { searchStrToObj, validator } from '../../utils';

// 发送cd时间
const TIME_LIMIT = 60;

// 密码强度
const STRONG_PWD = 2;
const MIDDLE_PWD = 1;
const WEAK_PWD = 0;
const NONE_PWD = 3;
const PWD_TIP = '请至少使用大小写字母、数字、符号两种类型组合的密码，长度至少为8位。';

const Tip = (status) => {
    switch (status) {
        case STRONG_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(153, 204, 153)' }}>当前密码强度：强</i>
                </span>
            );
        case MIDDLE_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(255, 180, 64)' }}>当前密码强度：中等</i>
                </span>
            );
        case WEAK_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(255, 43, 77)' }}>当前密码强度：弱</i>
                </span>
            );
        default:
            return PWD_TIP;
    }
};

// 验证密码强度
const validatePassword = (pwd) => {
    // 空输入框
    if (!pwd)
        return {
            status: NONE_PWD,
            msg: Tip(NONE_PWD)
        };

    // 密码为八位及以上并且大小写字母数字特殊字符三项都包括
    const strongRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/g;
    // 密码为八位及以上并且大小写字母、数字、特殊字符三项中有两项，强度是中等
    const mediumRegex = /^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*\W))|((?=.*[0-9])(?=.*\W))|((?=.*[A-Z])(?=.*\W))).*$/g;
    const enoughRegex = /(?=.{8,}).*/g;
    if (strongRegex.test(pwd)) {
        // console.log('强密码-----',value)
        return {
            status: STRONG_PWD,
            msg: Tip(STRONG_PWD)
        };
    } else if (mediumRegex.test(pwd)) {
        // console.log('中等密码-----',value)
        return {
            status: MIDDLE_PWD,
            msg: Tip(MIDDLE_PWD)
        };
    } else if (enoughRegex.test(pwd)) {
        // console.log('弱密码-----',value)
        return {
            status: WEAK_PWD,
            msg: Tip(WEAK_PWD)
        };
    } else {
        // console.log('密码-----',value)
        return {
            status: WEAK_PWD,
            msg: Tip(WEAK_PWD)
        };
    }
};

const MACHINE_AUTH_VID = process.env.ENV_MACHINE_AUTH_VID

/**
 * @description 手机注册表单
 * @param {Object} props 父组件传递对象
 */
export const RegisterForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [agree, setAgree] = useState(false);
    const [isSending, setSending] = useState(false);
    // const [isAuth, setAuth] = useState(false);
    const [time, setTime] = useState(TIME_LIMIT);
    const ref = useRef(null);
    const [form] = Form.useForm();
    const [openId, setOpenId] = useState('');
    const location = useLocation();
    const history = useHistory();

    const [pwdStatus, setPwdStatus] = useState({
        validateStatus: null,
        value: '',
        msg: PWD_TIP
    });

    const onPwdChange = (e) => {
        const validateInfo = validatePassword(e.target.value);
        const isOk = validateInfo.status !== WEAK_PWD && validateInfo.status !== NONE_PWD;
        // console.log("validate: ", validateInfo)
        setPwdStatus({
            validateStatus: isOk ? 'success' : 'error',
            value: validateInfo.value,
            msg: validateInfo.msg
        });
    };

    // 获取手机验证码
    const query = useCallback(async (phone) => {
        const res = await querySmsVCode(phone, 2);
        // 根据返回的错误代码显示错误信息
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '验证码发送成功，请注意查收！',
                duration: 5
            });

            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return false;
        }
    }, []);

    // 处理点击获取验证码按钮事件
    const handleClick = useCallback(
        async (phone) => {
            if (isSending) return;
            setSending(true);
            const success = await query(phone);
            // 如果请求验证码不成功直接返回
            if (!success) {
                setSending(false);
                return;
            }
            ref.current = setInterval(() => {
                setTime((time) => {
                    const left = time - 1;
                    // 点击验证码之后TIME_LIMIT秒
                    if (left === 0) {
                        setTime(TIME_LIMIT);
                        window.clearInterval(ref.current);
                        setSending(left > 0);
                    }
                    return left;
                });
            }, 1000);
        },
        [query, isSending]
    );

    useEffect(() => {
        const searchStr = location.search;
        const o = searchStrToObj(searchStr);
        if (o && o.openId) {
            setOpenId(o.openId);
        } else {
            setOpenId('');
        }
        // 2021/10/15 取消vaptcha验证
        // window
        //     .vaptcha({
        //         vid: MACHINE_AUTH_VID, // 验证单元id
        //         type: 'click', // 显示类型 点击式
        //         scene: 0, // 场景值 默认0
        //         container: '#vaptchaContainer', // 容器，可为Element 或者 selector
        //         offline_server: '' // 离线模式服务端地址，若尚未配置离线模式，请填写任意地址即可。
        //         // 可选参数
        //         // lang: 'auto', // 语言 默认auto,可选值auto,zh-CN,en,zh-TW,jp
        //         // https: true, // 使用https 默认 true
        //         // style: 'dark' //按钮样式 默认dark，可选值 dark,light
        //         // color: '#57ABFF' //按钮颜色 默认值#57ABFF
        //     })
        //     .then(function (vaptchaObj) {
        //         // const obj = vaptchaObj; // 将VAPTCHA验证实例保存到局部变量中
        //         vaptchaObj.render(); // 调用验证实例 vpObj 的 render 方法加载验证按钮
        //         // 获取token的方式一：
        //         // vaptchaObj.renderTokenInput('.login-form')//以form的方式提交数据时，使用此函数向表单添加token值
        //         // 获取token的方式二：
        //         vaptchaObj.listen('pass', function () {
        //             // 验证成功进行后续操作
        //             //   const data = {
        //             //     // 表单数据
        //             //     token: vaptchaObj.getToken(),
        //             //   };
        //             setAuth(true);
        //             // eslint-disable-next-line max-nested-callbacks
        //             setTimeout(() => {
        //                 // vaptcha验证 3 分钟后失效
        //                 setAuth(false)
        //             }, 1000 * 60 * 3)
        //         });
        //         // 关闭验证弹窗时触发
        //         vaptchaObj.listen('close', function () {
        //             // 验证弹窗关闭触发
        //         });
        //     });

        // return () => {
        //     // 销毁组件时如果存在定时器求删除它
        //     if (ref.current) {
        //         window.clearInterval(ref.current);
        //     }
        // };
    }, [location.search]);

    // 表单提交成功
    const onFinish = (values) => {
        // 有 openId 时自动设置密码
        if (!openId) {
            // 密码强度未达标
            const isPwdOk = pwdStatus?.validateStatus === 'success';
            if (!isPwdOk) return;
        }
        setLoading(true);
        let registerParams = {
            // username: values.username,
            password: values.password,
            phone: values.phone,
            vCode: values.vCode
        };
        if (openId) {
            registerParams = {
                ...registerParams,
                openId: openId
            };
        }
        register({
            ...registerParams
        })
            .then((res) => {
                if (res.data.code === 200) {
                    // 注册成功
                    notification.success({
                        className: 'custom-antd-notification',
                        message: '注册成功！',
                        duration: 5
                    });
                    setLoading(false);
                    // if (openId) {
                    //     history.replace(
                    //         `/InitAccount?username=${registerParams.username}&password=${registerParams.password}`
                    //     );
                    // } else {
                    history.replace('/accountLogin');
                    // }
                } else {
                    notification.warn({
                        className: 'custom-antd-notification',
                        message: res.data.msg,
                        duration: 5
                    });

                    setLoading(false);
                }
            })
            .catch((res) => {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: '注册失败！',
                    duration: 5
                });
                setLoading(false);
            });
    };
    // 表单提交失败
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            {/* 2021/10/20 注册不需要输入用户名，系统自动生成随机用户名 */}
            {/* <Form.Item
                name="username"
                rules={[
                    { required: true, message: '请输入用户名!' },
                    () => ({
                        validator(rule, value) {
                            const isNum = validator().numberRegex.test(value)
                            const isPhone = validator().phoneNumRegex.test(value)
                            if (isNum || isPhone) {
                                return Promise.reject(new Error('用户名不能是纯数字！'));
                            }
                            return Promise.resolve();
                        }
                    })

                ]}
                wrapperCol={{ offset: 4, span: 16 }}
            >
                <Input
                    placeholder="用户名"
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                />
            </Form.Item> */}
            <Form.Item
                name="phone"
                rules={[
                    {
                        required: true,
                        pattern: validator().phoneNumRegex,
                        message: '请输入正确的手机号!'
                    }
                ]}
                wrapperCol={{ offset: 4, span: 16 }}
            >
                <Input
                    placeholder="请输入手机号"
                    size="large"
                    prefix={<MobileOutlined className="site-form-item-icon" />}
                />
            </Form.Item>
            {/* <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <div id="vaptchaContainer" style={{ width: '100%', height: 40 }}>
                    <div className="vaptcha-init-main">
                        <div
                            className="vaptcha-init-loading"
                            style={{ textAlign: 'center', lineHeight: '40px' }}
                        >
                            <img
                                src="https://r.vaptcha.net/public/img/vaptcha-loading.gif"
                                alt="vaptcha"
                            />

                            <span className="vaptcha-text" style={{ paddingLeft: '5px' }}>
                                正在生成验证...
                            </span>
                        </div>
                    </div>
                </div>
            </Form.Item> */}
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Form.Item
                    name="vCode"
                    rules={[
                        {
                            required: true,
                            message: '验证码不能为空!'
                        },
                        {
                            len: 6,
                            message: '验证码长度为6位！'
                        }
                    ]}
                    noStyle
                >
                    <Input
                        placeholder="验证码"
                        className="sendcodeinput"
                        prefix={<MobileOutlined className="site-form-item-icon" />}
                        size="large"
                    />
                </Form.Item>
                <Button
                    size="large"
                    className={`sendcodebtn ${isSending
                        //  || !isAuth
                         ? '' : 'active'}`}
                    disabled={isSending
                        // || !isAuth
                    }
                    style={isSending ? null : {
                        background: '#00a2fe',
                        border: 'none',
                    }}
                    onClick={() => {
                        // console.log("click");
                        const phone = form.getFieldValue('phone');
                        // 手机号不为空时处理验证码获取事件
                        if (phone) {
                            handleClick(phone);
                        } else {
                            notification.info({
                                className: 'custom-antd-notification',
                                message: '请先输入手机号',
                                duration: 5
                            });
                        }
                    }}
                >
                    {isSending ? `${time}秒后重新发送` : '发送验证码'}
                </Button>
            </Form.Item>
            {!openId && (<>
                <Form.Item
                    name="password"
                    // hidden={openId ? true : false}
                    rules={[{ required: true, message: '请输入密码' }]}
                    wrapperCol={{ offset: 4, span: 16 }}
                    validateStatus={pwdStatus.validateStatus}
                    help={pwdStatus.msg}
                >
                    <Input.Password
                        placeholder="密码"
                        size="large"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        value={pwdStatus.value}
                        onChange={onPwdChange}
                    />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    // hidden={openId ? true : false}
                    dependencies={['password']}
                    hasFeedback
                    wrapperCol={{ offset: 4, span: 16 }}
                    rules={[
                        {
                            required: true,
                            message: '请确认你的密码!'
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('两次输入密码不同!'));
                            }
                        })
                    ]}
                >
                    <Input.Password
                        placeholder="重新输入密码"
                        size="large"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>
            </>)}
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Checkbox
                    className='register-checkbox'
                    checked={agree}
                    onChange={(e) => {
                        setAgree(e.target.checked);
                    }}
                >
                    我已阅读并同意
                    {/* <a href="/service-items.html" target="_blank">《瑞鹿云用户协议》</a>和 */}
                    {/* <a href="/privacy-items.html" target="_blank">《隐私政策声明》</a> */}
                    <Link style={{color: '#00a2fe'}}  to='/ServiceItems' target='_blank'>《瑞鹿云用户协议》</Link>
                    <Link style={{color: '#00a2fe'}}  to='/PrivacyPolicy' target='_blank'>《隐私政策声明》</Link>
                </Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button
                    disabled={!agree}
                    style={!agree ? null : {
                        background: '#00a2fe',
                        border: 'none',
                    }}
                    type="primary"
                    block
                    size="large"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                >
                    同意协议并注册
                </Button>
            </Form.Item>
        </Form>
    );
};
