/**
 * @file   产品介绍欢迎页面header组件
 * @create tianmu 2021/3/10
 * @update tianmu 2021/3/10
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LOGO from '../../assets/logo.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Avatar, Button, Divider, Dropdown, notification } from 'antd';
import { getUserDetail, logout } from '../../pages/login/store';
import { customerLogin, guestLogin, queryNotice, querySystems, updateDefaultSystem, updateNoticeStatus } from '../../service';
import { localDS } from '../../storage';
import { createFromIconfontCN, UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
// const guestAddr = process.env.ENV_GUEST_LOGIN;

const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;
const guestAddr = window.GUEST_LOGIN;

const IconFont = createFromIconfontCN({
    scriptUrl: window.ICONFONT_URL
});

/**
 * @description 产品介绍欢迎Header组件
 */
export const IntroHeader = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [systems, setSystems] = useState([]);
    const cachedUser = useMemo(() => getUserDetail(), [])

    const fetchInfo = useCallback(async () => {
        const sysRes = await querySystems();
        if (sysRes.data.code === 200) {
            setSystems(sysRes.data.data || []);
        }
    }, []);

    useEffect(() => {
        if (cachedUser) {
            // 登录状态
            fetchInfo();
        }
    }, [cachedUser, fetchInfo]);

    const ConsoleMenuDropdown = (
        <div className="user-dropdown">
            <div className="user-dropdown-info">
                <Avatar icon={<UserOutlined />} alt="avatar" />
                <span className="name">{cachedUser?.customerName}</span>
            </div>
            <div className="user-dropdown-flex">
                <Link to="/UserCenter?key=1" className="user-dropdown-flexitem">
                    基本信息
                </Link>
                <Link to="/Organization" className="user-dropdown-flexitem">
                    机构认证
                </Link>
                <Link to="/UserCenter?key=3" className="user-dropdown-flexitem">
                    安全设置
                </Link>
            </div>
            <div className="user-dropdown-list">
                <Link to="/MyOrder" className="user-dropdown-listitem">
                    {/* <img src={OrderIcon} alt="" /> */}
                    <IconFont type="iconwodedingdan" />
                    <span>我的订单</span>
                </Link>
                <Link to="/UserCenter?key=4" className="user-dropdown-listitem">
                    <IconFont type="iconbangdingshezhi" />
                    <span>绑定设置</span>
                </Link>
                <Link to="/UserCenter?key=5" className="user-dropdown-listitem">
                    <IconFont type="icondenglurizhi" />
                    <span>登录日志</span>
                </Link>
                <Link to="/UserCenter?key=2" className="user-dropdown-listitem">
                    <IconFont type="iconxiugaimima" />
                    <span>修改密码</span>
                </Link>
            </div>
            <Divider />
            <div className="user-dropdown-extra">
                {systems && systems.length > 0 && (
                    <>
                        <div>
                            所属机构
                            <span
                                style={{
                                    fontSize: '12px',
                                    color: 'rgb(206, 203, 202)'
                                }}
                            >
                                （您可以点击机构直接跳转管理后台，也可以加入新机构）
                            </span>
                        </div>
                        {systems.map((i) => (
                            <div
                                key={i.systemId}
                                className="user-dropdown-extraline"
                                onClick={() => {
                                    const token = localDS.get('saasToken')
                                    if (!token) {
                                        notification.warn({
                                            className: 'custom-antd-notification',
                                            description: '已退出登录或登录超时，请重新登录',
                                            duration: 5
                                        });
                                        window.location.href = "/accountLogin"
                                        return
                                    }
                                    customerLogin(i.systemId).then((res) => {
                                        // 请求错误直接返回
                                        if (res.data.code !== 200) {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                // description:
                                                // '请先购买或试用产品再进入管理后台',
                                                description: res.data.msg,
                                                duration: 5
                                            });
                                            return;
                                        }
                                        const d = res.data.data;
                                        if (d?.authorizationu) {
                                            let loc = '';
                                            if (i.systemId === '0') {
                                                loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${i.systemId}&customerId=${cachedUser.customerId}`;
                                            } else {
                                                loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${i.systemId}&customerId=${cachedUser.customerId}`;
                                            }
                                            localDS.set('gatewayToken', d?.authorizationu);
                                            localDS.set('systemId', i.systemId)
                                            window.open(loc);
                                        }
                                    });
                                }}
                            >
                                <span className="left">{i.systemName}</span>
                                {i.isHome ? (
                                    <span
                                        className="right isdefault-btn"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    >
                                        默认
                                    </span>
                                ) : (
                                    <span
                                        className="right notdefault-btn"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            updateDefaultSystem(i.systemId).then((res) => {
                                                if (res.data.code === 200) {
                                                    notification.success({
                                                        className: 'custom-antd-notification',
                                                        message: '设置默认机构成功',
                                                        duration: 5
                                                    });
                                                    fetchInfo();
                                                } else {
                                                    notification.warn({
                                                        className: 'custom-antd-notification',
                                                        message: '设置默认机构失败',
                                                        description: res.data.msg,
                                                        duration: 5
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        设置默认
                                    </span>
                                )}
                                <span className="right tag">机构</span>
                            </div>
                        ))}
                    </>
                )}
                <div
                    className="user-dropdown-extrabtn"
                    onClick={() => {
                        history.push('/Join');
                    }}
                >
                    + 加入新机构
                </div>
            </div>
            <Button
                block
                className="user-dropdown-exit"
                onClick={() => {
                    dispatch(logout());
                    history.push('/accountLogin');
                }}
            >
                退出登录
            </Button>
        </div>
    );

    return (
        <header className="introduction-header layout-header">
            <div className="header-inner">
                <div className="head-l">
                    <Link to="/" className="logo">
                        <img src={LOGO} alt="" />
                    </Link>
                </div>
                <div className="head-r">
                    {cachedUser ? (
                        <div className="entry">
                            <a
                                href="/#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    guestLogin().then((res) => {
                                        // 请求错误直接返回
                                        if (res.data.code !== 200) {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                description: res.data.msg,
                                                duration: 5
                                            });
                                            return;
                                        }
                                        const d = res.data.data;
                                        if (d?.authorizationu && d?.systemId) {
                                            let loc = `${guestAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`
                                            window.open(loc);
                                        }
                                    });
                                }}

                            >
                                进入体验环境
                            </a>
                            {systems && systems.length > 0 && (
                                <a
                                    href="/#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        customerLogin().then((res) => {
                                            // 请求错误直接返回
                                            if (res.data.code !== 200) {
                                                notification.warn({
                                                    className: 'custom-antd-notification',
                                                    // description:
                                                    // '请先购买或试用产品再进入管理后台',
                                                    description: res.data.msg,
                                                    duration: 5
                                                });
                                                return;
                                            }
                                            const d = res.data.data;
                                            if (d?.authorizationu && d?.systemId) {
                                                let loc = '';
                                                if (d?.systemId === '0') {
                                                    loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`;
                                                } else {
                                                    loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`;
                                                }
                                                localDS.set('gatewayToken', d?.authorizationu);
                                                localDS.set('systemId', d?.systemId)
                                                window.open(loc);
                                            }
                                        });
                                    }}
                                >
                                    进入管理后台
                                </a>
                            )}
                            <Link to="/index">进入控制台</Link>
                            <div style={{ display: 'inline-flex', marginLeft: 20 }}>
                                <Dropdown overlay={ConsoleMenuDropdown}>
                                    <div className="dropdown-link" style={{ cursor: 'pointer' }}>
                                        <Avatar icon={<UserOutlined />} alt="avatar" />
                                        <span className="name" style={{ marginLeft: 5 }}>{cachedUser?.customerName}</span>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    ) : (
                        <div className="entry">
                            <Link to="/accountLogin">登录</Link>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};
