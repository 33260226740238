/**
 * @file   接口服务模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */

import { guestHttp, http, saasHttp, testHttp } from './http';

const CryptoJS = require('crypto-js');

// const BASIC_GATEWAY = process.env.ENV_BASIC_GATEWAY;
// const GUEST_GATEWAY = process.env.ENV_GUEST_GATEWAY;
// const FILE_GATEWAY = process.env.ENV_FILE_GATEWAY;
// export const FILE_PREVIEW_GATEWAY = process.env.ENV_FILE_PREVIEW;

const BASIC_GATEWAY = window.BASIC_GATEWAY;
const GUEST_GATEWAY = window.GUEST_GATEWAY;
const FILE_GATEWAY = window.FILE_GATEWAY;
export const FILE_PREVIEW_GATEWAY = window.FILE_PREVIEW;

/**************************************************
 *
 *  登录注册相关  接口
 *
 ***************************************************/

/**
 * @description 获取图片验证码
 * @returns {Promise<Response>} - 响应
 */
export function queryVCode() {
    return saasHttp.get(`/saas/queryVCode`);
}

/**
 * @description 查询登录时是否需要传递验证码
 * @returns {Promise<Response>} - 响应
 */
export function queryNeedVCode() {
    return saasHttp.get(`/saas/queryNeedVCode`);
}

/**
 * @description 获取手机验证码(PC web )
 * @param {number} phone - 手机号
 * @param {Number} 验证类型
 * 1. 注册 —— 手机号不存在则发送
 * 2. 登录 —— 手机号存在（有效或者失效）则发送
 * 3. 申诉 —— 当申诉开关开启则发送
 * 4. 验证微信登录
 * 5. 忘记密码
 * @returns {Promise<Response>} - 响应
 */
export function querySmsVCode(phone, type) {
    return saasHttp.get(`/saas/querySmsVCode?phone=${phone}&type=${type}`);
}

/**
 * @export
 * @description 帐号密码登录
 * @param {Object} Params - 注册所需参数
 * @param {string} Params.username - 用户名
 * @param {string} Params.password - 密码
 * @param {string} Params.vid - 验证码id
 * @returns {Promise<Response>} - 响应
 */
export function accountLogIn({ username, password, vid, vcode }) {
    let params = {
        name: username,
        loginPassword: CryptoJS.MD5(
            CryptoJS.MD5(password)
                .toString()
                .toUpperCase() + vid.toUpperCase()
        )
            .toString()
            .toUpperCase(),
        vid: vid
    };
    return saasHttp.post(
        `/saas/login?name=${params.name}&loginPassword=${params.loginPassword}&vid=${params.vid}${
            vcode ? '&vcode=' + vcode : ''
        }`
    );
}

/**
 * @export
 * @description 手机号登录
 * @param {Object} Params - 注册所需参数
 * @param {string} Params.phone - 手机号
 * @param {string} Params.svCode - 手机号验证码
 * @returns {Promise<Response>} - 响应
 */
export function phoneLogin({ phone, svCode }) {
    const params = {
        phone: phone,
        svCode: svCode
    };
    return saasHttp.post(`/saas/svcodeLogin?phone=${params.phone}&svCode=${params.svCode}`);
}

/**
 * @export
 * @description SaaS用户注销
 * @returns {Promise<Response>} - 响应
 */
export function saasLogout() {
    return saasHttp.get(`/saas/logout`);
}

/**
 * @export
 * @description PC端注册
 * @param {Object} Params - 注册所需参数
 * @param {number} Params.phone - 手机号码
 * @param {number} Params.password - 密码
 * @param {string} Params.vcode - 手机验证码
 * @returns {Promise<Response>} - 响应
 */
export function register({ phone, password, vCode, openId }) {
    let p = {
        // customerName: username,
        customerPhone: phone,
        loginPassword: CryptoJS.MD5(password).toString() // 密码需要md5加密
    };
    if (openId) {
        p = {
            ...p,
            appOpenid: {
                openId: openId,
                type: 1
            }
        };
    }
    return saasHttp.post(`/saas/regist?vCode=${vCode}`, p);
}

/**
 * @export
 * @description 找回密码
 * @param {Object} Params - 注册所需参数
 * @param {number} Params.phone - 手机号码
 * @param {number} Params.password - 新密码
 * @param {string} Params.svCode - 手机验证码
 * @returns {Promise<Response>} - 响应
 */
export function changePwd({ phone, svCode, password }) {
    const params = {
        phone,
        svCode,
        newPassword: CryptoJS.MD5(password).toString()
    };
    return saasHttp.post(
        `/saas/changePwd?phone=${params.phone}&svCode=${params.svCode}&newPassword=${params.newPassword}`
    );
}

/***************************************
 *
 * 微信绑定相关
 */
/**
 * @description 微信授权登录
 * @param {String} code 微信openId
 * @returns  {Promise<Response>} - 响应
 */
export function wechatLogin(code) {
    return saasHttp.post(`/saas/wechatLogin`, code, {
        headers: {
            'Content-Type': 'application/text'
        }
    });
}

export function wechatSVCodeLogin(phone, svCode, openId="") {
    return saasHttp.post(`/saas/svcodeLogin?phone=${phone}&svCode=${svCode}&openId=${openId}`, {});
}


/**
 * @description 绑定微信
 * @param {String} code 微信的授权码
 * @returns  {Promise<Response>} - 响应
 */
export function saveWxOfficialAccount(code) {
    return saasHttp.post(`/customer/saveWxOfficialAccount?code=${code}`, {});
}

/**
 * @description 绑定微信平台
 * @param {String} code 微信的授权码
 * @returns  {Promise<Response>} - 响应
 */
export function saveWxOpenPlatform(code) {
    return saasHttp.post(
        `/customer/saveWxOpenPlatform?code=${code}`,
        {}
        // {
        //     headers: {
        //         "AuthorizationC": localDS.get("saasToken"),
        //         "AuthorizationU": localDS.get("gatewayToken"),
        //     }
        // }
    );
}

/**
 * @description 微信绑定信息列表
 * @returns  {Promise<Response>} - 响应
 */
export function wechatBindList() {
    return saasHttp.post(`/customer/queryById`, {});
}

/**
 * @description 解除微信绑定
 * @param {String} id openId
 * @returns  {Promise<Response>} - 响应
 */
// export function unbindWechat(id) {
//     return saasHttp.post(`/appOpenid/deleteBatch`, {
//         delIds: [id]
//     });
// }
export function unbindWechat(phone, svCode, id) {
    return saasHttp.post(`/appOpenid/unboundWx`, {
        phone,
        svCode,
        id,
    });
}


/**************************************************
 *
 *  用户个人中心相关  接口
 *
 ***************************************************/

/**
 * @description saas用户一键登录后台
 * @param {String} systemId 登录租户的系统id
 * @returns {Promise<Response>} - 响应
 */
export function customerLogin(systemId) {
    let url = `/${BASIC_GATEWAY}/sw/customerLogin`;
    if (systemId) {
        url = url + `?systemId=${systemId}`;
    }
    return http.post(url, {});
}

/**
 * @description GUEST用户一键登录后台
 * @returns {Promise<Response>} - 响应
 */
export function guestLogin() {
    let url = `/${GUEST_GATEWAY}/sw/guestLogin`;
    return guestHttp.post(url, {});
}

/**
 * @description 查询用户基本信息
 * @returns {Promise<Response>} - 响应
 */
export function queryUserInfo() {
    // 传递空请求体即可，有后台jwt作为登录凭证
    return saasHttp.post(`/customer/queryById`, {});
}

/**
 * @description 更新用户基本信息
 * @param {Object} params 请求参数
 * @returns {Promise<Response>} - 响应
 */
export function updateUserInfo(params) {
    return saasHttp.post(`/customer/updateById`, params);
}

/**
 * @description 修改用户名
 * @param {Object} params 请求参数
 * @returns {Promise<Response>} - 响应
 */
export function updateName(params) {
    return saasHttp.post(
        `/customer/updateName?isRetain=${params.isRetain}${
            params.name ? `&newName=${params.name}` : ''
        }`,
        {
            // isRetain: params.isRetain,
            // name: params.name,
        }
    );
}

/**
 * @description 登录日志查询
 * @param {Object} params 请求参数
 * @returns {Promise<Response>} - 响应
 */
export function queryLoginLogPageList(params) {
    return saasHttp.post(`/loginLog/queryPageList`, params);
}

/**
 * @description 修改手机号
 * @param {Object} params 请求参数
 * @returns {Promise<Response>} - 响应
 */
export function updatePhone(params) {
    return saasHttp.get(
        `/customer/updatePhone?oldSvCode=${params.oldSvCode}&newPhone=${params.newPhone}&svCode=${params.svCode}`,
        params
    );
}

/**
 * @description 重新认证手机号
 * @param {Object} params 请求参数
 * @returns {Promise<Response>} - 响应
 */
export function updateAuthPhone(params) {
    return saasHttp.get(
        `/customer/updateAuthPhone?newPhone=${params.newPhone}&svCode=${params.svCode}`,
        params
    );
}

/**************************************************
 *
 *  帐号申诉相关接口
 *
 ***************************************************/

/**
 * @description 查询申诉单
 * @param {String} phone - 手机号
 * @returns {Promise<Response>} - 响应
 */
export function queryAppeal(phone, params) {
    return saasHttp.post(`/appeal/queryAppeal?phone=${phone}`, params, {
        headers: {
            'content-type': 'text/html'
        }
    });
}

/**
 * @description 根据手机号查询会员(Basic admin)
 * @param {String} phone - 手机号
 * @returns {Promise<Response>} - 响应
 */
export function queryCustomerByPhone(phone) {
    return saasHttp.get(`/appeal/queryCustomerByPhone?phone=${phone}`);
}

/**
 * @description 提交帐号申诉
 * @param {String} vCode - 短信验证码
 * @param {Object} params - 实体参数
 * @returns {Promise<Response>} - 响应
 */
export function submitAppeal(vCode, params) {
    return saasHttp.post(`/appeal/submit?vCode=${vCode}`, params);
}

/**
 * @description 提交帐号申诉
 * @param {Object} params - 实体参数
 * @returns {Promise<Response>} - 响应
 */
export function submitAppealDetail(params) {
    return saasHttp.post(`/appeal/submitDetail`, params);
}

/**
 * @description 确认申诉单并修改登录密码
 * @param {Object} params - 实体参数
 * @returns {Promise<Response>} - 响应
 */
export function updateAppeal(phone, params) {
    return saasHttp.post(`/appeal/updateAppeal?phone=${phone}`, params);
}

/**
 * @description 重置申诉安全码
 * @param {Object} params - 实体参数
 * @returns {Promise<Response>} - 响应
 */
export function resetSecurity(params) {
    return saasHttp.get(
        `/appeal/resetSecurity?phone=${params.phone}&appealPhone=${params.appealPhone}`
    );
}

/**************************************************
 *
 *  文件上传相关  接口
 *
 ***************************************************/

/**
 * @export
 * @description 文件上传
 * @param {Object} filedata - 文件对象(表单文件输入框上传的内容)
 * @returns {Promise<Response>} - 响应
 */
export function fileUpload(filedata) {
    return http.post(`/${FILE_GATEWAY}/saas/normalUpload`, filedata, {
        headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
    });
}

/**
 * @description 通过certificateId获取图片文件
 * @param {String} id certificateId
 */
export function getFileById(id) {
    return http.post(`/${FILE_GATEWAY}/saas/queryById`, {
        ids: [id]
    });
}

/***************************************************************
 * 机构认证相关接口
 ***************************************************************/

/**
 * 获取机构实名认证信息
 */
export function queryAuthCompanyByCustomerId() {
    return saasHttp.get(`/authCompany/queryByCustomerId`, {});
}

/**
 * 提交机构认证信息
 */
export function saveAuthCompany(params) {
    return saasHttp.post(`/authCompany/save`, params);
}

/**
 * 变更机构认证信息
 * @returns
 */
export function updateAuthCompany(params) {
    return saasHttp.post(`/authCompany/updateAuthCompany`, params);
}

/**
 * 获取用户认证的详情
 */
export function queryByIdForCustomer(id) {
    return saasHttp.post(`/authCustomer/queryByIdForCustomer`, {
        ids: [id]
    });
}

/**
 * 获取邀请的用户的详情
 */
export function queryAuthInviteById(id) {
    return saasHttp.post(`/authInvite/queryById`, {
        ids: [id]
    });
}

/**
 * 获取邀请的用户的详情
 */
export function queryAuthCompanyByIdForCustomer(id) {
    return saasHttp.post(`/authCompany/queryByIdForCustomer`, {
        ids: [id]
    });
}

/**
 * 获取SystemId
 */
export function querySystemId(code) {
    return http.get(`/${BASIC_GATEWAY}/common/querySystemIdByCode?systemCode=${code}`, {});
}

/**
 * 提交用户审核
 */
export function saveAuthCustomer(params) {
    return saasHttp.post(`/authCustomer/save`, params);
}

/**
 * 查询机构列表
 */
export function querySystems(params) {
    return saasHttp.post(`/customer/querySystems`, params);
}

/**
 * 更新默认机构
 */
export function updateDefaultSystem(systemId) {
    return saasHttp.post(`/customer/updateDefaultSystem?systemId=${systemId}`, {});
}

/**
 * 根据systemId查询机构信息
 */
export function queryBySystemId(systemId) {
    return saasHttp.post(`/authCompany/queryBySystemId?systemId=${systemId}`, {});
}

/**
 * 撤销机构认证
 */
export function revokeAuthCompany(authCompanyId) {
    return saasHttp.post(`/authCompany/revoke?authCompanyId=${authCompanyId}`, {});
}

/***************************************************************
 * 我的产品相关接口
 ***************************************************************/
/**
 * 30天待续费产品
 */
export function queryToExpireProductCount() {
    return saasHttp.post(`/product/queryToExpireProductCount`, {});
}

/**
 * 查询产品列表
 */
export function queryProductList() {
    return saasHttp.post(`/product/queryList`, {});
}

/**
 * 查询套件列表
 */
export function queryKitList() {
    return saasHttp.post(`/kit/queryList`, {});
}

/**
 * 查询资源列表
 */
export function queryResourceList() {
    return saasHttp.post(`/resource/queryList`, {});
}

/**
 * 查询服务列表
 */
export function queryServiceList() {
    return saasHttp.post(`/service/queryList`, {});
}

/**
 * 查询产品商城产品列表
 */
export function queryMallProductList(params) {
    return saasHttp.post(`/product/mall/queryPageList`, params);
}

/**
 * 查询产品商城套件列表
 */
export function queryMallKitList(params) {
    return saasHttp.post(`/kit/mall/queryPageList`, params);
}

/**
 * 查询产品商城资源列表
 */
export function queryMallResourceList(params) {
    return saasHttp.post(`/resource/mall/queryPageList`, params);
}

/**
 * 查询产品商城服务列表
 */
export function queryMallServiceList(params) {
    return saasHttp.post(`/service/mall/queryPageList`, params);
}

/**
 * 查询产品详情
 */
export function queryProductById(id) {
    return saasHttp.post(`/product/queryById`, { ids: [id] });
}

/**
 * 查询套件详情
 */
export function queryKitById(id) {
    return saasHttp.post(`/kit/queryById`, { ids: [id] });
}

/**
 * 查询服务详情
 */
export function queryServiceById(id) {
    return saasHttp.post(`/service/queryById`, { ids: [id] });
}

/**
 * 查询资源详情
 */
export function queryResourceById(id) {
    return saasHttp.post(`/resource/queryById`, { ids: [id] });
}

/**
 * @description 提交购买产品列表
 * @param {Array} params 产品列表
 * @returns {Promise} 请求响应
 */
export function buyProducts(params) {
    return saasHttp.post(`/authTrial/save`, params);
}

/**
 * @description 试用列表
 * @param {Object} params 参数
 * @returns {Promise} 请求响应
 */
export function queryTrialList(params) {
    return saasHttp.post(`/authTrial/queryTrialList`, params);
}

/**
 * @description 获取试用详情
 * @param {Object} params 参数
 * @returns {Promise} 请求响应
 */
export function queryTrialByIdForCustomer(id) {
    return saasHttp.post(`/authTrial/queryByIdForCustomer`, { ids: [id] });
}

/**
 * @description 撤销
 * @param {Object} params 参数
 * @returns {Promise} 请求响应
 */
export function cancelTrial(params) {
    return saasHttp.post(`/authTrial/revoke`, params);
}

/***************************************************************
 * 我的发票相关接口
 ***************************************************************/

/**
 * 新增发票抬头
 */
export function SaveInvoiceTitle(params) {
    return saasHttp.post(`/customerInvoiceTitle/save`, params);
}

export function queryInvoiceTitleList(params) {
    return saasHttp.post(`/customerInvoiceTitle/queryPageList`, params);
}

export function queryInvoiceTitleById(id) {
    return saasHttp.post(`/customerInvoiceTitle/queryById`, {
        ids: [id]
    });
}

export function updateInvoiceTitleById(params) {
    return saasHttp.post(`/customerInvoiceTitle/updateById`, params);
}

export function deleteInvoiceTitleById(id) {
    return saasHttp.post(
        `/customerInvoiceTitle/deleteBatch`,
        { delIds: [id] } //  invoiceTitleId值
    );
}

export function queryInvoiceList(params) {
    return saasHttp.post(`/customerInvoice/queryPageList`, params);
}

export function queryInvoiceDetail(id) {
    return saasHttp.post(`/customerInvoice/queryById`, { ids: [id] });
}

export function saveInvoice(params) {
    return saasHttp.post(`/customerInvoice/save`, params);
}

// 撤销发票
export function cancelInvoice(params) {
    return saasHttp.post(`/customerInvoice/cancel`, params);
}

/***************************************************************
 * 我的订单相关接口
 ***************************************************************/
// TODO: 改为正式HTTPClient
/**
 * 获取订单数量
 * @param {Number} type 1：全部 2：待支付
 */
export function queryOrderCount(type) {
    return saasHttp.get(`/customerOrder/queryOrderCount?type=${type}`, {});
}

export function queryCustomerOrderList(params) {
    return saasHttp.post(`/customerOrder/queryPageList`, params);
}
export function queryCustomerOrderById(id) {
    return saasHttp.post(`/customerOrder/queryById`, { ids: [id] });
}

/**
 * 查询订单数量
 * @param {Number} type 1: 全部 2：待支付
 * @returns
 */
export function queryCustomerOrderCount(type) {
    return saasHttp.post(`/customerOrder/queryOrderCount?type=${type}`, {});
}

/**
 * @description 查询未过期产品列表
 * @param {Object} params 参数
 * @returns {Promise} 请求响应
 */
export function queryProductInUseList(params) {
    return saasHttp.get(`/order/queryProductInUseList?customerId=${params.customerId}`);
}

/**
 * 提交订单
 */
export function submitOrder(params) {
    return saasHttp.post(`/customerOrder/submitOrder `, params);
}

/**
 * 预提交订单，计算价格和明细
 */
export function preSubmitOrder(params) {
    return saasHttp.post(`/customerOrder/prepareSubmitOrder`, params);
}

/**
 * 计算价格
 */
export function queryOrderCost(params) {
    return saasHttp.post(`/customerOrder/queryOrderCost`, params);
}

/**
 * 查询已购产品
 */
export function queryOrderList(params) {
    return saasHttp.post(`/customerOrder/queryOrderList`, params);
}

/**
 * 取消订单
 */
export function cancelOrder(params) {
    return saasHttp.post(`/customerOrder/cancelOrder`, params);
}

// type=1套件2 产品 3资源 4服务
// 产品购买页 显示每种产品/服务价格折扣率接口
export function queryDiscountInfo(params) {
    return saasHttp.get(`/customerOrder/queryDiscountInfo?type=${params.type}&id=${params.id}`, {});
}

// 提交退单申请，订单状态为已支付且初始化完成才可操作
export function submitRefund(id) {
    return saasHttp.post(`/customerRefund/submitRefund`, { orderId: id });
}

// 根据订单id查询退单信息
export function queryRefundByOrderId(id) {
    return saasHttp.post(`/customerRefund/queryByOrderId`, { orderId: id });
}

// 根据订单id查询退单金额
export function evaluateRefund(id) {
    return saasHttp.post(`/customerRefund/evaluateRefund`, { orderId: id });
}

// 待审核退单记录撤销
export function cancelRefund(id) {
    return saasHttp.post(`/customerRefund/cancelRefund`, { refundId: id });
}

/*********************************************************************
 * 消息相关
 ******************************************************************** **/

/**
 * 读取未读消息
 */
export function queryNotice(params) {
    return saasHttp.post(`/notice/queryPageList`, params);
}

/**
 * 同意、拒绝消息中的加入机构邀请
 */
export function approveAuthInvite(params) {
    return saasHttp.post(`/authInvite/approve`, params);
}

/**
 * 更新消息已读未读
 */
export function updateNoticeStatus(id) {
    return saasHttp.post(`/notice/updateStatusBatch`, { ids: [id] });
}

/**
 * 批量更新消息已读未读
 */
export function updateNoticesStatusBatch(ids) {
    return saasHttp.post(`/notice/updateStatusBatch`, { ids: ids });
}

/********************************************************************
 * 首页公告、信息相关
 ********************************************************************/
export function queryBulletinPage(params) {
    return http.post(`/${BASIC_GATEWAY}/common/queryBulletinPage`, params);
}

export function queryBulletinById(id) {
    return http.get(`/${BASIC_GATEWAY}/common/queryBulletinById?bulletinId=${id}`);
}

/*********************************************************************
 * 积分商城
 *********************************************************************/
export function queryGoodsPageList(params) {
    return saasHttp.post(`/goods/queryPageList`, params);
}

export function queryGoodsStock(params) {
    return saasHttp.post(`/goods/queryStock`, params);
}

/*******************************************************************
 *
 * 会员积分相关接口
 ******************************************************************/
const vipscoreSystemId = '0';
/**
 * @description 积分记录
 * @returns {Promise<Response>} - 响应
 */
export function queryPointRecord(params) {
    return saasHttp.post(`/pointsRecord/queryRecord?systemId=${vipscoreSystemId}`, {
        entity: {
            recordType: params.recordType,
            settlementTimeQuery: params.settlementTimeQuery
        },
        param: {
            pageNum: params.pageNum,
            pageSize: params.pageSize
        }
    });
}

/**
 * @description 可用积分
 * @returns {Promise<Response>} - 响应
 */
export function queryPoints() {
    return saasHttp.post(`/points/queryPoints?systemId=${vipscoreSystemId}`, {});
}

/**
 * @description 收入和支出积分
 * @returns {Promise<Response>} - 响应
 */
export function queryChangePoints() {
    return saasHttp.post(`/pointsRecord/queryPointsSum?systemId=${vipscoreSystemId}`, {});
}

/**********************************************************************************************
 * 问题反馈相关接口
 ************************************************************************************************/

/**
 * @description 提交反馈
 * @returns {Promise<Response>} - 响应
 */
export function submitFeedback(params) {
    return saasHttp.post(`/feedback/submitFeedback`, params);
}

/**
 * @description 分页查询问题反馈
 * @returns {Promise<Response>} - 响应
 */
export function queryFeedbackPageList(params) {
    return saasHttp.post(`/feedback/queryPageList`, params);
}

/**
 * @description 查询反馈图片
 * @returns {Promise<Response>} - 响应
 */
export function queryFeedbackPictures(feedbackId) {
    return saasHttp.get(`/feedback/queryPictures?feedbackId=${feedbackId}`);
}

/**********************************************************************************************
 * 文章接口，新手入门
 ************************************************************************************************/
/**
 * @description 根据栏目编号查询详情
 * @returns {Promise<Response>} - 响应
 */
export function queryArchivesByNo(channelNo) {
    return testHttp.get(`api/queryArchivesByNo/${channelNo}`);
}

/**
 * @description 查询所有栏目
 * @returns {Promise<Response>} - 响应
 */
export function queryAllChannel() {
    return testHttp.post(`api/queryAllChannel`, {});
}
/**
 * @description 查询某个栏目下所有栏目
 * @returns {Promise<Response>} - 响应
 */
export function queryChannel(channelNo) {
    return testHttp.get(`api/queryAllChannel/${channelNo}`);
}

/**
 * @description 获取该栏目的子栏目
 * @returns {Promise<Response>} - 响应
 */
export function queryChildrenChannel(channelNo) {
    return testHttp.get(`api/queryChildrenChannel/${channelNo}`);
}

/**
 * @description 获取该栏目的信息
 * @returns {Promise<Response>} - 响应
 */
export function queryChannelInfo(channelNo) {
    return testHttp.get(`api/queryChannelInfo/${channelNo}`);
}

/**
 * @description 用户空间总量及当前用量
 * @param id systemId
 * @returns {Promise<Response>} - 响应
 */
export function queryFileResource(id) {
    return http.get(`/${BASIC_GATEWAY}/resource/queryFileResource?systemId=${id}`);
}
