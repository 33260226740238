/**
 * @file  志愿者管理系统
 * @create macaiqi 2021/07/29
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import cls from 'classnames'
import './style.scss'

/**
 * @description 志愿者管理系统组件
 */

export const WapVolunteersSystem = () => {
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    const settings = {
        arrows: false,
        dots: false,
        // infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "3rem",
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
    };
    const sec3 = [
        {
            name: '志愿者管理与服务全流程覆盖',
            des: '实现从志愿者招募、档案管理、服务发布、报名申请、服务排班、勤务考核等全流程业务管理。',
            icon: 'iconyizhanshijiejuefangan',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '基于移动终端的便捷式管理',
            des: '支持微信小程序作为移动终端，便捷、高效管理志愿者日常工作。',
            icon: 'iconshouji21',
            duration: '1s',
            delay: '0.7s'
        },
        {
            name: '实现多角色灵活管理',
            des: '支持总调度、组长、志愿者等角色灵活管理，利用微信小程序实现线上业务协同审批。',
            icon: 'iconlinghuoguanli',
            duration: '1s',
            delay: '0.9s'
        },
    ]
    // 功能组成
    const gongneng = [
        {
            name: "志愿者管理端",
            tab: ['档案管理', '分组管理', '服务管理', '申请记录', '排班记录', '数据统计', '基础配置'],
            des: '提供志愿者档案资料的统一集中管理。主要功能包括新增、查看、修改、删除、导入、导出、审核、查看个人信息。&&（1） 新增&&支持新增志愿者档案，对非在线申请志愿者的档案进行信息新增录入。',
            icon: 'iconshezhi'
        },
        {
            name: "志愿者小程序端",
            tab: ['志愿者报名', '服务签到', '服务调度', '志愿者风采', '考勤查询', '数据统计', '服务申请', '服务审批', '个人中心'],
            des: '为社会公众开放申请成为志愿者的渠道，提供志愿者报名申请，通过 “志愿者招募”入口，进入志愿者报名申请信息填报页，主要包括姓名、出生日期、学历、可服务时间、可服务岗位、特长等指标，填写完成后提交给管理员审核。',
            icon: 'iconxiaochengxu'
        },
        {
            name: "志愿者管理端",
            tab: ['档案管理', '分组管理', '服务管理', '申请记录', '排班记录', '数据统计', '基础配置'],
            des: '提供志愿者档案资料的统一集中管理。主要功能包括新增、查看、修改、删除、导入、导出、审核、查看个人信息。&&（1） 新增&&支持新增志愿者档案，对非在线申请志愿者的档案进行信息新增录入。',
            icon: 'iconshezhi'
        },
        {
            name: "志愿者小程序端",
            tab: ['志愿者报名', '服务签到', '服务调度', '志愿者风采', '考勤查询', '数据统计', '服务申请', '服务审批', '个人中心'],
            des: '为社会公众开放申请成为志愿者的渠道，提供志愿者报名申请，通过 “志愿者招募”入口，进入志愿者报名申请信息填报页，主要包括姓名、出生日期、学历、可服务时间、可服务岗位、特长等指标，填写完成后提交给管理员审核。',
            icon: 'iconxiaochengxu'
        }
    ]
    const [down, setDown] = useState()
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        if (anchorName || anchorName === 0) {
            const height = document.getElementById(anchorName).offsetTop - 40; // 计算需要向上移动的距离
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        }
    };
    const banner = useRef()
    const activeref = useRef()
    const { search } = useLocation()
    useEffect(() => {

        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // 滚动超过banner后nav吸顶
            if (scrollT >= banner.current.clientHeight + banner.current.offsetTop) {
                activeref.current?.classList.add('active');
            } else {
                activeref.current?.classList.remove('active');
            }
            // 滚动到对应点，对应nav高亮
            for (let i = 0; i < tab.length; i++) {

                if (scrollT >= document.getElementById(`item${i}`).offsetTop - 40) {
                    document.getElementById(`link${i}`).classList.add('active');
                    for (let j = 0; j < tab.length; j++) {
                        if (i !== j) {
                            document.getElementById(`link${j}`).classList.remove('active');
                        }
                    }
                }

            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(`item${search.split('?')[1]}`)
        }
    }, [tab.length, search]);

    return (<>
        <IntroHeader />
        <div className="mobile-content volunteer" ref={activeref}>
            <div className="banner" ref={banner}>
                <div className="content">
                    <p className="big">志愿者管理系统</p>
                    <div className="cont">
                        以协同志愿者团队更加灵活、专业、高效服务社会公众为目标，为志愿者建立全面档案，制定并发布志愿服务需求，实现志愿者服务的快速报名、签到签退、考勤统计，为志愿者管理团队提供服务管理、任务调度、勤务考核的一体化服务，简化志愿者工作流程，提高志愿服务及管理效率。
                    </div>
                    <div className="btnbox">
                        {/* <div className="btn btn1">
                             观看演示
                         </div> */}
                        {/* <div className=" btn btn2">
                             免费体验
                         </div> */}
                    </div>

                </div>
            </div>
            <div className="tab" >
                <ul>
                    {tab.map((item, index) => {
                        return (
                            <li key={index} id={`link${index}`} onClick={() => {
                                scrollToAnchor(`item${index}`)
                            }}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="people" id='item0'>
                <p className="title" >用户群体</p>
                <p className="txt">本产品适用于博物馆、美术馆、图书馆、文化馆、科技馆、规划馆、校史馆、档案馆等各类公共文化场馆、文旅景区及其他有志愿者管理的相关工作人员。</p>
            </div>
            <div className="section2" id='item1' >
                <p className="title" >功能组成</p>
                <div className="functionof">
                    <Slider {...settings} key={Math.random()} >
                        {gongneng.map((item, index) => {
                            return (
                                <div className="itembox" key={index}>
                                    <div className="item">
                                        <i className={cls('iconfont', item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        <div className="tabbox">
                                            {item.tab.map((item1, i1) => {
                                                return (<p className="txt" key={i1}>{item1}</p>)
                                            })}
                                        </div>


                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div className="section3" id='item2'>
                <p className="title">产品特点</p>
                {
                    sec3.map((item, index) => {
                        return (
                            <div className={cls("item")} key={index} >
                                <div className="box">
                                    <div className="l">
                                        <i className={cls('iconfont', item.icon)} />
                                        {item.name}
                                    </div>
                                    <div className="down" />
                                </div>
                                <div className="text">
                                    {item.des}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="price" id='item3'>
                <p className="title">产品价格</p>
                <div className="num">
                    4800
                    <span>元/年</span>
                </div>
            </div>
            <div className="update" id='item4'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }} />
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
