/**
 * @file   订单信息模块
 * @create tianmu 2021/3/9
 * @update tianmu 2021/3/9
 */
import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from './store';
import { OrderSumInfo } from './components/OrderSumInfo';
import { toFixedNumStr } from '../../utils';
import { ProductLayout } from './components/ProductLayout';
import './index.scss';

// 单位枚举转为字符串描述
const unitStr = (unit) => {
    switch (unit) {
        case 1:
            return '年';
        case 2:
            return '月';
        case 3:
            return '人';
        case 4:
            return 'GB';
        case 5:
            return '次';
        default:
            return '';
    }
};

 // 订单信息组件
 export const ProductOrderInfo = () => {
     const history = useHistory();
     const dispatch = useDispatch();
     const [loading, setLoading] = useState(false);
     const {
        orderState,
    } = useSelector((state) => state.productReducer);
    console.log('orderState: ', orderState)
    const {
        kit,
        productList,
        serviceList,
        resourceList,
        cost,
    } = orderState;

    const onSubmitClick = () => {
        setLoading(true);
        dispatch(submit()).then(ok => {
            if (ok) {
                history.replace("/ProductOrderSuccess");
            }
            setLoading(false);
        })
     }

     return (<>
        <ProductLayout title="所选产品" >
            <div className="product-order-info">
                <div className="product-order-list">
                    {kit && (
                        <div key={kit.kitId} className="product-order-item">
                            <Row gutter={[0, 20]}>
                                <Col className="product-order-title"  span={2}>套件名称：</Col>
                                <Col className="product-order-title"   span={22}>
                                    {kit.kitName}
                                </Col>
                            </Row>
                            <Row gutter={[0, 20]}>
                                <Col className="product-order-desc" span={2}>套件介绍：</Col>
                                <Col className="product-order-desc"  span={22}>{kit.description}</Col>
                            </Row>
                        </div>
                    )}
                    {Array.isArray(productList) && (
                        productList
                        .filter(i => i.value !== 0)
                        .map(item => (
                            <div key={item.productId} className="product-order-item">
                                <Row gutter={[0, 20]}>
                                    <Col className="product-order-title" span={2}>产品名称：</Col>
                                    <Col className="product-order-title"  span={22}>
                                        {item.productName}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col className="product-order-desc" span={2}>产品介绍：</Col>
                                    <Col className="product-order-desc" span={22}>{item.description}</Col>
                                </Row>
                                <Row gutter={[0]}>
                                    <Col className="product-order-desc" span={2}>购买时长：</Col>
                                    <Col className="product-order-desc" span={8}>{item.value}{unitStr(item.price?.unit)}</Col>
                                    <Col className="product-order-desc" span={14}>
                                        购买价格：
                                        <span className="cost-money-symbol">
                                        ￥
                                        </span>
                                        <span className="cost-money-number" style={{marginRight: 10}}>
                                            {toFixedNumStr(item.sum, 2)}
                                        </span>
                                        省
                                        <span className="save-money-symbol">
                                        ￥
                                        </span>
                                        <span className="save-money-number">
                                            {toFixedNumStr(item.sum - cost[item.productId], 2)}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    )}

                    {Array.isArray(serviceList) && (
                        serviceList
                        .filter(i => i.value !== 0)
                        .map(item => (
                            <div key={item.serviceId} className="product-order-item">
                                <Row gutter={[0, 20]}>
                                    <Col className="product-order-title" span={2}>服务名称：</Col>
                                    <Col className="product-order-title"  span={22}>
                                        {item.serviceName}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col className="product-order-desc" span={2}>服务介绍：</Col>
                                    <Col className="product-order-desc" span={22}>{item.description}</Col>
                                </Row>
                                <Row gutter={[0]}>
                                    <Col className="product-order-desc" span={2}>购买数量：</Col>
                                    <Col className="product-order-desc" span={8}>{item.value}{unitStr(item.price?.unit)}</Col>
                                    <Col className="product-order-desc" span={14}>
                                        购买价格：
                                        <span className="cost-money-symbol">
                                        ￥
                                        </span>
                                        <span className="cost-money-number" style={{marginRight: 10}}>
                                            {toFixedNumStr(item.sum, 2)}
                                        </span>
                                        省
                                        <span className="save-money-symbol">
                                        ￥
                                        </span>
                                        <span className="save-money-number">
                                            {toFixedNumStr(item.sum - cost[item.serviceId], 2)}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    )}
                    {Array.isArray(resourceList) && (
                        resourceList
                        .filter(i => i.value !== 0)
                        .map(item => (
                            <div key={item.resourceId} className="product-order-item">
                                <Row gutter={[0, 20]}>
                                    <Col className="product-order-title" span={2}>资源名称：</Col>
                                    <Col className="product-order-title" span={22}>
                                        {item.resourceName}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col className="product-order-desc" span={2}>资源介绍：</Col>
                                    <Col className="product-order-desc" span={22}>{item.description}</Col>
                                </Row>
                                <Row gutter={[0]}>
                                    <Col className="product-order-desc" span={2}>购买数量：</Col>
                                    <Col className="product-order-desc" span={8}>{item.value}{unitStr(item.price?.unit)}</Col>
                                    <Col className="product-order-desc" span={14}>
                                        购买价格：
                                        <span className="cost-money-symbol">
                                        ￥
                                        </span>
                                        <span className="cost-money-number" style={{marginRight: 10}}>
                                            {toFixedNumStr(item.sum, 2)}
                                        </span>
                                        省
                                        <span className="save-money-symbol">
                                        ￥
                                        </span>
                                        <span className="save-money-number">
                                            {toFixedNumStr(item.sum - cost[item.resourceId], 2)}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </ProductLayout>
        <div
            className="order-info-footer"
        >
            <Button
                className="order-info-footer-btns"
                onClick={() => history.goBack()}
            >
                返回
            </Button>
            <Button
            type="primary"
                loading={loading}
                className="order-info-footer-btns primary"
                onClick={onSubmitClick}
            >
                确认订购
            </Button>
            <OrderSumInfo />
        </div>
     </>);
 };
