/**
 * @file   基本信息模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/16
 */
 import React from 'react'
 import { InfoForm } from './InfoForm';
//  import Avatar from '../../../assets/avatar.svg'

 // 用户基本信息组件
 export const Info = () => {
     return (
         <div
             className="page-content"
             style={{
                 paddingTop: 60,
             }}
         >
             <div
                 style={{
                     display: 'flex',
                 }}
             >
                 {/* <div
                     style={{
                         borderRadius: '50%',
                     }}
                 >
                     <img style={{
                         width: 100,
                         height: 100,
                     }} src={Avatar} alt="" />
                 </div> */}
                 <div
                     style={{
                         flex: '1'
                     }}
                 >
                     <InfoForm  />
                 </div>
             </div>
         </div>
     );
 };
