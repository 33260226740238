/**
 * @file   单位注册表单模块
 * @create tianmu 2020/10/16
 * @update tianmu 2020/10/16
 */
import React, { useEffect, useState } from 'react';
import { Input, Form, Select, Radio, Checkbox, notification, Button, Tooltip } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { CustomUpload } from '../../components/CustomUpload/CustomUpload';
import Viewer from 'react-viewer';
import UploadBg from '../../assets/upload-bg.png';
import { uuid, validateIdcard } from '../../utils';

// const filePreviewPrefix = process.env.ENV_FILE_PREVIEW;
const filePreviewPrefix = window.FILE_PREVIEW;

/**
 * @description 获取随机字符串
 */
const getRandomStr = function () {
    return uuid(14, 16);
};

/**
 * @description 图片列表转为upload中的文件列表
 * @param {Array} urls 图片url列表
 * @returns {Array} 文件列表
 */
const urlsToFileList = (urls) => {
    let lst = [];
    if (Array.isArray(urls) && urls.length > 0) {
        lst = urls.map((path, idx) => {
            return {
                uid: idx,
                name: 'image.png',
                status: 'done',
                url: path
            };
        });
    }
    return lst;
};

// 以登陆用户修改密码表单
export const OrgAuthForm = (props) => {
    const { info, form } = props;
    const [fileList, setFileList] = useState([]);
    const [image, setImage] = useState({
        visible: false,
        src: null
    });
    const [canModifyIdent, setCanModifyIdent] = useState(true)
    // const [form] = Form.useForm();
    useEffect(() => {
        if (info) {
            form.setFieldsValue({
                ...info,
                editIdentMode: 1,
                identityCard: info.identityCard?.replace(/^(.{6})(?:\d+)(.{4})$/,  "$1****$2"),
                realIdentityCard: info.identityCard,
            });
            if (info.thumb) {
                setFileList(urlsToFileList([info.thumb]));
            }
            // 修改信息默认不能修改身份证信息
            setCanModifyIdent(false)
        } else {
            // 设置默认值
            form.setFieldsValue({
                identity: 1
            });
        }
    }, [form, info]);

    return (
        <Form
            labelCol={{
                span: 6,
                xxl: { span: 5 }
            }}
            wrapperCol={{
                span: 12,
                xxl: { span: 10 }
            }}
            layout="horizontal"
            form={form}
            className="secondstep"
        >
            <Form.Item name="editIdentMode">
                {/* 隐藏字段，只是为了判断是否修改法人信息，不提交 */}
                <Input type="number" hidden />
            </Form.Item>
            <Form.Item name="realIdentityCard">
                {/* 隐藏字段，只是为了存储真实的身份证号信息 */}
                <Input hidden />
            </Form.Item>
            <Form.Item name="authCompanyId">
                <Input hidden />
            </Form.Item>
            <Form.Item
                name="identity"
                label="您的身份"
                rules={[
                    {
                        required: true,
                        message: '请确认您的身份!'
                    }
                ]}
            >
                <Radio.Group defaultValue={1}>
                    <Radio value={1}>法人</Radio>
                    <Radio value={2}>被授权人</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="companyName"
                label="机构名称"
                rules={[
                    {
                        required: true,
                        message: '请确认机构名称!'
                    }
                ]}
            >
                <Input
                    onChange={(e) => {
                        const v = e.target.value;
                        if (info && info.approveStatus !== 3) {
                            // 更改机构认证时不能修改机构编号
                            // 机构认证被拒绝后进行修改时，可以修改机构编号
                            form.setFieldsValue({
                                companyName: v,
                            });
                        } else {
                            let genCode = ""
                            if (v !== "") {
                                // 多音单词备选列表
                                const backupLst = window.pinyinUtil.getFirstLetter(v, true);
                                const letters = Array.isArray(backupLst) && backupLst.length > 0 ? backupLst[0] : ""
                                genCode = 'rlyid_'
                                            + letters.toLocaleLowerCase()
                                            + '_' + getRandomStr();
                            }
                            form.setFieldsValue({
                                companyName: v,
                                // 全局引入的汉字转拼音首字母库
                                companyCode: genCode,
                            });
                        }
                    }}
                    placeholder="请确认机构名称"
                />
            </Form.Item>
            <Form.Item
                name="companyCode"
                label="机构编号"
                rules={[
                    {
                        required: true,
                        message: '请确认机构编号!'
                    }
                ]}
            >
                <Input
                    readOnly
                    style={{
                        background: 'rgb(217, 217, 217)'
                    }}
                    placeholder="请确认机构编号"
                />
            </Form.Item>
            <Form.Item
                name="contact"
                label="法人姓名"
                rules={[
                    {
                        required: true,
                        message: '请确认法人姓名!'
                    }
                ]}
                extra={
                !canModifyIdent ? (
                    <Tooltip
                        // color="#fff"
                        title="修改法人"
                    >
                        <span
                            style={{
                                position: 'absolute',
                                right: -30,
                                top: 0,
                                fontSize: '18px',
                                color: 'rgb(61, 188, 142)',
                                cursor: 'pointer'
                            }}
                            onClick={()=> {
                                form.setFieldsValue({
                                    contact: "",
                                    identityCard: "",
                                    editIdentMode: 2,
                                })
                                setCanModifyIdent(true)
                            }}
                        >
                            <EditFilled />
                        </span>
                    </Tooltip>
                ) : null}
            >
                <Input
                    disabled={!canModifyIdent}
                    placeholder="请确认法人姓名"
                />
            </Form.Item>
            <Form.Item
                name="identityCard"
                label="法定代表人身份证号"
                rules={[
                    {
                        required: true,
                        validator: (rule, value, cb) => {
                            if (!canModifyIdent) {
                                // 不能修改身份证号，这个时候后台传递过来的身份证号是带有***的
                                // 直接通过验证不用过滤，因为提交的时候会处理这个字段
                                cb();
                                return;
                            }
                            const res = validateIdcard(value);
                            if (res?.status === 1) {
                                cb();
                            } else {
                                cb('请输入格式正确的身份证号！');
                            }
                        }
                    }
                ]}
            >
                <Input
                    disabled={!canModifyIdent}
                    placeholder="请确认法定代表人身份证号"

                />
            </Form.Item>

            <Form.Item
                name="licenseType"
                label="机构证件类型"
                rules={[
                    {
                        required: true,
                        message: '请确认你的机构证件类型!'
                    }
                ]}
            >
                <Select placeholder="请选择机构证件类型">
                    <Select.Option key={1} value={1}>
                        机构营业执照
                    </Select.Option>
                    <Select.Option key={2} value={2}>
                        组织机构代码证
                    </Select.Option>
                    <Select.Option key={3} value={3}>
                        事业单位法人证书
                    </Select.Option>
                    <Select.Option key={4} value={4}>
                        社会团体法人证书
                    </Select.Option>
                    <Select.Option key={5} value={5}>
                        行政执法主体资格证
                    </Select.Option>
                    <Select.Option key={6} value={6}>
                        其它
                    </Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="上传机构证件附件"
                name="license"
                rules={[
                    {
                        required: true,
                        message: '请上传你的机构证件附件!'
                    }
                ]}
                extra="上传的图片格式要求jpg、jpeg、bmp、png，不超过10M；文件名(包含后缀名)的最大长度为100个字符。请勿遮挡关键信息，例如公司名称、公司证件号。"
            >
                <CustomUpload
                    className="register-unit-upload"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={(file) => {
                        let thumb;
                        const d = file.response?.data;
                        if (d) {
                            thumb = filePreviewPrefix + d.filePath + d.fileName;
                        } else {
                            thumb = file.url;
                        }
                        setImage({
                            visible: true,
                            src: thumb
                        });
                    }}
                    accept=".png, .jpg, .jpeg, .bmp"
                    beforeUpload={(file) => {
                        // console.log('before upload', file);
                        // 判断是否为图片文件
                        if (file.type !== 'image/png'
                        && file.type !== 'image/jpeg'
                        && file.type !== 'image/jpg'
                        && file.type !== 'image/bmp' ) {
                            notification.warn({
                                className: 'custom-antd-notification',
                                message: `${file.name} 不是一个图片文件`,
                                duration: 5,
                            })
                            return false;
                        }
                        if (file.size > 1024 * 1024 * 10 ) {
                            notification.warn({
                                className: 'custom-antd-notification',
                                message: `上传图片大小不能超过10M`,
                                duration: 5,
                            })
                            return false;
                        }
                        if (file.name.length > 100) {
                            notification.warn({
                                className: 'custom-antd-notification',
                                message: `上传文件名不能超过100个字符`,
                                duration: 5,
                            })
                            return false;
                        }
                        return true;
                    }}
                    onChange={(info) => {
                        // console.log(info.fileList);
                        // file.status is empty when beforeUpload return false
                        setFileList([...info.fileList].filter((file) => !!file.status).slice(-1)); //  Limit the number of uploaded files
                    }}
                >
                    {fileList.length === 0 && (
                        <>
                            {/*  <span
                                style={{
                                    position: 'absolute',
                                    left: '125px',
                                    bottom: '5px',
                                    width: '25px',
                                    height: '25px',
                                    lineHeight: '25px',
                                    textAlign: 'center',
                                    fontSize: '25px',
                                    borderRadius: '50%',
                                    background: 'rgba(0, 0, 0, .4)',
                                    color: '#fff'
                                }}
                            >
                                +
                            </span> */}
                            <img
                                style={{ width: '100%', objectFit: 'contain' }}
                                src={UploadBg}
                                alt=""
                            />
                        </>
                    )}
                </CustomUpload>
            </Form.Item>
            <Form.Item
                name="agree"
                wrapperCol={{
                    span: 12,
                    offset: 6,
                    xxl: { span: 10, offset: 5 }
                }}
            >
                <Checkbox
                    onChange={(e) => {
                        form.setFieldsValue({ agree: e.target.checked });
                    }}
                >
                    请务必提供真实信息，瑞鹿云有权自行或委托第三方，审查您提供的身份信息是否真实、有效。若提供虚假信息，由此带来的全部结果由您承担。
                </Checkbox>
            </Form.Item>
            <Viewer
                visible={image.visible}
                onClose={() => {
                    setImage({ visible: false, src: null });
                }}
                images={[{ src: image.src, alt: '' }]}
            />
        </Form>
    );
};
