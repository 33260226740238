/**
 * @file   开票详情
 * @create tianmu 2021/4/22
 * @update tianmu 2021/4/22
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Collapse, Layout, notification, Row } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { cancelInvoice, queryCustomerOrderById, queryInvoiceList, queryRefundByOrderId } from '../../service';
import { searchStrToObj, toFixedNumStr } from '../../utils';
import { useHistory, useLocation } from 'react-router';
import { unitStr } from '../../utils/productUtils';
import './index.scss';
import InvoiceStatusIcon0 from '../../assets/notice/10.png'
import InvoiceStatusIcon1 from '../../assets/notice/11.png'
import InvoiceStatusIcon2 from '../../assets/notice/12.png'
import InvoiceStatusIcon3 from '../../assets/notice/13.png'
import OrderStatusIcon0 from '../../assets/notice/6.png'
import OrderStatusIcon1 from '../../assets/notice/7.png'
import OrderStatusIcon2 from '../../assets/notice/9.png'
import OrderStatusIcon3 from '../../assets/notice/8.png'
import OrderStatusIcon4 from '../../assets/notice/1.png'
import OrderStatusIcon5 from '../../assets/notice/14.png'
import OrderStatusIcon6 from '../../assets/notice/15.png'

// 默认页面大小
const PAGE_SIZE = 50;

export const InvoiceDetail = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [invoiceList, setInvoiceList] = useState([]);
    const [orderDetail, setOrderDetail] = useState(null);
    // const [pagination, setPagination] = useState({
    //     current: 1,
    //     pageSize: PAGE_SIZE,
    // });
    const o = useMemo(() => searchStrToObj(location.search), [location.search])

    const fetchInvoices = useCallback(async (params) => {
        const res2 = await queryInvoiceList(params);
        const records = res2?.data?.data?.records
        if (Array.isArray(records)) {
            setInvoiceList(records.sort((a, b) => new Date(b.createTime) - new Date(a.createTime)));
        }
    }, []);

    const fetchOrderInfo = useCallback(async (id) => {
        const res1 = await queryCustomerOrderById(id);
        if (res1.data.code === 200) {
            const d = res1.data.data;
            setOrderDetail(d);
        }
    }, []);

    const fetchData = useCallback(() => {
        // orderId 是查看发票详情过来的
        // businessId 是从订单消息点击过来的
        const id = o?.orderId || o?.businessId;
        fetchInvoices({
            entity: {
                orderId: id
            },
            param: {
                pageNum: 1,
                pageSize: PAGE_SIZE
            }
        });
        fetchOrderInfo(id);
    }, [fetchInvoices, fetchOrderInfo, o]);

    const currInvoice = useMemo(() => {
        const id = o?.invoiceId
        if (!(Array.isArray(invoiceList) && invoiceList.length > 0)) return null;
        if (id) {
            return invoiceList.find(i => i.invoiceId === id)
        } else {
            return invoiceList[0]
        }
    }, [invoiceList, o])

    useEffect(() => {
        fetchData();
    }, [location, history, fetchData, fetchInvoices]);

    // 已退订当前订单
    const isUnsubscribe = orderDetail?.status === 5;

    return (
        <>
            <div className="page-header">
                <div className="title">发票详情</div>
            </div>
            <Layout className="page-content order-detail">
                <div className="order-detail-info">
                    <div className="notice-img-icon">
                        {orderDetail?.status === 0 && <img src={OrderStatusIcon0} alt="" />}
                        {orderDetail?.status === 1 && <img src={OrderStatusIcon1} alt="" />}
                        {orderDetail?.status === 2 && <img src={OrderStatusIcon2} alt="" />}
                        {orderDetail?.status === 3 ?
                                // 是否初始化成功，成功则显示审批成功，否则审批中
                                orderDetail?.initId ?
                                    <img src={OrderStatusIcon3} alt="" />
                                : <img src={OrderStatusIcon4} alt="" />
                            : null}
                        {orderDetail?.status === 4 && <img src={OrderStatusIcon5} alt="" />}
                        {orderDetail?.status === 5 && <img src={OrderStatusIcon6} alt="" />}
                    </div>
                    <h3
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            marginBottom: 10,
                            paddingBottom: 10,
                            borderBottom: '1px solid #ccc'
                        }}
                    >
                        订单详情
                        {/* {o?.orderId && ( */}
                            <span
                                onClick={() => {
                                    history.goBack();
                                }}
                                style={{
                                    float: 'right',
                                    color: 'rgb(86, 192, 150)',
                                    cursor: 'pointer',
                                    fontSize: '16px'
                                }}
                            >
                                &lt; 返回
                            </span>
                        {/* )} */}
                    </h3>
                    <Row gutter={[0, 20]}>
                        <Col style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }} span={2}>
                            产品名称：
                        </Col>
                        <Col style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }} span={22}>
                            {orderDetail?.orderTitle}
                        </Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>公司名称：</Col>
                        <Col span={22}>{orderDetail?.companyName}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>创建时间：</Col>
                        <Col span={22}>{orderDetail?.createTime}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>电话：</Col>
                        <Col span={22}>{orderDetail?.phoneNumber}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>金额：</Col>
                        <Col style={{ color: 'red' }} span={22}>
                            ￥{toFixedNumStr(orderDetail?.orderCost)}
                        </Col>
                    </Row>
                    {isUnsubscribe && (
                        <Row gutter={[0, 20]}>
                            <Col span={2}>退订金额：</Col>
                            <Col style={{ color: 'red' }} span={22}>
                                ￥{toFixedNumStr(orderDetail?.refundCost)}
                            </Col>
                        </Row>
                    )}

                </div>
                <h3
                    style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        marginBottom: 10,
                        paddingBottom: 10,
                        borderBottom: '1px solid #ccc'
                    }}
                >
                    发票详情
                </h3>

                {currInvoice && (
                    <div className="order-invoice-item">
                        <div className="notice-img-icon">
                            {currInvoice.status === 0 && <img src={InvoiceStatusIcon0} alt="" />}
                            {currInvoice.status === 1 && <img src={InvoiceStatusIcon1} alt="" />}
                            {currInvoice.status === 2 && <img src={InvoiceStatusIcon2} alt="" />}
                            {currInvoice.status === 3 && <img src={InvoiceStatusIcon3} alt="" />}
                        </div>

                        <Row gutter={[0, 20]}>
                            <Col span={2}>发票抬头：</Col>
                            <Col span={22}>{currInvoice.invoiceTitle}</Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col span={2}>金额：</Col>
                            <Col span={22}>￥{toFixedNumStr(currInvoice.invoiceCost)}</Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col span={2}>备注说明：</Col>
                            <Col span={22}>{currInvoice.remark}</Col>
                        </Row>
                        {currInvoice.invoiceType === 2 && (<>
                            <Row gutter={[0, 20]}>
                                <Col span={2}>公司税号：</Col>
                                <Col span={22}>{currInvoice.dutyParagraph}</Col>
                            </Row>
                            <Row gutter={[0, 20]}>
                                <Col span={2}>公司注册电话：</Col>
                                <Col span={22}>{currInvoice.companyPhone}</Col>
                            </Row>
                            <Row gutter={[0, 20]}>
                                <Col span={2}>公司地址：</Col>
                                <Col span={22}>{currInvoice.companyAddress}</Col>
                            </Row>
                            <Row gutter={[0, 20]}>
                                <Col span={2}>开户行：</Col>
                                <Col span={22}>{currInvoice.accountBank}</Col>
                            </Row>
                            <Row gutter={[0, 20]}>
                                <Col span={2}>银行账户：</Col>
                                <Col span={22}>{currInvoice.accountNumber}</Col>
                            </Row>
                        </>)}
                        <Row gutter={[0, 20]}>
                            <Col span={2}>收件人：</Col>
                            <Col span={22}>{currInvoice.receiver}</Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col span={2}>收件人电话：</Col>
                            <Col span={22}>{currInvoice.receiverPhone}</Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col span={2}>寄件地址：</Col>
                            <Col span={22}>{currInvoice.receiverAddress}</Col>
                        </Row>
                        {currInvoice.approveOpinion && (
                            <Row gutter={[0, 20]}>
                                <Col span={2}>审批意见：</Col>
                                <Col span={22}>{invoiceList[0].approveOpinion}</Col>
                            </Row>
                        )}
                        {currInvoice.approveTime && (
                            <Row gutter={[0, 20]}>
                                <Col span={2}>审批时间：</Col>
                                <Col span={22}>{currInvoice.approveTime}</Col>
                            </Row>
                        )}
                    </div>
                )}
            </Layout>
        </>
    );
};
