
/**
 * @file   数字资产管理系统
 * @create macaiqi 2021/04/14
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import { localDS } from '../../../storage';
import ReactWOW from 'react-wow'
import './style.scss'
import { handleFreeTrial } from '../../../utils/productUtils';

/**
 * @description 数字资产管理系统
 */
export const VisualAssetManagement = () => {
    const [tabindex, setTabindex] = useState(1)
    const oDiv = useRef();
    const gnzc = useRef()
    const cptd = useRef()
    const { search } = useLocation()
    const [showTrialTip, setShowTrialTip] = useState(false);

    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        setTabindex(anchorName)
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        }
    }
    useEffect(() => {
        // tab吸顶
        let divT = oDiv.current.offsetTop
        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

            if (scrollT >= divT) {
                if (window.navigator.userAgent.indexOf('MSIE 6.0') != -1) {
                    // 兼容IE6代码
                    // body[0].style.paddingTop = '50px'
                    oDiv.current.style.position = 'absolute';
                    oDiv.current.style.top = scrollT + 'px';
                    oDiv.current.style.left = 0 + 'px';
                } else {
                    // 正常浏览器代码
                    oDiv.current.style.position = 'fixed';
                    oDiv.current.style.top = 0;
                    oDiv.current.style.left = 0;
                }
                document.getElementById('content').style.paddingTop = 50 + 'px'
                for (let i = 0; i < tab.length; i++) {
                    if (scrollT >= document.getElementById(`${i + 1}`)?.offsetTop) {
                        document.getElementById(`link${i + 1}`).classList.add('active');
                        for (let j = 0; j < tab.length; j++) {
                            if (i !== j) {
                                document.getElementById(`link${j + 1}`).classList.remove('active');
                            }
                        }
                    }

                }
            } else {
                oDiv.current.style.position = '';
                document.getElementById('content').style.paddingTop = 0 + 'px'
                for (let j = 0; j < tab.length; j++) {
                    document.getElementById(`link${j + 1}`).classList.remove('active');
                }
            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(search.split('?')[1])
        }
    }, [cptd, gnzc, oDiv, search]);

    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']

    return (<>
        <IntroHeader />
        <div className="activity-appointment" id='content'>
            <div className="banner visual">
                <ReactWOW animation="fadeInLeft" duration="1.2s" delay="0.1s">
                    <div className="content">
                        <p className="big">数字资产管理系统</p>
                        <div className="cont">通过对数字资产上传、登记、入库、共享、授权、注销等全生命周期的规范化管理，建立数字资产管理应用的规范化流程。建立开放、共享、安全的数字资产库，满足数字资产在陈列展览、教育、学术研究、文创、版权授权等业务领域的广泛应用。</div>
                        <div className="btn">
                            {/* <div className="btn1" onClick={() => {
                                const token = localDS.get('saasToken')
                                if (!token) {
                                    window.location.href = "/accountLogin"
                                    return
                                }
                                let a = document.createElement("a"); // 创建一个<a></a>标签
                                a.href = `${window.location.origin}/pdf/visualasset.pdf`; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
                                a.download = "产品白皮书.pdf"; // 设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
                                a.style.display = "none"; // 障眼法藏起来a标签
                                document.body.appendChild(a); // 将a标签追加到文档对象中
                                a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                                a.remove(); // 一次性的，用完就删除a标签
                            }}>
                                产品白皮书
                            </div> */}
                            {/* <div className="btn1">
                                观看演示
                            </div> */}
                            <div
                                className="btn2"
                                onClick={handleFreeTrial}
                                onMouseOver={() => { setShowTrialTip(true) }}
                                onMouseLeave={() => { setShowTrialTip(false) }}
                            >
                                免费试用
                                {showTrialTip && <div className='hov'>! 注册并申请机构实名认证后即可免费试用</div>}
                            </div>
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="tab" ref={oDiv}>
                <ul>
                    {tab?.map((item, index) => {
                        return (
                            <li key={index}>
                                <span onClick={() => scrollToAnchor(index + 1)} id={`link${index + 1}`}>{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <div className="people" id='1'>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title" >用户群体</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <p className="txt">本产品适用于对图片、视频、模型等数字类资产有管理和利用需求的公共文化场馆、教育科研机构等企事业单位，面向数字资产责任部门及相关工作人员提供资产管理服务，面向其他部门人员提供全面的数字资产检索和下载服务。</p>
                </ReactWOW>
            </div>
            <div className="section2" id='2' ref={gnzc}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1.1s">
                    <p className="title" id='two'>功能组成</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1s">
                    <div className="visua-function">
                        <div className="item">
                            <i className="v1" />
                            <div className="tip">资产登记</div>
                            <p className="cont">
                                为用户进行数字资产信息登记和资源上传提供快捷入口，为数字资产的统一管理和共享利用奠定数据基础。主要功能包括数字资产的上传、登记和发布。
                            </p>
                        </div>
                        <div className="item">
                            <i className="v2" />
                            <div className="tip">待登记资产管理</div>
                            <p className="cont">
                                针对用户已上传但尚未提审的资产，提供资产重新上传、导入、删除等功能，针对同类型资产可建立资产集，从而实现待登记资产的全面、统一、分类管理。主要功能包括新建资产集、资产上传和删除。
                            </p>
                        </div>
                        <div className="item">
                            <i className="v3" />
                            <div className="tip">资产管理</div>
                            <p className="cont">
                                针对登录用户登记并发布的资产或资产集，提供便捷、高效的分类浏览、检索和编辑等功能，实现已入库资产文件或资产集的全面、统一管理。主要功能包括资产分类浏览、检索、查看详情、编辑和删除。
                            </p>
                        </div>
                        <div className="item">
                            <i className="v4" />
                            <div className="tip">回收站</div>
                            <p className="cont">
                                临时保存用户已删除的资产对象，并可还原已删除资源。用户可手动清空回收站，同时，系统会根据时间期限自动清空回收站。
                            </p>
                        </div>
                        <div className="item">
                            <i className="v5" />
                            <div className="tip">资产统计</div>
                            <p className="cont">
                                对已入库资产及个人管理资产进行不同维度的数据统计，帮助用户快速了解资产类型、数量、使用情况等信息。
                            </p>
                        </div>
                        <div className="item">
                            <i className="v6" />
                            <div className="tip">数字资产库</div>
                            <p className="cont">
                                为满足用户对数字资产的应用需求，以申请授权的方式提供对数字资产库的分类浏览、检索、查看详情、下载、分享、收藏等功能。
                            </p>
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="section3" id='3' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.5s">
                    <p className="title">产品特点</p>
                </ReactWOW>
                <div className="section3-box">
                    <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.5s">
                        <div className="item">
                            <div className="itembox">
                                <div className="l">
                                    <i className='icon6' />
                                </div>
                                <div className="r">
                                    <p className="tip">丰富的资产类型定义</p>
                                    <p className='txt'>以资产集的方式，支持图片、视频、模型等丰富的资产类型定义和管理。</p>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.7s">
                        <div className="item">
                            <div className="itembox">
                                <div className="l">
                                    <i className='icon7' />
                                </div>
                                <div className="r">
                                    <p className="tip">全面可靠的权限管理</p>
                                    <p className='txt'>提供强大的权限管理机制，权限控制可精确到个人或功能模块。</p>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.9s">
                        <div className="item">
                            <div className="itembox">
                                <div className="l">
                                    <i className='icon8' />
                                </div>
                                <div className="r">
                                    <p className="tip">灵活的审核流程设置</p>
                                    <p className='txt'>通过工作流自定义，灵活满足不同的审核流程设置需求。</p>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp-little" duration="1s" delay="1.1s">
                        <div className="item">
                            <div className="itembox">
                                <div className="l">
                                    <i className='icon9' />
                                </div>
                                <div className="r">
                                    <p className="tip">强大的外部数据对接</p>
                                    <p className='txt'>基于统一的接口规范，支持与第三方系统的外部数据对接。</p>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                </div>

            </div>
            <div className="section4" id='4' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title">产品价格</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <div className="box">
                        <p className="txt">
                            支持的数字资产相关文件存储最大存储空间≤1TB，每增加100GB，额外收费500元/年，依次递增。
                        </p>
                        <div className="price">
                            <div className="l">
                                <div className="red">
                                    <span>8800</span>
                                    元/年
                                </div>
                                {/* <span>首年免费</span> */}

                            </div>
                            {/* <div className="buy">立即购买</div> */}
                        </div>

                    </div>
                </ReactWOW>
            </div>

            <div className="update" id='5'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span>
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
