/**
 * @file    产品订购表单价格计算模块
 * @create tianmu 2021/3/23
 * @update tianmu 2021/3/23
 */

import { Radio } from 'antd';
import React from 'react';
import { toFixedNumStr } from '../../../../utils';
import { InputSpinner } from '../../../../components/InputSpinner'
import { unitStr } from '../../../../utils/productUtils';

/**
 * @description 购买产品数量与价格计算组件
 * @param {Object} props 参数对象
 * @param {Object} props.product 产品对象（产品、套件、资源或服务）
 * @param {Function} props.onValueChange 购买的产品数量变更时触发的回调
 * @param {Number} props.idx 多个产品组合成的计算组件Group中，当前组件位于的idx索引
 * @param {Number} props.optionNum 产品购买时有几个级别的可选数量
 * @param {Number} props.min 最小值
 * @param {Number} props.max 最大值
 */
export const PriceCounter = (props) => {
    const { product, onValueChange, idx, optionNum=3, min=0, max=9999, disabled=false } = props;
    if (!product?.price) return null;

    const { quota, unit, price } = product.price;
    if (quota === null || quota === undefined) return null;
    if (unit === null || unit === undefined) return null;
    if (price === null || price === undefined) return null;

    const opts = Array.from({length: optionNum}).map((v, i) => ({
        label: `${quota * (i + 1)} ${unitStr(unit)}`,
        value: quota * (i + 1)
    }));

    const calcMoney = toFixedNumStr(product.value / quota * price)

    return (<>
        <Radio.Group
            disabled={disabled}
            options={opts}
            onChange={(e) => { onValueChange(e.target.value, idx) }}
            value={product.value}
            optionType="button"
        />
            <span
                style={{
                    display: 'inline-block',
                    padding: '0 20px'
                }}
            >
                自定义
            </span>

            <InputSpinner
                disabled={disabled}
                type='real'
                precision={0}
                max={max}
                min={min}
                step={quota}
                value={product.value}
                onChange={num => onValueChange(num, idx)}
            />
            {/* <InputNumber
                value={product.value}
                min={0}
                step={product.price?.quota}
                onChange={(v) => {onProductValueChange(v, idx)}}
            /> */}

            <span className="cost-money-symbol" style={{marginLeft: 10}}>
            ￥
            </span>
            <span className="cost-money-number">
                {calcMoney}
            </span>
    </>)
}
