/**
 * @file   全局store模块
 * @create tianmu 2020/10/16
 * @update tianmu 2020/10/16
 */
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../pages/login/store";
import productReducer from "../pages/productShop/store";
import globReducer from "./globStore";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// import { composeWithDevTools } from "redux-devtools-extension";

// 持久化存储状态
const productConfig = {
    key: 'product',
    storage,
    // whitelist: [] // only ... store will be persisted
}
const authConfig = {
    key: 'auth',
    storage,
    // whitelist: [] // only ... store will be persisted
}


const rootReducer = combineReducers({
    globReducer,
    loginReducer: persistReducer(authConfig, loginReducer),
    productReducer: persistReducer(productConfig, productReducer),
});

// const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
//   reducer: persistedReducer,
    reducer: rootReducer
});
