/**
 * @file   产品介绍欢迎页面header组件手机版
 * @create macaiqi 2021/07/28
 * @update
 */
import React, { useState } from 'react';
// import LOGO from '../../assets/mobile/mobile-logo.png';
import LogoWhite from '../../assets/mobile/logo-white.png';
import cls from 'classnames'
import { Link, useLocation, useHistory } from 'react-router-dom';

// 侧边导航
export const nav = [{
    name: '智慧管理',
    children: [
        {
            name: "藏品管理系统",
            route: "CollectionSystem",
            path: "/CollectionSystem",
            des: "藏品全生命周期规范管理"
        }, {
            name: "数字资产管理系统",
            route: "VisualAssetManagement",
            path: "/VisualAssetManagement",
            des: "支持多类型数字资产共建共享"
        },
        {
            name: "志愿者管理系统",
            route: "VolunteersSystem",
            path: "/VolunteersSystem",
            des: "动态联接文旅机构与志愿者"
        },
        {
            name: "讲解员管理系统",
            route: "ExplainSystem",
            path: "/ExplainSystem",
            des: "讲解排班、预约与分派一体化管理"
        },
        {
            name: "客流管理系统",
            route: "TrafficManagement",
            path: "/TrafficManagement",
            des: "客流量精准统计与预测"
        }]
}, {
    name: '智慧服务',
    children: [{
        name: "参观预约系统",
        route: "VisitAppointment",
        path: "/VisitAppointment",
        des: "功能强大的分时预约规则设置"
    },
    {
        name: "活动预约系统",
        route: "ActivityAppointment",
        path: "/ActivityAppointment",
        des: "便捷的课程、活动发布和预约管理"
    },
    {
        name: "特展预约系统",
        route: "ShowBooking",
        path: "/ShowBooking",
        des: "支持特展预约和支付"
    },]
}]
const Menu = ({ close, setClose }) => {
    const { pathname } = useLocation()
    const history = useHistory()
    // 下拉条
    const [open, setOpen] = useState()
    const channel = pathname.split('/').reverse()[0]
    return (

        <div className={cls("menubox", { close })}>
            <div className={cls("shade")} onClick={(e) => {
                e.stopPropagation()
                setClose(true)
            }} />
            {nav.map((item, index) => {
                return (
                    <div className={cls("first open", {
                        // 'open': open === index,
                        'active': item.children.filter((val, i) => {
                            return val.route === channel
                        })?.[0]
                    })} key={index}
                    // onClick={() => {
                    //     setOpen(index)
                    // }}
                    >
                        <p>
                            <span className='name'>{item.name}</span>
                            <i className="iconfont iconxiangxia" />

                        </p>
                        <ul className='sec'>
                            {
                                item.children.map((item1, index1) => {
                                    return (
                                        <li key={index1} className={channel === item1.route ? 'active' : null} onClick={() => {

                                            history.push({
                                                pathname: item1.route
                                            })
                                        }}>
                                            <span >{item1.name}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )
            })}
        </div>


    )
}
/**
 * @description 产品介绍欢迎Header组件
 */
export const IntroHeader = () => {
    // 关闭侧边
    const [close, setClose] = useState(true)
    return (
        <header className="mobile-header">
            <Link to="/mobile/" className="logo">
                <img src={LogoWhite} alt="" />
            </Link>
            <i className="menu iconfont icona-caidan1" onClick={() => {
                setClose(false)
            }} />
            <Menu setClose={setClose} close={close} />
        </header>
    );
};
