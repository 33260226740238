/**
 * @file   登陆页面
 * @create tianmu 2020/10/16
 * @update tianmu 2020/10/16
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';
// import { AppHeader } from '../../containers/AppHeader'
import { AppFooter } from '../../containers/AppFooter';
import { AccountForm } from './AccountForm.jsx';
import { PhoneForm } from './PhoneForm.jsx';
import Logo from '../../assets/logo-a.png';
import '../login/index.scss';
import './index.scss';
import { queryNeedVCode } from '../../service';

// import WechatLoginICON from '../../assets/wechat-login-icon.png';

// import CryptoJS from 'crypto-js';

// const WECHAT_LOGIN_APPID = process.env.ENV_WECHAT_LOGIN_APPID;
// const WECHAT_LOGIN_ADDR = process.env.ENV_WECHAT_LOGIN_ADDR;

const { Content, Header } = Layout;
// https://open.weixin.qq.com/connect/qrconnect?appid=wx8c0337167237b94b&redirect_uri=http%3A%2F%2Fzjmuex.zjmuex.com%2Fuc%2F%23%2Flogin&response_type=code&scope=snsapi_login&state=123#wechat_redirect

/**
 * @description 登录表单组件
 */
export const Login = () => {
    const location = useLocation();

    return (
        <Layout className="login">
            {/* <Header className="layout-header">
                <div className="logo-box">
                    <a className="logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                </div>
            </Header> */}
            <Content>
                <div className='login-center-box'>
                    <div className='login-left'>
                        <div className="logo-box">
                            <a className="logo" href="/">
                                <img src={Logo} alt="logo" />
                            </a>
                        </div>
                        <h1>领先的智慧文旅</h1>
                        <h2>SaaS服务平台</h2>
                        <div className='login-left-foot'>
                            <span style={{fontWeight: 'bold'}}>版权所有</span> © 武汉瑞鹿文化科技有限公司 <br />
                            2021 保留一切权利 鄂ICP备2021006883号-1 鄂B2-20130048号鄂公网安备 32011402010636号
                        </div>
                    </div>
                    <div className="login-modal">
                        {location.pathname.includes('accountLogin') && <AccountForm />}
                        {location.pathname.includes('phoneLogin') && <PhoneForm />}
                    </div>

                </div>
            </Content>
            {/* <AppFooter /> */}
        </Layout>
    );
};
