/**
 * @file   手机版系统全部展示页
 * @create macaiqi 2022/06/02
 * @update
 */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { nav } from './header';
import './index.scss';


export const WapSystemAll = () => {
    const history = useHistory()

    return (
        <div className='mobile-systemall'>

            {nav?.map((item1, index1) => {
                return (
                    <div className="section" key={index1}>
                        <div className="title">
                            {item1.name}
                        </div>
                        <ul>
                            {
                                item1?.children?.map((item, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            history.push({
                                                pathname: item.route
                                            })
                                            document.documentElement.scrollTop = 0
                                        }}>
                                            <span >{item.name}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )
            })}




        </div>
    )
}
