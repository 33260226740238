/**
 * @file    产品订购表单模块
 * @create tianmu 2021/3/23
 * @update tianmu 2021/3/23
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row, Col, Radio, notification, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { preSubmit, updateOrderState } from '../../store';
import { OrderSumInfo } from '../OrderSumInfo';
import './index.scss';
import { queryDiscountInfo, queryKitById } from '../../../../service';
import UnderCarriageIcon from '../../../../assets/notice/5.png'

// 单位枚举转为字符串描述
const unitStr = (unit) => {
    switch (unit) {
        case 1:
            return '年';
        case 2:
            return '月';
        case 3:
            return '人';
        case 4:
            return 'GB';
        case 5:
            return '次';
        default:
            return '';
    }
};

// const moneyStyle = {
//     color: 'rgb(211, 60, 60)',
//     fontSize: 22,
//     fontWeight: 'bold',
//     marginLeft: 20,
// }

// 单个套件订购表单组件
export const KitOrderForm = (props) => {
    const { finish, id } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { orderState } = useSelector((state) => state.productReducer);
    const { kit } = orderState;
    const [discInfos, setDiscInfos] = useState([]);
    const [underCarriage, setUndercarriage] = useState(false);

    // 表单提交成功
    const onSubmitClick = async () => {
        setLoading(true);
        const success = await dispatch(preSubmit());
        setLoading(false);
        if (success) {
            finish();
        }
    };

    const fetchKitDetail = useCallback(async () => {
        const res = await queryKitById(id);
        if (res.data.code === 200) {
            const p = res.data.data;
            if (!p || !p.price) return null;
            return {
                ...p,
                value: p.price?.quota,
                sum: p.price?.price,
            }
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
            return null;
        }
    }, [id]);

    useEffect(() => {
        // console.log(product)
        if (!id) {
            history.push('/MyProduct');
        }
        const fetchData = async () => {
            const kit = await fetchKitDetail();
            const discInfoPromises = [queryDiscountInfo({ type: 1, id: kit.kitId })]
            // type=1套件 2 产品 3资源 4服务
            Promise.all(
                discInfoPromises
            ).then((rs) => {
            setDiscInfos(rs.map(i =>
                (Array.isArray(i?.data?.data) && i?.data?.data.length>0) ?
                i.data.data[0] :
                null
            ).filter(i => !!i))
            });
            // 如果产品下架，则禁用页面交互，显示下架
            if (kit && kit.status !== 1) {
                setUndercarriage(true)
            }

            // 初始化订单状态
            dispatch(updateOrderState({
                kit: kit,
                productList: [],
                serviceList: [],
                resourceList: [],
            }))
        }
        fetchData()
    }, [dispatch, fetchKitDetail, history, id]);

    return (
        <>
            <div
                className="product-order-form"
            >
                {underCarriage && (
                    <div className="notice-img-icon">
                        <img src={UnderCarriageIcon} alt="" />
                    </div>
                )}
                {kit && (<>
                    <div
                        className="order-product-item"
                    >
                        <Row gutter={[0, 20]}>
                            <Col className="order-product-title" span={2}>套件名称：</Col>
                            <Col className="order-product-title" span={22}>
                                {/* <a
                                    href="/#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(setDetailItem(kitToBuy));
                                        history.push('/ProductIntro');
                                    }}
                                > */}
                                    {kit?.kitName}
                                    <Tooltip
                                        placement="topLeft"
                                        color="#fff"
                                        title={
                                            <div style={{color: '#000'}}>
                                                套件一旦订购成功后，套件里的任何产品均不支持退订！
                                            </div>
                                        }
                                    >
                                        <span
                                            style={{
                                                fontSize: 16,
                                                color: '#559f80',
                                                marginLeft: '5px'
                                            }}
                                        >
                                            <ExclamationCircleOutlined />
                                        </span>
                                    </Tooltip>

                                {/* </a> */}
                            </Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col className="order-product-desc" span={2}>套件介绍：</Col>
                            <Col className="order-product-desc" span={22}>{kit?.description}</Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col className="order-product-desc" span={2}>套件内容：</Col>
                            <Col className="order-product-desc" span={22}>{kit?.details?.map(d => d.name + d.quota + unitStr(d.unit)).join(', ')}</Col>
                        </Row>
                        <Row className="order-product-desc" gutter={[0, 20]}>
                            <Col span={2}>购买数量：</Col>
                            <Col span={22}>
                                <Radio.Group
                                    disabled={underCarriage}
                                    options={[
                                        {
                                            label: "1套",
                                            value: 1,
                                        },
                                    ]}
                                    value={1}
                                    optionType="button"
                                />
                            </Col>
                        </Row>
                    </div>
                    <h3
                        style={{
                            fontSize: 22,
                            margin: '10px 0',
                        }}
                    >
                        套件详情
                    </h3>
                    {Array.isArray(kit.details) &&
                    (<div className="order-service-list">
                        {
                           kit.details.map(item => (
                            <div
                                className="order-service-item"
                                key={item.id}
                            >
                                <Row gutter={[0, 10]}>
                                    <Col className="order-service-title" span={2}>名 称：</Col>
                                    <Col className="order-service-title" span={22}>
                                        {item.name}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 10]}>
                                    <Col className="order-service-desc" span={2}>介 绍：</Col>
                                    <Col className="order-service-desc" span={22}>{item.description}</Col>
                                </Row>
                                <Row className="order-service-desc" gutter={[0, 10]}>
                                    <Col span={2}>数 量：</Col>
                                    <Col span={22}>
                                        {item.quota}
                                        {unitStr(item.unit)}
                                    </Col>
                                </Row>
                            </div>
                        ))
                        }
                    </div>)
                    }

                </>)}
            </div>
            <div
                className="order-form-footer"
            >
                <Button
                    className="order-form-footer-btns"
                    onClick={() => history.goBack()}
                >
                    返回
                </Button>
                <Button
                    loading={loading}
                    className="order-form-footer-btns"
                    type="primary"
                    disabled={underCarriage}
                    onClick={onSubmitClick}
                >
                    立即下单
                </Button>
                <OrderSumInfo discInfos={discInfos} />
            </div>
        </>
    );
};
