/**
 * @file   订购服务模块
 * @create tianmu 2021/3/9
 * @update tianmu 2021/3/9
 */
 import React, { useEffect, useRef, useState } from 'react';
 import { CheckCircleFilled } from '@ant-design/icons';
 import { useHistory } from 'react-router';
 import './index.scss'
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const WAIT_SECS = 5

 // 微信授权绑定成功
 export const WechatLoginSuccess = () => {
     const history = useHistory();
     const ref = useRef();
    //  const [time, setTime] = useState(WAIT_SECS);
    // useEffect(() => {
    //     ref.current = setInterval(() => {
    //         setTime((time) => {
    //             const left = time - 1;
    //             // 点击验证码之后TIME_LIMIT秒
    //             if (left === 0) {
    //                 window.clearInterval(ref.current);
    //                 history.replace("/Index")
    //             }
    //             return left;
    //         });
    //     }, 1000);

    // })
     return (
        <div className="wechatLoginSuccess" style={{overflow: 'hidden'}}>
            <div className="orderinfobox" style={{
                marginTop: '30vh',
                // overflow: 'hidden',
            }}>
                <div className="head">
                    <div
                        style={{
                            display: 'inline-block',
                            width: '100px',
                            height: '100px',
                            lineHeight: '100px',
                            fontSize: '50px',
                            color: 'rgb(61, 188, 142)',
                        }}
                    >
                        <CheckCircleFilled />
                    </div>
                    <div className="infobox">
                        <h1>
                            {/* <CheckCircleFilled style={{marginRight: 10}} /> */}
                            绑定成功！
                        </h1>
                        <p>
                            您的手机号已注册成为您的瑞鹿云账号，建议您再次登录时使用该手机号进行登录
                        </p>
                    </div>
                </div>
            </div>
            <div
                style={{
                    textAlign: 'center',
                    marginTop: '20px'
                }}
            >
                {/* {time} 秒后自动关闭 */}
                <Link to='/' style={{marginRight: 10}}><Button>首页</Button></Link>
                <Link to='/Index'><Button>控制台</Button></Link>
            </div>
        </div>
     );
 };
