/**
 * @file   注册模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/23
 */
import React from 'react';
import { Layout, Divider, Row, Col } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { AppHeader } from '../../containers/AppHeader'
import { AppFooter } from '../../containers/AppFooter';
import { RegisterForm } from './RegisterForm.jsx';
import '../login/index.scss';
import './index.scss';
import Logo from '../../assets/logo-a.png';
import { IntroHeader } from '../introduction/header';
const { Content, Header } = Layout;

/**
 * @description 注册组件
 */
export const Register = () => {
    const location = useLocation();
    const history = useHistory();
    return (
        <Layout className="register">
            {/* <IntroHeader /> */}
            {/* <Header className="layout-header">
                <div className="logo-box">
                    <a className="logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                </div>
            </Header> */}

            <Content>
                <div className='register-center-box'>
                    <div className='register-left'>
                        <div className="logo-box">
                            <a className="logo" href="/">
                                <img src={Logo} alt="logo" />
                            </a>
                        </div>
                        <h1>领先的智慧文旅</h1>
                        <h2>SaaS服务平台</h2>
                        <div className='register-left-foot'>
                            <span style={{fontWeight: 'bold'}}>版权所有</span> © 武汉瑞鹿文化科技有限公司 <br />
                            2021 保留一切权利 鄂ICP备2021006883号-1 鄂B2-20130048号鄂公网安备 32011402010636号
                        </div>
                    </div>
                    <div className="register-modal">
                    <h3 className="register-title">欢迎注册瑞鹿云</h3>
                        <RegisterForm />
                        <Row>
                            <Col span={16} offset={4}>
                                <div className="register-links">
                                    <Link style={{ color: '#aaa', marginRight: 10 }} to="/accountComplain" >帐号申诉</Link>

                                    已有帐号？<Link style={{color: '#00a2fe'}} to="/accountLogin">快捷登录</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

            </Content>
            {/* <AppFooter /> */}
        </Layout>
    );
};
