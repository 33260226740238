/**
 * @file   本地存储导出模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20
 */

import DataStorage from './DataStorage';
const sessionDS = new DataStorage(sessionStorage);
const localDS = new DataStorage(localStorage);

export { sessionDS };
export { localDS };
