import React from 'react';
import ProductTag from '../../../../assets/product-tag.svg';

export const EnumTag = Object.freeze({
    INUSE: '使用中',
    USED: '已使用',
    UNUSED: '未使用',
    EXPIRE: '已到期',
    ORDERED: '已订购',
    ONTRIAL: '试用中',
    TRIALEXPIRE: '试用到期',
    GIFT: '赠 送',
})

export const CornerTag = (props) => {
    const { tag } = props;
    if (!tag) return null;
    return (
        <span className="product-tag">
            <img src={ProductTag} alt="" />
            <span
                style={{
                    position: 'absolute',
                    left: tag.length > 3 ? 24: 30,
                    top: tag.length > 3 ? 22: 20,
                    color: '#fff',
                    transform: 'rotate(45deg)'
                }}
            >
                {tag}
            </span>
        </span>
    );
};
