/**
 * @file   藏品管理系统
 * @create macaiqi 2021/07/29
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import cls from 'classnames'
import './style.scss'



export const WapCollectionSystem = () => {
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    const settings = {
        arrows: false,
        dots: false,
        // infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "3rem",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    const sec3 = [
        {
            name: '藏品生命周期业务全覆盖',
            des: '实现了藏品管理的全流程覆盖，支持对已有藏品数据的智能化导入，全方位提升藏品管理的便捷性和效率。',
            icon: 'iconyizhanshijiejuefangan',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '灵活的业务规则配置',
            des: '针对用户藏品管理的业务特性，可自由定制藏品管理工作流程以及藏品登录、查询各项指标，拥有强大的业务适应能力。',
            icon: 'iconshouji21',
            duration: '1s',
            delay: '0.7s'
        },
        {
            name: '开放式的藏品资源库',
            des: '基于授权控制的藏品资源开放共享，充分发挥藏品资源价值，实现资源最大化利用。',
            icon: 'iconlinghuoguanli',
            duration: '1s',
            delay: '0.9s'
        },
        {
            name: '多维的藏品数据可视化',
            des: '基于藏品数据分析模型，可视化呈现藏品家底，辅助管理与决策。',
            icon: 'iconcangpintongji',
            duration: '1s',
            delay: '0.9s'
        },
    ]
    // 功能组成
    const gongneng = [
        {
            name: "藏品业务管理",
            tab: [],
            des: '为藏品的征集、登录、保管、流动、注销等全生命周期的业务流转和信息维护提供标准化、规范化管理，主要包含藏品征集、藏品入馆、藏品入藏、藏品编目、藏品上架、藏品提用、藏品出库、藏品入库、藏品盘核、藏品注销等业务功能。',
            icon: 'icona-404jiankongshexiangtou-xianxing'
        },
        {
            name: "藏品数字资源",
            tab: [],
            des: '对全馆/园区及各展厅的客流进行汇总统计，主要包括全馆客流量统计、展厅客流量统计、区域时段客流量统计。',
            icon: 'iconhuodongyuyueguanli'
        },
        {
            name: "藏品附属信息",
            tab: [],
            des: '提供藏品相关的附属信息管理维护，进而丰富藏品数据库，主要包括藏品鉴定信息、研究著录、展出记录、损坏记录、修复记录等方面。',
            icon: 'icona-fenxi1'
        },
        {
            name: "藏品数据统计",
            tab: [],
            des: '提供基于藏品账目、数量动态变化、提用出入库利用情况、数字资源利用率等维度的数据统计，主要包括藏品一张图、藏品账目统计、藏品变动统计、藏品出入库统计、藏品数字资源统计。',
            icon: 'iconshujutongji1'
        },
        {
            name: "藏品资源库",
            tab: [],
            des: '面向有藏品资源查阅、资源利用需求的用户开放藏品资源（如藏品信息、藏品数字资源），实现藏品信息与影像资源的共享，提供便捷、高效的检索，主要功能包括关键字检索、分类检索、查看详情、收藏、下载。',
            icon: 'iconzhanshi'
        },
        {
            name: "基础配置",
            tab: [],
            des: '提供支撑藏品管理系统业务正常运转所涉及的基础数据、全局参数配置维护等功能，主要包括藏品分类管理、库房方位管理、参数配置。',
            icon: 'iconpeizhi'
        },
    ]
    const [down, setDown] = useState()
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        if (anchorName || anchorName === 0) {
            const height = document.getElementById(anchorName).offsetTop - 40; // 计算需要向上移动的距离
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        }
    };
    const banner = useRef()
    const activeref = useRef()
    const { search } = useLocation()
    useEffect(() => {

        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // 滚动超过banner后nav吸顶
            if (scrollT >= banner.current.clientHeight + banner.current.offsetTop) {
                activeref.current?.classList.add('active');
            } else {
                activeref.current?.classList.remove('active');
            }
            // 滚动到对应点，对应nav高亮
            for (let i = 0; i < tab.length; i++) {

                if (scrollT >= document.getElementById(`item${i}`).offsetTop - 40) {
                    document.getElementById(`link${i}`).classList.add('active');
                    for (let j = 0; j < tab.length; j++) {
                        if (i !== j) {
                            document.getElementById(`link${j}`).classList.remove('active');
                        }
                    }
                }

            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(`item${search.split('?')[1]}`)
        }
    }, [tab.length, search]);

    return (<>
        <IntroHeader />
        <div className="mobile-content traffic" ref={activeref}>
            <div className="banner" ref={banner}>
                <div className="content">
                    <p className="big">藏品管理系统</p>
                    <div className="cont">
                        以藏品管理业务流程为主线，实现藏品征集、入馆、入藏、编目、上架、提用、出库、入库、盘核、注销等全生命周期的数字化协同管理；通过对藏品相关信息的汇聚和分析，辅助各级管理和决策；提供藏品数据授权开放共享，支持藏品数据在陈列展览、教育、学术研究、文创等业务领域的应用。
                    </div>
                    <div className="btnbox">
                        {/* <div className="btn btn1">
                             观看演示
                         </div> */}
                        {/* <div className=" btn btn2">
                             免费体验
                         </div> */}
                    </div>

                </div>
            </div>
            <div className="tab" >
                <ul>
                    {tab.map((item, index) => {
                        return (
                            <li key={index} id={`link${index}`} onClick={() => {
                                scrollToAnchor(`item${index}`)
                            }}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="people" id='item0'>
                <p className="title" >用户群体</p>
                <p className="txt">本产品适用于有文物、典籍、标本等藏品管理需求的中小型博物馆、美术馆、图书馆、文化馆、校史馆、陈列馆及其他有藏品管理需求的企事业单位。</p>
            </div>
            <div className="section2" id='item1' >
                <p className="title" >功能组成</p>
                <div className="functionof">
                    <Slider {...settings} key={Math.random()} >
                        {gongneng.map((item, index) => {
                            return (
                                <div className="itembox" key={index}>
                                    <div className="item">
                                        <i className={cls('iconfont', item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        {item.tab.map((item1, i1) => {
                                            return (<p className="txt" key={i1}>{item1}</p>)
                                        })}

                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div className="section3" id='item2'>
                <p className="title">产品特点</p>
                {
                    sec3.map((item, index) => {
                        return (
                            <div className={cls("item")} key={index} >
                                <div className="box">
                                    <div className="l">
                                        <i className={cls('iconfont', item.icon)} />
                                        {item.name}
                                    </div>
                                    <div className="down" />
                                </div>
                                <div className="text">
                                    {item.des}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="price" id='item3'>
                <p className="title">产品价格</p>
                <div className="num">
                    9800
                    <span>元/年</span>
                </div>
            </div>
            <div className="update" id='item4'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }} />
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
