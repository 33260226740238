/**
 * @file   我的发票模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Modal, Form, Radio, notification } from 'antd';
import { SaveInvoiceTitle, updateInvoiceTitleById } from '../../service';
import { validator } from '../../utils';
import { getBankBin } from 'bankcardinfo';

// 我的发票页面组件
export const InvoiceTitleModal = (props) => {
    const { isOpen, close, initValue } = props;
    const [form] = Form.useForm();
    const [confirmLoading, setLoading] = useState(false);
    const [currTitleType, setTitleType] = useState(1);
    const [accountBankReadOnly, setAccountBankReadOnly] = useState(false);
    // 重置表单为默认状态
    const resetForm = useCallback(() => {
        form.resetFields();
        form.setFieldsValue({
            titleType: 1,
            isDefault: 0
        });
        setTitleType(1);
    }, [form]);

    useEffect(() => {
        if (initValue) {
            form.setFieldsValue(initValue);
            setTitleType(initValue.titleType);
        } else {
            resetForm();
        }
    }, [form, resetForm, initValue]);

    const handleOk = async () => {
        await form.validateFields();
        setLoading(true);
        const v = form.getFieldsValue();
        let res;
        if (initValue?.invoiceTitleId) {
            if (currTitleType === 1) {
                [
                    'dutyParagraph',
                    'companyPhone',
                    'companyAddress',
                    'accountBank',
                    'accountNumber',
                ].forEach((k) => {
                    // 这里要传递空字符串更新后台数据
                    v[k] = ""
                })
            }
            // 有具体某条发票抬头的初始值则调用修改接口
            res = await updateInvoiceTitleById({
                ...v,
                invoiceTitleId: initValue.invoiceTitleId
            });
        } else {
            res = await SaveInvoiceTitle(v);
        }
        if (res && res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '保存成功！',
                duration: 5
            });

            setLoading(false);
            close();
            resetForm();
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });

            resetForm();
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setLoading(false);
        close();
        resetForm();
    };

    return (
        <Modal
            title={initValue ? '修改发票抬头' : '新增发票抬头'}
            centered
            width={700}
            visible={isOpen}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            maskClosable={false}
            keyboard={false}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    取消
                </Button>,
                <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
                    确定
                </Button>
            ]}
        >
            <Form
                form={form}
                labelCol={{
                    span: 6
                }}
                wrapperCol={{
                    span: 16
                }}
            >
                <Form.Item
                    name="titleType"
                    label="发票抬头类型"
                    rules={[
                        {
                            required: true,
                            message: '请确认发票抬头类型!'
                        }
                    ]}
                >
                    <Radio.Group
                        onChange={(e) => {
                            const t = parseInt(e.target.value, 10);
                            form.setFieldsValue('titleType', t);
                            setTitleType(t);
                        }}
                    >
                        <Radio value={1}>个人</Radio>
                        <Radio value={2}>企业单位</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="titleName"
                    label="抬头名称"
                    rules={[
                        {
                            required: true,
                            message: '请确认抬头名称!'
                        }
                    ]}
                >
                    <Input placeholder="请确认抬头名称" />
                </Form.Item>
                <Form.Item
                    name="isDefault"
                    label="是否默认"
                    rules={[
                        {
                            required: true,
                            message: '请确认是否默认!'
                        }
                    ]}
                >
                    <Radio.Group
                        onChange={(e) => {
                            const t = parseInt(e.target.value, 10);
                            form.setFieldsValue('isDefault', t);
                        }}
                    >
                        <Radio value={0}>否</Radio>
                        <Radio value={1}>是</Radio>
                    </Radio.Group>
                </Form.Item>
                {currTitleType === 2 && (
                    <>
                        <Form.Item
                            name="dutyParagraph"
                            label="公司税号"
                            rules={[
                                {
                                    required: true,
                                    message: '请确认公司税号!'
                                }
                            ]}
                        >
                            <Input placeholder="请确认公司税号" />
                        </Form.Item>
                        <Form.Item
                            name="companyPhone"
                            label="注册电话"
                            rules={[
                                {
                                    required: true,
                                    pattern: validator().phoneTelNumRegex,
                                    message: '请输入正确的手机号或座机号码!'
                                }
                            ]}
                        >
                            <Input placeholder="请确认注册电话" />
                        </Form.Item>
                        <Form.Item
                            name="companyAddress"
                            label="注册地址"
                            rules={[
                                {
                                    required: true,
                                    message: '请确认注册地址!'
                                }
                            ]}
                        >
                            <Input placeholder="请确认注册地址" />
                        </Form.Item>
                        <Form.Item
                            name="accountBank"
                            label="开户行"
                            rules={[
                                {
                                    required: true,
                                    message: '请确认开户行!'
                                }
                            ]}
                        >
                            <Input readOnly={accountBankReadOnly} placeholder="请确认开户行" />
                        </Form.Item>
                        <Form.Item
                            name="accountNumber"
                            label="银行账户"
                            rules={[
                                {
                                    required: true,
                                    message: '请确认银行账户!'
                                }
                                // () => ({
                                //     validator(rule, value) {
                                //         return new Promise((resolve, reject) => {
                                //             if (!value) resolve();
                                //             getBankBin(value, (err,data) => {
                                //                 if (!err) {
                                //                     // {
                                //                     //     bankName:"中国工商银行",
                                //                     //     bankCode:"ICBC",
                                //                     //     cardType:"DC",
                                //                     //     cardTypeName:"储蓄卡"
                                //                     // }
                                //                     form.setFieldsValue({
                                //                         accountBank: data.bankName
                                //                     })
                                //                     setAccountBankReadOnly(true)
                                //                     resolve();
                                //                 }
                                //                 reject(new Error('请输入正确的银行账户!'))
                                //             })
                                //         })
                                //     },
                                // }),
                            ]}
                        >
                            <Input placeholder="请确认银行账户" />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    );
};
