/**
 * @file   表单通用的提交footer模块
 * @create tianmu 2020/10/16
 * @update tianmu 2020/10/16
 */
import React from 'react';
import { Button } from 'antd';

// 表单提交的footer
export const FormFooter = (props) => {
    const { onOk, onCancel, okText, cancelText, loading, btnAlign = 'left' } = props;
    // btnAlign:left,center,right
    return (
        <div className={`common-bottom-btnbox ${btnAlign}`}>
            <Button loading={loading} type="primary" onClick={onOk}>
                {okText}
            </Button>
            <Button onClick={onCancel}>{cancelText}</Button>
        </div>
    );
};
