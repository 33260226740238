/**
 * @file   产品模块布局
 * @create tianmu 2021/4/19
 * @update tianmu 2021/4/19
 */
 import React from 'react';
 import { BackTop, Layout } from 'antd';
 import { AppHeader } from '../../../../containers/AppHeader';

 const { Content } = Layout;

 // 我的产品页面组件
 export const ProductLayout = (props) => {
     const { title, children } = props;
     return (
         <Layout className="app">
             <BackTop />
             <AppHeader activeMenuName="产品商城" />
             <Content className="layout-middle-content">
                 <div className="page-header">
                     <div className="title">{title}</div>
                 </div>
                 { children }
             </Content>
         </Layout>
     );
 };
