import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { searchStrToObj } from '../../utils'
import { saveWxOpenPlatform } from '../../service';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';

// 微信绑定授权与扫码关注组件
export const WechatBind = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        // 用户微信扫码后回调到该页面，静默登录
        const silentWechatBind = async () => {
            const searchStr = location.search;
            const o = searchStrToObj(searchStr)
            if (o && o.code) {
                const loginRes = await saveWxOpenPlatform(o.code);
                if (loginRes.data.code === 200) {
                    notification.success({
                        className: 'custom-antd-notification',
                        message: '绑定成功！',
                        duration: 5,
                    })
                } else {
                    notification.warn({
                        className: 'custom-antd-notification',
                        message: loginRes.data.msg,
                        duration: 5,
                    })
                }
            }
            history.replace(`/index`);
        }
        silentWechatBind();
    }, [location.search, history, dispatch])
    return (
        <div />
    )
}
