/**
 * @file   目录配置模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */
import React from 'react';
import { createFromIconfontCN, BulbOutlined } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
    scriptUrl: window.ICONFONT_URL
});
const menu = [
    {
        title: '首页',
        key: '/Index',
        icon: <IconFont type="iconshouye" />,
    },
    {
        title: '我的机构',
        key: '/Organization',
        icon: <IconFont type="iconwodejigou" />,
    },
    {
        title: '我的产品',
        key: '/MyProduct',
        icon: <IconFont type="iconwodechanpin" />,
    },
    {
        title: '我的订单',
        key: '/MyOrder',
        icon: <IconFont type="iconwodedingdan" />,
    },
    {
        title: '我的发票',
        key: '/MyBill',
        icon: <IconFont type="iconwodefapiao" />,
    },
    {
        title: '我的试用',
        key: '/MyTrial',
        icon: <IconFont type="iconwodeshiyong" />,
    },
    {
        title: '我的工单',
        key: '/MyWorkSheet',
        icon: <IconFont type="iconwodegongdan" />,
    },
    {
        title: '数据统计',
        key: '/Statistic',
        icon: <IconFont type="iconshujutongji" />,
    },
    {
        title: '问题反馈',
        key: '/Feedback',
        icon: <BulbOutlined />,
    }

    /*     {
        title: '我的互动',
        key: '/Interaction',
        icon: <IconFont type="iconshujutongji" />,
        // icon: <WechatFilled />,
        subs: [
            {
                title: '展品评论',
                key: '/Interaction/ExhibitionComment',
            },
            { title: '预约记录', key: '/Interaction/AppointmentRecord' },
            { title: '我的评论', key: '/Interaction/MyComment' },
            { title: '我的收藏', key: '/Interaction/MyCollection' },
            { title: '站内信', key: '/Interaction/SiteLetter' },
            { title: '我要投稿', key: '/Interaction/Contribute' }
        ]
    } */
];

export default menu;
