/**
 * @file   积分商城商品详情模块
 * @create tianmu 2021/7/1
 * @update tianmu 2021/7/1
 */
import React from 'react';
import { ScoreLayout } from './components/ScoreLayout';
import { Button } from 'antd'
import './index.scss';

import TEMP_PIC from '../../assets/temp.png'
import { useHistory } from 'react-router-dom';

 // 积分商城商品详情组件
 export const ScoreProductDetail = () => {
     const history = useHistory()
     return (
        <ScoreLayout>
            <div className="score-product-info">
                <div className="score-product-preview-wrap">
                    <div className="score-product-preview">
                        <img src={TEMP_PIC} alt="" />
                    </div>
                    <div className="score-product-thumb-list-wrap">
                        <div className="score-product-thumb-list">
                            <div className="score-product-thumb-item">
                                <img src={TEMP_PIC} alt="" />
                            </div>
                            <div className="score-product-thumb-item">
                                <img src={TEMP_PIC} alt="" />
                            </div>
                            <div className="score-product-thumb-item">
                                <img src={TEMP_PIC} alt="" />
                            </div>
                            <div className="score-product-thumb-item">
                                <img src={TEMP_PIC} alt="" />
                            </div>
                            <div className="score-product-thumb-item">
                                <img src={TEMP_PIC} alt="" />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="score-product-item-wrap">
                    <div className="score-product-item-title">
                        洁玉 质素毛巾单条装礼盒
                    </div>
                    <div className="score-product-item-attributes">
                        <div className="row">
                            <div className="p-key">兑换积分</div>
                            <div className="p-value active">4500积分</div>
                        </div>
                        <div className="row">
                            <div className="p-key">已兑换</div>
                            <div className="p-value">18件</div>
                        </div>
                        <div className="row">
                            <div className="p-key">库存</div>
                            <div className="p-value">100件</div>
                        </div>
                    </div>
                    <div className="score-product-item-specs">
                        <div className="title">商品规格</div>
                        <div className="spec-list">
                            <div className="spec-item active">粉色</div>
                            <div className="spec-item">灰色</div>
                            <div className="spec-item">蓝色</div>
                        </div>
                    </div>
                    <div className="btn">
                        <Button
                            type="primary"
                            onClick={() => {
                                history.push('/ScoreProductOrder')
                            }}
                        >
                            立即兑换
                        </Button>
                    </div>
                </div>
            </div>
            <div className="score-product-content">
                <div className="title">
                    商品详情
                </div>
            </div>
        </ScoreLayout>
     );
 };
