import { notification } from "antd";
import { queryAuthCompanyByCustomerId } from "../service";

export const fetchIsOrgAuthed = async () => {
    let res = await queryAuthCompanyByCustomerId();
    const d = res.data?.data;
    if (Array.isArray(d) && d.length > 0) {
        const firstSubmit = d.length === 1;
        const hasSubmited = d.length === 2;
        // 第一次审核且通过
        const firstAuthed = firstSubmit && d[0].approveStatus === 2;
        // 上一次审核通过
        const beforeAuthed = hasSubmited && d[1].approveStatus === 2;
        // 当前申请审核通过，前一次审核无所谓
        const currAuthed = hasSubmited && d[0].approveStatus === 2;
        if (firstAuthed || beforeAuthed || currAuthed) {
            return true;
        }
    }
    return false;
}

// eslint-disable-next-line max-params
export const doAfterOrgAuth = async (successCb, failCb) => {
    const isAuthed = await fetchIsOrgAuthed()
    if (isAuthed) {
        return successCb && successCb()
    } else {
        return failCb && failCb()
    }
};

