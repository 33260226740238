/**
 * @file  参观预约系统介绍页面
 * @create macaiqi 2021/07/29
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import cls from 'classnames'
import './style.scss'

/**
 * @description 参观预约系统组件
 */

export const WapShowBooking = () => {
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    const settings = {
        arrows: false,
        dots: false,
        // infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "3rem",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    const sec3 = [
        {
            name: '灵活的预约规则配置',
            des: '提供可预约人数和退订规则等预约规则的灵活配置，适应各类公共文化场馆的特展预约服务需求。',
            icon: 'iconpeizhiguanli',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '便捷的移动预约支持',
            des: '提供基于移动端（微信小程序）的预约服务，让公众随时随地高效预约。',
            icon: 'iconshouji21',
            duration: '1s',
            delay: '0.7s'
        },
        {
            name: '轻量化的验票方式',
            des: '支持观众在移动端自行核销，用户无需采购任何验票设备，免除繁杂的硬件选型、安装、调试、对接过程。',
            icon: 'iconyanpiao',
            duration: '1s',
            delay: '0.9s'
        },
        {
            name: '多维度观众统计分析',
            des: '建立观众画像，提供特展预约、入厅、营收等多维度统计分析，为优化特展选题、提升特展质量提供决策依据。',
            icon: 'icon503tongji_bingtu',
            duration: '1s',
            delay: '1.1s'
        },
    ]
    // 功能组成
    const gongneng = [
        {
            name: "特展管理",
            tab: ['预约规则配置', '特展发布', '预约观众档案管理', '数据统计', '数据统计'],
            des: '为场馆工作人员与管理者提供特展的管理与发布，实现统一的预约观众档案管理、精准的数据统计分析等，通过建立观众数据模型，不断优化特展选题、提高特展质量和成效，为特展的策划和举办提供精准的分析数据。主要功能包括：预约规则配置、特展发布、预约观众档案管理、数据统计和消息模板管理。',
            icon: 'i1'
        },
        {
            name: "特展预约",
            tab: ['注册', '登录', '特展展示', '预约', '用户中心'],
            des: '为观众提供便捷的特展预约服务，在微信小程序端最大化地为观众观看特展提供方便，让预约省时、省力、更省心。主要功能包括注册、登录、特展展示、预约和用户中心。',
            icon: 'i2'
        },
        {
            name: "特展核销",
            tab: ['票务核销'],
            des: '支持观众用微信自主扫描展厅入口的核销二维码打开订单核销页面，由工作人员确认观众点击【核销】按钮的操作过程，即可进行票务核销。如果预约订单有多个同行人，预约人可核销所有出行人订单，其同行人仅能核销本人订单。',
            icon: 'i3'
        },
        {
            name: "特展管理",
            tab: ['预约规则配置', '特展发布', '预约观众档案管理', '数据统计', '数据统计'],
            des: '为场馆工作人员与管理者提供特展的管理与发布，实现统一的预约观众档案管理、精准的数据统计分析等，通过建立观众数据模型，不断优化特展选题、提高特展质量和成效，为特展的策划和举办提供精准的分析数据。主要功能包括：预约规则配置、特展发布、预约观众档案管理、数据统计和消息模板管理。',
            icon: 'i1'
        },
        {
            name: "特展预约",
            tab: ['注册', '登录', '特展展示', '预约', '用户中心'],
            des: '为观众提供便捷的特展预约服务，在微信小程序端最大化地为观众观看特展提供方便，让预约省时、省力、更省心。主要功能包括注册、登录、特展展示、预约和用户中心。',
            icon: 'i2'
        },
        {
            name: "特展核销",
            tab: ['票务核销'],
            des: '支持观众用微信自主扫描展厅入口的核销二维码打开订单核销页面，由工作人员确认观众点击【核销】按钮的操作过程，即可进行票务核销。如果预约订单有多个同行人，预约人可核销所有出行人订单，其同行人仅能核销本人订单。',
            icon: 'i3'
        },

    ]
    const [down, setDown] = useState()
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        if (anchorName || anchorName === 0) {
            const height = document.getElementById(anchorName).offsetTop - 40; // 计算需要向上移动的距离
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        }
    };
    const banner = useRef()
    const activeref = useRef()
    const { search } = useLocation()
    useEffect(() => {

        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // 滚动超过banner后nav吸顶
            if (scrollT >= banner.current.clientHeight + banner.current.offsetTop) {
                activeref.current?.classList.add('active');
            } else {
                activeref.current?.classList.remove('active');
            }
            // 滚动到对应点，对应nav高亮
            for (let i = 0; i < tab.length; i++) {

                if (scrollT >= document.getElementById(`item${i}`).offsetTop - 40) {
                    document.getElementById(`link${i}`).classList.add('active');
                    for (let j = 0; j < tab.length; j++) {
                        if (i !== j) {
                            document.getElementById(`link${j}`).classList.remove('active');
                        }
                    }
                }

            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(`item${search.split('?')[1]}`)
        }
    }, [tab.length, search]);

    return (<>
        <IntroHeader />
        <div className="mobile-content booking" ref={activeref}>
            <div className="banner" ref={banner}>
                <div className="content">
                    <p className="big">特展预约系统</p>
                    <div className="cont">
                        为管理人员提供灵活的预约规则配置，实现特展发布、预约、核销等全流程的标准化、信息化管理；支持观众通过微信小程序完成在线预约，并在进入展厅时快速通过核验流程；提供丰富的特展数据统计功能，有力支撑特展智慧化管理、运营与分析决策。
                    </div>
                    <div className="btnbox">
                        {/* <div className="btn btn1">
                             观看演示
                         </div> */}
                        {/* <div className=" btn btn2">
                             免费体验
                         </div> */}
                    </div>

                </div>
            </div>
            <div className="tab" >
                <ul>
                    {tab.map((item, index) => {
                        return (
                            <li key={index} id={`link${index}`} onClick={() => {
                                scrollToAnchor(`item${index}`)
                            }}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="people" id='item0'>
                <p className="title" >用户群体</p>
                <p className="txt">本产品适用于对特展预约有需求的公共文化场馆、文旅景区的开放部、信息部、陈列设计部、宣传推广部及相关工作人员。</p>
            </div>
            <div className="section2" id='item1' >
                <p className="title" >功能组成</p>
                <div className="functionof">
                    <Slider {...settings} key={Math.random()} >
                        {gongneng.map((item, index) => {
                            return (
                                <div className="itembox" key={index}>
                                    <div className="item">
                                        <i className={cls('iconfont', item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        {item.tab.map((item1, i1) => {
                                            return (<p className="txt" key={i1}>{item1}</p>)
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div className="section3" id='item2'>
                <p className="title">产品特点</p>
                {
                    sec3.map((item, index) => {
                        return (
                            <div className={cls("item")} key={index} >
                                <div className="box">
                                    <div className="l">
                                        <i className={cls('iconfont', item.icon)} />
                                        {item.name}
                                    </div>
                                    <div className="down" />
                                </div>
                                <div className="text">
                                    {item.des}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="price" id='item3'>
                <p className="title">产品价格</p>
                <div className="num">
                    3800
                    <span>元/年</span>
                </div>
            </div>
            <div className="update" id='item4'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }} />
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
