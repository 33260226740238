
/**
 * @file   产品介绍欢迎页面footer组件
 * @create tianmu 2021/3/10
 * @update tianmu 2021/3/10
 */
import React, { useState } from 'react'
import Feedback from './components/Feedback'
import { Link } from 'react-router-dom';

/**
 * @description 产品介绍欢迎footer组件
 */
export const IntroFooter = () => {
    const [showfrom, setShowfrom] = useState()
    const year = new Date().getFullYear()
    return (
        <>
            <footer className="introduction-footer" id='footer'>
                <div className="footerbox">
                    <div className="footer-l">
                        <div className="site-links">
                            {/* <a href='/#'>服务</a> */}
                            <Link to='/HelpCenter'>帮助中心</Link>
                            {/* <a href="/helpcenter/index.html" target='_blank'>帮助中心</a> */}
                            {/* <a href='/#'>关于我们</a> */}
                            {/* <a href='/#'>联系我们 </a> */}
                            {/* <div className='a' onClick={() => { setShowfrom(true) }}>意见反馈 </div> */}
                            {/* <a href='/legalnotices.html' target='_blank'>法律声明 </a> */}
                            <Link to='/Legalnotices'>法律声明 </Link>
                            <Link to='/PrivacyPolicy'>隐私政策</Link>
                        </div>
                        <div className="phone">
                            <span>咨询电话</span>
                            <i />
                            <div className="number">181-6272-2753</div>
                        </div>
                        <div className="bq">
                            版权所有 © 武汉瑞鹿文化科技有限公司 {year}  保留一切权利  <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">鄂ICP备2021006883号-1</a>  鄂B2-20130048号
                            <i />
                            鄂公网安备 32011402010636号

                            <span id='cnzz_stat_icon_1280104732' className='tj' >
                                <img border="0" hspace="0" vspace="0" src="https://icon.cnzz.com/img/pic.gif" alt='' />
                            </span>
                            <script src='https://v1.cnzz.com/z_stat.php?id=1280104732&amp;show=pic' type='text/javascript' />


                            {/* <span id="cnzz_stat_icon_1280104732" className='tj'><a href="https://www.cnzz.com/stat/website.php?web_id=1280104732" target="_blank" rel="noreferrer" title="站长统计"><img border="0" hspace="0" vspace="0" src="https://icon.cnzz.com/img/pic.gif" alt='' /></a></span>
                            <script src="https://v1.cnzz.com/z_stat.php?id=1280104732&amp;show=pic" type="text/javascript" />
                            <script src="https://c.cnzz.com/core.php?web_id=1280104732&amp;show=pic&amp;t=z" charSet="utf-8" type="text/javascript" /> */}
                        </div>
                    </div>
                    <div className="footer-r">
                        {/* <div className="ewm wx">
                            <i />
                        </div> */}
                        <div className="ewm wb">
                            <i />
                        </div>
                    </div>
                </div>
            </footer>
            <Feedback showfrom={showfrom} setShowfrom={setShowfrom} />
        </>
    )
}
