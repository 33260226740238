/**
 * @file   会员积分模块
 * @create tianmu 2020/11/20
 * @update tianmu 2020/11/20
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Tabs, Table } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import QRIMG from '../../assets/qr-code.png';
import { Link } from 'react-router-dom';
import { queryChangePoints, queryPointRecord, queryPoints } from '../../service';
import './index.scss';

// 会员积分组件
export const MyScore = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    });

    const [points, setPoints] = useState(0);
    const [inPoints, setInPoints] = useState(0);
    const [outPoints, setOutPoints] = useState(0);

    // 获取积分数据
    const fetchScore = useCallback(() => {
        console.log("fetch score")
        queryPoints().then((res) => {
            if (res.data.code === 200) {
                const d = res.data.data;
                const p = d?.points ?? 0;
                const f = d?.frozenPoints ?? 0;
                setPoints(p - f);
            } else {
                setPoints(0);
            }
        });
        queryChangePoints().then((res) => {
            if (res.data.code === 200) {
                const d = res.data.data;
                const p = d?.payPoints ?? 0;
                const i = d?.incomePoints ?? 0;
                setInPoints(i);
                setOutPoints(p);
            } else {
                setInPoints(0);
                setOutPoints(0);
            }
        });
    }, []);

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        setLoading(true);
        return queryPointRecord(params).then((res) => {
            const listData = res.data.data.records.map((item) => {
                return {
                    key: 1,
                    usage: item.name,
                    income: item.points <= 0 ? item.points : '+' + item.points,
                    time: item.recordTime,
                    extra: ''
                };
            });
            setPagination({
                current: params.pageNum,
                pageSize: params.pageSize,
                total: res.data.data.total
            });
            setDataSource(listData);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        console.log("useEffect")
        fetchScore();
        fetchData({
            // recordType: 1,
            // settmentTimeQuery: '2021-01-01#2021-12-31',
            pageNum: 1,
            pageSize: 10
        });
    }, [fetchScore, fetchData]);

    const columns = [
        {
            title: '来源/用途',
            dataIndex: 'usage'
        },
        {
            title: '收入/支出',
            dataIndex: 'income'
        },
        {
            title: '时间',
            dataIndex: 'time'
        },
        {
            title: '备注',
            dataIndex: 'extra'
        }
    ];

    // 表格分页或筛选参数变化
    const handleTableChange = (pagination, filter = {}) => {
        return fetchData({
            pageNum: pagination.current,
            pageSize: pagination.pageSize,
            ...filter
        });
    };

    return (
        <Layout className="vipscore page-content" style={{ background: '#f0f2f5', padding: 0 }}>
            <div className="vipscore-board">
                <div className="score-touse">
                    <div className="score-inner">
                        <p>可用积分</p>
                        <p>{points}</p>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: '0 0 49.8%',
                        alignItems: 'center'
                    }}
                >
                    <div className="score-inner" style={{ borderLeft: '1px solid #ddd' }}>
                        <p>收入积分</p>
                        <p>{inPoints}</p>
                    </div>
                    <div className="score-inner">
                        <p>支出积分</p>
                        <p>0</p>
                    </div>
                    <div className="score-inner" style={{ borderRight: '1px solid #ddd' }}>
                        <p>消费积分</p>
                        <p>{outPoints}</p>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: '0 0 33.2%',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src={QRIMG}
                        alt=""
                        style={{
                            width: 100,
                            height: 100,
                            flex: '0 0 100px',
                            marginLeft: '80px'
                        }}
                    />
                    <br />
                    <div
                        style={{
                            flex: 1,
                            marginLeft: 10
                        }}
                    >
                        <p
                            style={{
                                margin: 0,
                                fontSize: 18,
                                fontWeight: 'bold'
                            }}
                        >
                            扫码直达
                        </p>
                        <p
                            style={{
                                fontSize: 12,
                                color: '#aaa',
                                margin: 0
                            }}
                        >
                            咸宁文旅云小程序
                        </p>
                        <p
                            style={{
                                fontSize: 12,
                                color: '#aaa',
                                margin: 0
                            }}
                        >
                            “个人中心/我的积分”
                        </p>
                    </div>
                    <Link
                        to="/scoreRule"
                        style={{
                            fontSize: 14,
                            height: '100%',
                            paddingTop: 10
                        }}
                    >
                        <span
                            style={{
                                display: 'inline-block',
                                color: '#3dbc8e',
                                marginRight: 5
                            }}
                        >
                            <QuestionCircleOutlined />
                        </span>
                        积分规则
                    </Link>
                </div>
            </div>
            <Tabs defaultActiveKey="1" onChange={() => {}}>
                <Tabs.TabPane tab="积分明细" key="1">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        bordered
                        pagination={{
                            ...pagination,
                            // showSizeChanger: true,
                            showTotal: (total) => {
                                return `共 ${total} 条数据`;
                            }
                        }}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="兑换记录" key="2">
                    <div />
                </Tabs.TabPane>
            </Tabs>
        </Layout>
    );
};
