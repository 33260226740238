/**
 * @file   公告列表模块
 * @create tianmu 2021/7/8
 * @update tianmu 2021/7/8
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Table, Tag, Form, Input, Button } from 'antd';
import { queryBulletinPage } from '../../service';
import { AppFooter } from '../../containers/AppFooter';
import { AppHeader } from '../../containers/AppHeader';
import { useHistory, Link } from 'react-router-dom';
import './index.scss';

const { Content } = Layout;

// 默认页面大小
const PAGE_SIZE = 10;

// 公告列表组件
export const AnounceList = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: PAGE_SIZE
    });

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        return queryBulletinPage(params).then(res => {
            if (res.data.code === 200) {
                const arr = res.data.data?.records || []
                // 需要置顶的公告
                const topStack = []
                // 剩余的公告
                const rest = []
                let finalData = arr;
                if (arr.length > 1) {
                    arr.forEach(a => {
                        if (a.flag) {
                            topStack.push(a)
                        } else {
                            rest.push(a)
                        }
                    })
                    finalData = [].concat(topStack, rest)
                }
                const data = finalData.map(item => {
                    return {
                        key: item.bulletinId,
                        title: item,
                        creator: item.creator,
                        time: item.time,
                    }
                })
                setPagination({
                    current: params.pageNum,
                    pageSize: params.pageSize,
                    total: res.data.data.total,
                });
                setDataSource(data);
                setLoading(false);
            }
        })
    }, []);

    useEffect(() => {
        fetchData({
            entity: {},
            param: {
                pageNum: 1,
                pageSize: PAGE_SIZE
            }
        });
    }, [fetchData]);

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            render: (item) => <Link to={`/anounceDetail?id=${item.bulletinId}`}>{item.title}{item.flag === 1 && <Tag color="#ffa500" className="common-set-top">顶</Tag>}</Link>
        },
        {
            title: '发布人',
            dataIndex: 'creator',
        },
        {
            title: '公告时间',
            dataIndex: 'time',
        },
    ];


    const handleTableChange = (pagination) => {
        // const values = form.getFieldsValue();
        fetchData({
            // entity: {
            //     ...values
            // },
            param: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize
            }
        });
    };

    return (
        <Layout
            className="app"
            style={{
                background: 'rgb(241, 242, 246)'
            }}
        >
            <AppHeader />
            <div
                className="page-header"
                style={{
                    marginTop: 100,
                    padding: '0 10%',
                }}
            >
                <div className="title" style={{position: 'relative'}}>
                    公告
                    <span
                        style={{
                            position: 'absolute',
                            right: 0,
                            color: 'rgb(86, 192, 150)',
                            cursor: 'pointer',
                            fontSize: '16px'
                        }}
                        onClick={() => {history.goBack()}}
                    >
                        &lt;返回
                    </span>
                </div>
            </div>
            <Content
                style={{
                    position: 'relative',
                    width: '80%',
                    margin: '0 auto',
                    padding: '20px',
                    height: '100%',
                    background: '#fff',
                }}
            >

            <div className="anouncelist">
                {/* <div
                    className="table-meta anouncelist-table-meta"
                >
                    <div className="anouncelist-table-filter" style={{float: 'right', marginBottom: 10}}>
                        <Form
                            layout="inline"
                            form={form}
                            onFinish={() => handleTableChange({ current: 1, pageSize: PAGE_SIZE })}
                        >
                            <Form.Item name="title">
                                <Input placeholder="请输入标题" />
                            </Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                查询
                            </Button>
                            <Button
                                onClick={() => {
                                    form.resetFields();
                                    fetchData({
                                        entity: {},
                                        param: {
                                            pageNum: 1,
                                            pageSize: PAGE_SIZE
                                        }
                                    });
                                }}
                            >
                                重置
                            </Button>
                        </Form>
                    </div>
                </div> */}
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    //  bordered
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => {
                            return `共 ${total} 条数据`;
                        }
                    }}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </div>
            </Content>
        <AppFooter />
    </Layout>

    );
};
