/**
 * @file   通用的提醒页面
 * @create tianmu 2021/4/29
 * @update tianmu 2020/4/29
 */
import React from 'react';
import { Button, Layout } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { AppFooter } from '../../containers/AppFooter';
import { AppHeader } from '../../containers/AppHeader';
import { InfoCircleOutlined } from '@ant-design/icons';
import { searchStrToObj } from '../../utils';

const { Content } = Layout;

/**
 * @description 通用的提醒页面
 */
export const CommonNotice = () => {
    const history = useHistory();
    const location = useLocation();
    const o = searchStrToObj(location.search);

    return (
        <Layout
            className="app"
            style={{
                background: 'rgb(241, 242, 246)'
            }}
        >
            <AppHeader />
            <Content
                style={{
                    position: 'relative',
                    // width: '1190px',
                    width: '100%',
                    margin: '200px auto',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        width: '800px',
                        padding: '80px 50px',
                        margin: 'auto',
                        background: '#fff'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        <div>
                            <InfoCircleOutlined
                                style={{
                                    color: 'rgb(61, 188, 142)',
                                    fontSize: '40px'
                                }}
                            />
                        </div>
                        <p
                            style={{
                                textAlign: 'center',
                                width: '80%',
                                margin: '20px auto',
                                fontSize: 16
                            }}
                        >
                            {decodeURIComponent(o?.content || "")}
                        </p>
                        <Button
                            type="primary"
                            style={{ marginRight: 10 }}
                            onClick={() => history.replace('/')}
                        >
                            回到首页
                        </Button>
                        <Button type="primary" onClick={() => history.replace('/index')}>
                            回到控制台
                        </Button>
                    </div>
                </div>
            </Content>
            <AppFooter />
        </Layout>
    );
};
