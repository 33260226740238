/**
 * @file   产品介绍欢迎页面header组件
 * @create tianmu 2021/3/10
 * @update tianmu 2021/3/10
 */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import LOGO from '../../assets/logo.png';
import LOGOA from '../../assets/logo-a.png';
import cls from 'classnames'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Avatar, Button, Divider, Dropdown, notification } from 'antd';
import { getUserDetail, logout } from '../../pages/login/store';
import { customerLogin, guestLogin, queryNotice, querySystems, updateDefaultSystem, updateNoticeStatus } from '../../service';
import { localDS } from '../../storage';
import { createFromIconfontCN, UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
// const guestAddr = process.env.ENV_GUEST_LOGIN;

const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;
const guestAddr = window.GUEST_LOGIN;

const menudata = [
    {
        data: [
            {
                name: "藏品管理系统",
                route: "CollectionSystem",
                path: "/CollectionSystem",
                des: "藏品全生命周期规范管理"
            }, {
                name: "数字资产管理系统",
                route: "VisualAssetManagement",
                path: "/VisualAssetManagement",
                des: "支持多类型数字资产共建共享"
            },
            {
                name: "志愿者管理系统",
                route: "VolunteersSystem",
                path: "/VolunteersSystem",
                des: "动态联接文旅机构与志愿者"
            },
            {
                name: "讲解员管理系统",
                route: "ExplainSystem",
                path: "/ExplainSystem",
                des: "讲解排班、预约与分派一体化管理"
            },
            {
                name: "客流管理系统",
                route: "TrafficManagement",
                path: "/TrafficManagement",
                des: "客流量精准统计与预测"
            }]
    },
    {
        data: [{
            name: "参观预约系统",
            route: "VisitAppointment",
            path: "/VisitAppointment",
            des: "功能强大的分时预约规则设置"
        },
        {
            name: "活动预约系统",
            route: "ActivityAppointment",
            path: "/ActivityAppointment",
            des: "便捷的课程、活动发布和预约管理"
        },
        {
            name: "特展预约系统",
            route: "ShowBooking",
            path: "/ShowBooking",
            des: "支持特展预约和支付"
        },]
    },
]
const DropdownMenu = ({ pathname, setMenuopen, menuopen }) => {
    const [menuchoose, setmenuchoose] = useState(Number(sessionStorage.getItem('menu')) || 0)
    return (
        <div className={cls("product-dropdown")}>
            {menuopen && <div className="close" onClick={() => { setMenuopen(!menuopen) }}>
                <i className='iconfont iconguanbi' />
            </div>}
            <div className="choose-l">
                <div className={menuchoose === 0 ? 'active' : null} onClick={() => { setmenuchoose(0); sessionStorage.setItem('menu', 0) }}>
                    智慧管理
                </div>
                <div className={menuchoose === 1 ? 'active' : null} onClick={() => { setmenuchoose(1); sessionStorage.setItem('menu', 1) }}>
                    智慧服务
                </div>
            </div>
            <div className=" linkbox">
                {menudata?.[menuchoose]?.data?.map((item, index) => {
                    return (
                        <Link
                            to={item.path}
                            key={index}
                            className={pathname.includes(item.route) ? 'tip active' : 'tip'}
                        >
                            <p className="name">{item.name}</p>
                            <p className="des">
                                {item.des}
                            </p>
                        </Link>
                    )
                })}

            </div>

        </div >
    );
};

const IconFont = createFromIconfontCN({
    scriptUrl: window.ICONFONT_URL
});

/**
 * @description 产品介绍欢迎Header组件
 */
export const IntroHeader = ({ small }) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [systems, setSystems] = useState([]);
    const cachedUser = useMemo(() => getUserDetail(), [])

    const fetchInfo = useCallback(async () => {
        const sysRes = await querySystems();
        if (sysRes.data.code === 200) {
            setSystems(sysRes.data.data || []);
        }
    }, []);

    useEffect(() => {
        if (cachedUser) {
            // 登录状态
            fetchInfo();
        }
    }, [cachedUser, fetchInfo]);
    const header = useRef()
    const [menuopen, setMenuopen] = useState()
    useEffect(() => {
        if (menuopen) {
            document.getElementById('app').style.height = '100vh'
            document.getElementById('app').style.overflow = 'hidden'
        } else {
            document.getElementById('app').style.height = 'unset'
            document.getElementById('app').style.overflow = 'unset'
        }
    }, [menuopen])
    const ConsoleMenuDropdown = (
        <div className={cls("user-dropdown homeuser", { 'small-user-dropdown': small })}>
            <div className="user-dropdown-info">
                {/* <Avatar icon={<UserOutlined />} alt="avatar" /> */}
                <i className='iconfont iconren' />
                <span className="name">{cachedUser?.customerName}</span>
            </div>
            <div className="user-dropdown-flex">
                <Link to="/UserCenter?key=1" className="user-dropdown-flexitem">
                    基本信息
                </Link>
                <Link to="/Organization" className="user-dropdown-flexitem">
                    机构认证
                </Link>
                <Link to="/UserCenter?key=3" className="user-dropdown-flexitem">
                    安全设置
                </Link>
            </div>
            <div className="user-dropdown-list">
                <Link to="/MyOrder" className="user-dropdown-listitem">
                    {/* <img src={OrderIcon} alt="" /> */}
                    <IconFont type="iconwodedingdan" />
                    <span>我的订单</span>
                </Link>
                <Link to="/UserCenter?key=4" className="user-dropdown-listitem">
                    <IconFont type="iconbangdingshezhi" />
                    <span>绑定设置</span>
                </Link>
                <Link to="/UserCenter?key=5" className="user-dropdown-listitem">
                    <IconFont type="icondenglurizhi" />
                    <span>登录日志</span>
                </Link>
                <Link to="/UserCenter?key=2" className="user-dropdown-listitem">
                    <IconFont type="iconxiugaimima" />
                    <span>修改密码</span>
                </Link>
            </div>
            <Divider />
            <div className="user-dropdown-extra">
                {systems && systems.length > 0 && (
                    <>
                        <div>
                            所属机构
                            <span
                                style={{
                                    fontSize: '12px',
                                    color: 'rgb(206, 203, 202)'
                                }}
                            >
                                （您可以点击机构直接跳转管理后台，也可以加入新机构）
                            </span>
                        </div>
                        {systems.map((i) => (
                            <div
                                key={i.systemId}
                                className="user-dropdown-extraline"
                                onClick={() => {
                                    const token = localDS.get('saasToken')
                                    if (!token) {
                                        notification.warn({
                                            className: 'custom-antd-notification',
                                            description: '已退出登录或登录超时，请重新登录',
                                            duration: 5
                                        });
                                        window.location.href = "/accountLogin"
                                        return
                                    }
                                    customerLogin(i.systemId).then((res) => {
                                        // 请求错误直接返回
                                        if (res.data.code !== 200) {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                // description:
                                                // '请先购买或试用产品再进入管理后台',
                                                description: res.data.msg,
                                                duration: 5
                                            });
                                            return;
                                        }
                                        const d = res.data.data;
                                        if (d?.authorizationu) {
                                            let loc = '';
                                            if (i.systemId === '0') {
                                                loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${i.systemId}&customerId=${cachedUser.customerId}`;
                                            } else {
                                                loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${i.systemId}&customerId=${cachedUser.customerId}`;
                                            }
                                            localDS.set('gatewayToken', d?.authorizationu);
                                            localDS.set('systemId', i.systemId)
                                            window.open(loc);
                                        }
                                    });
                                }}
                            >
                                <span className="left">{i.systemName}</span>
                                {i.isHome ? (
                                    <span
                                        className="right isdefault-btn"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    >
                                        默认
                                    </span>
                                ) : (
                                    <span
                                        className="right notdefault-btn"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            updateDefaultSystem(i.systemId).then((res) => {
                                                if (res.data.code === 200) {
                                                    notification.success({
                                                        className: 'custom-antd-notification',
                                                        message: '设置默认机构成功',
                                                        duration: 5
                                                    });
                                                    fetchInfo();
                                                } else {
                                                    notification.warn({
                                                        className: 'custom-antd-notification',
                                                        message: '设置默认机构失败',
                                                        description: res.data.msg,
                                                        duration: 5
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        设置默认
                                    </span>
                                )}
                                <span className="right tag">机构</span>
                            </div>
                        ))}
                    </>
                )}
                <div
                    className="user-dropdown-extrabtn"
                    onClick={() => {
                        history.push('/Join');
                    }}
                >
                    + 加入新机构
                </div>
            </div>
            <Button
                block
                className="user-dropdown-exit"
                onClick={() => {
                    dispatch(logout());
                    history.push('/accountLogin');
                }}
            >
                退出登录
            </Button>
        </div>
    );
    return (
        <header className={cls("introduction-header", { 'small': small })}>
            <div className="header-inner">
                <div className="head-l">
                    <Link to="/" className="logo">
                        <img src={small ? LOGO : LOGOA} alt="" />
                    </Link>
                    <div className={cls("menu", { active: menuopen })}  >
                        <i className={cls('iconfont', { 'icona-caidan1': !menuopen, 'iconguanbi': menuopen })} onClick={() => {
                            setMenuopen(!menuopen)
                        }} />
                        <DropdownMenu pathname={pathname} setMenuopen={setMenuopen} menuopen={menuopen} />
                    </div>
                    {/* <Dropdown
                        overlay={DropdownMenu(pathname)}
                    // visible
                    // onVisibleChange={(visible) => {if(visible){

                    // } }}
                    >
                        <div className="product-dropdown-link">
                            <div className="text">
                                <span>产品</span>
                                <i className="down" />
                            </div>
                        </div>
                    </Dropdown> */}
                </div>
                <div className="head-r">
                    {cachedUser ? (
                        <div className="entry">
                            {/* <a
                                href="/#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    guestLogin().then((res) => {
                                        // 请求错误直接返回
                                        if (res.data.code !== 200) {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                description: res.data.msg,
                                                duration: 5
                                            });
                                            return;
                                        }
                                        const d = res.data.data;
                                        if (d?.authorizationu && d?.systemId) {
                                            let loc = `${guestAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`
                                            window.open(loc);
                                        }
                                    });
                                }}

                            >
                                进入体验环境
                            </a> */}
                            {/* <Link to="/index" className='tokzt'>进入工作门户</Link> */}
                            {systems && systems.length > 0 && (
                            <a
                                href="/#"
                                className="tokzt"
                                onClick={(e) => {
                                    e.preventDefault()
                                    const token = localDS.get('saasToken')
                                    if (!token) {
                                        notification.warn({
                                            className: 'custom-antd-notification',
                                            description: '已退出登录或登录超时，请重新登录',
                                            duration: 5
                                        });
                                        window.location.href = "/accountLogin"
                                        return
                                    }
                                    customerLogin().then((res) => {
                                        // 请求错误直接返回
                                        if (res.data.code !== 200) {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                // description:
                                                // '请先购买或试用产品再进入管理后台',
                                                description: res.data.msg,
                                                duration: 5
                                            });
                                            return;
                                        }
                                        const d = res.data.data;
                                        if (d?.authorizationu && d?.systemId) {
                                            let loc = '';
                                            if (d?.systemId === '0') {
                                                loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`;
                                            } else {
                                                loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`;
                                            }
                                            localDS.set('gatewayToken', d?.authorizationu);
                                            localDS.set('systemId', d?.systemId)
                                            window.open(loc);
                                        }
                                    });
                                }}
                            >
                                进入工作门户
                            </a>
                        )}
                            <div style={{ display: 'inline-flex', marginLeft: 20 }}>
                                <Dropdown overlay={ConsoleMenuDropdown} >
                                    <div className="dropdown-link" style={{ cursor: 'pointer' }}>
                                        {/* <Avatar icon={<UserOutlined />} alt="avatar" /> */}
                                        <i className='iconfont icona-gerenzhongxin1' />
                                        <span
                                            className="name"
                                            style={{ marginLeft: 10, verticalAlign: 'top' }}
                                            onClick={() => {
                                                history.push('/Index')
                                            }}
                                        >
                                            {cachedUser?.customerName}
                                        </span>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    ) : (
                        <div className="entry">
                            {/* <a
                                href="/#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    guestLogin().then((res) => {
                                        // 请求错误直接返回
                                        if (res.data.code !== 200) {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                description: res.data.msg,
                                                duration: 5
                                            });
                                            return;
                                        }
                                        const d = res.data.data;
                                        if (d?.authorizationu && d?.systemId) {
                                            let loc = `${guestAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`
                                            window.open(loc);
                                        }
                                    });
                                }}

                            >
                                免费体验
                            </a> */}
                            <div className="border">
                                <Link to="/accountLogin">登录</Link>
                                <Link to="/register">注册</Link>
                            </div>

                        </div>
                    )}

                    {/* <Dropdown
                        overlay={DropdownMenu(pathname)}
                    // visible
                    // onVisibleChange={(visible) => {if(visible){

                    // } }}
                    >
                        <i className='iconfont icona-caidan1' onMouseMove={() => {
                            console.log(header.current.classList.add('active'))
                        }} onMouseLeave={() => {
                            console.log(header.current.classList.remove('active'))
                        }} />
                    </Dropdown> */}



                </div>
            </div>
            {menuopen && <div className="shade" onClick={() => { setMenuopen(false) }} />}
        </header >
    );
};
