/**
 * @file   挂载应用到页面
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App.jsx';
import { ConfigProvider } from 'antd';
import { store } from './store';
import { Provider } from 'react-redux';
import zhCN from 'antd/es/locale/zh_CN';
import 'animate.css';
import './theme.less';
import './index.scss';
import { localDS } from './storage/index.js';

const persistor = persistStore(store)

// let _beforeUnload_time = 0
// let _gap_time = 0;
// window.onbeforeunload = function () {
//     _beforeUnload_time = new Date().getTime();
// }
// window.onunload = function (){
//     _gap_time = new Date().getTime() - _beforeUnload_time;
//     if(_gap_time <= 5) {
//         let keepLogin = localDS.get('saasKeepLogin')
//         if (!keepLogin) {
//             localDS.remove('saasToken')
//             localDS.remove('gatewayToken')
//             localDS.remove('saasUser')
//         }
//     }
// }

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </ConfigProvider>
    </Provider>,
    document.getElementById('app')
);
