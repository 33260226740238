/**
 * @file    产品订购表单模块
 * @create tianmu 2021/3/23
 * @update tianmu 2021/3/23
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Radio, Row, Col, notification, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { preSubmit, updateOrderState } from '../../store';
import { OrderSumInfo } from '../OrderSumInfo';
import './index.scss';
import { queryDiscountInfo, queryServiceById } from '../../../../service';
import UnderCarriageIcon from '../../../../assets/notice/5.png'

// 单位枚举转为字符串描述
const unitStr = (unit) => {
    switch (unit) {
        case 1:
            return '年';
        case 2:
            return '月';
        case 3:
            return '人';
        case 4:
            return 'GB';
        case 5:
            return '次';
        default:
            return '';
    }
};

// 单个服务订购表单组件
export const ServiceOrderForm = (props) => {
    const { finish, id } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { orderState } = useSelector((state) => state.productReducer);
    const { serviceList } = orderState;
    const [discInfos, setDiscInfos] = useState([]);
    const [underCarriage, setUndercarriage] = useState(false);

    // 表单提交成功
    const onSubmitClick = async () => {
        const hasService = Array.isArray(serviceList)
                            && serviceList.filter(i => i.value !== 0)
                            .length > 0;
        if (!(hasService)) {
            notification.warn({
                className: 'custom-antd-notification',
                message: '下单失败',
                description:
                '请选择要购买的服务',
                duration: 5,
            });
            return;
        }

        setLoading(true);
        const success = await dispatch(preSubmit());
        setLoading(false);
        if (success) {
            finish();
        }
    };
    const fetchServiceList = useCallback(async () => {
        const res = await queryServiceById(id);
        if (res.data.code === 200) {
            const p = res.data.data;
            if (!p || !p.price) return [];
            return[{
                ...p,
                // 购买数量
                value: p.price?.quota,
                // 总价
                sum: p.price?.price,
            }]
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
            return [];
        }
    }, [id]);

    useEffect(() => {
        // console.log(product)
        if (!id) {
            history.push('/MyProduct');
        }
        const fetchData = async () => {
                const sLst = await fetchServiceList();
                const discInfoPromises = sLst.map((s) => queryDiscountInfo({ type: 4, id: s.serviceId }))
                // type=1套件2 产品 3资源 4服务
                Promise.all(
                    discInfoPromises
                ).then((rs) => {
                    setDiscInfos(rs.map(i =>
                        (Array.isArray(i?.data?.data) && i?.data?.data.length>0) ?
                        i.data.data[0] :
                        null
                    ).filter(i => !!i))
                });
                // 如果服务下架，则禁用页面交互，显示下架
                if (sLst.length > 0 && sLst[0].status !== 1) {
                    setUndercarriage(true)
                }

                // 初始化订单状态
                dispatch(updateOrderState({
                    kit: null,
                    productList: [],
                    serviceList: sLst,
                    resourceList: [],
                }))
        }
        fetchData()
    }, [dispatch, fetchServiceList, history, id]);

    return (
        <>
            <div className="product-order-form">
                {underCarriage && (
                    <div className="notice-img-icon">
                        <img src={UnderCarriageIcon} alt="" />
                    </div>
                )}
                {serviceList.length > 0 &&
                    serviceList.map((service, idx) => (
                        <div key={service.serviceId} className="order-product-item">
                            <Row gutter={[0, 20]}>
                                <Col className="order-product-title" span={2}>
                                    服务名称：
                                </Col>
                                <Col className="order-product-title" span={22}>
                                    {service?.serviceName}
                                    <Tooltip
                                        placement="topLeft"
                                        color="#fff"
                                        title={
                                            <div style={{color: '#000'}}>
                                                服务订购成功后，如果已使用则不支持退订！
                                            </div>
                                        }
                                    >
                                        <span
                                            style={{
                                                fontSize: 16,
                                                color: '#559f80',
                                                marginLeft: '5px'
                                            }}
                                        >
                                            <ExclamationCircleOutlined />
                                        </span>
                                    </Tooltip>

                                </Col>
                            </Row>
                            <Row gutter={[0, 20]}>
                                <Col className="order-product-desc" span={2}>
                                    服务介绍：
                                </Col>
                                <Col className="order-product-desc" span={22}>
                                    {service?.description}
                                </Col>
                            </Row>
                            <Row className="order-product-desc" gutter={[0, 20]}>
                                <Col span={2}>购买数量：</Col>
                                <Col span={22}>
                                    <Radio.Group
                                        disabled={underCarriage}
                                        options={[
                                            {
                                                label: `${service.price?.quota} ${unitStr(
                                                    service.price?.unit
                                                )}`,
                                                value: service.price?.quota
                                            },
                                        ]}
                                        value={service.value}
                                        optionType="button"
                                    />
                                </Col>
                            </Row>
                        </div>
                    ))}
            </div>
            <div
                className="order-form-footer"
            >
                <Button
                    className="order-form-footer-btns"
                    onClick={() => history.goBack()}
                >
                    返回
                </Button>
                <Button
                    loading={loading}
                    className="order-form-footer-btns"
                    type="primary"
                    disabled={underCarriage}
                    onClick={onSubmitClick}
                >
                    立即下单
                </Button>
                <OrderSumInfo discInfos={discInfos} />
            </div>
        </>
    );
};
