/**
 * @file    产品订购表单模块
 * @create tianmu 2021/3/23
 * @update tianmu 2021/3/23
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row, Col, notification, Alert } from 'antd';
import {
    queryDiscountInfo,
    queryMallResourceList,
    queryMallServiceList,
    queryProductById
} from '../../../../service';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderState, preSubmit } from '../../store';
import { OrderSumInfo } from '../OrderSumInfo';
import { testPositiveIntOrZero, toFixedNumStr } from '../../../../utils';
import { PriceCounter } from './PriceCounter';
import './index.scss';
import UnderCarriageIcon from '../../../../assets/notice/5.png'

// 产品订购表单组件
export const ProductOrderForm = (props) => {
    const { finish, type, id } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { orderState } = useSelector((state) => state.productReducer);
    const { productList, serviceList, resourceList, cost } = orderState;
    const [discInfos, setDiscInfos] = useState([]);
    const [underCarriage, setUndercarriage] = useState(false);

    // 总价格计算结果
    // const allCost = cost ? cost.cost : 0;

    // 表单提交成功
    const onSubmitClick = async () => {
        const hasProduct =
            Array.isArray(productList) && productList.filter((i) => i.value !== 0).length > 0;

        const hasService =
            Array.isArray(serviceList) && serviceList.filter((i) => i.value !== 0).length > 0;
        const hasResource =
            Array.isArray(resourceList) && resourceList.filter((i) => i.value !== 0).length > 0;
        if (!(hasProduct || hasService || hasResource)) {
            notification.warn({
                className: 'custom-antd-notification',
                message: '下单失败',
                description: '请选择要购买的产品',
                duration: 5
            });
            return;
        }
        setLoading(true);
        const success = await dispatch(preSubmit());
        setLoading(false);
        if (success) {
            finish();
        }
    };

    const fetchProductList = useCallback(async () => {
        const res = await queryProductById(id);
        if (res.data.code === 200) {
            const p = res.data.data;
            if (!p || !p.price) return [];
            return [
                {
                    ...p,
                    // 购买数量
                    value: p.price?.quota,
                    // 总价
                    sum: p.price?.price
                }
            ];
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return [];
        }
    }, [id]);
    const fetchServiceList = useCallback(async () => {
        const serviceRes = await queryMallServiceList({
            searchKey: 'productId',
            searchValue: id,
            searchMap: {
                // 只显示已上架的资源（未报价的不会上架）
                // equalsMap里的是json string格式
                // equalsMap: '{"status": 1}'
                // 2021/8/11 改成了键值对map形式
                equalsMap: {
                    status: 1
                }

            },
            pageNum: 1,
            pageSize: 100
        });
        if (serviceRes.data.code === 200) {
            const lst = serviceRes.data.data.list || [];
            // 不需要购买产品，只是购买关联服务
            return lst.map((i) => ({
                ...i,
                value: 0,
                sum: i.price?.price
            }));
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: serviceRes.data.msg,
                duration: 5
            });

            return [];
        }
    }, [id]);

    const fetchResourceList = useCallback(async () => {
        const resourceRes = await queryMallResourceList({
            searchKey: 'productId',
            searchValue: id,
            searchMap: {
                // 只显示已上架的资源（未报价的不会上架）
                // equalsMap里的是json string格式
                // equalsMap: '{"status": 1}'
                // 2021/8/11 改成了键值对map形式
                equalsMap: {
                    status: 1
                }

            },
            pageNum: 1,
            pageSize: 100
        });
        if (resourceRes.data.code === 200) {
            const lst = resourceRes.data.data.list || [];
            // 不需要购买产品，只是购买关联资源
            return lst.map((i) => ({
                ...i,
                value: 0,
                sum: i.price?.price
            }));
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: resourceRes.data.msg,
                duration: 5
            });

            return [];
        }
    }, [id]);

    const onProductValueChange = useCallback(
        (v, idx) => {
            if (!testPositiveIntOrZero(v)) return;
            const newLst = productList.map((i, num) => {
                const price = i?.price?.price;
                const quota = i?.price?.quota;
                const value = v;
                if (price === null) return null;
                const sum = price * (value / quota);

                return num === idx
                    ? {
                          ...i,
                          value: v,
                          sum
                      }
                    : i;
            });
            dispatch(
                updateOrderState({
                    productList: newLst
                })
            );
        },
        [dispatch, productList]
    );

    const onServiceValueChange = useCallback(
        (v, idx) => {
            if (!testPositiveIntOrZero(v)) return;
            const newLst = serviceList.map((i, num) => {
                const price = i?.price?.price;
                const quota = i?.price?.quota;
                const value = v;
                if (price === null) return null;
                const sum = price * (value / quota);

                return num === idx
                    ? {
                          ...i,
                          value: v,
                          sum
                      }
                    : i;
            });
            dispatch(
                updateOrderState({
                    serviceList: newLst
                })
            );
        },
        [dispatch, serviceList]
    );

    const onResourceValueChange = useCallback(
        (v, idx) => {
            if (!testPositiveIntOrZero(v)) return;
            const newLst = resourceList.map((i, num) => {
                const price = i?.price?.price;
                const quota = i?.price?.quota;
                const value = v;
                if (price === null) return null;
                const sum = price * (value / quota);

                return num === idx
                    ? {
                          ...i,
                          value: v,
                          sum
                      }
                    : i;
            });
            // console.log("new resourceList: ", newLst)
            dispatch(
                updateOrderState({
                    resourceList: newLst
                })
            );
        },
        [dispatch, resourceList]
    );

    useEffect(() => {
        // console.log("use effect")
        const fetchData = async () => {
            if (type === 'product') {
                const pLst = await fetchProductList();
                const rLst = await fetchResourceList();
                const sLst = await fetchServiceList();
                const discInfoPromises = []
                                .concat(pLst.map((p) => queryDiscountInfo({ type: 2, id: p.productId })))
                                .concat(rLst.map((p) => queryDiscountInfo({ type: 3, id: p.resourceId })))
                                .concat(sLst.map((s) => queryDiscountInfo({ type: 4, id: s.serviceId })))
                // type=1套件 2 产品 3资源 4服务
                Promise.all(
                    discInfoPromises
                ).then((rs) => {
                    setDiscInfos(rs.map(i =>
                        (Array.isArray(i?.data?.data) && i?.data?.data.length>0) ?
                        i.data.data[0] :
                        null
                    ).filter(i => !!i))
                });
                // 如果产品下架，则禁用页面交互，显示下架
                if (pLst.length > 0 && pLst[0].status !== 1) {
                    setUndercarriage(true)
                }
                // 初始化订单状态
                dispatch(
                    updateOrderState({
                        kit: null,
                        productList: pLst,
                        serviceList: sLst,
                        resourceList: rLst
                    })
                );
            } else if (type === 'applyService') {
                const sLst = await fetchServiceList();
                const discInfoPromises = sLst.map((s) => queryDiscountInfo({ type: 4, id: s.serviceId }))
                // type=1套件2 产品 3资源 4服务
                Promise.all(
                    discInfoPromises
                ).then((rs) => {
                    setDiscInfos(rs.map(i =>
                        (Array.isArray(i?.data?.data) && i?.data?.data.length>0) ?
                        i.data.data[0] :
                        null
                    ).filter(i => !!i))
                });
                // 不需要购买产品，只是购买关联服务
                dispatch(
                    updateOrderState({
                        kit: null,
                        productList: [],
                        serviceList: sLst,
                        resourceList: []
                    })
                );
            } else if (type === 'applyResource') {
                const rLst = await fetchResourceList();
                const discInfoPromises = rLst.map((p) => queryDiscountInfo({ type: 3, id: p.resourceId }))
                // type=1套件2 产品 3资源 4服务
                Promise.all(
                    discInfoPromises
                ).then((rs) => {
                    setDiscInfos(rs.map(i =>
                        (Array.isArray(i?.data?.data) && i?.data?.data.length>0) ?
                        i.data.data[0] :
                        null
                    ).filter(i => !!i))
                });
                // 不需要购买产品，只是购买关联资源
                dispatch(
                    updateOrderState({
                        kit: null,
                        productList: [],
                        resourceList: rLst,
                        serviceList: []
                    })
                );
            }
        };
        fetchData();
    }, [dispatch, fetchProductList, fetchResourceList, fetchServiceList, type]);

    return (<>
        {/* <Alert
            message={
                <span>费用说明：本产品首年免费，您实际需要支付的费用为【总费用 - {toFixedNumStr(allCost, 2)}】。</span>
            }
            type="info"
            showIcon
            style={{
                marginBottom: 10
            }}
        /> */}
        <div className="product-order-form">
            {underCarriage && (
                <div className="notice-img-icon">
                    <img src={UnderCarriageIcon} alt="" />
                </div>
            )}
            {productList.map((product, idx) => (
                <div className="order-product-item" key={product.productId}>
                    <Row gutter={[0, 20]}>
                        <Col className="order-product-title" span={2}>
                            产品名称：
                        </Col>
                        <Col className="order-product-title" span={22}>
                            {product?.productName}
                        </Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col className="order-product-desc" span={2}>
                            产品介绍：
                        </Col>
                        <Col className="order-product-desc" span={22}>
                            {product?.description}
                        </Col>
                    </Row>
                    <Row gutter={[0]}>
                        <Col className="order-product-desc" span={2}>
                            购买时长：
                        </Col>
                        <Col span={22}>
                            <PriceCounter
                                disabled={underCarriage}
                                product={product}
                                onValueChange={onProductValueChange}
                                idx={idx}
                                optionNum={3}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
            {/* {serviceList?.length > 0} */}
            {type === 'product' && serviceList.length + resourceList.length > 0 && (
                <p
                    style={{
                        margin: '10px 0',
                        fontWeight: 'bold'
                    }}
                >
                    为提升您的服务质量，您还可以购买以下服务：
                </p>
            )}

            <div className="order-service-list">
                {serviceList.map((item, idx) => (
                    <div key={item.serviceId} className="order-service-item">
                        <h3 className="order-service-title">{item.serviceName}</h3>
                        <Row gutter={[0, 20]}>
                            <Col className="order-service-desc" span={2}>
                                服务介绍：
                            </Col>
                            <Col className="order-service-desc" span={22}>
                                {item?.description}
                            </Col>
                        </Row>
                        <Row gutter={[0]}>
                            <Col className="order-service-desc" span={2}>
                                购买数量：
                            </Col>
                            <Col span={22}>
                                <PriceCounter
                                    disabled={underCarriage}
                                    product={item}
                                    onValueChange={onServiceValueChange}
                                    idx={idx}
                                    optionNum={1}
                                    // 服务只能购买一次
                                    max={1}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
                {resourceList.map((item, idx) => (
                    <div key={item.resourceId} className="order-service-item">
                        <h3 className="order-service-title">{item.resourceName}</h3>
                        <Row gutter={[0, 20]}>
                            <Col className="order-service-desc" span={2}>
                                介 绍：
                            </Col>
                            <Col className="order-service-desc" span={22}>
                                {item?.description}
                            </Col>
                        </Row>
                        <Row className="order-service-desc" gutter={[0]}>
                            <Col span={2}>购买数量：</Col>
                            <Col span={22}>
                                <PriceCounter
                                    disabled={underCarriage}
                                    product={item}
                                    onValueChange={onResourceValueChange}
                                    idx={idx}
                                    optionNum={3}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
            <div className="order-form-footer">
                <Button
                    className="order-form-footer-btns"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    返回
                </Button>
                <Button
                    className="order-form-footer-btns"
                    type="primary"
                    loading={loading}
                    onClick={onSubmitClick}
                    disabled={underCarriage}
                >
                    立即下单
                </Button>
                <OrderSumInfo discInfos={discInfos} />
            </div>
        </div>
    </>);
};
