/**
 * @file   用户模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Progress, Tag } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
    queryAuthCompanyByCustomerId,
    queryFileResource,
    queryOrderCount,
    queryToExpireProductCount
} from '../../service';
import { UserOutlined } from '@ant-design/icons';
import { localDS } from '../../storage';
import { toFixedNumStr } from '../../utils';
/**
 * @description 拼接className
 */
const concatCls = (prefix) => (cls) => prefix + '-' + cls;

// 用户个人信息组件
export const ProfileCard = (props) => {
    const { user } = props;
    const [unPayCnt, setUnPayCnt] = useState(0);
    const [allCnt, setAllCnt] = useState(0);
    const [toExpireCnt, setToExpireCnt] = useState(0);
    const history = useHistory();
    const cx = concatCls('profile-card');

    const [isAuthed, setIsAuthed] = useState(false);
    const [space, setSpace] = useState(null)

    // 获取数据并设置数据源和分页状态
    const fetchCnt = useCallback(async () => {
        const allCntRes = await queryOrderCount(1);
        const unPayCntRes = await queryOrderCount(2);
        const toExpireCntRes = await queryToExpireProductCount();
        setAllCnt(allCntRes?.data?.data || 0);
        setUnPayCnt(unPayCntRes?.data?.data || 0);
        setToExpireCnt(toExpireCntRes?.data?.data || 0);
    }, []);

    // 获取空间占用数量
    const fetchSpace = useCallback(async () => {
        const id = localDS.get('systemId');
        if (id) {
            const res = await queryFileResource(id)
            if (res.data.code === 200) {
                setSpace(res.data.data)
            }
        }
    }, [])

    useEffect(() => {
        fetchCnt();
        fetchSpace();
        queryAuthCompanyByCustomerId().then((res) => {
            let infos = res.data?.data;
            if (infos) {
                setIsAuthed(
                    infos.length === 1
                        ? infos[0].approveStatus === 2
                        : infos.length === 2
                        ? (infos[0].approveStatus === 3 || infos[0].approveStatus === 4) &&
                          infos[1].approveStatus === 2
                        : false
                );
            }
        });
    }, [fetchCnt, fetchSpace]);
    return (
        <div className="profile-card">
            <div className={cx('header')}>
                <h2 className='hello'>您好！</h2>
                <Link className='name' to="/UserCenter?key=1">
                    {user?.customerName}
                </Link>
            </div>
            <div className={cx('body')}>
                <div className={cx('avatar')}>
                    <Avatar size={80} icon={<UserOutlined />} />
                </div>
                <div className={cx('tags')}>
                    {isAuthed ? (
                        <Tag
                            color="success"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                history.push('/Organization');
                            }}
                        >
                            机构已认证
                        </Tag>
                    ) : (
                        <Tag
                            color="error"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                history.push('/Organization');
                            }}
                        >
                            机构未认证
                        </Tag>
                    )}
                    {!user?.phoneInvalid ? (
                        <Tag
                            color="success"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                history.push('/UserCenter?key=3');
                            }}
                        >
                            手机号已认证
                        </Tag>
                    ) : (
                        <Tag
                            color="error"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                history.push('/validatePhone');
                            }}
                        >
                            手机号未认证
                        </Tag>
                    )}
                </div>
            </div>
            <div className={cx('space')}>
                {space && (<>
                    <Progress
                        strokeLinecap="square"
                        strokeColor="rgb(40, 167, 69)"
                        percent={space.used / space.limitCount * 100}
                        // percent={50}
                        showInfo={false}
                    />
                    <span style={{
                    }} >{toFixedNumStr(space.used / 1024 / 1024)}GB/{toFixedNumStr(space.limitCount / 1024 / 1024)}GB</span>
                </>)}
            </div>
            <div className={cx('footer')}>
                <div className={cx('action')}>
                    <Link to="/MyOrder">{allCnt}</Link>
                    <br />
                    全部订单
                </div>
                {/* <div className={cx('action')}>
                    <Link to="/MyOrder">{toExpireCnt}</Link>
                    <br />
                    30天内待续费
                </div> */}
                <div className={cx('action')}>
                    <Link to="/MyOrder?status=0">{unPayCnt}</Link>
                    <br />
                    未付订单
                </div>
            </div>
        </div>
    );
};
