
/**
 * @file   志愿者管理系统
 * @create macaiqi 2022/01/12
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import { localDS } from '../../../storage';
import ReactWOW from 'react-wow'
import cls from 'classnames'
import './style.scss'
import { handleFreeTrial } from '../../../utils/productUtils';

/**
 * @description 志愿者管理系统组件
 */
export const VolunteersSystem = () => {
    const [index, setIndex] = useState(0)
    const [tabindex, setTabindex] = useState(1)
    const oDiv = useRef();
    const gnzc = useRef()
    const cptd = useRef()
    const { search } = useLocation()
    const [showTrialTip, setShowTrialTip] = useState(false);
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        setTabindex(anchorName)
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        }
    }
    useEffect(() => {
        // tab吸顶
        let divT = oDiv.current.offsetTop
        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

            if (scrollT >= divT) {
                if (window.navigator.userAgent.indexOf('MSIE 6.0') != -1) {
                    // 兼容IE6代码
                    // body[0].style.paddingTop = '50px'
                    oDiv.current.style.position = 'absolute';
                    oDiv.current.style.top = scrollT + 'px';
                    oDiv.current.style.left = 0 + 'px';
                } else {
                    // 正常浏览器代码
                    oDiv.current.style.position = 'fixed';
                    oDiv.current.style.top = 0;
                    oDiv.current.style.left = 0;
                }
                document.getElementById('content').style.paddingTop = 50 + 'px'
                for (let i = 0; i < tab.length; i++) {
                    if (scrollT >= document.getElementById(`${i + 1}`)?.offsetTop) {
                        document.getElementById(`link${i + 1}`).classList.add('active');
                        for (let j = 0; j < tab.length; j++) {
                            if (i !== j) {
                                document.getElementById(`link${j + 1}`).classList.remove('active');
                            }
                        }
                    }

                }
            } else {
                oDiv.current.style.position = '';
                document.getElementById('content').style.paddingTop = 0 + 'px'
                for (let j = 0; j < tab.length; j++) {
                    document.getElementById(`link${j + 1}`).classList.remove('active');
                }
            }

        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(search.split('?')[1])
        }
    }, [cptd, gnzc, oDiv, search]);
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    // 功能组成
    const gongneng = [
        {
            name: "志愿者管理端",
            tab: ['档案管理', '分组管理', '服务管理', '申请记录', '排班记录', '数据统计', '基础配置'],
            des: '提供志愿者档案资料的统一集中管理。主要功能包括新增、查看、修改、删除、导入、导出、审核、查看个人信息。&&（1） 新增&&支持新增志愿者档案，对非在线申请志愿者的档案进行信息新增录入。',
            icon: 'iconshezhi'
        },
        {
            name: "志愿者小程序端",
            tab: ['志愿者报名', '服务签到', '服务调度', '志愿者风采', '考勤查询', '数据统计', '服务申请', '服务审批', '个人中心'],
            des: '为社会公众开放申请成为志愿者的渠道，提供志愿者报名申请，通过 “志愿者招募”入口，进入志愿者报名申请信息填报页，主要包括姓名、出生日期、学历、可服务时间、可服务岗位、特长等指标，填写完成后提交给管理员审核。',
            icon: 'iconxiaochengxu'
        },
    ]
    // 产品特点
    const tedian = [
        {
            name: '志愿者管理与服务全流程覆盖',
            des: '实现从志愿者招募、档案管理、服务发布、报名申请、服务排班、勤务考核等全流程业务管理。',
            icon: 'iconyizhanshijiejuefangan',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '基于移动终端的便捷式管理',
            des: '支持微信小程序作为移动终端，便捷、高效管理志愿者日常工作。',
            icon: 'iconshouji21',
            duration: '1s',
            delay: '0.7s'
        },
        {
            name: '实现多角色灵活管理',
            des: '支持总调度、组长、志愿者等角色灵活管理，利用微信小程序实现线上业务协同审批。',
            icon: 'iconlinghuoguanli',
            duration: '1s',
            delay: '0.9s'
        },
    ]

    return (<>
        <IntroHeader />
        <div className="activity-appointment volunteer" id='content'>
            <div className="banner">
                <ReactWOW animation="fadeInLeft" duration="1.2s" delay="0.1s">
                    <div className="content">
                        <p className="big">志愿者管理系统</p>
                        <div className="cont">
                            以协同志愿者团队更加灵活、专业、高效服务社会公众为目标，为志愿者建立全面档案，制定并发布志愿服务需求，实现志愿者服务的快速报名、签到签退、考勤统计，为志愿者管理团队提供服务管理、任务调度、勤务考核的一体化服务，简化志愿者工作流程，提高志愿服务及管理效率。
                        </div>
                        <div className="btn">
                            {/* <div className="btn1" onClick={() => {
                                const token = localDS.get('saasToken')
                                if (!token) {
                                    window.location.href = "/accountLogin"
                                    return
                                }
                                let a = document.createElement("a"); // 创建一个<a></a>标签
                                a.href = `${window.location.origin}/pdf/volunteer.pdf`; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
                                a.download = "产品白皮书.pdf"; // 设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
                                a.style.display = "none"; // 障眼法藏起来a标签
                                document.body.appendChild(a); // 将a标签追加到文档对象中
                                a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                                a.remove(); // 一次性的，用完就删除a标签
                            }}>
                                产品白皮书
                            </div> */}
                            {/* <div className="btn1">
                                观看演示
                            </div> */}
                            <div
                                className="btn2"
                                onClick={handleFreeTrial}
                                onMouseOver={() => { setShowTrialTip(true) }}
                                onMouseLeave={() => { setShowTrialTip(false) }}
                            >
                                免费试用
                                {showTrialTip && <div className='hov'>! 注册并申请机构实名认证后即可免费试用</div>}

                            </div>
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="tab" ref={oDiv}>
                <ul>
                    {tab?.map((item, index) => {
                        return (
                            <li key={index}>
                                <span onClick={() => scrollToAnchor(index + 1)} id={`link${index + 1}`} >{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <div className="people" id='1'>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title" >用户群体</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <p className="txt">本产品适用于博物馆、美术馆、图书馆、文化馆、科技馆、规划馆、校史馆、档案馆等各类公共文化场馆、文旅景区及其他有志愿者管理的相关工作人员。</p>
                </ReactWOW>
            </div>
            <div className="section2" id='2' ref={gnzc}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1.1s">
                    <p className="title" id='two'>功能组成</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1s">
                    <div className="functionof">
                        {gongneng.map((item, i) => {
                            return (
                                <div className={cls('item', { active: index === i })} onClick={() => { setIndex(i) }} key={i}>
                                    <div className="moren">
                                        <i className={cls('iconfont', item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        {item.tab.length > 6 ? <div className="txtbox">
                                            {item.tab.map((item1, i1) => {
                                                return (<span key={i1}>{item1}</span>)
                                            })}
                                        </div> : item.tab.map((item1, i1) => {
                                            return (<p className="txt" key={i1}>{item1}</p>)
                                        })}
                                    </div>
                                    <div className="activeitem">
                                        <p className='top'>
                                            <i className={cls('iconfont', item.icon)} />
                                            <span className='tip'>{item.name}</span>
                                        </p>
                                        <div className="cont">
                                            {item.des.includes("&&") ? item.des.split('&&').map((val, index2) => {
                                                return (
                                                    <p key={index2}>{val}</p>
                                                )
                                            }) : item.des}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </ReactWOW>
            </div>
            <div className="section3" id='3' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.5s">
                    <p className="title">产品特点</p>
                </ReactWOW>
                <div className="section3-box">
                    {tedian.map((item, index) => {
                        return (
                            <ReactWOW animation="fadeInUp-little" key={index} duration={item.duration} delay={item.delay}>
                                <div className="item">
                                    <div className="itembox">
                                        <div className="l">
                                            <i className={`iconfont ${item.icon}`} />
                                        </div>
                                        <div className="r">
                                            <p className="tip">{item.name}</p>
                                            <p className='txt'>{item.des}</p>
                                        </div>
                                    </div>
                                </div>
                            </ReactWOW>
                        )
                    })}
                </div>

            </div>
            <div className="section4" id='4' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title">产品价格</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <div className="box">
                        <div className="price">
                            <div className="l">
                                <div className="red">
                                    <span>4800</span>
                                    元/年
                                </div>
                            </div>
                            {/* <div className="buy">立即购买</div> */}
                        </div>

                    </div>
                </ReactWOW>
            </div>
            <div className="update" id='5'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    {/* <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span> */}
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
