/**
 * @file   订购服务模块
 * @create tianmu 2021/3/9
 * @update tianmu 2021/3/9
 */
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ProductOrderForm } from './components/OrderForms/ProductOrderForm';
import { ServiceOrderForm } from './components/OrderForms/ServiceOrderForm';
import { ResourceOrderForm } from './components/OrderForms/ResourceOrderForm';
import { KitOrderForm } from './components/OrderForms/KitOrderForm';
import { searchStrToObj } from '../../utils';
import { ProductLayout } from './components/ProductLayout';
import './index.scss';
import { TrialOrderForm } from './components/OrderForms/TrialOrderForm';

// 订购服务组件
export const ProductOrder = () => {
    const history = useHistory();
    const location = useLocation();
    const o = useMemo(() => searchStrToObj(location.search) || {}, [location.search])

    useEffect(() => {
        const { type, id } = o;
        if (!(type && id)) {
            history.replace('/MyProduct');
        }
    }, [history, o]);

    return (
        <ProductLayout title={
            o.type === 'product'
            ? '订购产品'
            : o.type === 'kit'
            ? '订购套件'
            : (o.type === 'applyService' || o.type === 'service')
            ? '订购服务'
            : (o.type === 'applyResource' || o.type === 'resource')
            ? '订购资源'
            : (o.type === 'trial')
            ? '试用产品'
            : ""
        }>
            {(o.type === 'product' ||
                o.type === 'applyService' ||
                o.type === 'applyResource') && (
                <ProductOrderForm
                    type={o.type}
                    id={o.id}
                    finish={() => {
                        history.push("/ProductOrderInfo")
                    }}
                />
            )}
            {o.type === 'service' && (
                <ServiceOrderForm
                    id={o.id}
                    finish={() => {
                        history.push("/ProductOrderInfo")
                    }}
                />
            )}
            {o.type === 'resource' && (
                <ResourceOrderForm
                    id={o.id}
                    finish={() => {
                        history.push("/ProductOrderInfo")
                    }}
                />
            )}
            {o.type === 'kit' && (
                <KitOrderForm
                    id={o.id}
                    finish={() => {
                        history.push("/ProductOrderInfo")
                    }}
                />
            )}
            {o.type === 'trial' && (
                <TrialOrderForm
                    id={o.id}
                />
            )}
        </ProductLayout>
    );
};
