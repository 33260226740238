/**
 * @file   我的发票模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Layout, Input, Form, Radio, Row, Col, notification } from 'antd';
import { queryCustomerOrderById, queryInvoiceList, queryInvoiceTitleList, saveInvoice } from '../../service';
import { FormFooter } from '../../containers/FormFooter';
import { searchStrToObj, toFixedNumStr, validator } from '../../utils';
import './index.scss';

const AlignLabelStyle = {
    display: 'inline-block',
    width: 100,
    textAlign: 'right',
}
// 我的发票页面组件
export const ApplyInvoice = () => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    // 当前开票的订单
    const [order, setOrder] = useState(null);
    // 当前选中的发票抬头
    const [currInvoiceTitle, setInvoiceTitle] = useState(null);
    // 发票类型
    const [currInvoiceType, setInvoiceType] = useState(1);

    /**
     * @description 设置当前选中的发票抬头
     * @param {Array} lst 最新获取的发票抬头表单
     */
    const setCurrInvoiceTitle = useCallback((lst) => {
        // 空列表直接返回
        if (Array.isArray(lst) && lst.length === 0) return;
        const defaultLst = lst.filter(l => l.isDefault);
        if (defaultLst.length > 0) {
            setInvoiceTitle(defaultLst[0]);
        } else {
            setInvoiceTitle(lst[0]);
        }
    }, []);

    const fetchData = useCallback(async (type, orderId) => {
        /**
         * 发票类型为普通发票时，发票抬头获取的数据应是个人+机构数据
         * 发票类型为专用发票时，发票抬头获取的数据应是机构数据
         */
        const res1 = await queryInvoiceTitleList({
            entity:{
                titleType: type === 2 ? 2 : undefined
            },
            param:{pageNum:1, pageSize:100}
        });
        if (res1.data.code === 200) {
            setCurrInvoiceTitle(res1.data.data.records)
        }

        // 优化，如果用户曾经开过发票且填写过地址，把地址自动填上
        const res2 = await queryInvoiceList({
            pageNum: 1,
            pageSize: 1,
        })
        if (res2.data.code === 200) {
            const lst = res2.data.data.records;
            if (Array.isArray(lst) && lst.length > 0) {
                form.setFieldsValue({
                    receiver: lst[0].receiver,
                    receiverAddress: lst[0].receiverAddress,
                    receiverPhone: lst[0].receiverPhone,
                });
            }
        }

        // 获取开票的订单
        if (orderId) {
            const res2 = await queryCustomerOrderById(orderId)
            if (res2.data.code === 200) {
                setOrder(res2.data.data)
            }
        }
    }, [form, setCurrInvoiceTitle]);


    useEffect(() => {
        // url上的搜索串键值对
        const o = searchStrToObj(location.search);
        if (!o?.orderId) {
            // 没有订单id返回我的订单页面
            history.replace("/MyOrder");
            return;
        }
        form.setFieldsValue({
            invoiceType: currInvoiceType,
            orderId: o?.orderId  // 从开发票的地方读取
        })
        fetchData(currInvoiceType, o.orderId);
    }, [history, fetchData, form, currInvoiceType, location])

    const onFinish = (values) => {
        if (!currInvoiceTitle) {
            notification.warn({
                className: 'custom-antd-notification',
                message: '请选择发票抬头！',
                duration: 5,
            });
            return;
        }
        if (values.invoiceType === 2 && currInvoiceTitle.titleType === 1) {
            notification.warn({
                className: 'custom-antd-notification',
                message: '请先完善增值税专用发票相关信息！',
                duration: 5,
            });
            return;
        }
        setLoading(true)
        saveInvoice({
            invoiceTitleId: currInvoiceTitle.invoiceTitleId,
            invoiceCost: order?.refundCost
            ? order?.orderCost - order?.refundCost
            : order?.orderCost,
            titleName: currInvoiceTitle.titleName,
            ...(currInvoiceType === 2 ? {
                dutyParagraph: currInvoiceTitle.dutyParagraph,
                companyPhone: currInvoiceTitle.companyPhone,
                accountBank: currInvoiceTitle.accountBank,
                accountNumber: currInvoiceTitle.accountNumber,
                companyAddress: currInvoiceTitle.companyAddress,
            } : {}),
            ...values,
        }).then(res => {
            if (res.data.code === 200) {
                notification.success({
                    className: 'custom-antd-notification',
                    message: "保存成功！",
                    duration: 5,
                })

                history.push('/MyOrder');
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: res.data.msg,
                    duration: 5,
                })

            }
            setLoading(false);
        })
    }

    return (<>
        <div className="page-header">
            <div className="title">申请发票</div>
        </div>
        <Layout className="page-content apply-invoice">
            <div
                style={{
                    width: '100%',
                    height: '60px',
                    lineHeight: '60px',
                    paddingLeft: '30px',
                    marginBottom: '30px',
                    background: 'rgb(247, 247, 247)',
                    borderRadius: '3px',
                    fontSize: '16px',
                }}
            >
                可开票金额：
                <span style={{color: 'rgb(105, 191, 143)'}}>{
                    (order?.orderCost && order?.refundCost)
                    ? toFixedNumStr(order?.orderCost - order?.refundCost)
                    : order?.orderCost
                    ? toFixedNumStr(order?.orderCost)
                    : 0.00
                }元</span>
                <span
                    onClick={() => {
                        history.goBack();
                    }}
                    style={{
                        float: 'right',
                        marginRight: '20px',
                        fontSize: '14px',
                        color: 'rgb(86, 192, 150)',
                        cursor: 'pointer',
                    }}
                >
                    &lt; 返回
                </span>
            </div>
            <Form
                wrapperCol={{
                    span: 18
                }}
                labelCol={{ style: { width: 100 } }}
                form={form}
                onFinish={onFinish}
                style={{
                    width: '75%',
                }}
                // onFinishFailed={onFinishFailed}
            >
                <h1 style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    borderTop: '1px dashed #ccc',
                    paddingTop: 10,
                    marginBottom: 10,
                }}>
                    发票类型
                </h1>
                <Form.Item
                    name="orderId"
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="invoiceType"
                    rules={[
                        {
                            required: true,
                            message: '请选择发票类型!',
                        },
                    ]}
                >
                    <Radio.Group
                        onChange={(e) => {
                            const v = e.target.value
                            form.setFieldsValue({
                                invoiceType: v,
                            });
                            setInvoiceType(v);
                            fetchData(v)
                        }}
                    >
                        <Radio value={1}>普通发票</Radio>
                        <Radio value={2}>增值税专用发票</Radio>
                    </Radio.Group>
                </Form.Item>
                <h1 style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    borderTop: '1px dashed #ccc',
                    paddingTop: 10,
                    marginBottom: 30,
                }}>发票抬头
                    <Link
                        to={`/MyOrder/ManageInvoice?orderId=${
                            searchStrToObj(location.search)?.orderId
                        }`}
                        style={{float: 'right', fontSize: '14px'}}
                    >
                        管理发票信息
                    </Link>
                </h1>

                {currInvoiceType === 2 ? (<>
                <Row gutter={[0, 20]}>
                    <Col span={8}>
                        <span style={AlignLabelStyle}>发票抬头：</span>
                        {currInvoiceTitle?.titleName}
                    </Col>
                    <Col span={16}>
                        <span style={AlignLabelStyle}>税 号：</span>
                        {currInvoiceTitle?.dutyParagraph}
                    </Col>
                </Row>
                <Row gutter={[0, 20]}>
                    <Col span={8}>
                        <span style={AlignLabelStyle}>开户行：</span>
                        {currInvoiceTitle?.accountBank}
                    </Col>
                    <Col span={8}>
                        <span style={AlignLabelStyle}>电 话：</span>
                        {currInvoiceTitle?.companyPhone}
                    </Col>
                    <Col span={8}>
                        <span style={AlignLabelStyle}>账 号：</span>
                        {currInvoiceTitle?.accountNumber}
                    </Col>
                </Row>
                <Row gutter={[0, 20]}>
                    <Col span={24}>
                        <span style={AlignLabelStyle}>地 址：</span>
                        {currInvoiceTitle?.companyAddress}
                    </Col>
                </Row>
                </>) : (
                    <Row gutter={[0, 20]}>
                        <Col span={24}>
                            <span style={AlignLabelStyle}>发票抬头：</span>
                            {currInvoiceTitle ? currInvoiceTitle.titleName : '暂无'}
                        </Col>
                    </Row>
                )}

                <Row gutter={[0, 10]}>
                    <Col span={24}>
                        <Form.Item
                            name="remark"
                            label="备 注"
                            wrapperCol={24}
                        >
                            <Input placeholder="请填写备注说明" />
                        </Form.Item>
                    </Col>
                </Row>
                <h1 style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    borderTop: '1px dashed #ccc',
                    paddingTop: 10,
                    marginBottom: 20,
                }}>
                    收件信息
                </h1>
                <Row gutter={[20, 10]}>
                    <Col span={12}>
                        <Form.Item
                            name="receiver"
                            label="收件人"
                            wrapperCol={24}
                            rules={[
                                {
                                    required: true,
                                    message: '请填写收件人!'
                                }
                            ]}
                        >
                            <Input placeholder="请填写收件人" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="receiverPhone"
                            label="收件人电话"
                            wrapperCol={24}
                            rules={[
                                {
                                    required: true,
                                    message: '请填写手机号!'
                                },
                                { pattern: validator().phoneNumRegex, message: '请输入正确的手机号！'}
                            ]}
                        >
                            <Input placeholder="请填写收件人电话" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 10]}>
                    <Col span={24}>
                        <Form.Item
                            name="receiverAddress"
                            label="邮寄地址"
                            wrapperCol={24}
                            rules={[
                                {
                                    required: true,
                                    message: '请填写邮寄地址!'
                                }
                            ]}
                        >
                            <Input placeholder="请填写邮寄地址" />
                        </Form.Item>
                    </Col>
                </Row>

                <FormFooter
                    okText="确认开票"
                    onOk={()=>form.submit()}
                    loading={loading}
                />
            </Form>
        </Layout>
    </>);
};
