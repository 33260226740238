/**
 * @file   积分商城模块
 * @create tianmu 2021/7/1
 * @update tianmu 2021/7/1
 */
import React, { useState, useEffect } from 'react';
import { ScoreLayout } from './components/ScoreLayout';
import { useHistory } from 'react-router-dom';
import { CategoryFilter } from './components/CategoryFilter';

import './index.scss';
import TEMP_PIC from '../../assets/temp.png';
import { queryGoodsPageList } from '../../service';

// const FTP_PREVIEW = process.env.ENV_FTP_PREVIEW
const FTP_PREVIEW = window.FTP_PREVIEW

// 积分商城商品详情组件
export const ScoreShop = () => {
    const history = useHistory();
    const [goods, setGoods] = useState([])
    const fetchData = async () => {
        const res = await queryGoodsPageList({});
        if (res.data.code === 200) {
            setGoods(res.data.data?.list || [])
        }
    }
    useEffect(() => {
        fetchData()
    }, []);

    return (
        <ScoreLayout header={<CategoryFilter />}>
            <div className="category-list-type-filter">
                <div className="category-list-type-item">默认</div>
                <div className="category-list-type-item">积分</div>
                <div className="category-list-type-item">销量</div>
            </div>
            <div className="category-list">
                {goods.map((i, idx) => (
                    <div
                        className="category-list-item"
                        key={i.goodsId}
                        onClick={() => history.push('/ScoreProductDetail')}
                    >
                        <div className="cover">
                            <img
                                src={
                                    FTP_PREVIEW +
                                    i.pictures?.find(p => p.isMain === 1)?.path
                                }
                                alt=""
                            />
                        </div>
                        <div className="description">
                            <div className="title">{i.goodsName}</div>
                            <div className="meta">
                                <div className="score">
                                    {i.exchangeType === 1 ?
                                    <span>{i.exchangePoints}积分</span> :
                                    <>
                                        <span>{i.exchangePoints}积分</span>
                                        <span>￥{i.exchangeAmount}</span>
                                    </>}
                                </div>
                                <div className="count">已兑18件</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </ScoreLayout>
    );
};
