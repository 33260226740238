/**
 * @file   帐号申诉查询模块
 * @create tianmu 2021/6/10
 * @update tianmu 2021/6/10
 */
import React, { useState } from 'react';
import { Button, Input, Layout, notification, Form } from 'antd';
import { AppFooter } from '../../containers/AppFooter';
import Logo from '../../assets/logo.png';
import { queryAppeal } from '../../service';
import './index.scss'
import { InfoBox, STATUS_ENUM } from './InfoBox';
import { useHistory } from 'react-router-dom';
const CryptoJS = require('crypto-js');

const { Content, Header } = Layout;

// 帐号申诉组件
export const AccountComplainQuery = () => {
    // flag 失效状态(0:未失效 1:失效)
    // status 申诉状态(1:审核中 2:通过 3:未通过 9:完成)
    const [complainState, setComplainState] = useState(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const REASON = complainState && complainState.appealReason
    const OUTDATED = complainState && complainState.flag === 1
    const AUTHING = complainState && complainState.status === 1
    const PASSED = complainState && complainState.status === 2
    const REJECTED = complainState && complainState.status === 3
    return (
        <Layout className="account-complain">
            <Header className="layout-header">
                <div className="logo-box">
                    <a className="logo" href="/">
                        <img src={Logo} alt="logo" />
                    </a>
                </div>
            </Header>
            <Content className="account-complain-content">
                <div className="page-header">
                    <div className="title">帐号申诉</div>
                </div>
                <div className="page-content">
                    {
                    (AUTHING) ? (
                        <InfoBox
                            title="你提交的申诉正在审核中，请耐心等待！"
                            description="工作人员会在1-3个工作日内完成审核，审核后，申诉结果会立即通过短信发送至您的手机，请关注手机短信"
                            status={STATUS_ENUM.AUTHING}
                            extra={
                                <Button
                                    onClick={() => {
                                        history.push('/accountComplain')
                                    }}
                                >
                                    返回
                                </Button>
                            }
                        />
                    ) : (REASON === 1 && PASSED) ? (
                        // 当选择注册手机号失效，审核通过且未失效可以设置密码（给个提示，只允许设置一次密码）
                        // 审核通过且失效不能设置密码
                        <InfoBox
                            title="恭喜，你提交的申诉已通过审核！"
                            status={STATUS_ENUM.AUTHED}
                            description={!OUTDATED ? '请修改登录密码，密码只可设置一次' : ''}
                            extra={<>
                                {!OUTDATED && (
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            history.push('/accountComplainChangePwd')
                                        }}
                                    >
                                        修改登录密码
                                    </Button>
                                )}
                                <Button
                                    onClick={() => {
                                        history.push('/accountComplain')
                                    }}
                                >
                                    返回
                                </Button>
                            </>}
                        />
                    ) : (REASON === 2 && PASSED) ? (
                        // 当选择手机号被占用，审核通过就默认失效，界面上给出提示可用手机号去注册帐号了
                        <InfoBox
                            title="恭喜，你提交的申诉已通过审核！"
                            status={STATUS_ENUM.AUTHED}
                            description="可用手机号去注册帐号了"
                            extra={<>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        history.push('/register')
                                    }}
                                >
                                    注册帐号
                                </Button>
                                <Button
                                    onClick={() => {
                                        history.push('/accountComplain')
                                    }}
                                >
                                    返回
                                </Button>
                            </>}
                        />
                    )

                    : REJECTED ? (
                        <InfoBox
                            title="你提交的申诉已被拒绝！"
                            description={complainState.rejectReason ? `拒绝原因：${
                                complainState.rejectReason === 1
                                ? '申诉资料匹配比例不达标'
                                : complainState.rejectReason === 9
                                ? '其他'
                                : ''}` : ""}
                            status={STATUS_ENUM.REJECTED}
                            extra={
                                <Button
                                    onClick={() => {
                                        history.push('/accountComplain')
                                    }}
                                >
                                    返回
                                </Button>
                            }
                        />
                    ) : (
                        <div
                            style={{
                                width: '400px',
                                margin: '100px auto 0',
                            }}
                        >
                            <Form layout="vertical" form={form} initialValues={{appealReason: 1, appealPhone: '', phone: ''}}>
                                <Form.Item
                                    name="phone"
                                    label="接收查询信息的手机号"
                                    rules={[
                                        {
                                            required: true,
                                            message: '接收查询信息的手机号不能为空!'
                                        }
                                    ]}
                                >
                                    <Input size="large" placeholder="请输入接收查询信息手机号" />
                                </Form.Item>
                                <Form.Item
                                    name="security"
                                    label="安全码"
                                    rules={[
                                        {
                                            required: true,
                                            message: '安全码不能为空!'
                                        }
                                    ]}
                                >
                                    <Input size="large" placeholder="请输入安全码" />
                                </Form.Item>
                            </Form>
                            <div>
                                <Button
                                    type="primary"
                                    style={{marginRight: '20px'}}
                                    onClick={async () => {
                                        await form.validateFields()
                                        const values = form.getFieldsValue()
                                        queryAppeal(values.phone,
                                            CryptoJS.MD5(values.security).toString().toUpperCase()
                                        ).then(res => {
                                            if (res.data.code === 200) {
                                                if (res.data.data) {
                                                    setComplainState(res.data.data)
                                                    notification.success({
                                                        className: 'custom-antd-notification',
                                                        message: '查询成功！',
                                                        duration: 5,
                                                    })
                                                } else {
                                                    notification.warn({
                                                        className: 'custom-antd-notification',
                                                        message: '未查询到申诉记录！',
                                                        duration: 5,
                                                    })
                                                }
                                            } else {
                                                notification.warn({
                                                    className: 'custom-antd-notification',
                                                    message: res.data.msg,
                                                    duration: 5,
                                                })
                                            }
                                        })
                                    }}
                                >
                                    查询
                                </Button>
                                <Button
                                    onClick={() => history.push('/accountComplain')}
                                >
                                    返回
                                </Button>

                            </div>
                        </div>

                    )}

                </div>
            </Content>
            <AppFooter />
        </Layout>
    );
};
