/**
 * @file   问题反馈
 * @create tianmu 2021/9/1
 * @update tianmu 2021/9/1
 */
import React, { useEffect, useState, useCallback } from 'react';
import { Layout, Input, Form, notification, Button, Select, Table, Modal } from 'antd';
import { CustomUpload } from '../../components/CustomUpload/CustomUpload';
import { queryFeedbackPageList, queryFeedbackPictures, submitFeedback } from '../../service';
import { PlusOutlined } from '@ant-design/icons';
import Viewer from 'react-viewer';

// const filePreviewPrefix = process.env.ENV_FILE_PREVIEW;
// const ftpPreviewPrefix = process.env.ENV_FTP_PREVIEW;
const filePreviewPrefix = window.FILE_PREVIEW;
const ftpPreviewPrefix = window.FTP_PREVIEW;

const FeedbackDetail = (props) => {
    const { data, goback } = props;
    const feedbackPics = Array.isArray(data.feedbackPictures) ?
                data.feedbackPictures :
                []
    const replyPics = Array.isArray(data.replyPictures) ?
                data.replyPictures :
                []
    console.log(replyPics)
    return (<div>
        <p style={{padding: '20px 0'}}>
            <b>我的反馈</b>：{data.description}
        </p>
        <div style={{marginLeft: 20}}>
            {feedbackPics.map(p => <img style={{marginRight: 8}} key={p.feedbackPictureId} src={filePreviewPrefix + p.path} alt="" />)}
        </div>
        <p style={{padding: '20px 0'}}>
            <b>回复</b>：{data.reply || '暂无回复'}
        </p>
        <div style={{marginLeft: 20}}>
            {replyPics.map(p => <img style={{marginRight: 8}} key={p.feedbackPictureId} src={ftpPreviewPrefix + (p.path.startsWith('/') ? p.path.substring(1) : p.path)} alt="" />)}
        </div>
        <Button style={{marginTop:20}} onClick={goback}>返回</Button>
    </div>)
}

// 默认页面大小
const PAGE_SIZE = 10;

const replyStatus = (status) => {
    if (!status) return "";
    switch (status) {
        case 1:
            return <span className="state-waring">未回复</span>
        case 2:
            return <span className="state-success">已回复</span>
        default:
            return "";
    }
}

const feedbackTypeStr = (type) => {
    if (!type) return "";
    switch (type) {
        case 1:
            return "帐号问题"
        case 2:
            return "支付问题"
        case 3:
            return "产品问题"
        case 4:
            return "使用问题"
        case 99:
            return "其他"
        default:
            return "";
    }
}

const AddFeedback = (props) => {
    const { visible, setVisible } = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [image, setImage] = useState({
        visible: false,
        src: null
    });

    // 处理提交机构认证的事件
    const handleSubmit = useCallback(async () => {
        await form.validateFields()
        const values = form.getFieldsValue();
        const appendix = values.appendix;
        let files = []
        if (Array.isArray(appendix?.fileList) && appendix.fileList.length > 0) {
            files = appendix.fileList.map(f => ({path: f.response.data.filePath + f.response.data.fileName}))
        }
        setLoading(true);
        const res = await submitFeedback({
            entity: {
                type: values.type,
                description: values.description,
            },
            pictures: files,
        });

        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '提交成功！',
                duration: 5,
            })
            setLoading(false);
            setVisible(false)
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
            setLoading(false);
        }
    }, [form, setVisible])

    const uploadButton = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <Modal
            centered
            width={700}
            visible={visible}
            onCancel={() => setVisible(false)}
            maskClosable={false}
            keyboard={false}
            footer={[
                <Button key="back" onClick={() => setVisible(false)}>
                    取消
                </Button>,
                <Button loading={loading} key="submit" type="primary" onClick={handleSubmit} >
                    提交
                </Button>
            ]}
        >

            <Form
                style={{
                    width: '90%'
                }}
                form={form}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="反馈类型"
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: '请确认反馈类型!'
                        }
                    ]}
                >
                    <Select placeholder="请选择反馈类型">
                        <Select.Option key={1} value={1}>
                        帐号问题
                        </Select.Option>
                        <Select.Option key={2} value={2}>
                        支付问题
                        </Select.Option>
                        <Select.Option key={3} value={3}>
                        产品问题
                        </Select.Option>
                        <Select.Option key={4} value={4}>
                        使用问题
                        </Select.Option>
                        <Select.Option key={99} value={99}>
                        其他
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="反馈内容"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: '请确认反馈内容!'
                        }
                    ]}
                >
                    <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item
                    label="上传图片附件"
                    name="appendix"
                    extra="上传的图片格式要求jpg、jpeg、bmp、png，不超过2M；文件名(包含后缀名)的最大长度为100个字符。"
                >
                    <CustomUpload
                        // className="register-unit-upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={(file) => {
                            let thumb;
                            const d = file.response?.data;
                            if (d) {
                                thumb = filePreviewPrefix + d.filePath + d.fileName;
                            } else {
                                thumb = file.url;
                            }
                            setImage({
                                visible: true,
                                src: thumb
                            });
                        }}
                        accept=".png, .jpg, .jpeg, .bmp"
                        beforeUpload={(file) => {
                            // console.log('before upload', file);
                            // 判断是否为图片文件
                            if (file.type !== 'image/png'
                            && file.type !== 'image/jpeg'
                            && file.type !== 'image/jpg'
                            && file.type !== 'image/bmp' ) {
                                notification.warn({
                                    className: 'custom-antd-notification',
                                    message: `${file.name} 不是一个图片文件`,
                                    duration: 5,
                                })
                                return false;
                            }
                            if (file.size > 1024 * 1024 * 2 ) {
                                notification.warn({
                                    className: 'custom-antd-notification',
                                    message: `上传图片大小不能超过2M`,
                                    duration: 5,
                                })
                                return false;
                            }
                            if (file.name.length > 100) {
                                notification.warn({
                                    className: 'custom-antd-notification',
                                    message: `上传文件名不能超过100个字符`,
                                    duration: 5,
                                })
                                return false;
                            }
                            return true;
                        }}
                            onChange={(info) => {
                            setFileList([...info.fileList].filter((file) => !!file.status).slice(-4)); //  Limit the number of uploaded files
                        }}
                    >
                        {fileList.length >= 4 ? null : uploadButton}
                        {/* {fileList.length === 0 && (

                            <img
                                style={{ width: '100%', objectFit: 'contain' }}
                                src={UploadBg}
                                alt=""
                            />

                        )} */}
                    </CustomUpload>
                </Form.Item>
                <Viewer
                    visible={image.visible}
                    onClose={() => {
                        setImage({ visible: false, src: null });
                    }}
                    images={[{ src: image.src, alt: '' }]}
                />
            </Form>
        </Modal>
    )
}

export const Feedback = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [feedbackDetail, setFeedbackDetail] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: PAGE_SIZE
    });

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback(
        (params) => {
            queryFeedbackPageList(params).then((res) => {
                // console.log(res)
                const listData = res.data.data.records.map((item) => {
                    return {
                        key: item.feedbackId,
                        description: item,
                        feedbackTime: item.feedbackTime,
                        feedbackType: feedbackTypeStr(item.type),
                        status: item.status
                    };
                });
                setPagination({
                    current: params.pageNum,
                    pageSize: params.pageSize,
                    total: res.data.data.total
                });
                setDataSource(listData);
                setLoading(false);
            });
        },
        []
    );

    useEffect(() => {
        fetchData({
            pageNum: 1,
            pageSize: PAGE_SIZE
        })
    }, [fetchData]);


    const showFeedbackDetail = async (id) => {
        const res = await queryFeedbackPictures(id)
        setFeedbackDetail(res.data?.data || null)
    }

    const columns = [
        {
            title: '反馈内容',
            dataIndex: 'description',
            render: (item) => (
            <p
                style={{
                    padding: 10,
                    cursor: 'pointer',
                    color: 'rgb(61, 188, 142)'
                }}
                onClick={() => showFeedbackDetail(item.feedbackId)}
            >
                {item.description?.length > 100 ?
                item.description.substring(0, 100) + "..." :
                item.description}
            </p>)
        },
        {
            title: '反馈时间',
            dataIndex: 'feedbackTime'
        },
        {
            title: '反馈类型',
            dataIndex: 'feedbackType',
        },
        {
            title: '回复状态',
            dataIndex: 'status',
            render: (status) => replyStatus(status)
        }
    ];

    const handleTableChange = (pagination) => {
        const values = form.getFieldsValue();
        const timeRange = values.createTimeQuery;
        fetchData({
            entity: {
                ...values,
                createTimeQuery: timeRange && timeRange.map((t) => t.format('YYYY-MM-DD')).join('#')
            },
            param: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize
            }
        });
    };


    return (
    <>
        <div className="page-header">
            <div className="title">问题反馈</div>
        </div>
        <Layout className="page-content feedback">
        {feedbackDetail ? (
            <FeedbackDetail data={feedbackDetail} goback={() => setFeedbackDetail(null)}/>
        ) : (<>
            <div className="table-meta feedback-table-meta">
                <Button type="primary" onClick={() => setShowModal(true)}>新增反馈</Button>
                <Form
                    form={form}
                    layout="inline"
                    onFinish={() => handleTableChange({ current: 1, pageSize: PAGE_SIZE })}
                >
                    {/* <Form.Item name="description">
                        <Input placeholder="请输入反馈问题描述" />
                    </Form.Item> */}
                    <Form.Item
                        name="type"
                    >
                        <Select placeholder="请选择反馈类型">
                            <Select.Option key={1} value={1}>
                            帐号问题
                            </Select.Option>
                            <Select.Option key={2} value={2}>
                            支付问题
                            </Select.Option>
                            <Select.Option key={3} value={3}>
                            产品问题
                            </Select.Option>
                            <Select.Option key={4} value={4}>
                            使用问题
                            </Select.Option>
                            <Select.Option key={99} value={99}>
                            其他
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="status"
                    >
                        <Select placeholder="请选择回复状态">
                            <Select.Option key={1} value={1}>
                            未回复
                            </Select.Option>
                            <Select.Option key={2} value={2}>
                            已回复
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <Button type="primary" htmlType="submit" loading={loading}>
                        查询
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                            let params = {
                                entity: {},
                                param: {
                                    pageNum: 1,
                                    pageSize: PAGE_SIZE
                                }
                            };
                            fetchData(params);
                        }}
                    >
                        重置
                    </Button>
                </Form>
            </div>

            <Table
                columns={columns}
                dataSource={dataSource}
                bordered
                pagination={{
                    ...pagination,
                    // showSizeChanger: true,
                    showTotal: (total) => {
                        return `共 ${total} 条数据`;
                    }
                }}
                loading={loading}
                onChange={handleTableChange}
            />

        </>)}

        <AddFeedback visible={showModal} setVisible={() => {
            setShowModal(false);
            fetchData({
                pageNum: 1,
                pageSize: PAGE_SIZE
            })
        }} />
        </Layout>
    </>);
};
