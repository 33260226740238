
/**
 * @file   藏品管理系统
 * @create macaiqi 2022/01/12
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import { localDS } from '../../../storage';
import ReactWOW from 'react-wow'
import cls from 'classnames'
import './style.scss'
import { handleFreeTrial } from '../../../utils/productUtils';

/**
 * @description 志愿者管理系统组件
 */
export const CollectionSystem = () => {
    const [index, setIndex] = useState(0)
    const [tabindex, setTabindex] = useState(1)
    const oDiv = useRef();
    const gnzc = useRef()
    const cptd = useRef()
    const { search } = useLocation()
    const [showTrialTip, setShowTrialTip] = useState(false);
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        setTabindex(anchorName)
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        }
    }
    useEffect(() => {
        // tab吸顶
        let divT = oDiv.current.offsetTop
        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

            if (scrollT >= divT) {
                if (window.navigator.userAgent.indexOf('MSIE 6.0') != -1) {
                    // 兼容IE6代码
                    // body[0].style.paddingTop = '50px'
                    oDiv.current.style.position = 'absolute';
                    oDiv.current.style.top = scrollT + 'px';
                    oDiv.current.style.left = 0 + 'px';
                } else {
                    // 正常浏览器代码
                    oDiv.current.style.position = 'fixed';
                    oDiv.current.style.top = 0;
                    oDiv.current.style.left = 0;
                }
                document.getElementById('content').style.paddingTop = 50 + 'px'
                for (let i = 0; i < tab.length; i++) {
                    if (scrollT >= document.getElementById(`${i + 1}`)?.offsetTop) {
                        document.getElementById(`link${i + 1}`).classList.add('active');
                        for (let j = 0; j < tab.length; j++) {
                            if (i !== j) {
                                document.getElementById(`link${j + 1}`).classList.remove('active');
                            }
                        }
                    }

                }
            } else {
                oDiv.current.style.position = '';
                document.getElementById('content').style.paddingTop = 0 + 'px'
                for (let j = 0; j < tab.length; j++) {
                    document.getElementById(`link${j + 1}`).classList.remove('active');
                }
            }

        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(search.split('?')[1])
        }
    }, [cptd, gnzc, oDiv, search]);
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    // 功能组成
    const gongneng = [
        {
            name: "藏品业务管理",
            tab: [],
            des: '为藏品的征集、登录、保管、流动、注销等全生命周期的业务流转和信息维护提供标准化、规范化管理，主要包含藏品征集、藏品入馆、藏品入藏、藏品编目、藏品上架、藏品提用、藏品出库、藏品入库、藏品盘核、藏品注销等业务功能。',
            icon: 'icona-404jiankongshexiangtou-xianxing'
        },
        {
            name: "藏品数字资源",
            tab: [],
            des: '对全馆/园区及各展厅的客流进行汇总统计，主要包括全馆客流量统计、展厅客流量统计、区域时段客流量统计。',
            icon: 'iconhuodongyuyueguanli'
        },
        {
            name: "藏品附属信息",
            tab: [],
            des: '提供藏品相关的附属信息管理维护，进而丰富藏品数据库，主要包括藏品鉴定信息、研究著录、展出记录、损坏记录、修复记录等方面。',
            icon: 'icona-fenxi1'
        },
        {
            name: "藏品数据统计",
            tab: [],
            des: '提供基于藏品账目、数量动态变化、提用出入库利用情况、数字资源利用率等维度的数据统计，主要包括藏品一张图、藏品账目统计、藏品变动统计、藏品出入库统计、藏品数字资源统计。',
            icon: 'iconshujutongji1'
        },
        {
            name: "藏品资源库",
            tab: [],
            des: '面向有藏品资源查阅、资源利用需求的用户开放藏品资源（如藏品信息、藏品数字资源），实现藏品信息与影像资源的共享，提供便捷、高效的检索，主要功能包括关键字检索、分类检索、查看详情、收藏、下载。',
            icon: 'iconzhanshi'
        },
        {
            name: "基础配置",
            tab: [],
            des: '提供支撑藏品管理系统业务正常运转所涉及的基础数据、全局参数配置维护等功能，主要包括藏品分类管理、库房方位管理、参数配置。',
            icon: 'iconpeizhi'
        },
    ]
    // 产品特点
    const tedian = [
        {
            name: '藏品生命周期业务全覆盖',
            des: '实现了藏品管理的全流程覆盖，支持对已有藏品数据的智能化导入，全方位提升藏品管理的便捷性和效率。',
            icon: 'iconyizhanshijiejuefangan',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '灵活的业务规则配置',
            des: '针对用户藏品管理的业务特性，可自由定制藏品管理工作流程以及藏品登录、查询各项指标，拥有强大的业务适应能力。',
            icon: 'iconshouji21',
            duration: '1s',
            delay: '0.7s'
        },
        {
            name: '开放式的藏品资源库',
            des: '基于授权控制的藏品资源开放共享，充分发挥藏品资源价值，实现资源最大化利用。',
            icon: 'iconlinghuoguanli',
            duration: '1s',
            delay: '0.9s'
        },
        {
            name: '多维的藏品数据可视化',
            des: '基于藏品数据分析模型，可视化呈现藏品家底，辅助管理与决策。',
            icon: 'iconcangpintongji',
            duration: '1s',
            delay: '0.9s'
        },
    ]

    return (<>
        <IntroHeader />
        <div className="activity-appointment collection" id='content'>
            <div className="banner">
                <ReactWOW animation="fadeInLeft" duration="1.2s" delay="0.1s">
                    <div className="content">
                        <p className="big">藏品管理系统</p>
                        <div className="cont">
                            以藏品管理业务流程为主线，实现藏品征集、入馆、入藏、编目、上架、提用、出库、入库、盘核、注销等全生命周期的数字化协同管理；通过对藏品相关信息的汇聚和分析，辅助各级管理和决策；提供藏品数据授权开放共享，支持藏品数据在陈列展览、教育、学术研究、文创等业务领域的应用。
                        </div>
                        <div className="btn">
                            {/* <div className="btn1" onClick={() => {
                                const token = localDS.get('saasToken')
                                if (!token) {
                                    window.location.href = "/accountLogin"
                                    return
                                }
                                let a = document.createElement("a"); // 创建一个<a></a>标签
                                a.href = `${window.location.origin}/pdf/volunteer.pdf`; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
                                a.download = "产品白皮书.pdf"; // 设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
                                a.style.display = "none"; // 障眼法藏起来a标签
                                document.body.appendChild(a); // 将a标签追加到文档对象中
                                a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                                a.remove(); // 一次性的，用完就删除a标签
                            }}>
                                产品白皮书
                            </div> */}
                            {/* <div className="btn1">
                                观看演示
                            </div> */}
                            <div
                                className="btn2"
                                onClick={handleFreeTrial}
                                onMouseOver={() => { setShowTrialTip(true) }}
                                onMouseLeave={() => { setShowTrialTip(false) }}
                            >
                                免费试用
                                {showTrialTip && <div className='hov'>! 注册并申请机构实名认证后即可免费试用</div>}

                            </div>
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="tab" ref={oDiv}>
                <ul>
                    {tab?.map((item, index) => {
                        return (
                            <li key={index}>
                                <span onClick={() => scrollToAnchor(index + 1)} id={`link${index + 1}`} >{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <div className="people" id='1'>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title" >用户群体</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <p className="txt">本产品适用于有文物、典籍、标本等藏品管理需求的中小型博物馆、美术馆、图书馆、文化馆、校史馆、陈列馆及其他有藏品管理需求的企事业单位。</p>
                </ReactWOW>
            </div>
            <div className="section2" id='2' ref={gnzc}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1.1s">
                    <p className="title" id='two'>功能组成</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1s">
                    <div className="functionof">
                        {gongneng.map((item, i) => {
                            return (
                                <div className={cls('item', { active: index === i })} onClick={() => { setIndex(i) }} key={i}>
                                    <div className="moren">
                                        <i className={cls('iconfont', item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        {item.tab.length > 6 ? <div className="txtbox">
                                            {item.tab.map((item1, i1) => {
                                                return (<span key={i1}>{item1}</span>)
                                            })}
                                        </div> : item.tab.map((item1, i1) => {
                                            return (<p className="txt" key={i1}>{item1}</p>)
                                        })}
                                    </div>
                                    <div className="activeitem">
                                        <p className='top'>
                                            <i className={cls('iconfont', item.icon)} />
                                            <span className='tip'>{item.name}</span>
                                        </p>
                                        <div className="cont">
                                            {item.des.includes("&&") ? item.des.split('&&').map((val, index2) => {
                                                return (
                                                    <p key={index2}>{val}</p>
                                                )
                                            }) : item.des}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </ReactWOW>
            </div>
            <div className="section3" id='3' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.5s">
                    <p className="title">产品特点</p>
                </ReactWOW>
                <div className="section3-box">
                    {tedian.map((item, index) => {
                        return (
                            <ReactWOW animation="fadeInUp-little" key={index} duration={item.duration} delay={item.delay}>
                                <div className="item">
                                    <div className="itembox">
                                        <div className="l">
                                            <i className={`iconfont ${item.icon}`} />
                                        </div>
                                        <div className="r">
                                            <p className="tip">{item.name}</p>
                                            <p className='txt'>{item.des}</p>
                                        </div>
                                    </div>
                                </div>
                            </ReactWOW>
                        )
                    })}
                </div>

            </div>
            <div className="section4" id='4' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title">产品价格</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <div className="box">
                        <p className="txt">
                            支持的数字资产相关文件存储最大存储空间≤1TB，每增加100GB，额外收费500元/年，依次递增。
                        </p>
                        <div className="price">
                            <div className="l">
                                <div className="red">
                                    <span>9800</span>
                                    元/年
                                </div>
                            </div>
                            {/* <div className="buy">立即购买</div> */}
                        </div>

                    </div>
                </ReactWOW>
            </div>
            <div className="update" id='5'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    {/* <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span> */}
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
