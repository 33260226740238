/**
 * @file   开票详情
 * @create tianmu 2021/4/22
 * @update tianmu 2021/4/22
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Layout, notification, Row } from 'antd';
import { evaluateRefund, queryCustomerOrderById, submitRefund } from '../../service';
import { searchStrToObj, toFixedNumStr } from '../../utils';
import { useHistory, useLocation } from 'react-router';
import './index.scss';
import { unitStr } from '../../utils/productUtils';

export const OrderUnsubscribe = (props) => {
    const location = useLocation();
    const history = useHistory();
    const o = useMemo(() => searchStrToObj(location.search), [location.search]);
    const [orderDetail, setOrderDetail] = useState(null);
    const [refundDict, setRefundDict] = useState(null);

    const fetchOrderInfo = useCallback(async (id) => {
        const res1 = await queryCustomerOrderById(id);
        const res2 = await evaluateRefund(id);
        setOrderDetail(res1.data.data);
        setRefundDict(res2.data.data);
    }, []);

    const fetchData = useCallback(() => {
        fetchOrderInfo(o?.orderId);
    }, [fetchOrderInfo, o]);

    // 退订金额
    const refundCost = useCallback((id) => refundDict?.[id] ? refundDict[id].price : 0, [refundDict]);
    // 退订数量
    const refundCount = useCallback((id) => refundDict?.[id] ? `${refundDict[id].quota}${unitStr(refundDict[id].unit)}` : '', [refundDict]);
    const refundCostAll = useMemo(
        () => {
            if (orderDetail?.kit) return 0;
            if (!refundDict) return 0;
            return Object.keys(refundDict).reduce((pre, cur) => pre + refundCost(cur), 0);
        },
        [refundDict, orderDetail, refundCost]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            <div className="page-header">
                <div className="title">退订申请</div>
            </div>
            <Alert
                message="因退订的产品、服务涉及费用问题，需评估和协商，申请退订成功后，24小时内会有工作人员联系您沟通费用相关问题，请保持手机畅通。费用沟通达成一致后，24小时之内费用会原路径退回。注意： 1.【退单申请】提交至【退单申请审批】完成，该时间段内可以继续使用，其延时损失由运营方承担，但不计入产品有效期； 2.【退单申请】的退款时长计算，以1自然日(24小时)为最小计量单位，超过24小时则计入下个自然日； 3.【退单申请】的退款起始时间，以【退单申请】提交后满足最小计量单位(24小时)规则的时间点为准。"
                type="info"
                showIcon
                style={{
                    marginBottom: 10
                }}
            />
            <Layout className="page-content order-detail">
                <div className="order-detail-info">
                    <h3
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            marginBottom: 10,
                            paddingBottom: 10,
                            borderBottom: '1px solid #ccc'
                        }}
                    >
                        订单详情
                        <span
                            onClick={() => {
                                history.goBack();
                            }}
                            style={{
                                float: 'right',
                                color: 'rgb(86, 192, 150)',
                                cursor: 'pointer',
                                fontSize: '16px'
                            }}
                        >
                            &lt; 返回
                        </span>
                    </h3>
                    <Row gutter={[0, 20]}>
                        <Col style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }} span={2}>
                            产品名称：
                        </Col>
                        <Col style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }} span={22}>
                            {orderDetail?.orderTitle}
                        </Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>机构名称：</Col>
                        <Col span={22}>{orderDetail?.companyName}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>提交时间：</Col>
                        <Col span={22}>{orderDetail?.createTime}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>电 话：</Col>
                        <Col span={22}>{orderDetail?.phoneNumber}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>金 额：</Col>
                        <Col style={{ color: 'red' }} span={22}>
                            ￥{toFixedNumStr(orderDetail?.orderCost)}
                        </Col>
                    </Row>
                </div>
                <h3
                    style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        marginBottom: 10,
                        paddingBottom: 10,
                        borderBottom: '1px solid #ccc'
                    }}
                >
                    订单产品
                </h3>
                {orderDetail?.kit ? (
                    <div style={{ fontSize: 16 }}>
                        <Row gutter={[0, 20]}>
                            <Col
                                style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }}
                                flex="150px"
                            >
                                套件名称：
                            </Col>
                            <Col
                                style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }}
                                flex="auto"
                            >
                                {orderDetail?.kit.kitName}
                            </Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col flex="150px">购买数量：</Col>
                            <Col flex="150px">{orderDetail?.kit.kitValue}套</Col>
                            <Col flex="150px">购买价格：</Col>
                            <Col flex="150px" style={{ color: 'red' }}>
                                ￥{toFixedNumStr(orderDetail?.kit.kitCost)}
                            </Col>
                        </Row>
                        <Row gutter={[0, 20]}>
                            <Col flex="150px">可退订数：</Col>
                            <Col flex="150px">
                                0
                            </Col>
                            <Col flex="150px">预估退订价格：</Col>
                            <Col flex="150px" style={{ color: 'red' }}>
                                ￥ 0.00
                            </Col>
                        </Row>
                        <Row gutter={[0, 20]} style={{ color: 'red' }}>
                            套件不可退订
                        </Row>

                    </div>
                ) : (
                    <div style={{ fontSize: 16 }}>
                    {Array.isArray(orderDetail?.productList) &&
                        (orderDetail?.productList).map((i) => (
                            <div
                                key={i.productId}
                                style={{ borderBottom: '1px dashed #ccc', marginTop: 10 }}
                            >
                                <Row gutter={[0, 20]}>
                                    <Col
                                        style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }}
                                        flex="150px"
                                    >
                                        产品名称：
                                    </Col>
                                    <Col
                                        style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }}
                                        flex="auto"
                                    >
                                        {i.productName}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col flex="150px">购买数量：</Col>
                                    <Col flex="150px">{i.productValue}{unitStr(i.productType)}</Col>
                                    <Col flex="150px">购买价格：</Col>
                                    <Col flex="150px" style={{ color: 'red' }}>
                                        ￥{toFixedNumStr(i.productCost)}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col  flex="150px">退订数量：</Col>
                                    <Col  flex="150px">
                                        {refundCount(i.productId)}
                                    </Col>
                                    <Col  flex="150px">预估退订价格：</Col>
                                    <Col  flex="150px" style={{ color: 'red' }}>
                                        ￥{toFixedNumStr(refundCost(i.productId))}
                                    </Col>
                                </Row>
                                {refundCost(i.productId) === 0 && (
                                    <Row gutter={[0, 20]} style={{ color: 'red' }}>
                                        该产品不可退订
                                    </Row>
                                )}
                            </div>
                        ))}
                    {Array.isArray(orderDetail?.serviceList) &&
                        (orderDetail?.serviceList).map((i) => (
                            <div
                                key={i.serviceId}
                                style={{ borderBottom: '1px dashed #ccc', marginTop: 10 }}
                            >
                                <Row gutter={[0, 20]}>
                                    <Col
                                        style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }}
                                        span={2}
                                    >
                                        服务名称：
                                    </Col>
                                    <Col
                                        style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }}
                                        span={22}
                                    >
                                        {i.serviceName}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col span={2}>购买数量：</Col>
                                    <Col span={2}>{i.serviceValue}次</Col>
                                    <Col span={2}>购买价格：</Col>
                                    <Col span={2} style={{ color: 'red' }}>
                                        ￥{toFixedNumStr(i.serviceCost)}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col span={2}>退订数量：</Col>
                                    <Col span={2}>{refundCount(i.serviceId)}</Col>
                                    <Col span={2}>退订价格：</Col>
                                    <Col span={2} style={{ color: 'red' }}>
                                        ￥{toFixedNumStr(refundCost(i.serviceId))}
                                    </Col>
                                </Row>
                                {refundCost(i.serviceId) === 0 && (
                                    <Row gutter={[0, 20]} style={{ color: 'red' }}>
                                        该服务不可退订
                                    </Row>
                                )}
                            </div>
                        ))}
                    {Array.isArray(orderDetail?.resourceList) &&
                        (orderDetail?.resourceList).map((i) => (
                            <div
                                key={i.resourceId}
                                style={{ borderBottom: '1px dashed #ccc', marginTop: 10 }}
                            >
                                <Row gutter={[0, 20]}>
                                    <Col
                                        style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }}
                                        span={2}
                                    >
                                        资源名称：
                                    </Col>
                                    <Col
                                        style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }}
                                        span={22}
                                    >
                                        {i.resourceName}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col span={2}>购买数量：</Col>
                                    <Col span={2}>
                                        {i.resourceValue}
                                        {unitStr(i.resourceType)}
                                    </Col>
                                    <Col span={2}>购买价格：</Col>
                                    <Col span={2} style={{ color: 'red' }}>
                                        ￥{toFixedNumStr(i.resourceCost)}
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]}>
                                    <Col span={2}>退订数量：</Col>
                                    <Col span={2}>
                                        0
                                    </Col>
                                    <Col span={2}>退订价格：</Col>
                                    <Col span={2} style={{ color: 'red' }}>
                                        ￥ 0.00
                                    </Col>
                                </Row>
                                <Row gutter={[0, 20]} style={{ color: 'red' }}>
                                    资源不可退订
                                </Row>

                            </div>
                        ))}
                </div>

                )}
                <div className="order-form-footer">
                    <Button
                        className="order-form-footer-btns"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        返回
                    </Button>
                    <Button
                        className="order-form-footer-btns"
                        type="primary"
                        // 退订金额为0时表示不能退订
                        // 套件订单不能退订
                        disabled={orderDetail?.kit || !refundCostAll}
                        onClick={() => {
                            submitRefund(orderDetail?.orderId).then((res) => {
                                if (res.data.code === 200) {
                                    notification.success({
                                        message: '退订申请成功！',
                                        className: 'custom-antd-notification',
                                        duration: 5
                                    });
                                    history.push(
                                        `/MyOrder/UnsubscribeSuccess?orderId=${orderDetail?.orderId}&refundCost=${toFixedNumStr(refundCostAll)}`
                                    );
                                } else {
                                    notification.warn({
                                        message: res.data.msg,
                                        className: 'custom-antd-notification',
                                        duration: 5
                                    });
                                }
                            });
                        }}
                    >
                        确认退订
                    </Button>
                    <div style={{ float: 'right', marginRight: 20 }}>
                        <span>预估退订总价格：</span>
                        <span
                            style={{
                                color: 'red',
                                fontSize: 18,
                                fontWeight: 'bold'
                            }}
                        >
                            ￥
                        </span>
                        <span
                            style={{
                                color: 'red',
                                fontSize: 24
                            }}
                        >
                            {toFixedNumStr(refundCostAll)}
                        </span>
                    </div>
                </div>
            </Layout>
        </>
    );
};
