
/**
 * @file   特展预约
 * @create macaiqi 2022/01/14
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import { localDS } from '../../../storage';
import ReactWOW from 'react-wow'
import cls from 'classnames'
import './style.scss'
import { handleFreeTrial } from '../../../utils/productUtils';

/**
 * @description 特展预约系统组件
 */
export const ShowBooking = () => {
    const [index, setIndex] = useState(0)
    const [tabindex, setTabindex] = useState(1)
    const oDiv = useRef();
    const gnzc = useRef()
    const cptd = useRef()
    const { search } = useLocation()
    const [showTrialTip, setShowTrialTip] = useState(false);
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        setTabindex(anchorName)
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        }
    }
    useEffect(() => {
        // tab吸顶
        let divT = oDiv.current.offsetTop
        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if (scrollT >= divT) {
                if (window.navigator.userAgent.indexOf('MSIE 6.0') != -1) {
                    // 兼容IE6代码
                    // body[0].style.paddingTop = '50px'
                    oDiv.current.style.position = 'absolute';
                    oDiv.current.style.top = scrollT + 'px';
                    oDiv.current.style.left = 0 + 'px';
                } else {
                    // 正常浏览器代码
                    oDiv.current.style.position = 'fixed';
                    oDiv.current.style.top = 0;
                    oDiv.current.style.left = 0;
                }
                document.getElementById('content').style.paddingTop = 50 + 'px'
                for (let i = 0; i < tab.length; i++) {
                    if (scrollT >= document.getElementById(`${i + 1}`)?.offsetTop) {
                        document.getElementById(`link${i + 1}`).classList.add('active');
                        for (let j = 0; j < tab.length; j++) {
                            if (i !== j) {
                                document.getElementById(`link${j + 1}`).classList.remove('active');
                            }
                        }
                    }

                }
            } else {
                oDiv.current.style.position = '';
                document.getElementById('content').style.paddingTop = 0 + 'px'
                for (let j = 0; j < tab.length; j++) {
                    document.getElementById(`link${j + 1}`).classList.remove('active');
                }
            }

        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(search.split('?')[1])
        }
    }, [cptd, gnzc, oDiv, search]);
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    // 功能组成
    const gongneng = [
        {
            name: "特展管理",
            tab: ['预约规则配置', '特展发布', '预约观众档案管理', '数据统计', '数据统计'],
            des: '为场馆工作人员与管理者提供特展的管理与发布，实现统一的预约观众档案管理、精准的数据统计分析等，通过建立观众数据模型，不断优化特展选题、提高特展质量和成效，为特展的策划和举办提供精准的分析数据。主要功能包括：预约规则配置、特展发布、预约观众档案管理、数据统计和消息模板管理。',
            icon: 'i1'
        },
        {
            name: "特展预约",
            tab: ['注册', '登录', '特展展示', '预约', '用户中心'],
            des: '为观众提供便捷的特展预约服务，在微信小程序端最大化地为观众观看特展提供方便，让预约省时、省力、更省心。主要功能包括注册、登录、特展展示、预约和用户中心。',
            icon: 'i2'
        },
        {
            name: "特展核销",
            tab: ['票务核销'],
            des: '支持观众用微信自主扫描展厅入口的核销二维码打开订单核销页面，由工作人员确认观众点击【核销】按钮的操作过程，即可进行票务核销。如果预约订单有多个同行人，预约人可核销所有出行人订单，其同行人仅能核销本人订单。',
            icon: 'i3'
        },

    ]
    // 产品特点
    const tedian = [
        {
            name: '灵活的预约规则配置',
            des: '提供可预约人数和退订规则等预约规则的灵活配置，适应各类公共文化场馆的特展预约服务需求。',
            icon: 'icon1',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '便捷的移动预约支持',
            des: '提供基于移动端（微信小程序）的预约服务，让公众随时随地高效预约。',
            icon: 'iconfont iconshouji21',
            duration: '1s',
            delay: '0.7s'
        },
        {
            name: '轻量化的验票方式',
            des: '支持观众在移动端自行核销，用户无需采购任何验票设备，免除繁杂的硬件选型、安装、调试、对接过程。',
            icon: 'icon3',
            duration: '1s',
            delay: '0.9s'
        },
        {
            name: '多维度观众统计分析',
            des: '建立观众画像，提供特展预约、入厅、营收等多维度统计分析，为优化特展选题、提升特展质量提供决策依据。',
            icon: 'icon4',
            duration: '1s',
            delay: '1.1s'
        },
    ]

    return (<>
        <IntroHeader />
        <div className="activity-appointment booking" id='content'>
            <div className="banner">
                <ReactWOW animation="fadeInLeft" duration="1.2s" delay="0.1s">
                    <div className="content">
                        <p className="big">特展预约系统</p>
                        <div className="cont">
                            为管理人员提供灵活的预约规则配置，实现特展发布、预约、核销等全流程的标准化、信息化管理；支持观众通过微信小程序完成在线预约，并在进入展厅时快速通过核验流程；提供丰富的特展数据统计功能，有力支撑特展智慧化管理、运营与分析决策。
                        </div>
                        <div className="btn">
                            {/* <div className="btn1" onClick={() => {
                                const token = localDS.get('saasToken')
                                if (!token) {
                                    window.location.href = "/accountLogin"
                                    return
                                }
                                let a = document.createElement("a"); // 创建一个<a></a>标签
                                a.href = `${window.location.origin}/pdf/Showbooking.pdf`; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
                                a.download = "产品白皮书.pdf"; // 设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
                                a.style.display = "none"; // 障眼法藏起来a标签
                                document.body.appendChild(a); // 将a标签追加到文档对象中
                                a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                                a.remove(); // 一次性的，用完就删除a标签
                            }}>
                                产品白皮书
                            </div> */}
                            {/* <div className="btn1">
                                观看演示
                            </div> */}
                            <div
                                className="btn2"
                                onClick={handleFreeTrial}
                                onMouseOver={() => { setShowTrialTip(true) }}
                                onMouseLeave={() => { setShowTrialTip(false) }}
                            >
                                免费试用
                                {showTrialTip && <div className='hov'>! 注册并申请机构实名认证后即可免费试用</div>}
                            </div>
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="tab" ref={oDiv}>
                <ul>
                    {tab?.map((item, index) => {
                        return (
                            <li key={index}>
                                <span onClick={() => scrollToAnchor(index + 1)} id={`link${index + 1}`}>{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <div className="people" id='1'>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title" >用户群体</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <p className="txt">本产品适用于对特展预约有需求的公共文化场馆、文旅景区的开放部、信息部、陈列设计部、宣传推广部及相关工作人员。</p>
                </ReactWOW>
            </div>
            <div className="section2" id='2' ref={gnzc}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1.1s">
                    <p className="title" id='two'>功能组成</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1s">
                    <div className="functionof">
                        {gongneng.map((item, i) => {
                            return (
                                <div className={cls('item', { active: index === i })} onClick={() => { setIndex(i) }} key={i}>
                                    <div className="moren">
                                        <i className={cls(item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        {item.tab.map((item1, i1) => {
                                            return (<p className="txt" key={i1}>{item1}</p>)
                                        })}
                                    </div>
                                    <div className="activeitem">
                                        <p className='top'>
                                            <i className={cls(item.icon)} />
                                            <span className='tip'>{item.name}</span>
                                        </p>
                                        <div className="cont">
                                            {item.des}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </ReactWOW>
            </div>
            <div className="section3" id='3' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.5s">
                    <p className="title">产品特点</p>
                </ReactWOW>
                <div className="section3-box">
                    {tedian.map((item, index) => {
                        return (
                            <ReactWOW animation="fadeInUp-little" key={index} duration={item.duration} delay={item.delay}>
                                <div className="item">
                                    <div className="itembox">
                                        <div className="l">
                                            <i className={item.icon} />
                                        </div>
                                        <div className="r">
                                            <p className="tip">{item.name}</p>
                                            <p className='txt'>{item.des}</p>
                                        </div>
                                    </div>
                                </div>
                            </ReactWOW>
                        )
                    })}
                </div>

            </div>
            <div className="section4" id='4' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title">产品价格</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <div className="box">
                        <div className="price">
                            <div className="l">
                                <div className="red">
                                    <i className='iconfont icona-31-jiage1' />
                                    <span>3800</span>
                                    元/年
                                </div>
                                {/* <span>首年免费</span> */}

                            </div>
                            {/* <div className="buy">立即购买</div> */}
                        </div>

                    </div>
                </ReactWOW>
            </div>
            <div className="update" id='5'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    {/* <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span> */}
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
