
/**
 * @file   活动预约系统介绍页面
 * @create tianmu 2021/2/25
 * @update tianmu 2021/2/25
 */
import React, { useState, useRef, useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import { localDS } from '../../../storage';
import ReactWOW from 'react-wow'
import cls from 'classnames'
import './style.scss'
import { handleFreeTrial } from '../../../utils/productUtils';

/**
 * @description 活动预约系统组件
 */
export const ActivityAppointment = () => {
    const [index, setIndex] = useState(1)
    const [tabindex, setTabindex] = useState(1)
    const oDiv = useRef();
    const gnzc = useRef()
    const cptd = useRef()
    const { search } = useLocation()
    const [showTrialTip, setShowTrialTip] = useState(false);

    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        setTabindex(anchorName)
        if (anchorName) {
            // 找到锚点
            let anchorElement = document.getElementById(anchorName);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        }
    }
    useEffect(() => {
        // tab吸顶
        let divT = oDiv.current.offsetTop
        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop

            if (scrollT >= divT) {
                if (window.navigator.userAgent.indexOf('MSIE 6.0') != -1) {
                    // 兼容IE6代码
                    // body[0].style.paddingTop = '50px'
                    oDiv.current.style.position = 'absolute';
                    oDiv.current.style.top = scrollT + 'px';
                    oDiv.current.style.left = 0 + 'px';
                } else {
                    // 正常浏览器代码
                    oDiv.current.style.position = 'fixed';
                    oDiv.current.style.top = 0;
                    oDiv.current.style.left = 0;
                }
                document.getElementById('content').style.paddingTop = 50 + 'px'
                for (let i = 0; i < tab.length; i++) {
                    if (scrollT >= document.getElementById(`${i + 1}`)?.offsetTop) {
                        document.getElementById(`link${i + 1}`).classList.add('active');
                        for (let j = 0; j < tab.length; j++) {
                            if (i !== j) {
                                document.getElementById(`link${j + 1}`).classList.remove('active');
                            }
                        }
                    }

                }
            } else {
                oDiv.current.style.position = '';
                document.getElementById('content').style.paddingTop = 0 + 'px'
                for (let j = 0; j < tab.length; j++) {
                    document.getElementById(`link${j + 1}`).classList.remove('active');
                }
            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(search.split('?')[1])
        }
    }, [cptd, gnzc, oDiv, search]);
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']

    return (<>
        <IntroHeader />
        <div className="activity-appointment" id='content'>
            <div className="banner activity">
                <ReactWOW animation="fadeInLeft" duration="1.2s" delay="0.1s">
                    <div className="content">
                        <p className="big">活动预约系统</p>
                        <div className="cont">以学术讲座、研学交流及社教课程等活动管理业务流程为主线，实现活动发布、预约报名、活动签到、数据统计等全流程的标准化、数字化、智慧化管理。</div>
                        <div className="btn">
                            {/* <div className="btn1" onClick={() => {
                                const token = localDS.get('saasToken')
                                if (!token) {
                                    window.location.href = "/accountLogin"
                                    return
                                }
                                let a = document.createElement("a"); // 创建一个<a></a>标签
                                a.href = `${window.location.origin}/pdf/activity.pdf`; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
                                a.download = "产品白皮书.pdf"; // 设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
                                a.style.display = "none"; // 障眼法藏起来a标签
                                document.body.appendChild(a); // 将a标签追加到文档对象中
                                a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
                                a.remove(); // 一次性的，用完就删除a标签
                            }}>
                                产品白皮书
                            </div> */}
                            {/* <div className="btn1">
                                观看演示
                            </div> */}
                            <div
                                className="btn2"
                                onClick={handleFreeTrial}
                                onMouseOver={() => { setShowTrialTip(true) }}
                                onMouseLeave={() => { setShowTrialTip(false) }}
                            >
                                免费试用
                                {showTrialTip && <div className='hov'>! 注册并申请机构实名认证后即可免费试用</div>}
                            </div>
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="tab" ref={oDiv}>
                <ul>
                    {tab?.map((item, index) => {
                        return (
                            <li key={index}>
                                <span onClick={() => scrollToAnchor(index + 1)} id={`link${index + 1}`} >{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <div className="people" id='1'>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title" >用户群体</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <p className="txt">本产品适用于对学术讲座、研学交流及社教课程等活动预约有需求的公共文化场馆、文旅景区的社教、宣传部门及相关工作人员。</p>
                </ReactWOW>
            </div>
            <div className="section2" ref={gnzc} id='2'>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1.1s">
                    <p className="title" >功能组成</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1s">
                    <div className="functionof activity">
                        <div className={cls('item', { active: index === 1 })} onClick={() => { setIndex(1) }}>
                            <div className="moren">
                                <i className='i1' />
                                <p className='tip'>活动管理</p>
                                <p className="txt">活动发布</p>
                                <p className="txt">预约观众档案管理</p>
                                <p className="txt">数据统计</p>
                            </div>
                            <div className="activeitem">
                                <p className='top'>
                                    <i className='i1' />
                                    <span className='tip'>活动管理</span>
                                </p>
                                <div className="cont">
                                    为场馆或景区工作人员与管理者提供不同类型活动的管理与发布，实现统一的预约观众档案管理、精准的数据统计分析等，通过建立观众数据模型，不断提高活动质量和成效，为活动的策划和举办提供精准的分析数据。主要功能包括：活动发布、预约观众档案管理、数据统计。
                                </div>
                            </div>
                        </div>
                        <div className={cls('item', { active: index === 2 })} onClick={() => { setIndex(2) }}>
                            <div className="moren">
                                <i className='i2' />
                                <p className='tip'>活动预约</p>
                                <p className="txt">微信小程序预约</p>
                                <p className="txt">Web端预约</p>
                            </div>
                            <div className="activeitem">
                                <p className='top'>
                                    <i className='i2' />
                                    <span className='tip'>活动预约</span>
                                </p>
                                <div className="cont">
                                    为观众提供便捷的活动预约服务，支持在微信小程序端、Web端进行预约，
                                    最大化地为观众参与活动提供方便，让预约省时、省力、更省心。主要功能包括微信小程序预约和Web端预约。
                                </div>
                            </div>
                        </div>
                        <div className={cls('item', { active: index === 3 })} onClick={() => { setIndex(3) }}>
                            <div className="moren">
                                <i className='i4' />
                                <p className='tip'>活动签到</p>
                                <p className="txt">扫码签到</p>
                                <p className="txt">自助签到</p>
                            </div>
                            <div className="activeitem">
                                <p className='top'>
                                    <i className='i4' />
                                    <span className='tip'>活动签到</span>
                                </p>
                                <div className="cont">
                                    通过观众主动扫码或出示二维码两种方式，实现高效的活动签到核验机制，方便已预约的公众快速完成现场签到并参加活动。主要包括扫码签到和自助签到。
                                </div>
                            </div>

                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="section3" id='3' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.5s">
                    <p className="title">产品特点</p>
                </ReactWOW>
                <div className="section3-box">
                    <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.5s">
                        <div className="item">
                            <div className="itembox">
                                <div className="l">
                                    <i className='icon5' />
                                </div>
                                <div className="r">
                                    <p className="tip">适应多类型活动管理</p>
                                    <p className='txt'>可适应学术讲座、研学交流及社教课程等不同类型活动的全流程管理。</p>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.7s">
                        <div className="item">
                            <div className="itembox">
                                <div className="l">
                                    <i className='icon2' />
                                </div>
                                <div className="r">
                                    <p className="tip">全面的预约终端支持</p>
                                    <p className='txt'>在移动端（微信小程序）和Web端均提供入口，让公众预约便捷高效。</p>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp-little" duration="1s" delay="0.9s">
                        <div className="item">
                            <div className="itembox">
                                <div className="l">
                                    <i className='icon3' />
                                </div>
                                <div className="r">
                                    <p className="tip">快捷多样的验票方式</p>
                                    <p className='txt'>支持扫码签到、自助签到等多种验票方式，方便观众快速签到并参加活动。</p>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp-little" duration="1s" delay="1.1s">
                        <div className="item">
                            <div className="itembox">
                                <div className="l">
                                    <i className='icon4' />
                                </div>
                                <div className="r">
                                    <p className="tip">多维度观众统计分析</p>
                                    <p className='txt'>建立观众画像，提供活动预约、签到、营收等多维度统计分析，为提升活动质量提供决策依据。</p>
                                </div>
                            </div>
                        </div>
                    </ReactWOW>
                </div>

            </div>
            <div className="section4" id='4' ref={cptd}>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.5s">
                    <p className="title">产品价格</p>
                </ReactWOW>
                <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.8s">
                    <div className="box">
                        <div className="price">
                            <div className="l">
                                <div className="red">
                                    <span>3800</span>
                                    元/年
                                </div>
                                {/* <span>首年免费</span> */}

                            </div>
                            {/* <div className="buy">立即购买</div> */}
                        </div>

                    </div>
                </ReactWOW>
            </div>
            <div className="update" id='5'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span>
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
