import React, { useEffect, useState } from 'react';
import Viewer from 'react-viewer';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { getFileById, queryAuthCompanyByIdForCustomer } from '../../service';
import OrgauthAuthedIcon from '../../assets/orgauth-authed.png';
import OrgauthRejectIcon from '../../assets/orgauth-reject.png';
import { AppFooter } from '../../containers/AppFooter';
import { searchStrToObj } from '../../utils';
import { AppHeader } from '../../containers/AppHeader';

const { Content } = Layout;
// 图片预览
// const filePreviewPrefix = process.env.ENV_FILE_PREVIEW;
const filePreviewPrefix = window.FILE_PREVIEW;

// TODO: 目前暂时只有营业执照
const licenseTypeStr = (type) => {
    switch (type) {
        case 1:
            return '机构营业执照';
        case 2:
            return '组织机构代码证';
        case 3:
            return '事业单位法人证书';
        case 4:
            return '社会团体法人证书';
        case 5:
            return '行政执法主体资格证';
        case 6:
            return '其他'
        default:
            return '';
    }
};

const InfoCard = (props) => {
    const { title, info, extra } = props;
    const [imageVisible, setImageVisible] = useState(false);
    return (info ?
        <div>
            <fieldset className="common-fieldset">
                <legend>{title}</legend>
                {extra && (
                    <div className="extra-operations">
                        {extra}
                    </div>
                )}

                <div className="info-row">
                    <div className="info-label">您的身份：</div>
                    <div className="info-value">{
                        info.identity === 1 ? '法人'
                        : info.identity === 2 ? '被授权人'
                        : ""}
                    </div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构名称：</div>
                    <div className="info-value">{info.companyName}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构编号：</div>
                    <div className="info-value">{info.companyCode}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">法人姓名：</div>
                    <div className="info-value">{info.contact}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">法定代表人身份证号：</div>
                    <div className="info-value">{info.identityCard.replace(/^(.{6})(?:\d+)(.{4})$/,  "$1****$2")}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构证件类型：</div>
                    <div className="info-value">{licenseTypeStr(info.licenseType)}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构证件附件：</div>
                    {/* <div className="info-value">{props.identfile}</div> */}
                    <div className="info-value">
                        <img
                            onClick={() => setImageVisible(true)}
                            src={info.thumb}
                            alt="certificate"
                        />
                        <Viewer
                            visible={imageVisible}
                            onClose={() => {
                                setImageVisible(false);
                            }}
                            images={[{ src: info.thumb, alt: '' }]}
                        />
                    </div>
                </div>
            </fieldset>
        </div> : null
    )
}

// eslint-disable-next-line complexity
export const OrgNoticeInfo = () => {
    const location = useLocation();
    const history = useHistory();
    const [info, setInfo] = useState([]);
    useEffect(() => {
        const o = searchStrToObj(location.search);
        const fetchInfo = async () => {
            let res = await queryAuthCompanyByIdForCustomer(o?.businessId);
            let t = res?.data?.data;
            if (t) {
                if (t.license) {
                    res = await getFileById(t.license);
                    const file = res.data.data;
                    if (file && file.filePath && file.fileName) {
                        t.thumb = filePreviewPrefix + file.filePath + file.fileName;
                    }
                }
            }
            setInfo(t);
        }
        fetchInfo()
    }, [location.search])
    /**
     * infos 是一个数组
     * 只有一个对象的情况下表示只提交过一次申请
     * 有两个对象时第一个对象表示当前变更申请，第二个表示之前的变更申请
     *
     * approveStatus：
     * 1 审核中
     * 2 通过
     * 3 拒绝
     * 4 撤销
     */
    // 审核通过
    const authed = info.approveStatus === 2;
    // 第一次申请，审核拒绝
    const reject = info.approveStatus === 3;

    return (
    <Layout
        className="app"
        style={{
            background: 'rgb(241, 242, 246)'
        }}
    >
        <AppHeader />
            <Content
                className="layout-middle-content layout-middle-content-w"
            >
                <div className="orgauthinfobox">
                    {authed && (<>
                        <div className="head">
                            <img src={OrgauthAuthedIcon} alt="" />
                            <div className="infobox">
                                <h1>恭喜！您已完成机构实名认证</h1>
                                <span
                                    onClick={() => {
                                        history.goBack();
                                    }}
                                    style={{
                                        color: 'rgb(86, 192, 150)',
                                        cursor: 'pointer',
                                        fontSize: '16px'
                                    }}
                                >
                                    &lt; 返回
                                </span>
                            </div>
                        </div>
                        {info.approveOpinion && (
                            <h3
                                style={{
                                    margin: '20px 0'
                                }}
                            >
                                审批意见：{info.approveOpinion}
                            </h3>
                        )}
                        {info.approveTime && (
                            <h3
                                style={{
                                    margin: '20px 0'
                                }}
                            >
                                审批时间：{info.approveTime}
                            </h3>

                        )}
                        <InfoCard
                            title="认证信息"
                            info={info}
                        />
                    </>)}
                    {reject && (<>
                        <div className="head">
                            <img src={OrgauthRejectIcon} alt="" />
                            <div className="infobox">
                                <h1>您申请的机构认证信息被拒绝！</h1>
                            </div>
                        </div>
                        {info.approveOpinion && (
                            <h3
                                style={{
                                    margin: '20px 0'
                                }}
                            >
                                拒绝原因：{info.approveOpinion}
                            </h3>
                        )}

                        {info.approveTime && (
                            <h3
                                style={{
                                    margin: '20px 0'
                                }}
                            >
                                审批时间：{info.approveTime}
                            </h3>

                        )}

                        <InfoCard
                            title="拒绝的认证信息"
                            info={info}
                        />
                    </>)}
                </div>
            </Content>
        <AppFooter />
    </Layout>

    );
};
