/**
 * @file   修改密码表单模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/23
 */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Input, Form, Button, notification } from 'antd';
import { querySmsVCode, changePwd, queryUserInfo } from '../../service';

import { validator } from '../../utils';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../login/store';
import { UserOutlined, MobileOutlined, LockOutlined } from '@ant-design/icons';

// 发送cd时间
const TIME_LIMIT = 60;
// 密码强度
const STRONG_PWD = 2;
const MIDDLE_PWD = 1;
const WEAK_PWD = 0;
const NONE_PWD = 3;
const PWD_TIP = '请至少使用大小写字母、数字、符号两种类型组合的密码，长度至少为8位。';
const Tip = (status) => {
    switch (status) {
        case STRONG_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(153, 204, 153)' }}>当前密码强度：强</i>
                </span>
            );
        case MIDDLE_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(255, 180, 64)' }}>当前密码强度：中等</i>
                </span>
            );
        case WEAK_PWD:
            return (
                <span>
                    {PWD_TIP}
                    <i style={{ color: 'rgb(255, 43, 77)' }}>当前密码强度：弱</i>
                </span>
            );
        default:
            return PWD_TIP;
    }
};

// 验证密码强度
const validatePassword = (pwd) => {
    // 空输入框
    if (!pwd)
        return {
            status: NONE_PWD,
            msg: Tip(NONE_PWD)
        };

    // 密码为八位及以上并且大小写字母数字特殊字符三项都包括
    const strongRegex = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/g;
    // 密码为八位及以上并且大小写字母、数字、特殊字符三项中有两项，强度是中等
    const mediumRegex = /^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*\W))|((?=.*[0-9])(?=.*\W))|((?=.*[A-Z])(?=.*\W))).*$/g;
    const enoughRegex = /(?=.{8,}).*/g;
    if (strongRegex.test(pwd)) {
        // console.log('强密码-----',value)
        return {
            status: STRONG_PWD,
            msg: Tip(STRONG_PWD)
        };
    } else if (mediumRegex.test(pwd)) {
        // console.log('中等密码-----',value)
        return {
            status: MIDDLE_PWD,
            msg: Tip(MIDDLE_PWD)
        };
    } else if (enoughRegex.test(pwd)) {
        // console.log('弱密码-----',value)
        return {
            status: WEAK_PWD,
            msg: Tip(WEAK_PWD)
        };
    } else {
        // console.log('密码-----',value)
        return {
            status: WEAK_PWD,
            msg: Tip(WEAK_PWD)
        };
    }
};

const MACHINE_AUTH_VID = process.env.ENV_MACHINE_AUTH_VID

/**
 * @description 修改密码表单
 */
export const ChangePwdForm = (props) => {
    const { isCustomer } = props;
    const [loading, setLoading] = useState(false);
    const [isSending, setSending] = useState(false);
    // const [isAuth, setAuth] = useState(false);
    const [time, setTime] = useState(TIME_LIMIT);
    const ref = useRef(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const [pwdStatus, setPwdStatus] = useState({
        validateStatus: null,
        value: '',
        msg: PWD_TIP
    });

    const onPwdChange = (e) => {
        const validateInfo = validatePassword(e.target.value);
        const isOk = validateInfo.status !== WEAK_PWD && validateInfo.status !== NONE_PWD;
        // console.log("validate: ", validateInfo)
        setPwdStatus({
            validateStatus: isOk ? 'success' : 'error',
            value: validateInfo.value,
            msg: validateInfo.msg
        });
    };

    // 获取手机验证码
    const query = useCallback(async (phone) => {
        const res = await querySmsVCode(phone, 5);
        // 根据返回的错误代码显示错误信息
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '验证码发送成功，请注意查收！',
                duration: 5
            });
            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return false;
        }
    }, []);

    // 处理点击获取验证码按钮事件
    const handleClick = useCallback(
        async (phone) => {
            if (isSending) return;
            setSending(true);
            const success = await query(phone);
            // 如果请求验证码不成功直接返回
            if (!success) {
                setSending(false);
                return;
            }
            ref.current = setInterval(() => {
                setTime((time) => {
                    const left = time - 1;
                    // 点击验证码之后TIME_LIMIT秒
                    if (left === 0) {
                        setTime(TIME_LIMIT);
                        window.clearInterval(ref.current);
                        setSending(left > 0);
                    }
                    return left;
                });
            }, 1000);
        },
        [query, isSending]
    );

    useEffect(() => {
        // window
        //     .vaptcha({
        //         vid: MACHINE_AUTH_VID, // 验证单元id
        //         type: 'click', // 显示类型 点击式
        //         scene: 0, // 场景值 默认0
        //         container: '#vaptchaContainer', // 容器，可为Element 或者 selector
        //         offline_server: '' // 离线模式服务端地址，若尚未配置离线模式，请填写任意地址即可。
        //         // 可选参数
        //         // lang: 'auto', // 语言 默认auto,可选值auto,zh-CN,en,zh-TW,jp
        //         // https: true, // 使用https 默认 true
        //         // style: 'dark' //按钮样式 默认dark，可选值 dark,light
        //         // color: '#57ABFF' //按钮颜色 默认值#57ABFF
        //     })
        //     .then(function (vaptchaObj) {
        //         // const obj = vaptchaObj; // 将VAPTCHA验证实例保存到局部变量中
        //         vaptchaObj.render(); // 调用验证实例 vpObj 的 render 方法加载验证按钮
        //         // 获取token的方式一：
        //         // vaptchaObj.renderTokenInput('.login-form')//以form的方式提交数据时，使用此函数向表单添加token值
        //         // 获取token的方式二：
        //         vaptchaObj.listen('pass', function () {
        //             // 验证成功进行后续操作
        //             setAuth(vaptchaObj.getToken());
        //             // eslint-disable-next-line max-nested-callbacks
        //             setTimeout(() => {
        //                 // vaptcha验证 3 分钟后失效
        //                 setAuth(false)
        //             }, 1000 * 60 * 3)
        //         });
        //         // 关闭验证弹窗时触发
        //         vaptchaObj.listen('close', function () {
        //             // 验证弹窗关闭触发
        //         });
        //     });

        if (isCustomer) {
            // 已登录SaaS用户修改密码，直接查询customer手机信息
            queryUserInfo().then(res => {
                if (res.data.code === 200) {
                    form.setFieldsValue({
                        phone: res.data.data?.customerPhone
                    })
                }
            })
        }

        // return () => {
        //     // 销毁组件时如果存在定时器求删除它
        //     if (ref.current) {
        //         window.clearInterval(ref.current);
        //     }
        // };
    }, [form, isCustomer]);
    // 表单提交成功
    const onFinish = (values) => {
        if (!values.password) {
            setPwdStatus({
                validateStatus: 'error',
                value: '',
                msg: '请输入密码！'
            });
            return;
        }
        if (pwdStatus.validateStatus === 'error') {
            // 密码验证未通过
            return;
        }
        setLoading(true);
        changePwd({
            phone: values.phone,
            svCode: values.svCode,
            password: values.password,
        }).then((res) => {
            if (res.data.code === 200) {
                // 修改密码成功显示提示信息
                notification.success({
                    className: 'custom-antd-notification',
                    message: "密码修改成功，请重新登录！",
                    duration: 5,
                })
                dispatch(logout());
                history.push('/accountLogin');
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: res.data.msg,
                    duration: 5,
                })
                // notification.warn({
                //     message: "请输入正确的验证码！",
                //     duration: 5,
                // })
            }
            setLoading(false)
        }).catch((res) => {
            notification.warn({
                className: 'custom-antd-notification',
                message: "密码修改失败",
                duration: 5,
            })
            setLoading(false)
        })
    };
    // 表单提交失败
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item
                name="phone"
                rules={[
                    {
                        required: true,
                        pattern: validator().phoneNumRegex,
                        message: '请输入正确的手机号!'
                    }
                ]}
                wrapperCol={{ offset: 4, span: 16 }}
            >
                <Input
                    disabled={isCustomer}
                    placeholder="手机号"
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                />
            </Form.Item>
            {/* <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <div id="vaptchaContainer" style={{ width: '100%', height: 40 }}>
                    <div className="vaptcha-init-main">
                        <div
                            className="vaptcha-init-loading"
                            style={{ textAlign: 'center', lineHeight: '40px' }}
                        >
                            <img
                                src="https://r.vaptcha.net/public/img/vaptcha-loading.gif"
                                alt="vaptcha"
                            />

                            <span className="vaptcha-text" style={{ paddingLeft: '5px' }}>
                                正在生成验证...
                            </span>
                        </div>
                    </div>
                </div>
            </Form.Item> */}
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Form.Item
                    name="svCode"
                    rules={[
                        {
                            required: true,
                            message: '验证码不能为空!'
                        },
                        {
                            len: 6,
                            message: '验证码长度为6位！'
                        }
                    ]}
                    noStyle
                >
                    <Input
                        placeholder="验证码"
                        size="large"
                        className="sendcodeinput"
                        autoComplete={false}
                        prefix={<MobileOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>
                <Button
                    size="large"
                    className={`sendcodebtn ${isSending
                        // || !isAuth
                        ? '' : 'active'}`}
                    disabled={isSending
                        // || !isAuth
                    }
                    onClick={() => {
                        console.log('click');
                        const phone = form.getFieldValue('phone');
                        // 手机号不为空时处理验证码获取事件
                        if (phone) {
                            handleClick(phone);
                        } else {
                            notification.info({
                                className: 'custom-antd-notification',
                                message: '请先输入手机号',
                                duration: 5
                            });
                        }
                    }}
                >
                    {isSending ? `${time}秒后重新发送` : '发送验证码'}
                </Button>
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
                wrapperCol={{ offset: 4, span: 16 }}
                validateStatus={pwdStatus.validateStatus}
                help={pwdStatus.msg}
            >
                <Input.Password
                    placeholder="新密码"
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    value={pwdStatus.value}
                    onChange={onPwdChange}
                />
            </Form.Item>
            <Form.Item
                name="confirm"
                dependencies={['password']}
                wrapperCol={{ offset: 4, span: 16 }}
                rules={[
                    {
                        required: true,
                        message: '请确认你的密码!'
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            // 如果两次密码输入相同通过校验
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error('两次输入密码不同!'));
                        }
                    })
                ]}
            >
                <Input.Password
                    type="password"
                    placeholder="重新输入新密码"
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button
                // disabled={!isAuth}
                type="primary" block size="large" htmlType="submit" loading={loading}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    );
};
