/**
 * @file   本地存储模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20
 */

 // 存储类
export default class DataStorage {
    /**
     * @description 构造函数
     * @param {Object} engine localStorage
     */
  constructor(engine) {
    const isDOMEngine = engine instanceof Storage;
    if (isDOMEngine) {
      this.engine = engine;
    } else {
      throw Error('engine 🛰🛰🛰');
    }
  }

  /**
   * @description 存储一个键值对
   * @param {Stirng} key 键
   * @param {String} value 值
   * @returns {Object} 返回当前对象
   */
  set(key, value) {
    const _type = Object.prototype.toString.call(value);
    const valueType = [
      'Array',
      'Object',
      'Date',
      'Boolean',
      'Number',
      'String',
      'Null',
      'Undefined',
    ];
    const support = valueType.some(i => _type.includes(i));
    if (support) {
      // 相对的
      const isPrimitive = typeof value !== 'object' || value === null; // boolean string null undefined number 直接存储

      this.engine.setItem(
        key,
        JSON.stringify({
          value: value,
          isPrimitive,
          expires: 0,
        }),
      );
      return this;
    } else {
      throw Error(`value只支持 ${valueType.join(',')}类型`);
    }
  }
  /**
   * @description 获取键对应的值
   * @param {String} key 键
   * @returns {String} 值
   */
  get(key) {
    const result = this.engine.getItem(key);
    return result === null ? result : JSON.parse(result).value;
  }
    /**
   * @description 判断是否存在键对应的值
   * @param {String} key 键
   * @returns {Boolean} 是否存在值
   */
  has(key) {
    return this.engine.getItem(key) !== null;
  }
    /**
   * @description 删除键对应的值
   * @param {String} key 键
   * @returns {Object} 返回当前对象
   */
  remove(key) {
    this.engine.removeItem(key);
    return this;
  }
    /**
   * @description 增加一个键值对
   * @param {Stirng} key 键
   * @param {String} value 值
   */
  append(key, value) {
    const result = this.engine.getItem(key);
    const parsed = JSON.parse(result);
    if (parsed.isPrimitive) {
      this.set(key, value);
    } else {
      // TODO 完成
      if (Array.isArray(value)) {
        this.set(key, Array.from(new Set([...value, ...parsed.value])));
      }
    }
  }
}
