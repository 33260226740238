/**
 * @file   试用成功模块
 * @create tianmu 2021/5/6
 * @update tianmu 2021/5/6
 */
 import React, { useEffect, useState } from 'react';
 import { Button } from 'antd';
 import { CheckCircleFilled } from '@ant-design/icons';
 import { useHistory, useLocation } from 'react-router';
 import { ProductLayout } from './components/ProductLayout';
 import './index.scss'
import { dateFormat, searchStrToObj } from '../../utils';
import { queryTrialByIdForCustomer } from '../../service';

 // 试用成功组件
export const TrialOrderSuccess = () => {
    const history = useHistory();
    const location = useLocation();
    const [trialInfo, setTrialInfo] = useState(null);
    useEffect(() => {
        const o = searchStrToObj(location.search);
        queryTrialByIdForCustomer(o?.trialId).then(res => {
            if (res.data.code === 200) {
                setTrialInfo(res.data.data);
            }
        })
    }, [location.search])

    return (
        <ProductLayout
            title="试用申请提交成功"
        >
            <div className="orderinfobox-wrapper">
                <div className="orderinfobox">
                    <div className="head">
                        <div
                            style={{
                                display: 'inline-block',
                                width: '100px',
                                height: '100px',
                                lineHeight: '100px',
                                fontSize: '50px',
                                color: 'rgb(61, 188, 142)',
                            }}
                        >
                            <CheckCircleFilled />
                        </div>
                        <div className="infobox">
                            <h1>恭喜您，试用申请提交成功！</h1>
                            <p>
                                您提交的
                                <span style={{color: 'rgb(61, 188, 142)'}}>
                                    {`${trialInfo?.trialDetailList?.[0]?.prodName || ""}`}
                                </span>
                                产品试用申请已审核通过，预计10分钟内完成产品初始化，请及时关注消息通知。
                            </p>
                        </div>
                    </div>
                    <div>
                        <fieldset className="common-fieldset">
                            <legend>试用信息</legend>
                            <div className="info-row">
                                <div className="info-label">试用产品：</div>
                                <div className="info-value">{trialInfo?.trialDetailList?.[0]?.prodName}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">申请日期：</div>
                                <div className="info-value">{dateFormat("YYYY-mm-dd HH:MM:SS", trialInfo?.applyTime)}</div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '20px'
                    }}
                >
                    <Button type="primary" style={{marginRight: '20px'}} onClick={() => history.push('/MyTrial')}>我的试用</Button>
                    <Button type="primary" onClick={() => history.push('/index')}>返回控制台</Button>
                </div>
            </div>
        </ProductLayout>
    );
};
