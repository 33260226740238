/**
 * @file   申请加入机构页面
 * @create tianmu 2021/4/7
 * @update tianmu 2020/4/7
 */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Input, Layout, notification } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppFooter } from '../../containers/AppFooter';
import Logo from '../../assets/logo.png';
import './index.scss';
import {
    querySystemId,
    saveAuthCustomer,
    queryBySystemId,
    queryByIdForCustomer,
    customerLogin
} from '../../service';
import { getUserDetail } from '../login/store';
import { AppHeader } from '../../containers/AppHeader';
import { searchStrToObj } from '../../utils';
import { localDS } from '../../storage';
import AuthedIcon from '../../assets/orgauth-authed.png';
import RejectIcon from '../../assets/orgauth-reject.png';

const { Content, Header } = Layout;
// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;

/**
 * @description 申请加入机构组件
 */
export const JoinInfo = () => {
    const location = useLocation();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [orgInfo, setOrgInfo] = useState(null);
    const [applyAuthInfo, setApplyAuthInfo] = useState(null);

    useEffect(() => {
        const o = searchStrToObj(location.search);
        queryByIdForCustomer(o?.businessId).then((res) => {
            if (res.data.code === 200) {
                const d = res.data.data;
                if (d.status === 2) {
                    setApplyAuthInfo(d);
                    setStep(1)
                } else if (d.status === 3) {
                    setApplyAuthInfo(d);
                    setStep(2)
                }
            }
        });
    }, [location.search]);

    return (
        <Layout
            className="app"
            style={{
                background: 'rgb(241, 242, 246)'
            }}
        >
            <AppHeader />
            <Content
                style={{
                    position: 'relative',
                    // width: '1190px',
                    width: '100%',
                    margin: 'auto',
                    height: '100%'
                }}
            >
                <div
                    style={{
                        margin: '80px 30% 60px'
                    }}
                >
                    <h1>申请加入机构</h1>
                    <p>{decodeURIComponent(searchStrToObj(location.search)?.content)}</p>
                </div>

                {step === 1 && (
                    <div
                        style={{
                            width: '800px',
                            padding: '80px 50px',
                            margin: 'auto',
                            background: '#fff'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div>
                                <img src={AuthedIcon} alt="" />
                            </div>
                            <div
                                style={{
                                    // textAlign: 'center',
                                    // width: '80%',
                                    margin: '30px',
                                    fontSize: '16px'
                                }}
                            >
                                <p style={{margin: 10}}>
                                    {applyAuthInfo?.approve}已同意您加入【
                                    {applyAuthInfo?.systemName}】
                                </p>
                                {applyAuthInfo?.approveOpinion && <p style={{margin: 10}}>审批意见：{applyAuthInfo?.approveOpinion}</p>}
                                {applyAuthInfo?.approveTime && <p style={{margin: 10}}>审批时间：{applyAuthInfo?.approveTime}</p>}
                                <p style={{margin: 10}}>
                                    点击
                                    <a
                                        href="/#"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            const systemId = applyAuthInfo?.systemId;
                                            const token = localDS.get('saasToken')
                                            if (!token) {
                                                notification.warn({
                                                    className: 'custom-antd-notification',
                                                    description: '已退出登录或登录超时，请重新登录',
                                                    duration: 5
                                                });
                                                window.location.href = "/accountLogin"
                                                return
                                            }
                                            customerLogin(systemId).then((res) => {
                                                // 请求错误直接返回
                                                if (res.data.code !== 200) {
                                                    notification.warn({
                                                        className: 'custom-antd-notification',
                                                        // description:
                                                        //     '请先购买或试用产品再进入管理后台',
                                                        description: res.data.msg,
                                                        duration: 5
                                                    });
                                                    return;
                                                }
                                                const d = res.data.data;
                                                if (d?.authorizationu) {
                                                    let loc = '';
                                                    if (systemId === '0') {
                                                        loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${systemId}`;
                                                    } else {
                                                        loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${systemId}`;
                                                    }
                                                    localDS.set('gatewayToken', d?.authorizationu);
                                                    localDS.set('systemId', systemId)
                                                    window.open(loc);
                                                }
                                            });
                                        }}
                                    >
                                        【{applyAuthInfo?.systemName}】
                                    </a>
                                    进入云产品系统
                                </p>
                                <p style={{margin: 10}}>您还可以申请加入其他机构，请点击<Link to="/join">加入新机构</Link></p>
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div
                        style={{
                            width: '800px',
                            padding: '80px 50px',
                            margin: 'auto',
                            background: '#fff'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div>
                                <img src={RejectIcon} alt="" />
                            </div>
                            <div
                                style={{
                                    // textAlign: 'center',
                                    // width: '80%',
                                    margin: '30px',
                                    fontSize: '16px'
                                }}
                            >
                                <p style={{margin: 10}}>
                                    {applyAuthInfo?.approve}已拒绝您加入【
                                    {applyAuthInfo?.systemName}】
                                </p>
                                {applyAuthInfo?.approveOpinion && <p  style={{margin: 10}}>审批意见：{applyAuthInfo?.approveOpinion}</p>}
                                {applyAuthInfo?.approveTime && <p  style={{margin: 10}}>审批时间：{applyAuthInfo?.approveTime}</p>}
                                <p style={{margin: 10}}>您可以申请加入其他机构，请点击<Link to="/join">加入新机构</Link></p>
                            </div>
                        </div>
                    </div>
                )}

            </Content>
            <AppFooter />
        </Layout>
    );
};
