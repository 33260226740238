/**
 * @file   产品商城模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React from 'react';
import { ProductList } from './components/ProductList';
import { ProductLayout } from './components/ProductLayout'
import './index.scss';

// 产品商城页面组件
export const ProductShop = () => {
    return (
    <ProductLayout title="产品商城">
        {/* 产品商城中为未订购的产品 */}
        <ProductList route="ProductShop" size="large" />
    </ProductLayout>
    );
};
