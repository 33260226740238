/**
 * @file   更改机构认证模块
 * @create tianmu 2021/3/5
 * @update tianmu 2021/3/5
 */
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Checkbox, Form, message, notification, Space, Steps } from 'antd';
import './index.scss';
import { OrgAuthForm } from '../OrgAuth/OrgAuthForm';
import { OrgAuthInfo } from '../OrgAuth/OrgAuthInfo';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    queryAuthCompanyByCustomerId,
    getFileById,
    updateAuthCompany,
} from '../../service';
import { FormFooter } from '../../containers/FormFooter';
import { searchStrToObj, omit } from '../../utils';
import Viewer from 'react-viewer';
import OrgauthAuthingIcon from '../../assets/orgauth-authing.png';

// 图片预览
// const filePreviewPrefix = process.env.ENV_FILE_PREVIEW;
const filePreviewPrefix = window.FILE_PREVIEW;

const Steppers = (props) => {
    const { stepNum } = props;
    return (
        <Steps labelPlacement="vertical" current={stepNum}>
            <Steps.Step title="原认证主体校验" />
            <Steps.Step title="变更认证主体" />
            <Steps.Step title="完成" />
        </Steps>
    );
};
const Step0 = (props) => {
    const { setStepNum } = props;
    const [agree, setAgree] = useState(false);
    const history = useHistory();

    return (
        <div className="firststep">
            <h2>机构认证变更协议</h2>
            <p>
                变更为新的主体之前，需接受<a href="/privacy-items.html" target="_blank">《申请帐号主体变更协议》</a>继续流程
            </p>

            <Checkbox
                checked={agree}
                onChange={(e) => {
                    setAgree(e.target.checked);
                }}
            >
                阅读并接受变更协议
            </Checkbox>
            <div className="tc btnbox">
                <Button
                    type="primary"
                    disabled={!agree}
                    onClick={() => {
                        if (agree) {
                            setStepNum(1);
                        }
                    }}
                >
                    确认
                </Button>
                <Button
                    onClick={() => {
                        history.push('/Organization');
                    }}
                >
                    取消验证
                </Button>
            </div>
        </div>
    );
};

const Step1 = (props) => {
    const { setStepNum } = props;
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [infos, setInfos] = useState(null);
    const [form] = Form.useForm();

    // 获取机构验证信息
    const queryInfo = useCallback(async () => {
        let res = await queryAuthCompanyByCustomerId();
        let t = res?.data?.data;
        if (!t) return;
        for (let i = 0; i < t.length; i++) {
            if (t[i].license) {
                res = await getFileById(t[i].license);
                const file = res.data.data;
                if (file && file.filePath && file.fileName) {
                    t[i].thumb = filePreviewPrefix + file.filePath + file.fileName;
                }
            }
        }
        setInfos(t);
    }, [])

    useEffect(() => {
        queryInfo();
    }, [queryInfo]);

    // 更新机构认证信息
    const updateInfo = (values) => {
        // return querySystemId(values.companyCode).then((res) => {
        //     if (!res.data.data) {
        //         // 没有systemId继续提交表单
        //         return updateAuthCompany(values);
        //     } else {
        //         // 有systemId提示公司编号已存在
        //         message.warn('公司编号已存在');
        //         return Promise.reject(new Error('公司编号已存在'));
        //     }
        // });
        return updateAuthCompany(values);
    };

    // 处理更改机构认证的事件
    const handleSubmit = useCallback(async () => {
        let values = form.getFieldsValue();
        if (!values.agree) {
            notification.info({
                className: 'custom-antd-notification',
                message: '请先确认协议内容！',
                duration: 5,
            });
            return;
        }
        const l = values.license;
        if (!l) {
            notification.warn({
                className: 'custom-antd-notification',
                message: '请上传机构证件附件！',
                duration: 5,
            })
            return;
        }
        const isNewLicense = Object.prototype.toString.call(l) !== '[object String]';
        if (isNewLicense
            && Array.isArray(l.fileList)
            && values.license.fileList.length === 0) {
            // 此时图片被删除，应该提示重新上传
            notification.warn({
                className: 'custom-antd-notification',
                message: '请上传机构证件附件！',
                duration: 5,
            })
            return;
        }
        if (values.editIdentMode === 1) {
            // 修改表单，但不修改法人身份信息，需要将法人身份证号设置为原来的真实身份证号（而不是加密后的）
            values = omit({
                ...values,
                identityCard: values.realIdentityCard,
            }, ['realIdentityCard', 'editIdentMode'])
        } else if (values.editIdentMode === 2) {
            // 修改表单，同时修改法人身份信息
            values = omit(values, ['realIdentityCard', 'editIdentMode'])
        } else {
            // 不上传这个字段
            values = omit(values, ['realIdentityCard', 'editIdentMode'])
        }

        setLoading(true);
        const res = await updateInfo({
            ...values,
            license: isNewLicense
                ? values.license.fileList[0].response.data.fileInfoId // 上传新文件后返回的对象中取出license
                : values.license // 没有更改之前的license
        });
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '提交成功！',
                duration: 5,
            })
            setStepNum(2);
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
        }
        setLoading(false);
    }, [form, setStepNum])

    const o = searchStrToObj(location.search);

    return (
        <div>
            <OrgAuthForm
                form={form}
                info={
                    o?.id ?
                        infos ?
                        infos.find(i => i.authCompanyId === o?.id) :
                        null :
                    infos[0]
                }
            />
            <FormFooter
                okText='确认更改'
                cancelText='上一步'
                onOk={handleSubmit}
                onCancel={() => {
                    setStepNum(0);
                }}
                loading={loading}
                btnAlign="center"
            />
        </div>
    );
};

const licenseTypeStr = (type) => {
    switch (type) {
        case 1:
            return '机构营业执照';
        case 2:
            return '组织机构代码证';
        case 3:
            return '事业单位法人证书';
        case 4:
            return '社会团体法人证书';
        case 5:
            return '行政执法主体资格证';
        case 6:
            return '其他'
        default:
            return '';
    }
};

const InfoCard = (props) => {
    const { title, info, extra } = props;
    const [imageVisible, setImageVisible] = useState(false);
    return (info ?
        <div>
            <fieldset className="common-fieldset">
                <legend>{title}</legend>
                {extra && (
                    <div className="extra-operations">
                        {extra}
                    </div>
                )}

                <div className="info-row">
                    <div className="info-label">您的身份：</div>
                    <div className="info-value">{
                        info.identity === 1 ? '法人'
                        : info.identity === 2 ? '被授权人'
                        : ""}
                    </div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构名称：</div>
                    <div className="info-value">{info.companyName}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构编号：</div>
                    <div className="info-value">{info.companyCode}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">法人姓名：</div>
                    <div className="info-value">{info.contact}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">法定代表人身份证号：</div>
                    <div className="info-value">{info.identityCard.replace(/^(.{6})(?:\d+)(.{4})$/,  "$1****$2")}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构证件类型：</div>
                    <div className="info-value">{licenseTypeStr(info.licenseType)}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">机构证件附件：</div>
                    {/* <div className="info-value">{props.identfile}</div> */}
                    <div className="info-value">
                        <img
                            onClick={() => setImageVisible(true)}
                            src={info.thumb}
                            alt="certificate"
                        />
                        <Viewer
                            visible={imageVisible}
                            onClose={() => {
                                setImageVisible(false);
                            }}
                            images={[{ src: info.thumb, alt: '' }]}
                        />
                    </div>
                </div>
            </fieldset>
        </div> : null
    )
}

const Step2 = () => {
    const [infos, setInfos] = useState(null);
    const history = useHistory();
    const queryInfo = useCallback(async () => {
        let res = await queryAuthCompanyByCustomerId();
        let t = res.data?.data;
        for (let i = 0; i < t.length; i++) {
            if (t[i].license) {
                res = await getFileById(t[i].license);
                const file = res.data.data;
                if (file && file.filePath && file.fileName) {
                    t[i].thumb = filePreviewPrefix + file.filePath + file.fileName;
                }
            }
        }
        setInfos(t);
    },[])

    useEffect(() => {
        queryInfo();
    }, [queryInfo]);

    return (
        <div
            style={{
                width: '50vw',
                margin: 'auto'
            }}
        >
            <Link
                to="/Organization"
                style={{
                    float: 'right',
                    fontSize: '16px',
                    margin: '50px'
                }}
            >
                &lt;返回
            </Link>
            <div className="orgauthinfobox">
                <div className="head">
                    <img src={OrgauthAuthingIcon} alt="" />
                    <div className="infobox">
                        <h1>您申请的机构认证信息变更正在审核中，请耐心等待！</h1>
                    </div>
                </div>
                <Space
                    direction="vertical"
                    style={{
                        width: '100%'
                    }}
                >
                    <InfoCard
                        title="变更中认证信息"
                        info={infos?.[0]}
                    />
                    <InfoCard
                        title="变更前认证信息"
                        info={infos?.[1]}
                    />
                </Space>
            </div>
        </div>
    );
};

// 单位注册组件
export const ChangeOrgAuth = () => {
    const [stepNum, setStepNum] = useState(0);
    return (
        <>
            <div className="page-header">
                <div className="title">变更机构信息</div>
            </div>
            <div className="page-content">
                <div className="changeorgauth">
                    <Steppers stepNum={stepNum} />
                    {stepNum === 0 && <Step0 setStepNum={setStepNum} />}
                    {stepNum === 1 && <Step1 setStepNum={setStepNum} />}
                    {stepNum === 2 && <Step2 setStepNum={setStepNum} />}
                </div>{' '}
            </div>
        </>
    );
};
