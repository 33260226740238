/**
 * @file   公告详情
 * @create tianmu 2021/7/9
 * @update tianmu 2021/7/9
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {  Layout, Row, Col } from 'antd';
import {
    queryBulletinById,
} from '../../service';
import { searchStrToObj } from '../../utils';
import { useHistory, useLocation } from 'react-router';
import './index.scss';
import { AppHeader } from '../../containers/AppHeader';

const { Content } = Layout;
// const PREVIEW_PREFIX = process.env.ENV_FTP_PREVIEW
const PREVIEW_PREFIX = window.FTP_PREVIEW

export const AnounceDetail = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [anounceDetail, setAnounceDetail] = useState(null);
    const o = useMemo(() => searchStrToObj(location.search), [location.search]);

    const fetchAnounceInfo = useCallback(async (id) => {
        const res1 = await queryBulletinById(id);
        if (res1.data.code === 200) {
            const d = res1.data.data;
            setAnounceDetail(d);
        }
    }, []);

    const fetchData = useCallback(() => {
        const id = o?.id;
        fetchAnounceInfo(id);
    }, [fetchAnounceInfo, o]);

    useEffect(() => {
        fetchData();
    }, [location, history, fetchData]);

    return (
        <Layout className="app">
            <AppHeader />
            <div
                className="page-header"
                style={{
                    marginTop: 100,
                    padding: '0 10%',
                }}
            >
                <div className="title">
                    公告详情
                    <span
                        onClick={() => {
                            history.goBack();
                        }}
                        style={{
                            float: 'right',
                            color: 'rgb(86, 192, 150)',
                            cursor: 'pointer',
                            fontSize: '16px'
                        }}
                    >
                        &lt; 返回
                    </span>
                </div>
            </div>
            <Content
                style={{
                    position: 'relative',
                    width: '80%',
                    margin: '0 auto',
                    padding: '20px',
                    height: '100%',
                    background: '#fff',
                }}
            >

            <h3
                style={{
                    textAlign: 'center',
                    fontSize: 20,
                    fontWeight: 'bold',
                    marginBottom: 10,
                    paddingBottom: 10,
                    borderBottom: '1px solid #ccc'
                }}
            >
                系统公告
            </h3>
            <h1 style={{
                textAlign: 'center',
                fontSize: '20px',
            }}>{anounceDetail?.title}</h1>
            <div style={{textAlign: 'right', margin: '20px 50px 0 0'}}>
                时间：{anounceDetail?.time}
            </div>
            <div style={{margin: '30px 0'}} dangerouslySetInnerHTML={{__html: anounceDetail?.content || ""}} />
            <div style={{textAlign: 'center'}}>
                <img src={anounceDetail?.path ? PREVIEW_PREFIX + anounceDetail?.path : ''} alt="" />
            </div>
            </Content>
        </Layout>
    );
};
