/**
 * @file   我的试用模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Button, Table, Form, DatePicker, Select, notification, Space, Tooltip } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons'
import { cancelTrial, queryTrialList, customerLogin } from '../../service';
import './index.scss';
import { dateFormat } from '../../utils';
import { Link } from 'react-router-dom';
import { localDS } from '../../storage';

// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;

// 默认页面大小
const PAGE_SIZE = 10;
const { RangePicker } = DatePicker;

const statusStr = (item) => {
    const status = item.approveStatus;
    const progress = item.initProgress;
    // 审核状态 0:保存 1:审批中 2:核准 3:驳回 4:撤销

    switch (status) {
        // case 0:
        //     return <span className="state-info">保存</span>;
        case 1:
            return <span className="state-waring">审批中</span>;
        case 2:
            if (progress === 0) {
                // 未初始化
                return <span className="state-waring">审批中</span>;
            } else if (progress === 1) {
                // 初始化中
                return <span className="state-waring">审批中</span>;
            } else if (progress === 2) {
                // 初始化成功
                return <span className="state-success">审批通过</span>;
            } else if (progress === 3) {
                // 初始化失败
                return <span className="state-error">审批中</span>;
            }
            break;
        case 3:
            return <span className="state-error">已拒绝</span>;
        case 4:
            return <span className="state-default">已撤销</span>;
        default:
            return '';
    }
};

// 嵌套子表格组件
const nestedTableRow = (record) => {
    const { trialDetailList, approveStatus, customerId } = record;
    const prodInfoList = trialDetailList.map((i) => {
        const o = i.prodInfo ? JSON.parse(i.prodInfo) : null;
        // if (!o) {
        //     return null;
        // }
        // const now = o.nowTime;
        // const end = o.expireTime;
        // const restTime = Math.floor((new Date(end) - new Date(now)) / 1000 / 60 / 60 / 24);
        return {
            productName: i.prodName,
            // restTime: (o.isTrial && approveStatus !== 3 && approveStatus !== 4) ? i.remainDay : 0,
            effectDate: i.effectDate,
            expireDate: i.expireDate,
            operation: {
                isValid: o &&
                        o.isTrial &&
                        // o.diff >= 0 &&
                        (new Date() - new Date(o.expireTime)) < 0 &&
                        approveStatus !== 3 &&
                        approveStatus !== 4,
                systemId: o.systemId,
                customerId,
            }
            // expireTime: o.isTrial
            //             ?
            //                 approveStatus === 3
            //                 ? '已驳回'
            //                 : approveStatus === 4
            //                     ? '已撤销'
            //                     : o.expireDate
            //             : '已失效'
        };
    });
    const columns = [
        { title: '产品名称', dataIndex: 'productName', key: 'productName' },
        {
            title: '生效日期',
            dataIndex: 'effectDate',
            key: 'effectDate',
            render: (time) => time ? time : ""
        },
        { title: '失效日期',
            dataIndex: 'expireDate',
            key: 'expireDate',
            render: (expireDate) => expireDate ? expireDate : ""
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (opts) => (
                opts.isValid ? (
                <Space size="middle">
                    <a
                        href="#/"
                        onClick={(ev)=> {
                            ev.preventDefault();
                            const token = localDS.get('saasToken')
                            if (!token) {
                                notification.warn({
                                    className: 'custom-antd-notification',
                                    description: '已退出登录或登录超时，请重新登录',
                                    duration: 5
                                });
                                window.location.href = "/accountLogin"
                                return
                            }
                            customerLogin(opts.systemId).then((res) => {
                                // 请求错误直接返回
                                if (res.data.code !== 200) {
                                    notification.warn({
                                        className: 'custom-antd-notification',
                                        // description:
                                        // '请先购买或试用产品再进入管理后台',
                                        description: res.data.msg,
                                        duration: 5
                                    });
                                    return;
                                }
                                const d = res.data.data;
                                if (d?.authorizationu) {
                                    let loc = '';
                                    if (opts.systemId === '0') {
                                        loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${opts.systemId}&customerId=${opts.customerId}`;
                                    } else {
                                        loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${opts.systemId}&customerId=${opts.customerId}`;
                                    }
                                    localDS.set('gatewayToken', d?.authorizationu);
                                    window.open(loc);
                                }
                            });
                        }}
                    >开始试用</a>
                <Tooltip
                    color="#fff"
                    title={
                        <div
                            style={{
                                color: 'rgb(112, 112, 112)',
                            }}
                        >
                            您也可通过点击界面右上角
                            <span style={{color: 'rgb(99, 170, 136)', fontWeight: 'bold'}}> 账户名称 </span>弹出框中的
                            <span style={{color: 'rgb(99, 170, 136)', fontWeight: 'bold'}}> 所属机构 </span>进入产品试用界面。
                        </div>
                    }
                >
                    <ExclamationCircleFilled style={{color: 'rgb(99, 170, 136)'}}/>
                </Tooltip>
                </Space>
                ) : null
            )
        }
    ];
    return <Table columns={columns} dataSource={prodInfoList} pagination={false} />;
};

// 我的试用页面组件
export const MyTrial = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: PAGE_SIZE
    });
    const [form] = Form.useForm();

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        setLoading(true);
        return queryTrialList(params).then((res) => {
            //  console.log(res.data.data);
            const listData = res.data.data.records.map((item) => {
                return {
                    key: item.trialId,
                    prodNameList: item.trialDetailList,
                    // trialId: item.trialId,
                    applyTime: item.applyTime,
                    approveTime: item.approveTime,
                    remark: item.remark,
                    approveStatus: item,
                    operation: item,
                    trialDetailList: item.trialDetailList
                };
            });
            setPagination({
                current: params.pageNum,
                pageSize: params.pageSize,
                total: res.data.data.total
            });
            setDataSource(listData);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchData({
            entity: {},
            param: {
                pageNum: 1,
                pageSize: PAGE_SIZE
            }
        });
    }, [fetchData]);

    const columns = [
        {
            // title: '试用编号',
            title: '试用产品',
            dataIndex: 'prodNameList',
            render: (lst) => (Array.isArray(lst) && lst.length > 0 ? lst.map(i => i.prodName).join(',') : '')
        },
        {
            title: '申请时间',
            dataIndex: 'applyTime',
            render: (time) => {
                if (!time) return '';
                const date = new Date(time);
                return dateFormat("YYYY-mm-dd HH:MM:SS", date);
            }
        },
        {
            title: '审核时间',
            dataIndex: 'approveTime',
            render: (time) => {
                if (!time) return '';
                const date = new Date(time);
                return dateFormat("YYYY-mm-dd HH:MM:SS", date);
            }
        },
        {
            title: '备注',
            dataIndex: 'remark'
        },
        {
            title: '状态',
            dataIndex: 'approveStatus',
            render: (item) => statusStr(item)
        },
        {
            title: '操作',
            dataIndex: 'operation',
            render: (data) => {
                return (<>
                <Link style={{marginRight: 10}} to={`/MyTrial/TrialDetail?trialId=${data.trialId}`}>查看详情</Link>
                {data.approveStatus === 1 ? (
                    <a
                        href="/#"
                        onClick={(e) => {
                            e.preventDefault();
                            cancelTrial({
                                trialId: data.trialId,
                                approveStatus: 4
                            }).then((res) => {
                                if (res.data.code === 200) {
                                    notification.success({
                                        className: 'custom-antd-notification',
                                        message: '撤销成功！',
                                        duration: 5,
                                    })

                                    fetchData({
                                        entity: {},
                                        param: {
                                            pageNum: 1,
                                            pageSize: PAGE_SIZE
                                        }
                                    });
                                } else {
                                    notification.warn({
                                        className: 'custom-antd-notification',
                                        message: res.data.msg,
                                        duration: 5,
                                    })
                                }
                            });
                        }}
                    >
                        撤销
                    </a>
                ) : null}
            </>)
            }
        }
    ];

    const handleTableChange = (pagination) => {
        const values = form.getFieldsValue();
        const timeRange = values.applyTimeQuery;
        fetchData({
            entity: {
                ...values,
                applyTimeQuery: timeRange && timeRange.map((t) => t.format('YYYY-MM-DD')).join('#')
            },
            param: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize
            }
        });
    };

    return (
        <>
            <div className="page-header">
                <div className="title">我的试用</div>
            </div>
            <Layout className="page-content mytrial">
                <div className="table-meta mytrial-table-meta">
                    <Form
                        layout="inline"
                        form={form}
                        onFinish={() => handleTableChange({ current: 1, pageSize: PAGE_SIZE })}
                    >
                        <Form.Item
                            name="approveStatus"
                        >
                            {/* 审核状态 0:保存 1:审批中 2:核准 3:驳回 4:撤销 */}
                            <Select placeholder="选择状态">
                                <Select.Option key={1} value={1}>
                                    审批中
                                </Select.Option>
                                <Select.Option key={2} value={2}>
                                    核准
                                </Select.Option>
                                <Select.Option key={3} value={3}>
                                    驳回
                                </Select.Option>
                                <Select.Option key={4} value={4}>
                                    撤销
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="applyTimeQuery">
                            <RangePicker placeholder={['申请开始时间', '申请结束时间']} />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            查询
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                fetchData({
                                    entity: {},
                                    param: {
                                        pageNum: 1,
                                        pageSize: PAGE_SIZE
                                    }
                                });
                            }}
                        >
                            重置
                        </Button>
                    </Form>
                </div>

                <Table
                    columns={columns}
                    dataSource={dataSource}
                    expandable={{ expandedRowRender: nestedTableRow }}
                    bordered
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => {
                            return `共 ${total} 条数据`;
                        }
                    }}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Layout>
        </>
    );
};
