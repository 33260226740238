/**
 * @file   订单的开票历史列表
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Collapse, Layout, notification, Row } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import {
    cancelInvoice,
    evaluateRefund,
    queryCustomerOrderById,
    queryInvoiceList,
    queryRefundByOrderId
} from '../../service';
import { searchStrToObj, toFixedNumStr } from '../../utils';
import { useHistory, useLocation } from 'react-router';
import InvoiceStatusIcon0 from '../../assets/notice/10.png';
import InvoiceStatusIcon1 from '../../assets/notice/11.png';
import InvoiceStatusIcon2 from '../../assets/notice/12.png';
import InvoiceStatusIcon3 from '../../assets/notice/13.png';
import OrderStatusIcon0 from '../../assets/notice/6.png';
import OrderStatusIcon1 from '../../assets/notice/7.png';
import OrderStatusIcon2 from '../../assets/notice/9.png';
import OrderStatusIcon3 from '../../assets/notice/8.png';
import OrderStatusIcon4 from '../../assets/notice/1.png';
import OrderStatusIcon5 from '../../assets/notice/14.png';
import OrderStatusIcon6 from '../../assets/notice/15.png';
import './index.scss';
import { unitStr } from '../../utils/productUtils';

// 默认页面大小
const PAGE_SIZE = 50;

const { Panel } = Collapse;

// 开票状态与数字枚举对应
const statusStr = (status) => {
    switch (status) {
        case 0:
            return <span className="state-waring">开票中</span>;
        case 1:
            return <span className="state-cancel">已撤销</span>;
        case 2:
            return <span className="state-success">已开票</span>;
        case 3:
            return <span className="state-error">开票失败</span>;
        default:
            return '';
    }
};

export const OrderInvoiceHistory = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [invoiceList, setInvoiceList] = useState([]);
    const [orderDetail, setOrderDetail] = useState(null);
    const [refundRecords, setRefundRecords] = useState([]);
    const [currRefund, setCurrRefund] = useState(null)
    const o = useMemo(() => searchStrToObj(location.search), [location.search]);

    const fetchInvoices = useCallback(async (params) => {
        const res2 = await queryInvoiceList(params);
        setInvoiceList(res2.data.data.records);
    }, []);

    const fetchOrderInfo = useCallback(async (id) => {
        const res1 = await queryCustomerOrderById(id);
        if (res1.data.code === 200) {
            const d = res1.data.data;
            setOrderDetail(d);
            // 获取退订订单的审批信息
            const res2 = await queryRefundByOrderId(id);
            const records = res2?.data?.data;
            if (Array.isArray(records) && records.length > 0) {
                // 记录按照退单的时间，从大到小（最近的到最以前的）排序
                records.sort((a, b) => new Date(b.createTime) - new Date(a.createTime));
                setCurrRefund(records[0])
                setRefundRecords(
                    records
                        // 退单状态（0待审核；1审核中；2取消；3通过；4拒绝）
                        .filter((i) => i.status === 3 || i.status === 4)
                );
            }
        }
    }, []);


    const fetchData = useCallback(() => {
        // orderId 是从订单列表页点击过来的
        // businessId 是从订单消息点击过来的
        const id = o?.orderId || o?.businessId;
        fetchInvoices({
            entity: {
                orderId: id
            },
            param: {
                pageNum: 1,
                pageSize: PAGE_SIZE
            }
        });
        fetchOrderInfo(id);
    }, [fetchInvoices, fetchOrderInfo, o]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    // 已退订当前订单
    const isUnsubscribe = orderDetail?.status === 5;
    const refundCost = useCallback(id => currRefund?.detailList?.find(i => i.prodId === id)?.refundCost, [currRefund])

    return (
        <>
            <div className="page-header">
                <div className="title">订单详情</div>
            </div>
            <Layout className="page-content order-detail">
                <div className="order-detail-info">
                    <div className="notice-img-icon">
                        {orderDetail?.status === 0 && <img src={OrderStatusIcon0} alt="" />}
                        {orderDetail?.status === 1 && <img src={OrderStatusIcon1} alt="" />}
                        {orderDetail?.status === 2 && <img src={OrderStatusIcon2} alt="" />}
                        {orderDetail?.status === 3 ? (
                            orderDetail?.initId ? (
                                <img src={OrderStatusIcon3} alt="" />
                            ) : (
                                <img src={OrderStatusIcon4} alt="" />
                            )
                        ) : null}
                        {orderDetail?.status === 4 && <img src={OrderStatusIcon5} alt="" />}
                        {orderDetail?.status === 5 && <img src={OrderStatusIcon6} alt="" />}
                    </div>
                    <h3
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            marginBottom: 10,
                            paddingBottom: 10,
                            borderBottom: '1px solid #ccc'
                        }}
                    >
                        订单详情
                        {/* {o?.orderId && ( */}
                            <span
                                onClick={() => {
                                    history.goBack();
                                }}
                                style={{
                                    float: 'right',
                                    color: 'rgb(86, 192, 150)',
                                    cursor: 'pointer',
                                    fontSize: '16px'
                                }}
                            >
                                &lt; 返回
                            </span>
                        {/* )} */}
                    </h3>
                    <Row gutter={[0, 20]}>
                        <Col style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }} span={2}>
                            产品名称：
                        </Col>
                        <Col style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }} span={22}>
                            {orderDetail?.orderTitle}
                        </Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>公司名称：</Col>
                        <Col span={22}>{orderDetail?.companyName}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>创建时间：</Col>
                        <Col span={22}>{orderDetail?.createTime}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>电话：</Col>
                        <Col span={22}>{orderDetail?.phoneNumber}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>金额：</Col>
                        <Col style={{ color: 'red' }} span={22}>
                            ￥{toFixedNumStr(orderDetail?.orderCost)}
                        </Col>
                    </Row>
                    {isUnsubscribe && (
                        <Row gutter={[0, 20]}>
                            <Col span={2}>退订金额：</Col>
                            <Col style={{ color: 'red' }} span={22}>
                                ￥{toFixedNumStr(currRefund?.refundCost)}
                            </Col>
                        </Row>
                    )}

                </div>
                <h3
                    style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        marginBottom: 10,
                        paddingBottom: 10,
                        borderBottom: '1px solid #ccc'
                    }}
                >
                    {orderDetail?.kit ? '套件明细' : '产品列表'}
                </h3>
                <div className="order-detail-product-info">
                    <div className="order-detail-product-list">
                        {orderDetail?.productList &&
                            orderDetail.productList.map((item) => (
                                <div key={item.productId} className="order-detail-product-item">
                                    <Row gutter={[0, 20]}>
                                        <Col
                                            style={{
                                                color: 'rgb(86, 192, 150)',
                                                fontWeight: 'bold'
                                            }}
                                            span={2}
                                        >
                                            产品名称：
                                        </Col>
                                        <Col
                                            style={{
                                                color: 'rgb(86, 192, 150)',
                                                fontWeight: 'bold'
                                            }}
                                            span={22}
                                        >
                                            {item.productName}
                                        </Col>
                                    </Row>
                                    <Row gutter={[0, 20]}>
                                        <Col span={2}>购买数量：</Col>
                                        <Col span={2}>
                                            {item.productValue}
                                            {unitStr(item.productType)}
                                        </Col>
                                        {/* 如果是套件明细，不显示价格 */}
                                        {!orderDetail?.kit && (
                                            <>
                                                <Col span={2}>购买价格：</Col>
                                                <Col span={2}>
                                                    <span style={{ color: 'red' }}>
                                                        ￥{toFixedNumStr(item.productCost)}
                                                    </span>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    {isUnsubscribe && (
                                        <Row gutter={[0, 20]}>
                                            <Col span={2}>退订价格：</Col>
                                            <Col span={2} style={{ color: 'red' }}>
                                                ￥{toFixedNumStr(refundCost(item.productId)) || 0.00}
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            ))}
                        {orderDetail?.serviceList &&
                            orderDetail.serviceList.map((item) => (
                                <div key={item.serviceId} className="order-detail-product-item">
                                    <Row gutter={[0, 20]}>
                                        <Col
                                            style={{
                                                color: 'rgb(86, 192, 150)',
                                                fontWeight: 'bold'
                                            }}
                                            span={2}
                                        >
                                            服务名称：
                                        </Col>
                                        <Col
                                            style={{
                                                color: 'rgb(86, 192, 150)',
                                                fontWeight: 'bold'
                                            }}
                                            span={22}
                                        >
                                            {item.serviceName}
                                        </Col>
                                    </Row>
                                    <Row gutter={[0, 20]}>
                                        <Col span={2}>购买数量：</Col>
                                        <Col span={2}>
                                            {item.serviceValue}
                                            {unitStr(item.serviceType)}
                                        </Col>
                                        {/* 如果是套件明细，不显示价格 */}
                                        {!orderDetail?.kit && (
                                            <>
                                                <Col span={2}>购买价格：</Col>
                                                <Col span={2}>
                                                    <span style={{ color: 'red' }}>
                                                        ￥{toFixedNumStr(item.serviceCost)}
                                                    </span>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    {isUnsubscribe && (
                                        <Row gutter={[0, 20]}>
                                            <Col span={2}>退订价格：</Col>
                                            <Col span={2} style={{ color: 'red' }}>
                                                ￥{toFixedNumStr(refundCost(item.serviceId)) || 0.00}
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            ))}
                        {orderDetail?.resourceList &&
                            orderDetail.resourceList.map((item) => (
                                <div key={item.resourceId} className="order-detail-product-item">
                                    <Row gutter={[0, 20]}>
                                        <Col
                                            style={{
                                                color: 'rgb(86, 192, 150)',
                                                fontWeight: 'bold'
                                            }}
                                            span={2}
                                        >
                                            资源名称：
                                        </Col>
                                        <Col
                                            style={{
                                                color: 'rgb(86, 192, 150)',
                                                fontWeight: 'bold'
                                            }}
                                            span={22}
                                        >
                                            {item.resourceName}
                                        </Col>
                                    </Row>
                                    <Row gutter={[0, 20]}>
                                        <Col span={2}>购买数量：</Col>
                                        <Col span={2}>
                                            {item.resourceValue}
                                            {unitStr(item.resourceType)}
                                        </Col>
                                        {/* 如果是套件明细，不显示价格 */}
                                        {!orderDetail?.kit && (
                                            <>
                                                <Col span={2}>购买价格：</Col>
                                                <Col span={2}>
                                                    <span style={{ color: 'red' }}>
                                                        ￥{toFixedNumStr(item.resourceCost)}
                                                    </span>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                    {isUnsubscribe && (
                                        <Row gutter={[0, 20]}>
                                            <Col span={2}>退订价格：</Col>
                                            <Col span={2} style={{ color: 'red' }}>
                                                ￥0.00
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
                {refundRecords.length > 0 && (
                    <>
                        <h3
                            style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                marginBottom: 10,
                                paddingBottom: 10,
                                borderBottom: '1px solid #ccc'
                            }}
                        >
                            退订审批
                        </h3>
                        {refundRecords.map((i) => {
                            return (
                                <div
                                    style={{
                                        fontSize: 16,
                                        borderBottom: '1px dashed #ccc',
                                        marginTop: 10
                                    }}
                                    key={i.refundId}
                                >
                                    <Row gutter={[0, 20]}>
                                        <Col span={2}>审批时间：</Col>
                                        <Col span={22}>{i?.approveTime}</Col>
                                    </Row>
                                    <Row gutter={[0, 20]}>
                                        <Col span={2}>审批意见：</Col>
                                        <Col span={22}>{i?.approveOpinion}</Col>
                                    </Row>
                                </div>
                            );
                        })}
                    </>
                )}
                {Array.isArray(invoiceList) && invoiceList.length > 0 && (
                    <>
                        <h3
                            style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                marginBottom: 10,
                                paddingBottom: 10,
                                borderBottom: '1px solid #ccc'
                            }}
                        >
                            开票历史
                        </h3>
                        <Collapse
                            collapsible="header"
                            expandIcon={({ isActive }) => (
                                <CaretRightOutlined rotate={isActive ? 90 : 0} />
                            )}
                        >
                            {invoiceList.map((i) => {
                                return (
                                    <Panel
                                        className="order-invoice-item"
                                        header={
                                            <div style={{ display: 'inline-block' }}>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        marginRight: '20px'
                                                    }}
                                                >
                                                    {i.invoiceTitle}
                                                </span>
                                                {statusStr(i.status)}
                                                {i.approveOpinion && (
                                                    <span style={{ marginLeft: '20px' }}>
                                                        {i.approveOpinion}
                                                    </span>
                                                )}
                                            </div>
                                        }
                                        key={i.invoiceId}
                                        extra={
                                            <>
                                                {i.status === 0 && ( // 发票状态为开票中才可以撤销
                                                    <Button
                                                        onClick={() => {
                                                            cancelInvoice({
                                                                invoiceId: i.invoiceId
                                                            }).then((res) => {
                                                                if (res.data.code === 200) {
                                                                    notification.success({
                                                                        className:
                                                                            'custom-antd-notification',
                                                                        message: '撤销成功！',
                                                                        duration: 5
                                                                    });
                                                                    fetchData();
                                                                } else {
                                                                    notification.warn({
                                                                        className:
                                                                            'custom-antd-notification',
                                                                        message: res.data.msg,
                                                                        duration: 5
                                                                    });
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        撤销
                                                    </Button>
                                                )}
                                                {/* <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            console.log("修改")
                                        }}
                                    >
                                        修改
                                    </Button> */}
                                            </>
                                        }
                                    >
                                        <div className="notice-img-icon">
                                            {i.status === 0 && (
                                                <img src={InvoiceStatusIcon0} alt="" />
                                            )}
                                            {i.status === 1 && (
                                                <img src={InvoiceStatusIcon1} alt="" />
                                            )}
                                            {i.status === 2 && (
                                                <img src={InvoiceStatusIcon2} alt="" />
                                            )}
                                            {i.status === 3 && (
                                                <img src={InvoiceStatusIcon3} alt="" />
                                            )}
                                        </div>
                                        <div className="info-row">
                                            <label className="info-label">发票抬头：</label>
                                            <span className="info-value">{i.invoiceTitle}</span>
                                        </div>
                                        <div className="info-row">
                                            <label className="info-label">金额：</label>
                                            <span className="info-value">￥{i.invoiceCost}</span>
                                        </div>
                                        <div className="info-row">
                                            <label className="info-label">备注说明：</label>
                                            <span className="info-value">{i.remark}</span>
                                        </div>
                                        {i.invoiceTitleType === 2 && (
                                            <>
                                                <div className="info-row">
                                                    <label className="info-label">公司税号：</label>
                                                    <span className="info-value">
                                                        {i.dutyParagraph}
                                                    </span>
                                                </div>
                                                <div className="info-row">
                                                    <label className="info-label">
                                                        公司注册电话：
                                                    </label>
                                                    <span className="info-value">
                                                        {i.companyPhone}
                                                    </span>
                                                </div>
                                                <div className="info-row">
                                                    <label className="info-label">公司地址：</label>
                                                    <span className="info-value">
                                                        {i.companyAddress}
                                                    </span>
                                                </div>
                                                <div className="info-row">
                                                    <label className="info-label">开户行：</label>
                                                    <span className="info-value">
                                                        {i.accountBank}
                                                    </span>
                                                </div>
                                                <div className="info-row">
                                                    <label className="info-label">银行账户：</label>
                                                    <span className="info-value">
                                                        {i.accountNumber}
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                        <div className="info-row">
                                            <label className="info-label">收件人：</label>
                                            <span className="info-value">{i.receiver}</span>
                                        </div>
                                        <div className="info-row">
                                            <label className="info-label">收件人电话：</label>
                                            <span className="info-value">{i.receiverPhone}</span>
                                        </div>
                                        <div className="info-row">
                                            <label className="info-label">寄件地址：</label>
                                            <span className="info-value">{i.receiverAddress}</span>
                                        </div>
                                    </Panel>
                                );
                            })}
                        </Collapse>
                    </>
                )}
            </Layout>
        </>
    );
};
