/**
 * @file 全局修改modal.confirm默认行为
 * @create pengdemu
 * 
 */

import React from 'react';
import { Modal, Button } from 'antd';
import ReactDOM from 'react-dom';


// modal高阶组件
const HOCModal = (Component) => {
    // 剔除出visible，footer，closable，使其不可配，赋予永久默认值
    return ({
        visible,
        footer,
        closable,
        okText = '知道了',
        okType = 'primary',
        onOk = () => { },
        onCancel = () => { },
        maskClosable = true,
        name,
        icon,
        content,
        destroy,
        ...props
    }) => {
        // 修改onOk方法传入关闭Modal方法destroy();
        const onOk_1 = () => {
            onOk();
            destroy();
        }
        // 修改onCancel方法传入关闭Modal方法destroy();
        const onCancel_1 = () => {
            onCancel();
            destroy();
        }
        // Modal底部footer固定使用这里为默认值，且不可自定义footer，如果调用的是confirm返回undefined走Modal的默认配置，其他则只显示一个OK、button
        // eslint-disable-next-line react/no-multi-comp
        const Footer = () => (
            name === 'confirm' ? <>
                <Button onClick={onOk_1} type={okType}>{okText}</Button>
                <Button onClick={onCancel_1} type='default'>取消</Button>
            </> : <Button onClick={onOk_1} type={okType}>{okText}</Button>
        )

        return (
            <Component
                okText={okText}
                closable={true}
                maskClosable={maskClosable}
                onOk={onOk_1}
                footer={Footer()}
                onCancel={onCancel_1}
                okType={okType}
                visible
                {...props}
                className="modal-confirm"
            >
                {icon}
                <span>{content}</span>
            </Component>
        )
    }
}

['confirm'].forEach(item => {
    // eslint-disable-next-line react/no-multi-comp
    Modal[item] = ({ ...props }) => {
        let div = document.createElement('div');
        let currentConfig = { ...props };
        document.body.appendChild(div);
        // 使用高阶组件剔除Method（）调用形式不可配置的props和默认值
        const FunModal = HOCModal(Modal);
        // 关闭
        const destroy = () => {
            const unmountResult = ReactDOM.unmountComponentAtNode(div);
            if (unmountResult && div.parentNode) {
                div.parentNode.removeChild(div);
            }
        }
        const render = (config) => {
            // name传入调用的方法名，用于区分使用不同footer和Icon
            ReactDOM.render(<FunModal destroy={destroy} name={item} {...config} />, div);
        }
        // 更新
        const update = (newConfig) => {
            currentConfig = { ...newConfig };
            render(currentConfig);
        }
        render(currentConfig);
        return {
            destroy: destroy,
            update: update
        }
    }
});