/**
 * @file   页面内容默认布局模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Layout, BackTop, Modal, Spin, Button } from 'antd';
import routes from '../routes';
import { AppHeader } from './AppHeader.jsx';
import { AppAside } from './AppAside.jsx';
import menu from './menuConfig.jsx';
import { queryAuthCompanyByCustomerId } from '../service';
import { createFromIconfontCN } from '@ant-design/icons';

const { Content } = Layout;

const IconFont = createFromIconfontCN({
    scriptUrl: window.ICONFONT_URL
});
const AuthedRoute = ({ component: Component, ...rest }) => {
    const [authed, setAuthed] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        const fetchAuthInfo = async () => {
            setLoading(true);
            const res = await queryAuthCompanyByCustomerId();
            // 第一次申请，审核通过
            if (res?.data?.code === 200) {
                const infos = res.data.data;
                if (!infos) {
                    setAuthed(false);
                    setLoading(false);
                    return;
                }
                const firstSubmit = infos.length === 1;
                const hasSubmited = infos.length === 2;
                // 第一次审核且通过
                const firstAuthed = firstSubmit && infos[0].approveStatus === 2;
                // 前一次审核通过
                const beforeAuthed = hasSubmited && infos[1].approveStatus === 2;
                // 当前申请审核通过，前一次审核拒绝
                const authedReject =
                    hasSubmited && infos[0].approveStatus === 2 && infos[1].approveStatus === 3;
                if (firstAuthed || beforeAuthed || authedReject) {
                    setAuthed(true);
                } else {
                    setAuthed(false);
                }
                setLoading(false);
            } else {
                setAuthed(false);
                setLoading(false);
            }
        };
        fetchAuthInfo();
    }, []);
    return (
        <Route
            {...rest}
            render={(props) =>
                loading ? (
                    <Spin />
                ) : authed ? (
                    <Component {...props} />
                ) : (
                    <div className="not-authed-layout">
                        <Component {...props} />
                        <Modal
                            title={null}
                            footer={null}
                            closable={false}
                            maskClosable={false}
                            keyboard={false}
                            // getContainer=".not-authed-layout"
                            centered
                            visible={!authed}
                            width={600}
                            wrapClassName="orgwarmtips"
                        >
                            <div className="head">
                                <p>温馨提示</p>
                                <h2>您还没有完成机构实名认证哦</h2>
                            </div>
                            <div className="cont">
                                <p>实名认证后</p>
                                <ul>
                                    <li>
                                        <IconFont type="iconshenqingshiyong" />
                                        <p>产品试用</p>
                                    </li>
                                    <li>
                                        <IconFont type="icongoumai" />
                                        <p>产品购买</p>
                                    </li>
                                    <li>
                                        <IconFont type="icondingdan" />
                                        <p>订单查询</p>
                                    </li>
                                    <li>
                                        <IconFont type="iconfapiaochaxun" />
                                        <p>发票查询</p>
                                    </li>
                                </ul>
                            </div>

                            <Button
                                type="primary"
                                onClick={() => {
                                    history.push('/Organization');
                                }}
                                size="large"
                            >
                                立即认证
                            </Button>
                        </Modal>
                    </div>
                )
            }
        />
    );
};

const AUTHED_ROUTES = ['MyProduct', 'MyOrder', 'MyBill', 'MyTrial'];

/**
 * @description 默认布局组件
 */
export const DefaultLayout = () => {
    return (
        <Layout className="app">
            <BackTop />
            <AppHeader activeMenuName="控制台" />
            <Layout style={{ position: 'relative' }}>
                <AppAside menuToggle={false} menu={menu} />
                {/* <Layout style={{ marginLeft: '200px', minHeight: '100vh' }}> */}
                <Content className="layout-content">
                    <Switch>
                        {routes.map((item) =>
                            AUTHED_ROUTES.some((i) => item.path.includes(i)) ? (
                                <AuthedRoute
                                    key={item.path}
                                    path={item.path}
                                    exact={item.exact}
                                    component={item.component}
                                />
                            ) : (
                                <Route
                                    key={item.path}
                                    path={item.path}
                                    exact={item.exact}
                                    component={item.component}
                                />
                            )
                        )}
                        <Redirect to="/404" />
                    </Switch>
                </Content>
                {/* </Layout> */}
            </Layout>
            {/* <AppFooter /> */}
        </Layout>
    );
};
