/**
 * @file   用户修改手机号
 * @create tianmu 2020/7/9
 * @update tianmu 2020/7/9
 */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MobileOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, notification, Radio, Row, Select, Steps, Space, Modal } from 'antd';
import { validator } from '../../utils/index.js';
import { querySmsVCode, queryUserInfo, updatePhone } from '../../service/index.js';
import './index.scss'
import { InfoBox } from './InfoBox.jsx';
import { useHistory } from 'react-router-dom';
import { getUserDetail, logout } from '../../pages/login/store.js';
import { useDispatch } from 'react-redux';

const Steppers = (props) => {
    const { stepNum } = props;
    return (
        <Steps labelPlacement="vertical" current={stepNum}>
            <Steps.Step title="原手机号验证" />
            <Steps.Step title="新手机号验证" />
            <Steps.Step title="完成" />
        </Steps>
    );
}

// 发送cd时间
const TIME_LIMIT = 60;
const MACHINE_AUTH_VID = process.env.ENV_MACHINE_AUTH_VID

const Step0 = (props) => {
    const { setStepNum, formState, setFormState } = props;
    const [form] = Form.useForm();
    const [isSending, setSending] = useState(false);
    const [time, setTime] = useState(TIME_LIMIT);
    const ref = useRef(null);
    // const [isAuth, setAuth] = useState(false);
    useEffect(() => {
        // window
        //     .vaptcha({
        //         vid: MACHINE_AUTH_VID, // 验证单元id
        //         type: 'click', // 显示类型 点击式
        //         scene: 0, // 场景值 默认0
        //         container: '#vaptchaContainer', // 容器，可为Element 或者 selector
        //         offline_server: '' // 离线模式服务端地址，若尚未配置离线模式，请填写任意地址即可。
        //     })
        //     .then(function (vaptchaObj) {
        //         vaptchaObj.render(); // 调用验证实例 vpObj 的 render 方法加载验证按钮
        //         vaptchaObj.listen('pass', function () {
        //             setAuth(true);
        //             // eslint-disable-next-line max-nested-callbacks
        //             setTimeout(() => {
        //                 // vaptcha验证 3 分钟后失效
        //                 setAuth(false)
        //             }, 1000 * 60 * 3)
        //         });
        //     });

        queryUserInfo().then(res => {
            if (res.data.code === 200) {
                form.setFieldsValue({
                    phone: res.data.data?.customerPhone
                })
            }
        })

        // return () => {
        //     // 销毁组件时如果存在定时器求删除它
        //     if (ref.current) {
        //         window.clearInterval(ref.current);
        //     }
        // };
    }, [form]);

    // 获取手机验证码
    const query = useCallback(async (phone) => {
        // 原手机号认证，手机号存在，因此类型为1（类似于登录）
        const res = await querySmsVCode(phone, 1);
        // 根据返回的错误代码显示错误信息
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '验证码发送成功，请注意查收！',
                duration: 5
            });

            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return false;
        }
    }, []);

    // 处理点击获取验证码按钮事件
    const handleClick = useCallback(
        async (phone) => {
            if (isSending) return;
            setSending(true);
            const success = await query(phone);
            // 如果请求验证码不成功直接返回
            if (!success) {
                setSending(false);
                return;
            }
            ref.current = setInterval(() => {
                setTime((time) => {
                    const left = time - 1;
                    // 点击验证码之后TIME_LIMIT秒
                    if (left === 0) {
                        setTime(TIME_LIMIT);
                        window.clearInterval(ref.current);
                        setSending(left > 0);
                    }
                    return left;
                });
            }, 1000);
        },
        [query, isSending]
    );


    const handleSubmit = async () => {
        await form.validateFields();
        const values = form.getFieldsValue();
        setFormState({
            ...formState,
            oldSvCode: values.oldSvCode,
        })
        setStepNum(1)
    }

    return (
        <div className="change-phone-stepbox step0">
            <Form layout="horizontal" form={form} >
                <Row>
                    <Col span={[4]} className="change-phone-formlabel">
                        原手机号：
                    </Col>
                    <Col span={[20]}>
                        <Form.Item
                            name="phone"
                            // rules={[
                            //     () => ({
                            //         validator(rule, value) {
                            //             if (!value) {
                            //                 return Promise.reject(new Error('原手机号不能为空!'));
                            //             }
                            //             if (!validator().phoneNumRegex.test(value)) {
                            //                 return Promise.reject(new Error('请输入正确的手机号!'));
                            //             }
                            //             const u = getUserDetail();
                            //             if (u && u.customerPhone !== value) {
                            //                 return Promise.reject(new Error('请输入该帐号绑定的原手机号!'));
                            //             }
                            //             return Promise.resolve()
                            //         }
                            //     })
                            // ]}
                        >
                            <Input
                                disabled={true}
                                size="large"
                                placeholder="请输入原手机号"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
                    <div id="vaptchaContainer" style={{ width: '100%', height: 40 }}>
                        <div className="vaptcha-init-main">
                            <div
                                className="vaptcha-init-loading"
                                style={{ textAlign: 'center', lineHeight: '40px' }}
                            >
                                <img
                                    src="https://r.vaptcha.net/public/img/vaptcha-loading.gif"
                                    alt="vaptcha"
                                />

                                <span className="vaptcha-text" style={{ paddingLeft: '5px' }}>
                                    正在生成验证...
                                </span>
                            </div>
                        </div>
                    </div>
                </Form.Item> */}
                <Row>
                    <Col span={[4]} className="change-phone-formlabel">
                        验证码：
                    </Col>
                    <Col span={[16]}>
                        <Form.Item
                            name="oldSvCode"
                            rules={[
                                {
                                    required: true,
                                    message: '验证码不能为空!'
                                },
                                {
                                    len: 6,
                                    message: '验证码长度为6位！'
                                }
                            ]}
                        >
                            <Input
                                placeholder="验证码"
                                className="sendcodeinput"
                                prefix={<MobileOutlined className="site-form-item-icon" />}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={[4]} style={{ textAlign: 'right' }}>
                        <Button
                            size="large"
                            className={`sendcodebtn ${isSending
                                // || !isAuth
                                ? '' : 'active'}`}
                            disabled={isSending
                                // || !isAuth
                            }
                            onClick={() => {
                                // console.log("click");
                                const phone = form.getFieldValue('phone');
                                // 手机号不为空时处理验证码获取事件
                                if (phone) {
                                    handleClick(phone);
                                } else {
                                    notification.info({
                                        className: 'custom-antd-notification',
                                        message: '请先输入手机号',
                                        duration: 5
                                    });
                                }
                            }}
                        >
                            {isSending ? `${time}秒后重新发送` : '发送验证码'}
                        </Button>
                    </Col>
                </Row>
                <div className="change-phone-confirmbox">
                    <Button
                        // disabled={!isAuth}
                        type="primary"
                        className="change-phone-confirmbtn"
                        onClick={handleSubmit}
                    >
                        下一步
                    </Button>
                </div>
            </Form>
        </div>
    );
};

const Step1 = (props) => {
    const { setStepNum, formState, setFormState } = props;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [isSending, setSending] = useState(false);
    const [time, setTime] = useState(TIME_LIMIT);
    const ref = useRef(null);
    const [isAuth, setAuth] = useState(false);
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        window
            .vaptcha({
                vid: MACHINE_AUTH_VID, // 验证单元id
                type: 'click', // 显示类型 点击式
                scene: 0, // 场景值 默认0
                container: '#vaptchaContainer', // 容器，可为Element 或者 selector
                offline_server: '' // 离线模式服务端地址，若尚未配置离线模式，请填写任意地址即可。
            })
            .then(function (vaptchaObj) {
                vaptchaObj.render(); // 调用验证实例 vpObj 的 render 方法加载验证按钮
                vaptchaObj.listen('pass', function () {
                    setAuth(true);
                });
            });

        return () => {
            // 销毁组件时如果存在定时器求删除它
            if (ref.current) {
                window.clearInterval(ref.current);
            }
        };
    }, []);

    // 获取手机验证码
    const query = useCallback(async (phone) => {
        // 新手机号认证，手机号不存在，因此类型为2（类似于注册）
        const res = await querySmsVCode(phone, 2);
        // 根据返回的错误代码显示错误信息
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '验证码发送成功，请注意查收！',
                duration: 5
            });

            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return false;
        }
    }, []);

    // 处理点击获取验证码按钮事件
    const handleClick = useCallback(
        async (phone) => {
            if (isSending) return;
            setSending(true);
            const success = await query(phone);
            // 如果请求验证码不成功直接返回
            if (!success) {
                setSending(false);
                return;
            }
            ref.current = setInterval(() => {
                setTime((time) => {
                    const left = time - 1;
                    // 点击验证码之后TIME_LIMIT秒
                    if (left === 0) {
                        setTime(TIME_LIMIT);
                        window.clearInterval(ref.current);
                        setSending(left > 0);
                    }
                    return left;
                });
            }, 1000);
        },
        [query, isSending]
    );


    const handleSubmit = async () => {
        await form.validateFields();
        const values = form.getFieldsValue();
        setLoading(true)
        const res = await updatePhone({
            ...formState,
            newPhone: values.newPhone,
            svCode: values.svCode,
        });
        if (res.data.code === 200) {
            console.log(res.data)
            notification.success({
                className: 'custom-antd-notification',
                message: '手机修改提交成功，请重新登录！',
                duration: 5
            });
            setLoading(false);
            setStepNum(2)
            setTimeout(() => {
                dispatch(logout()).then(() => {
                    history.push('/accountLogin')
                })
            }, 1000)
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            setLoading(false)
        }
    }

    return (
        <div className="change-phone-stepbox step1">
            <Form layout="horizontal" form={form} >
                <Row>
                    <Col span={[4]} className="change-phone-formlabel">
                    新手机号：
                    </Col>
                    <Col span={[20]}>
                        <Form.Item
                            name="newPhone"
                            rules={[
                                () => ({
                                    validator(rule, value) {
                                        if (!value) {
                                            return Promise.reject(new Error('新手机号不能为空!'));
                                        }
                                        if (!validator().phoneNumRegex.test(value)) {
                                            return Promise.reject(new Error('请输入正确的手机号!'));
                                        }
                                        return Promise.resolve()
                                    }
                                })
                            ]}
                        >
                            <Input size="large" placeholder="请输入新手机号" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
                    <div id="vaptchaContainer" style={{ width: '100%', height: 40 }}>
                        <div className="vaptcha-init-main">
                            <div
                                className="vaptcha-init-loading"
                                style={{ textAlign: 'center', lineHeight: '40px' }}
                            >
                                <img
                                    src="https://r.vaptcha.net/public/img/vaptcha-loading.gif"
                                    alt="vaptcha"
                                />

                                <span className="vaptcha-text" style={{ paddingLeft: '5px' }}>
                                    正在生成验证...
                                </span>
                            </div>
                        </div>
                    </div>
                </Form.Item>
                <Row>
                    <Col span={[4]} className="change-phone-formlabel">
                        验证码：
                    </Col>
                    <Col span={[16]}>
                        <Form.Item
                            name="svCode"
                            rules={[
                                {
                                    required: true,
                                    message: '验证码不能为空!'
                                },
                                {
                                    len: 6,
                                    message: '验证码长度为6位！'
                                }
                            ]}
                        >
                            <Input
                                placeholder="验证码"
                                className="sendcodeinput"
                                prefix={<MobileOutlined className="site-form-item-icon" />}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={[4]} style={{ textAlign: 'right' }}>
                        <Button
                            size="large"
                            className={`sendcodebtn ${isSending || !isAuth ? '' : 'active'}`}
                            disabled={isSending || !isAuth}
                            onClick={() => {
                                // console.log("click");
                                const phone = form.getFieldValue('newPhone');
                                // 手机号不为空时处理验证码获取事件
                                if (phone) {
                                    handleClick(phone);
                                } else {
                                    notification.info({
                                        className: 'custom-antd-notification',
                                        message: '请先输入手机号',
                                        duration: 5
                                    });
                                }
                            }}
                        >
                            {isSending ? `${time}秒后重新发送` : '发送验证码'}
                        </Button>
                    </Col>
                </Row>
                <div className="change-phone-confirmbox">
                    <Button
                        loading={loading}
                        disabled={!isAuth}
                        type="primary"
                        className="change-phone-confirmbtn"
                        onClick={handleSubmit}
                    >
                        提交
                    </Button>
                </div>
            </Form>
        </div>
    );
};

const Step2 = () => {
    const history = useHistory()
    return (
        <div className="change-phone-stepbox infobox">
            <InfoBox
                title="手机修改成功！"
            />
            <div style={{textAlign: 'center', marginTop: 20}}>
                <Button onClick={() => history.goBack()}>返回</Button>
            </div>
        </div>
    );
}


/**
 * @description 用户修改手机号
 */
export const CustomerChangePhone = () => {
    const [stepNum, setStepNum] = useState(0);
    const [formState, setFormState] = useState({
        oldSvCode: "",
        newPhone: "",
        svCode: "",
    })
    return (
        <div className="change-phone">
            <div className="page-header">
                <div className="title">修改手机号</div>
            </div>
            <div className="page-content">
                <div className="change-phone">
                    <Steppers stepNum={stepNum} />
                    {stepNum === 0 && <Step0 setStepNum={setStepNum} formState={formState} setFormState={setFormState} />}
                    {stepNum === 1 && <Step1 setStepNum={setStepNum} formState={formState} setFormState={setFormState} />}
                    {stepNum === 2 && <Step2 setStepNum={setStepNum} formState={formState} setFormState={setFormState} />}
                </div>
            </div>
        </div>
    );
};
