import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { searchStrToObj } from '../../utils'
import { wechatLogin } from '../../service';
import { useDispatch } from 'react-redux';
import { localDS } from '../../storage';
import { wechatUserLogin } from '../login/store';
import { notification } from 'antd';
// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;

// 微信绑定授权与扫码关注组件
export const WechatLogin = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        // 用户微信扫码后回调到该页面，静默登录
        const silentWechatLogin = async () => {
            const searchStr = location.search;
            const o = searchStrToObj(searchStr)
            if (o && o.code) {
                const loginRes = await wechatLogin(o.code);
                const { authorizationc, authorizationu, systemId, openId } = loginRes.data?.data;
                if (authorizationc && authorizationu) {
                    // 有后台用户信息，直接登录（通过jwt获取用户信息）
                    localDS.set("saasToken", authorizationc);
                    localDS.set("gatewayToken", authorizationu)
                    await dispatch(wechatUserLogin())
                    let targetLink;
                    if (systemId === '0') {
                        targetLink = platformAddr + `?token=${authorizationu}&systemId=${systemId}`;
                    } else {
                        targetLink = customerAddr + `?token=${authorizationu}&systemId=${systemId}`;
                    }
                    // console.log("targetLink: ", targetLink)
                    window.location.href = targetLink;

                } else if (authorizationc) {
                    localDS.set("saasToken", authorizationc)
                    dispatch(wechatUserLogin()).then(is_login => {
                        // 登陆成功跳转首页
                        if (is_login) {
                            history.replace("/index");
                        } else {
                            history.replace(`/accountLogin`);
                        }
                    })

                } else {
                    // 无用户信息，
                    history.replace(`/wechatAuthPhone?openId=${openId}`)
                    // notification.warn({
                    //     className: 'custom-antd-notification',
                    //     message: '请先进行用户注册！如已有账户则登录后进行微信绑定',
                    //     duration: 5,
                    // })

                    // // 没有用户信息，跳转注册页，注册完成的同时使用openId完成绑定
                    // history.replace(`/register?openId=${openId}`);
                }
            }
            else {
                // 用户没有关联微信，跳转登录页
                history.replace(`/accountLogin`);
            }
        }
        silentWechatLogin();
    }, [location.search, history, dispatch])
    return (
        <div />
    )
}
