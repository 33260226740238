/**
 * @file   个人中心模块
 * @create tianmu 2020/3/22
 * @update tianmu 2020/3/22
 */
import React, { useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';
import { Info } from './Info';
import { UserChangePwd } from './UserChangePwd';
import { Security } from './Security';
import { Binding } from './Binding';
import { Log } from './Log';
import { useHistory, useLocation } from 'react-router';
import { searchStrToObj } from '../../utils';

const { TabPane } = Tabs;

// 用户基本信息组件
export const UserCenter = () => {
    const location = useLocation();
    const history = useHistory();
    const [k, setK] = useState('1');
    useEffect(() => {
        const o = searchStrToObj(location.search);
        if (o?.key) {
            setK(o.key);
        }
    }, [location]);

    function callback(key) {
        const url = location.pathname + `?key=${key}`;
        setK(key);
        history.replace(url);
    }

    return (
        <>
            <div className="page-header">
                <div className="title">个人中心</div>
            </div>
            <Layout className="page-content">
                <Tabs activeKey={k} onChange={callback}>
                    <TabPane tab="基本信息" key="1">
                        <Info />
                    </TabPane>
                    <TabPane tab="密码修改" key="2">
                        <UserChangePwd />
                    </TabPane>
                    <TabPane tab="安全设置" key="3">
                        <Security />
                    </TabPane>
                    <TabPane tab="绑定设置" key="4">
                        <Binding />
                    </TabPane>
                    <TabPane tab="登录日志" key="5">
                        <Log />
                    </TabPane>
                </Tabs>
            </Layout>
        </>
    );
};
