/**
 * @file   客流手机系统介绍页面
 * @create macaiqi 2021/07/29
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import cls from 'classnames'
import './style.scss'

/**
 * @description 客流系统组件
 */

export const WapTrafficManagement = () => {
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    const settings = {
        arrows: false,
        dots: false,
        // infinite: true,
        className: "center",
        centerMode: true,
        centerPadding: "3rem",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    const sec3 = [
        {
            name: '灵活的客流管理规则配置',
            des: '提供展厅、展览、进出客流等规则的灵活配置，适应多类型场馆、园区、展厅的客流统计需求。',
            icon: 'iconpeizhiguanli',
            duration: '1s',
            delay: '0.5s'
        },
        {
            name: '动态实时的客流监测预警',
            des: '通过客流实时监控和超限预警，保障良好的参观体验，为维护展厅秩序提供决策信息支撑。',
            icon: 'icona-03-yujingyucefenxi',
            duration: '1s',
            delay: '0.7s'
        },
        {
            name: '多维度的客流统计与对比分析',
            des: '从范围、周期等多维度进行客流走势统计和对比，全面掌握全馆/园区/展厅客流情况。',
            icon: 'iconjiageyuce',
            duration: '1s',
            delay: '0.9s'
        },
        {
            name: '基于深度学习的客流预测',
            des: '基于深度学习方法建立客流预测模型，准确预测未来入馆/入园客流量，辅助管理与决策。',
            icon: 'icon503tongji_bingtu',
            duration: '1s',
            delay: '1.1s'
        },
    ]
    // 功能组成
    const gongneng = [
        {
            name: "客流监控",
            tab: ['缩略图模式', '平面图模式'],
            des: '提供全馆/景区客流摄像头的视频监控画面，方便管理者在线观察各楼层不同区域的客流摄像头实时运行情况及画面。支持以缩略图和平面图两种模式进行监控画面的呈现。',
            icon: 'icona-404jiankongshexiangtou-xianxing'
        },
        {
            name: "客流统计",
            tab: ['全馆、园区客流量统计', '展厅客流量统计', '区域时段客流量统计'],
            des: '对全馆/园区及各展厅的客流进行汇总统计，主要包括全馆客流量统计、展厅客流量统计、区域时段客流量统计。',
            icon: 'iconshujutongji1'
        },
        {
            name: "客流分析",
            tab: ['观众聚集分析', '观展热度分析', '观众客流预测'],
            des: '结合全馆/园区的楼层平面图，根据不同展厅当前的客流量，以热力图的形式直观呈现当前各展厅的密集程度（密集程度根据预设的客流承载量最大阈值进行计算）。当全馆/园区/展厅的实时客流超过承载量最大阈值时，在全馆/园区的楼层平面图上标记预警，并同时给相关负责人发送预警消息。',
            icon: 'icona-fenxi1'
        },
        {
            name: "基础配置",
            tab: ['楼层信息管理', '客流设备管理', '展厅设备绑定', '展览位置绑定', '预警消息提醒'],
            des: '提供客流相关的基础数据/参数配置，主要包括客流设备管理、展厅及客流承载量、展厅进出客流规则、展厅关联的临展、预警消息提醒等的配置管理。',
            icon: 'iconpeizhi'
        },
        {
            name: "客流展示",
            tab: ['客流数据展示'],
            des: '向用户直观展示整个全馆/园区及各个展厅的实时客流数据，主要包括当前在馆/在园/在展厅总人数、当日入馆/入园/入展厅总人数，并支持在不同终端上呈现（如手机端、大屏），可配置实时客流数据的更新机制（如每隔一分钟更新一次）。',
            icon: 'iconzhanshi'
        },
    ]
    const [down, setDown] = useState()
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        if (anchorName || anchorName === 0) {
            const height = document.getElementById(anchorName).offsetTop - 40; // 计算需要向上移动的距离
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        }
    };
    const banner = useRef()
    const activeref = useRef()
    const { search } = useLocation()
    useEffect(() => {

        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // 滚动超过banner后nav吸顶
            if (scrollT >= banner.current.clientHeight + banner.current.offsetTop) {
                activeref.current?.classList.add('active');
            } else {
                activeref.current?.classList.remove('active');
            }
            // 滚动到对应点，对应nav高亮
            for (let i = 0; i < tab.length; i++) {

                if (scrollT >= document.getElementById(`item${i}`).offsetTop - 40) {
                    document.getElementById(`link${i}`).classList.add('active');
                    for (let j = 0; j < tab.length; j++) {
                        if (i !== j) {
                            document.getElementById(`link${j}`).classList.remove('active');
                        }
                    }
                }

            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(`item${search.split('?')[1]}`)
        }
    }, [tab.length, search]);

    return (<>
        <IntroHeader />
        <div className="mobile-content traffic" ref={activeref}>
            <div className="banner" ref={banner}>
                <div className="content">
                    <p className="big">客流管理系统</p>
                    <div className="cont">以观众为中心，结合公共文化场馆、文旅景区的客流监控数据，实现客流数据的实时显示和管理，合理控制入馆、入园的观众数量。通过客流数据实时统计、历史同期对比、客流预测等，为管理决策提供数据支持，提高服务水平。</div>
                    <div className="btnbox">
                        {/* <div className="btn btn1">
                             观看演示
                         </div> */}
                        {/* <div className=" btn btn2">
                             免费体验
                         </div> */}
                    </div>

                </div>
            </div>
            <div className="tab" >
                <ul>
                    {tab.map((item, index) => {
                        return (
                            <li key={index} id={`link${index}`} onClick={() => {
                                scrollToAnchor(`item${index}`)
                            }}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="people" id='item0'>
                <p className="title" >用户群体</p>
                <p className="txt">本产品适用于有客流管理需求的公共文化场馆、文旅景区。</p>
            </div>
            <div className="section2" id='item1' >
                <p className="title" >功能组成</p>
                <div className="functionof">
                    <Slider {...settings} key={Math.random()} >
                        {gongneng.map((item, index) => {
                            return (
                                <div className="itembox" key={index}>
                                    <div className="item">
                                        <i className={cls('iconfont', item.icon)} />
                                        <p className='tip'>{item.name}</p>
                                        {item.tab.map((item1, i1) => {
                                            return (<p className="txt" key={i1}>{item1}</p>)
                                        })}

                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div className="section3" id='item2'>
                <p className="title">产品特点</p>
                {
                    sec3.map((item, index) => {
                        return (
                            <div className={cls("item")} key={index} >
                                <div className="box">
                                    <div className="l">
                                        <i className={cls('iconfont', item.icon)} />
                                        {item.name}
                                    </div>
                                    <div className="down" />
                                </div>
                                <div className="text">
                                    {item.des}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="price" id='item3'>
                <p className="title">产品价格</p>
                <div className="num">
                    4800
                    <span>元/年</span>
                </div>
            </div>
            <div className="update" id='item4'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }} />
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
