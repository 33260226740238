/**
 * @file   基本信息表单模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/16
 */
import React, { useState, useEffect, useCallback } from 'react'
import { Input, Form, Button, notification, Tooltip, Modal } from 'antd'
import { EditFilled } from '@ant-design/icons';
import { updateUserInfo, queryUserInfo, updateName } from '../../../service'
import { validator, isEqual } from '../../../utils';
import { cacheUserDetail } from '../../../pages/login/store';

/**
 * @description 修改用户基本信息表单
 * @param {Object} props 父组件传递的参数
 * @param {Object} props.user 初始的用户详情数据
 */
export const InfoForm = () => {
    const [form] = Form.useForm();
    const [initValues, setInitValues] = useState(null);
    const [formChanged, setFormChanged] = useState(false);
    const [needModifyName, setNeedModifyName] = useState(false);
    const [editName, setEditName] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const fillForm = useCallback(() => {
        queryUserInfo().then(res => {
            const user = res.data.data;
            const init = {
                customerName: user.customerName,
                userId: user.userId,
                customerPhone: user.customerPhone,
                email: user.email,
                wechat: user.wechat,
                createTime: user.createTime,
            };
            form.setFieldsValue(init);
            setInitValues(init);
            setNeedModifyName(user.randomName)
            cacheUserDetail(user)
        })
    }, [form]);

    useEffect(() => {
        fillForm();
    }, [fillForm]);

    const [loading, setLoading] = useState(false)

    const onFinish = values => {
        // console.log('values: ', values)
        setLoading(true);
        if (editName) {
            // 修改用户名
            updateName({
                isRetain: false,
                name: values.customerName
            }).then(res => {
                if (res?.data?.code === 200) {
                    fillForm()
                    notification.success({
                        className: 'custom-antd-notification',
                        message: '用户名保存成功！',
                        duration: 5,
                    })
                    setEditName(false)
                    setFormChanged(false)
                } else {
                    notification.warn({
                        className: 'custom-antd-notification',
                        message: res?.data?.msg,
                        duration: 5,
                    })
                }
                setLoading(false)
            })
        } else {
            // 没有修改任何东西
            if (!formChanged) return;
            setLoading(true);
            updateUserInfo({
                customerName: values.customerName,
                userId: values.userId,
                customerPhone: values.customerPhone,
                email: values.email,
                wechat: values.wechat,
            }).then(res => {
                if (res.data.code === 200) {
                    notification.success({
                        className: 'custom-antd-notification',
                        message: '修改成功！',
                        duration: 5,
                    })
                    fillForm();
                    setFormChanged(false)
                } else {
                    notification.warn({
                        className: 'custom-antd-notification',
                        message: res.data.msg,
                        duration: 5,
                    })
                }
                setLoading(false)
            })

        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (<>
    <Form
        labelCol={{
            span: 4,
        }}
        wrapperCol={{
            span: 8,
        }}
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={() => {
            const values = form.getFieldsValue();
            setFormChanged(!isEqual(values, initValues))
        }}
    >
        <Form.Item
            name="userId"
            hidden
        >
            <Input />
        </Form.Item>
        <Form.Item
            name="customerName"
            label="帐号名"
            rules={[
                {required: true, message: '请输入用户名!'},
                () => ({
                    validator(rule, value) {
                        if (!value) return Promise.resolve(); // 上一个已经校验过
                        const isNum = validator().numberRegex.test(value)
                        const isPhone = validator().phoneNumRegex.test(value)
                        const isChName = /^[\u4e00-\u9fa5]+$/i.test(value) && value.length >= 2 // 纯中文名
                        if (isChName) {
                            return Promise.resolve();
                        }
                        // 2022/9/5 开放纯数字
                        // if (isNum || isPhone) {
                        //     return Promise.reject(new Error('用户名为6-20位，中、英、数字字符，不能是纯数字！'));
                        // }
                        if (value?.length < 6 || value.length > 20) {
                            return Promise.reject(new Error('用户名为6-20位，中、英、数字字符！'));
                        }
                        return Promise.resolve();
                    }
                })
            ]}
            extra={
                needModifyName ? (
                    <Tooltip
                        // color="#fff"
                        title="修改账户名"
                    >
                        <span
                            style={{
                                position: 'absolute',
                                right: -30,
                                top: 0,
                                fontSize: '18px',
                                color: 'rgb(61, 188, 142)',
                                cursor: 'pointer'
                            }}
                            onClick={()=> {
                                setIsOpen(true)
                            }}
                        >
                            <EditFilled />
                        </span>
                    </Tooltip>
                ) : null}
        >
            <Input disabled={!editName} />
        </Form.Item>
        <Form.Item
            name="createTime"
            label="注册时间"
        >
            <Input disabled />
        </Form.Item>
        <Form.Item
            name="customerPhone"
            label="手机号"
            rules={[
                {required: true, pattern: validator().phoneNumRegex, message: '请输入正确的手机号'},
            ]}

        >
            <Input placeholder='请输入手机号' disabled />
        </Form.Item>

        <Form.Item
            name="email"
            label="邮箱"
            rules={[
                {type: "email", message: '请输入有效的邮箱!'}
            ]}
        >
            <Input
                placeholder='请输入邮箱'
                disabled={editName}
            />
        </Form.Item>
        <Form.Item
            name="wechat"
            label="微信号"
        >
            <Input
                placeholder='请输入微信号码'
                disabled={editName}
            />
        </Form.Item>
        <Form.Item>
                <Form.Item noStyle >
                    <Button
                        disabled={
                            editName
                            ? false
                            : !formChanged
                        }
                        type="primary"
                        htmlType='submit'
                        loading={loading}
                        style={{
                            marginLeft: '50%',
                            // background: 'rgb(51, 183, 137)',
                            // border: 'none',
                            // color: '#fff',
                        }}
                    >
                        保存修改
                    </Button>
                </Form.Item>
                {/* <Button
                    style={{
                        marginLeft: 20,
                        display: 'inline-block',
                    }}
                    onClick={fillForm}
                >
                    放弃
                </Button> */}
            </Form.Item>
    </Form>
    <Modal
        // title=""
        centered
        width={450}
        visible={isOpen}
        onCancel={()=>{ setIsOpen(false) }}
        maskClosable={false}
        keyboard={false}
        footer={[
            <Button key="back" onClick={()=>{ setIsOpen(false) }}>
                取消
            </Button>,
            <Button key="ok" type="primary" onClick={() => {
                setIsOpen(false)
                setEditName(true)
            }} >
                确定
            </Button>
        ]}
    >
        <div style={{height: '100px', padding: '35px 0', fontSize: '16px'}}>
            【帐号名】只允许修改一次，修改后使用新【帐号名】登录
        </div>
    </Modal>

    </>);
}
