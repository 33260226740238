/**
 * @file   消息列表模块
 * @create tianmu 2021/4/21
 * @update tianmu 2021/4/21
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Button, Input, Table, Form, notification } from 'antd';
import { queryNotice, updateNoticesStatusBatch, updateNoticeStatus } from '../../service';
import './index.scss';
import { AppFooter } from '../../containers/AppFooter';
import { AppHeader } from '../../containers/AppHeader';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUnReadMsgs } from '../../store/globStore';

const { Content } = Layout;

// 默认页面大小
const PAGE_SIZE = 10;

const statusStr = (status) => {
    switch (status) {
        case 0:
            return <span className="state-waring">未读</span>;
        case 1:
            return <span className="state-success">已读</span>;
        default:
            return '';
    }
};

// 消息列表组件
export const NoticeList = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: PAGE_SIZE
    });
    const [form] = Form.useForm();
    const [statusFilter, setStatusFilter] = useState(-1);
    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch()
    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback((params) => {
        return queryNotice(params).then(res => {
            const listData = res.data.data.records.map((item) => {
                return {
                    key: item.noticeId,
                    title: item,
                    status: item.isRead,
                };
            });
            setPagination({
                current: params.pageNum,
                pageSize: params.pageSize,
                total: res.data.data.total,
            });
            setDataSource(listData);
            setLoading(false);
        })
    }, []);

    const refreshNotice = useCallback(() => {
        return queryNotice({
            entity: {
                isRead: 0
            }
        }).then(res => {
            dispatch(setUnReadMsgs(res?.data?.data?.records))
        })
    }, [dispatch])

    useEffect(() => {
        fetchData({
            entity: {
                isRead: statusFilter !== -1 ? statusFilter : undefined // 不是显示全部时，传递对应的status数字
            },
            param: {
                pageNum: 1,
                pageSize: PAGE_SIZE
            }
        });
    }, [fetchData, statusFilter]);

    const columns = [
        {
            title: 'title',
            dataIndex: 'title',
            render: (item) => {
                return (
                <div>
                    <div style={{marginBottom: 5}}>
                        {statusStr(item.isRead)}
                        <a
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault();
                                updateNoticeStatus(item.noticeId)
                                // window.open(`/#${item.url}?content=${encodeURIComponent(
                                //     item.content
                                // )}&businessId=${item.businessId}&noticeId=${item.noticeId}`)
                                history.push(`${item.url}?content=${encodeURIComponent(
                                    item.content
                                )}&businessId=${item.businessId}&noticeId=${item.noticeId}`)

                            }}
                        >
                            <span style={{marginLeft: 10}}>{item.title}</span>
                        </a>
                    </div>
                    <div>
                        <span style={{color: '#ccc'}}>{item.createTime}</span>
                    </div>
                </div>
                )
            }
        },
        // {
        //     title: 'status',
        //     dataIndex: 'status',
        //     render: (status) => statusStr(status)
        // },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.status, // 已读的不可再选择并进行标记
        })
    };

    const handleTableChange = (pagination) => {
        const values = form.getFieldsValue();
        // const timeRange = values.createTimeQuery;
        fetchData({
            entity: {
                ...values,
                status: statusFilter !== -1 ? statusFilter : undefined,
                // createTimeQuery: timeRange && timeRange.map((t) => t.format('YYYY-MM-DD')).join('#')
            },
            param: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize
            }
        });
    };

    return (
        <Layout
            className="app"
            style={{
                background: 'rgb(241, 242, 246)'
            }}
        >
            <AppHeader />
            <div
                className="page-header"
                style={{
                    marginTop: 100,
                    padding: '0 10%',
                }}
            >
                <div className="title" style={{position: 'relative'}}>
                    消息中心
                    <span
                        style={{
                            position: 'absolute',
                            right: 0,
                            color: 'rgb(86, 192, 150)',
                            cursor: 'pointer',
                            fontSize: '16px'
                        }}
                        onClick={() => {history.goBack()}}
                    >
                        &lt;返回
                    </span>
                </div>
            </div>
            <Content
                style={{
                    position: 'relative',
                    width: '80%',
                    margin: '0 auto',
                    padding: '20px',
                    height: '100%',
                    background: '#fff',
                }}
            >

            <div className="noticelist">
                <div
                    className="table-meta noticelist-table-meta"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div className="noticelist-table-filter">
                        <span
                            onClick={() => setStatusFilter(-1)}
                            className={`noticelist-table-filteritem ${
                                statusFilter === -1 ? 'active' : ''
                            }`}
                        >
                            全部消息
                        </span>
                        <span
                            onClick={() => setStatusFilter(0)}
                            className={`noticelist-table-filteritem ${
                                statusFilter === 0 ? 'active' : ''
                            }`}
                        >
                            未读消息
                        </span>
                        <span
                            onClick={() => setStatusFilter(1)}
                            className={`noticelist-table-filteritem ${
                                statusFilter === 1 ? 'active' : ''
                            }`}
                        >
                            已读消息
                        </span>
                    </div>
                    <Form
                        layout="inline"
                        form={form}
                        onFinish={() => handleTableChange({ current: 1, pageSize: PAGE_SIZE })}
                    >
                        <Button
                            type="primary"
                            onClick={async () => {
                                if (Array.isArray(selectedRows) && selectedRows.length > 0) {
                                    updateNoticesStatusBatch(selectedRows.map(i => i.key)).then(res => {
                                        if (res.data.code === 200) {
                                            fetchData({
                                                entity: {
                                                    status: statusFilter !== -1 ? statusFilter : undefined // 不是显示全部时，传递对应的status数字
                                                },
                                                param: {
                                                    pageNum: 1,
                                                    pageSize: PAGE_SIZE
                                                }
                                            })
                                            refreshNotice()
                                            notification.success({
                                                className: 'custom-antd-notification',
                                                message: "消息标记已读成功！",
                                                duration: 5,
                                            })
                                        } else {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                message: res.data.msg,
                                                duration: 5,
                                            })
                                        }
                                    })
                                } else {
                                    notification.warn({
                                        className: 'custom-antd-notification',
                                        message: '请选择要标记为已读的消息！',
                                        duration: 5,
                                    })
                                }
                            }}
                        >
                            标记已读
                        </Button>
                        <Button
                            type="primary"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                if (Array.isArray(dataSource) && dataSource.length > 0) {
                                    if (!dataSource.some(i => !i.status)) {
                                        notification.warn({
                                            className: 'custom-antd-notification',
                                            message: "该页消息全部已读！",
                                            duration: 5,
                                        })
                                        return;
                                    }
                                    updateNoticesStatusBatch(dataSource.map(i => i.key)).then(res => {
                                        if (res.data.code === 200) {
                                            fetchData({
                                                entity: {
                                                    status: statusFilter !== -1 ? statusFilter : undefined // 不是显示全部时，传递对应的status数字
                                                },
                                                param: {
                                                    pageNum: 1,
                                                    pageSize: PAGE_SIZE
                                                }
                                            })
                                            refreshNotice()
                                            notification.success({
                                                className: 'custom-antd-notification',
                                                message: "消息标记已读成功！",
                                                duration: 5,
                                            })
                                        } else {
                                            notification.warn({
                                                className: 'custom-antd-notification',
                                                message: res.data.msg,
                                                duration: 5,
                                            })
                                        }
                                    })
                                }
                            }}
                        >
                            全部已读
                        </Button>

                        <Form.Item name="title">
                            <Input placeholder="请输入关键字" />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            查询
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                fetchData({
                                    entity: {
                                        status: statusFilter !== -1 ? statusFilter : undefined // 不是显示全部时，传递对应的status数字
                                    },
                                    param: {
                                        pageNum: 1,
                                        pageSize: PAGE_SIZE
                                    }
                                });
                            }}
                        >
                            重置
                        </Button>
                    </Form>
                </div>

                <Table
                    showHeader={false}
                    columns={columns}
                    dataSource={dataSource}
                    rowSelection={{
                        ...rowSelection
                    }}
                    //  bordered
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => {
                            return `共 ${total} 条数据`;
                        }
                    }}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </div>
            </Content>
        <AppFooter />
    </Layout>

    );
};
