/**
 * @file   http模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */

import { notification } from 'antd';
import axios from 'axios';
import { localDS } from '../storage';
import { createHttpClient } from '@portal-site/data';

// 部分路由下 不做登录限制
const exclude = ['/register', '/accountLogin', '/phoneLogin'];

/**
 * @description 处理请求错误信息的通用函数
 * @param {Object} error Axios错误对象
 */
const errorProcessor = (error) => {
    // eslint-disable-next-line no-console
    console.log(error);
    // document.getElementById('error-overlay').classList.add('show');
    const status = error.response.status;
    const statusText = error.response.statusText;
    const message = error.response.data.message;
    const path = window.location.pathname;
    if (status === 401 && !exclude.includes(path)) {
        // 删除登陆信息，重新登陆
        localDS.remove('saasToken');
        localDS.remove('gatewayToken');
        localDS.remove('saasUser');
        localDS.remove('systemId');
        window.location.replace('/accountLogin');
    }
    // eslint-disable-next-line no-console
    console.log(`🚑🚑🚑请求失败:错误状态码:${status},错误描述：${statusText},详细信息：${message}`);
};

/***********************************************************
 * Saas接口使用的httpClient
 ***********************************************************/
export const saasHttp = axios.create({
    baseURL: window.SAAS_API
});

// saas相关的调用
saasHttp.interceptors.request.use((config) => {
    const token = localDS.get('saasToken');
    if (token) {
        config.headers.common['AuthorizationC'] = token;
        // config.headers.common['token'] = Encrypt(apikey);
        // config.headers.common['appId'] = apiId;
    }
    return config;
});

saasHttp.interceptors.response.use((res) => {
    // 未登录跳转至登录界面
    if (res.data.code === 310) {
        const path = window.location.pathname;
        if (!exclude.includes(path)) {
            if (res.data.msg) {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: res.data.msg,
                    duration: 5
                });
            }
            // 删除登陆信息，重新登陆
            localDS.remove('saasToken');
            localDS.remove('gatewayToken');
            localDS.remove('saasUser');
            localDS.remove('systemId');
            window.location.replace('/accountLogin');
        }
    } else if (res.data.code === 401) {
        // 验证失败，未传递或传递的token是错误的
        // 删除登陆信息，重新登陆
        localDS.remove('saasToken');
        localDS.remove('gatewayToken');
        localDS.remove('saasUser');
        localDS.remove('systemId');
        window.location.replace('/accountLogin');
    }
    return res;
}, errorProcessor);

/***********************************************************
 * 后台数据接口使用的httpClient
 ***********************************************************/
export const http = axios.create({
    baseURL: window.API
});

// 后台相关的调用
http.interceptors.request.use((config) => {
    const saasToken = localDS.get('saasToken');
    const gatewayToken = localDS.get('gatewayToken');
    if (gatewayToken) {
        // 使用后台Token执行后台权限操作(使用Authorization请求头)
        config.headers.common['AuthorizationU'] = gatewayToken;
    }

    if (saasToken) {
        // 没有后台权限时，通过SaaS平台的权限，使用一键登录获取后台权限(使用AuthorizationC请求头)
        config.headers.common['AuthorizationC'] = saasToken;
        // config.headers.common['token'] = Encrypt(apikey);
        // config.headers.common['appId'] = apiId;
    }
    return config;
});

http.interceptors.response.use((res) => {
    // 未登录跳转至登录界面
    // 删除登陆信息，重新登陆
    if (res.data.code === 310) {
        if (res.data.msg) {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
        }
        localDS.remove('saasToken');
        localDS.remove('gatewayToken');
        localDS.remove('saasUser');
        localDS.remove('systemId');
        window.location.replace('/accountLogin');
    } else if (res.data.code === 401) {
        // 验证失败，未传递或传递的token是错误的
        // 删除登陆信息，重新登陆
        localDS.remove('saasToken');
        localDS.remove('gatewayToken');
        localDS.remove('saasUser');
        localDS.remove('systemId');
        window.location.replace('/accountLogin');
    }
    return res;
}, errorProcessor);

/***********************************************************
 * 临时使用的httpClient
 ***********************************************************/
export const guestHttp = axios.create({
    baseURL: window.GUEST_API
});

guestHttp.interceptors.request.use((config) => {
    const saasToken = localDS.get('saasToken');
    if (saasToken) {
        // 没有后台权限时，通过SaaS平台的权限，使用一键登录获取后台权限(使用AuthorizationC请求头)
        config.headers.common['authorizationc'] = saasToken;
    }
    return config;
});

guestHttp.interceptors.response.use((res) => {
    // 未登录跳转至登录界面
    // 删除登陆信息，重新登陆
    if (res.data.code === 310) {
        if (res.data.msg) {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
        }
        localDS.remove('saasToken');
        localDS.remove('gatewayToken');
        localDS.remove('saasUser');
        localDS.remove('systemId');
        window.location.replace('/accountLogin');
    } else if (res.data.code === 401) {
        // 验证失败，未传递或传递的token是错误的
        // 删除登陆信息，重新登陆
        localDS.remove('saasToken');
        localDS.remove('gatewayToken');
        localDS.remove('saasUser');
        localDS.remove('systemId');
        window.location.replace('/accountLogin');
    }
    return res;
}, errorProcessor);

/***********************************************************
 * 新手入门文章类测试用
 ***********************************************************/
const appId = '4ba930e70bdd44abbc2b2976ba39bf2e';
const appKey = 'TSD958WBXMD4WUQ7R5P84L7RLLCAJHIJ';
export const testHttp = createHttpClient(window.TEST_API, appId, appKey);
