import { queryProductList, customerLogin  } from "../service";
import { localDS } from '../storage';
import { fetchIsOrgAuthed } from "./orgAuthUtils";
import { notification } from "antd";

// const platformAddr = process.env.ENV_PLATFORM_LOGIN;
// const customerAddr = process.env.ENV_CUSTOMER_LOGIN;
const platformAddr = window.PLATFORM_LOGIN;
const customerAddr = window.CUSTOMER_LOGIN;

// 单位枚举转为字符串描述
export const unitStr = (unit) => {
    switch (unit) {
        case 0:
            return '天';
        case 1:
            return '年';
        case 2:
            return '月';
        case 3:
            return '人';
        case 4:
            return 'GB';
        case 5:
            return '次';
        default:
            return '';
    }
};

// 展示单位的基数，如果基数为1不展示
export const displayQuota = (quota) => {
    if (quota === undefined || quota === null) return '';
    if (quota <= 1) return '';
    return quota;
}

export const fetchUnExpireProducts = async () => {
    const res = await queryProductList();
    if (res?.data?.code === 200 && res?.data?.data) {
        return res?.data?.data.filter(i =>
            // i.diff >= 0
            new Date() - new Date(i.expireTime) < 0

        )
    }
    return []
}

export const handleFreeTrial = async () => {
        const token = localDS.get('saasToken')
        if (!token) {
            window.location.href = "/accountLogin"
            return
        }
        // 是否通过机构认证
        const isAuthed = await fetchIsOrgAuthed();
        console.log("isAuthed: ", isAuthed)
        if (!isAuthed) {
            window.location.href = "/Organization"
            return;
        }
        const prods = await fetchUnExpireProducts();
        console.log("prods: ", prods)
        // 有已购买或试用产品且未过期
        if (Array.isArray(prods) && prods.length > 0) {
            const res = await customerLogin()
            console.log("customer login: ", res)
            // 请求错误直接返回登录
            if (res?.data?.code !== 200) {
                notification.warn({
                    className: 'custom-antd-notification',
                    description: res.data.msg,
                    duration: 5
                });
                window.location.href = "/accountLogin"
                return;
            }
            const d = res?.data?.data;
            if (d?.authorizationu && d?.systemId) {
                let loc = '';
                if (d?.systemId === '0') {
                    loc = `${platformAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`;
                } else {
                    loc = `${customerAddr}?token=${d?.authorizationu}&systemId=${d?.systemId}`;
                }
                localDS.set('gatewayToken', d?.authorizationu);
                window.open(loc);
            } else {
                window.location.href = "/Organization"
            }
        } else {
            window.location.href = "/Organization"
        }
    }
