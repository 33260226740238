
/**
 * @file   产品介绍欢迎页面
 * @create tianmu 2021/2/25
 * @update tianmu 2021/2/25
 */
import React, { useRef, useEffect } from 'react'
import Slider from 'react-slick';
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IntroHeader } from './header';
import { IntroFooter } from './footer';
import './index.scss'
import slide1 from '../../assets/home/slide1.png'
import RIGHT_ARROW from '../../assets/right-arrow.png'
// import Video from '../../assets/home/home.mp4'

/**
 * @description 产品介绍欢迎组件
 */
export const Introduction = () => {
    const settings = {
        customPaging: function (i) {
            return <span className="carousel-dot" data-idx={i} />;
        },
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />
    };
    const pics = [
        { picture: slide1 },
    ]
    const sliderRef = useRef();
    // 禁用右键:
    function stop() {
        return false;
    }
    useEffect(() => {
        let doc = document.getElementById('homevideo')
        doc.oncontextmenu = stop;
    })
    return (<div className='index-cont'>
        <IntroHeader />
        <div className='carousel-wrapper'>
            {/* <div
                className="carousel-prev"
                onClick={() => {
                    // toPrev();
                    sliderRef.current.slickPrev();
                }}
            >
                <img src={RIGHT_ARROW} alt="prev" />
            </div>
            <Slider {...settings} key={Math.random()} ref={sliderRef} >
                {pics?.map((item, index) => {
                    return (item.link ?
                        <Link to={item.link} key={index} className="carousel-item">
                            <figure className='portal-picture' >
                                <img src={item.picture} alt="" />
                            </figure>
                        </Link>
                        : <div key={index} className="carousel-item">
                            <figure className='portal-picture' >
                                <img src={item.picture} alt="" />
                            </figure>
                        </div>
                    );
                })}
            </Slider>
            <div
                className="carousel-next"
                onClick={() => {
                    // toNext();
                    sliderRef.current.slickNext();
                }}
            >
                <img src={RIGHT_ARROW} alt="next" />
            </div> */}
            <video src='./video/home.mp4' muted loop="loop" autoPlay id='homevideo' />
            <div className="txt">
                {/* <p>领先的智慧文旅</p>
                <p>SaaS服务平台</p> */}
            </div>
        </div>
        <IntroFooter />
    </div>
    )
}
