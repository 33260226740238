/**
 * @file   积分商品分类筛选模块
 * @create tianmu 2021/7/1
 * @update tianmu 2021/7/1
 */
import React from 'react';
import './index.scss'

const CategoryRow = ({ title, data }) => {
    return (
        <div className="row">
            <div className="head">
                <span className="title">
                    {title}
                </span>
            </div>
            <div className="extra-head">
                <a className="item active" href="/#">
                    全部
                </a>
            </div>
            <div className="body">
                <div className={`items ${data.length > 6 ? 'line2' : ''}`}>
                    {data.map((c, idx) => (
                        <div className="items-inner" key={idx}>
                            <a className="item" href="/#" onClick={(e) => e.preventDefault()}>
                                <span className="text">{c}</span>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}

// 积分商品分类筛选组件
export const CategoryFilter = () => {
    const bigCategories = ['手机话费', '图书教育', '电子卡券', '健康生活', '学习工具', '数码电器']
    const smallCategories = ['人文社科', '电影票', '电脑外设', '教辅图书', '保险', '地图册', '移动充值', '视频会员', '手机配件', '试题资源', '百货', '地球仪', '联通充值', '电信充值']
    const scoreCategories = ['0-500', '501-2000', '2001-5000', '5001-10000', '10001-20000', '20000以上']

    return (
        <div className="category-filter">
            <div className="category-groups">
                <CategoryRow title="分类" data={bigCategories} />
                <CategoryRow title="小分类" data={smallCategories} />
                <CategoryRow title="积分" data={scoreCategories} />
            </div>
        </div>
    );
};
