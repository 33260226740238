import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Input, Form, Button, notification, Checkbox } from 'antd';
import { UserOutlined, MobileOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { querySmsVCode, queryUserInfo, queryVCode, wechatSVCodeLogin } from '../../service';
import { searchStrToObj, validator } from '../../utils';
import { localDS, sessionDS } from '../../storage';
import { afterLogin, setUser } from '../login/store';
import AVATAR from '../../assets/avatar.png';
import './index.scss';
// 发送cd时间
const TIME_LIMIT = 60;

export const WechatAuthPhone = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isSending, setSending] = useState(false);
    // const [isAuth, setAuth] = useState(false);
    const [time, setTime] = useState(TIME_LIMIT);
    const ref = useRef(null);
    const [form] = Form.useForm();
    const searchStr = location.search;
    const o = searchStrToObj(searchStr)


    // 获取手机验证码
    const query = useCallback(async (phone) => {
        // 验证微信登录的验证码
        const res = await querySmsVCode(phone, 4);
        // 根据返回的错误代码显示错误信息
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '验证码发送成功，请注意查收！',
                duration: 5
            });
            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return false;
        }
    }, []);

    // 处理点击获取验证码按钮事件
    const handleClick = useCallback(
        async (phone) => {
            if (isSending) return;
            setSending(true);
            const success = await query(phone);
            // 如果请求验证码不成功直接返回
            if (!success) {
                setSending(false);
                return;
            }
            ref.current = setInterval(() => {
                setTime((time) => {
                    const left = time - 1;
                    // 点击验证码之后TIME_LIMIT秒
                    if (left === 0) {
                        setTime(TIME_LIMIT);
                        window.clearInterval(ref.current);
                        setSending(left > 0);
                    }
                    return left;
                });
            }, 1000);
        },
        [query, isSending]
    );

    // 表单提交成功
    const onFinish = async (values) => {
        setLoading(true);
        const { phone, svCode } = values;
        const res = await wechatSVCodeLogin(phone, svCode, o.openId)
        if (res.data.code !== 200) {
            // loginFail()
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5,
            })
            setLoading(false);
            return;
        }
        const authc = res.data?.data?.authorizationc
        if (!authc) {
            setLoading(false);
            return;
        }
        await dispatch(afterLogin(res));
        history.push("/wechatLoginSuccess")
        setLoading(false);
    };
    // 表单提交失败
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="wechatAuthPhone">
            <div className="wrapper">
                <div className="tip">
                    <div className='title'>
                        <CheckCircleFilled style={{marginRight: 10}} />
                        验证成功
                    </div>
                    <div className='desc'>尊敬的用户，请完善您的个人资料</div>
                </div>
                <div className="innerBox">
                    <div className="avatarBox">
                        <div className='avatar'>
                            <img src={AVATAR} alt="" />
                        </div>
                    </div>
                    <div className='border' />
                    <div className="formBox">
                        <div className='logo'>
                            <h2>瑞鹿云账号</h2>
                        </div>
                        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        pattern: validator().phoneNumRegex,
                                        message: '请输入正确的手机号!'
                                    }
                                ]}
                                wrapperCol={{ offset: 4, span: 16 }}
                            >
                                <Input
                                    placeholder="手机号"
                                    size="large"
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                                <Form.Item
                                    name="svCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: '验证码不能为空!'
                                        },
                                        {
                                            len: 6,
                                            message: '验证码长度为6位！'
                                        }
                                    ]}
                                    noStyle
                                >
                                    <Input
                                        placeholder="验证码"
                                        size="large"
                                        className="svcodeInput"
                                        prefix={<MobileOutlined className="site-form-item-icon" />}
                                    />
                                </Form.Item>
                                <Button
                                    size="large"
                                    className={`svcodeBtn ${isSending
                                        // || !isAuth
                                        ? '' : 'active'
                                    }`}
                                    disabled={isSending
                                        // || !isAuth
                                    }
                                    style={isSending ? null : {
                                        background: '#00a2fe',
                                        border: 'none',
                                    }}
                                    onClick={() => {
                                        console.log('click');
                                        const phone = form.getFieldValue('phone');
                                        // 手机号不为空时处理验证码获取事件
                                        if (phone) {
                                            handleClick(phone);
                                        } else {
                                            notification.info({
                                                className: 'custom-antd-notification',
                                                message: '请先输入手机号！',
                                                duration: 5
                                            });
                                        }
                                    }}
                                >
                                    {isSending ? `${time}秒后重新发送` : '发送验证码'}
                                </Button>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                                <Button
                                    style={{
                                        background: '#00a2fe',
                                        border: 'none',
                                    }}
                                    type="primary"
                                    block
                                    size="large"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    同意协议并绑定
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
