/**
 * @file   发票抬头表格模块
 * @create tianmu 2021/3/29
 * @update tianmu 2021/3/29
 */
 import React, { useCallback, useEffect, useState } from 'react';
 import { Link, useHistory, useLocation } from 'react-router-dom';
 import { Button, Table, message, Modal, notification } from 'antd';
 import './index.scss';
 import { deleteInvoiceTitleById, queryInvoiceTitleById, queryInvoiceTitleList, updateInvoiceTitleById } from '../../service';
 import {
    ExclamationCircleOutlined
} from '@ant-design/icons'
import { InvoiceTitleModal } from './InvoiceTitleModal';


// 发票抬头表格
export const InvoiceTitleTable = (props) => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    // 当前编辑的发票抬头
    const [currInvoiceTitle, setInvoiceTitle] = useState(null);
    const [showAddModal, setShow] = useState(false);
    const history = useHistory();

    const { invoiceType, orderId } = props;

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback(
        () => {
            setLoading(true);
           /**
            * 发票类型为普通发票时，发票抬头获取的数据应是个人+机构数据
            * 发票类型为专用发票时，发票抬头获取的数据应是机构数据
            */
            queryInvoiceTitleList({
               entity:{
                   titleType: invoiceType === 2 ? 2 : undefined
               },
               param:{pageNum:1, pageSize:100}
           }).then((res) => {
                // console.log(res)
                const listData = res.data.data.records.map((item) => {
                    return {
                        key: item.invoiceTitleId,
                        titleName: item.titleName,
                        dutyParagraph: item.dutyParagraph,
                        companyPhone: item.companyPhone,
                        accountBank: item.accountBank,
                        accountNumber: item.accountNumber,
                        companyAddress: item.companyAddress,
                        operations: item
                    };
                });
                setDataSource(listData);
                setLoading(false);
            });
        },
        [invoiceType]
    );

    useEffect(() => {
        fetchData();
    }, [location, fetchData]);

    // 删除发票抬头
    const delInvoiceTitle = useCallback((options) => {
        deleteInvoiceTitleById(options.invoiceTitleId)
        .then(res => {
            if (res.data.code === 200) {
                notification.success({
                    className: 'custom-antd-notification',
                    message: '删除成功！',
                    duration: 5,
                })
                fetchData();
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: res.data.msg,
                    duration: 5,
                })
            }
        })
    }, [fetchData])

    /**
     * @description 确认删除弹框
     * @param {String} options 待删除的发票抬头信息
     */
    const confirmDel = useCallback((options) => {
        Modal.confirm({
            title: '确认删除该发票抬头吗？',
            icon: <ExclamationCircleOutlined />,
            content: '发票抬头：' + options.titleName,
            okText: '确认',
            cancelText: '取消',
            centered: true,
            onOk: () => delInvoiceTitle(options),
        });
    }, [delInvoiceTitle])

    // 打开修改或添加发票抬头的面板
    const openModal = useCallback((type, options) => {
        // const id = form.getFieldValue('invoiceTitleId')
        if (type === 'ADD') {
            if (dataSource.length >= 10) {
                message.warn("发票抬头数量不允许超过10条！")
                return;
            }
            setInvoiceTitle(null);
            setShow(true);
        } else if (type === 'EDIT') {
            queryInvoiceTitleById(options.invoiceTitleId)
            .then(res => {
                if (res.data.code === 200) {
                    setInvoiceTitle(res.data.data);
                    setShow(true);
                } else {
                    notification.warn({
                        className: 'custom-antd-notification',
                        message: res.data.msg,
                        duration: 5,
                    })
                }
            })
        }
    }, [dataSource.length])

    const closeModal = () => {
        setShow(false);
        fetchData();
    }

    // 发票抬头相关操作单元格
    const getOperationCell = useCallback((item) => {
        return (
            <div style={{
                display: 'flex',
            }}>
                {item.isDefault ? (
                 <Button
                     className="isdefault-btn"
                     style={{
                         padding: '3px 5px',
                         marginRight: '8px',
                     }}
                 >
                       默认抬头
                   </Button>
                ) : (
                     <Button
                         className="notdefault-btn"
                         style={{
                             padding: '3px 5px',
                             marginRight: '8px',
                         }}
                        onClick={() => {
                            updateInvoiceTitleById({
                                ...item,
                                isDefault: 1
                            }).then(res => {
                                if (res.data.code === 200) {
                                    notification.success({
                                        className: 'custom-antd-notification',
                                        message: "已设置该发票抬头为默认！",
                                        duration: 5,
                                    })

                                    fetchData();
                                }
                            })
                        }}
                    >
                       设置默认
                     </Button>
                )}
                 <a
                     href="/#"
                     style={{
                         display: 'inline-block',
                         marginRight: '8px'
                     }}
                     onClick={(e) => {
                        e.preventDefault()
                        openModal('EDIT', item)
                     }}
                 >
                    修改
                 </a>
                 <a
                     href="/#"
                     style={{
                         display: 'inline-block',
                         marginRight: '8px'
                     }}
                     onClick={(e) => {
                        e.preventDefault()
                        confirmDel(item);
                     }}
                 >
                    删除
                 </a>
            </div>
         )
    }, [fetchData, confirmDel, openModal])


    const columns = [
        {
            title: '发票抬头',
            dataIndex: 'titleName'
        },
        {
            title: '税号',
            dataIndex: 'dutyParagraph'
        },
        {
           title: '电话',
           dataIndex: 'companyPhone'
       },
        {
            title: '开户行',
            dataIndex: 'accountBank',
        },
        {
            title: '帐号',
            dataIndex: 'accountNumber'
        },
        {
            title: '地址',
            dataIndex: 'companyAddress',
        },
        {
            title: '操作',
            dataIndex: 'operations',
            render: (item) => getOperationCell(item),
        }
    ];

    return (<>
        <div className="table-meta mytrial-table-meta">
            <Button
                type="primary"
                onClick={() => {
                    openModal('ADD')
                }}
            >
                + 新增发票抬头
            </Button>
            <a
                href="/#"
                style={{
                    float: 'right',
                }}
                onClick={(ev) => {
                    ev.preventDefault();
                    history.goBack()
                }}
            >
               &lt; 返回
            </a>
            {/* <Link
                to={`/MyOrder/ApplyInvoice?orderId=${
                    orderId
                }`}
            >
                &lt; 返回
            </Link> */}
        </div>
        <Table
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            pagination={false}
        />
        <InvoiceTitleModal
            isOpen={showAddModal}
            close={closeModal}
            initValue={currInvoiceTitle}
        />
    </>);
}
