/**
 * @file   安全设置模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */

import React, { useEffect, useState } from 'react'
import { List } from 'antd'
import { queryUserInfo } from '../../../service';
import { Link } from 'react-router-dom';


/**
 * @description 安全设置组件
 */
export const Security = () => {
    const [list, setList] = useState([]);
    useEffect(() => {
        queryUserInfo().then(res => {
            if (res.data.code === 200) {
                setList([
                    {
                        title: '安全手机',
                        content: res.data?.data?.customerPhone,
                        description: `已绑定手机：${res.data?.data?.customerPhone || '暂无'}`,
                    },
                    // {
                    //     title: '安全邮箱',
                    //     content: res.data?.data?.email,
                    //     description: `已绑定邮箱：${res.data?.data?.email || '暂无'}`,
                    // }
                ])
            }
        })
    }, [])
    return (
        <div
            className="page-content"
        >
            <List
                // header={<h1 style={{fontSize: 18}}>安全设置</h1>}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Link key="list-edit" to={item.content ? '/changePhone' : '/validatePhone'}>{item.content ? '修改' : '绑定'}</Link>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={item.avatar}
                            title={item.title}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />

        </div>
    );
};
