
/**
 * @file   隐私政策
 * @create macaiqi 2021/07/07
 * @update
 */
import React, { useEffect, useState, useCallback } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import Loading from '../../../components/Loading'
import { queryChannel, queryArchivesByNo, queryChannelInfo } from '../../../service/index'
import './style.scss'

export const PrivacyPolicy = () => {
    const [firsttab, setFirsttab] = useState();
    const [data, setData] = useState(''); // 文章数据
    const [loading, setLoading] = useState('');
    const [index, setindex] = useState(0)  // 数据加载到第几个，避免后面数据先加载出来打乱顺序
    const [topdata, setTopdata] = useState() // 顶部数据，栏目标题和描述
    // 获取左边栏目数据
    useEffect(() => {
        queryChannel('PrivacyPolicy').then(res => {
            setFirsttab(res?.data?.data?.records)
        })
        queryChannelInfo('PrivacyPolicy').then(res => {
            setTopdata(res?.data?.data)
        })
    }, [])
    //    获取全部文章数据
    const getdata = useCallback(() => {
        if (firsttab) {
            const lasttab = firsttab?.[firsttab?.length - 1]?.channelNo?.replace(/[^\d]/g, "") // 最后一项数字
            for (let i = 0; i < lasttab; i++) {
                // eslint-disable-next-line no-loop-func
                if (index === i) {
                    setLoading(true)
                    queryArchivesByNo(`policy${i + 1}`).then(res => {
                        console.log(i)
                        if (i === 0) {
                            localStorage.setItem('data', `<h3 id=${`content${i + 1}`}>${res?.data?.data?.title}</h3>${res?.data?.data?.content}`)
                        } else {
                            localStorage.setItem('data', (localStorage.getItem('data') || '') + `<h3 id=${`content${i + 1}`}>${res?.data?.data?.title}</h3>${res?.data?.data?.content}`)

                        }
                        setindex(i + 1)
                        if (i === lasttab - 1) {
                            setData(localStorage.getItem('data'))
                            setLoading(false)
                        }
                    })

                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firsttab, index])
    useEffect(() => {
        getdata()
    }, [getdata])



    // 滚动到锚点
    const scrollToAnchor = (index) => {
        // setTabindex(index)
        if (index) {
            // 找到锚点
            let anchorElement = document.getElementById(index);
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
        }
    }


    useEffect(() => {
        document.documentElement.scrollTop = 0
        if (loading) {
            document.body.style.overflow = 'hidden'
        }
        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            //   滚动的时候对应的高亮
            if (scrollT >= document.getElementById('content1')?.offsetTop) {
                for (let i = 1; i <= index; i++) {
                    // 10的误差
                    if (scrollT >= document.getElementById(`content${i}`)?.offsetTop) {
                        document.getElementById(`link${i}`)?.classList?.add('active');
                        for (let j = 1; j <= index; j++) {
                            // console.log(i, index, j)
                            if (i !== j) {
                                document.getElementById(`link${j}`)?.classList?.remove('active');
                            }
                        }
                    }

                }
            } else {
                for (let j = 1; j <= index + 1; j++) {
                    document.getElementById(`link${j}`)?.classList?.remove('active');
                }
            }

        }

    }, [index, loading]);

    return (<>
        {/* <IntroHeader small={true} /> */}
        <div className="privacypolicy">
            <div className="allcenter">
                <div className="text">
                    <h2>
                        {topdata?.channelName}
                    </h2>
                    <div className="contbox" id='contbox' dangerouslySetInnerHTML={{ __html: topdata?.description || '' }} />
                    {/* <p>
                        瑞鹿云深知隐私对您的重要性，并充分尊重您的隐私。在向我们提交您的信息之前，请您仔细阅读本《隐私政策声明》（以下简称“本政策”）。本政策适用于瑞鹿云和本政策所包含或援引的所有产品和服务（以下简称“服务”）。
                    </p> */}
                    {loading ? <Loading /> :
                        <div className="contbox" id='contbox' dangerouslySetInnerHTML={{ __html: data || '' }} />
                    }
                </div>
                <div className="r">
                    <ul>
                        {firsttab?.map((item, index) => {
                            return (
                                <li key={index} onClick={() => scrollToAnchor(`content${index + 1}`)} id={`link${index + 1}`}>{item.channelName}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
