/**
 * @file   订购服务模块
 * @create tianmu 2021/3/9
 * @update tianmu 2021/3/9
 */
import React, { useEffect } from 'react';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { toFixedNumStr } from '../../utils';
import { ProductLayout } from './components/ProductLayout';
import './index.scss'

// 订购服务组件
export const ProductOrderSuccess = () => {
    const history = useHistory();
    const { order } = useSelector((state) => state.productReducer);

    useEffect(() => {
        if (!order) {
            // history.replace("/MyProduct");
        }
    }, [order, history]);

    return (
        <ProductLayout
            title="订单提交成功"
        >
            <div className="orderinfobox-wrapper">
                <div className="orderinfobox">
                    <div className="head">
                        <div
                            style={{
                                display: 'inline-block',
                                width: '100px',
                                height: '100px',
                                lineHeight: '100px',
                                fontSize: '50px',
                                color: 'rgb(61, 188, 142)',
                            }}
                        >
                            <CheckCircleFilled />
                        </div>
                        <div className="infobox">
                            <h1>恭喜您，订单提交成功！</h1>
                            <p>
                                {/* 您申请的产品已申请成功，24小时内会有工作人员联系您评估工作量并进行报价，请保持手机畅通。
                                双方报价达成一致后，请到我的订单支付，支付成功后，工作人员会在24小时内联系您。 */}
                                您申请的产品已申请成功，24小时内会有工作人员联系您评估工作量并进行报价，请保持手机畅通。
                                双方报价达成一致后，请线下支付，支付成功后，工作人员会在24小时内完成审批，审批后您即可使用订购产品。
                            </p>
                        </div>
                    </div>
                    <div>
                        <fieldset className="common-fieldset">
                            <legend>订单信息</legend>
                            <div className="info-row">
                                <div className="info-label">订单号：</div>
                                <div className="info-value">{order?.orderNo}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">订购产品：</div>
                                <div className="info-value">{order?.orderTitle}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">订购日期：</div>
                                <div className="info-value">{order?.createTime}</div>
                            </div>
                            <div className="info-row">
                                <div className="info-label">订购金额：</div>
                                <div className="info-value">￥{toFixedNumStr(order?.orderCost, 2)}</div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '20px'
                    }}
                >
                    <Button type="primary" style={{marginRight: '20px'}} onClick={() => history.push('/MyOrder')}>我的订单</Button>
                    <Button type="primary" onClick={() => history.push('/index')}>返回控制台</Button>
                </div>
            </div>
        </ProductLayout>
    );
};
