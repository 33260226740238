
/**
 * @file   数字资产管理系统
 * @create macaiqi 2021/04/14
 * @update
 */
import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { IntroHeader } from '../header';
import { IntroFooter } from '../footer';
import cls from 'classnames'
import './style.scss'


/**
 * @description 数字资产管理系统
 */

export const WapVisualAssetManagement = () => {
    const tab = ['用户群体', '功能组成', '产品特点', '产品价格', '更新历史']
    const sec3 = [
        { title: '丰富的资产类型定义', txt: '以资产集的方式，支持图片、视频、模型等丰富的资产类型定义和管理。' },
        { title: '全面可靠的权限管理', txt: '提供强大的权限管理机制，权限控制可精确到个人或功能模块。' },
        { title: '灵活的审核流程设置', txt: '通过工作流自定义，灵活满足不同的审核流程设置需求。' },
        { title: '强大的外部数据对接', txt: '基于统一的接口规范，支持与第三方系统的外部数据对接。' },
    ]
    const sec2 = [
        { title: '资产登记', txt: '为用户进行数字资产信息登记和资源上传提供快捷入口，为数字资产的统一管理和共享利用奠定数据基础。主要功能包括数字资产的上传、登记和发布。', index: 0 },
        { title: '待登记资产管理', txt: '针对用户已上传但尚未提审的资产，提供资产重新上传、导入、删除等功能，针对同类型资产可建立资产集，从而实现待登记资产的全面、统一、分类管理。主要功能包括新建资产集、资产上传和删除。', index: 1 },
        { title: '资产管理', txt: '针对登录用户登记并发布的资产或资产集，提供便捷、高效的分类浏览、检索和编辑等功能，实现已入库资产文件或资产集的全面、统一管理。主要功能包括资产分类浏览、检索、查看详情、编辑和删除。', index: 2 },
        { title: '回收站', txt: '临时保存用户已删除的资产对象，并可还原已删除资源。用户可手动清空回收站，同时，系统会根据时间期限自动清空回收站。', index: 3 },
        { title: '资产统计', txt: '对已入库资产及个人管理资产进行不同维度的数据统计，帮助用户快速了解资产类型、数量、使用情况等信息。', index: 4 },
        { title: '数字资产库', txt: '为满足用户对数字资产的应用需求，以申请授权的方式提供对数字资产库的分类浏览、检索、查看详情、下载、分享、收藏等功能。', index: 5 },
    ]
    const [showsec, setShowsec] = useState()
    // 滚动到锚点
    const scrollToAnchor = (anchorName) => {
        if (anchorName || anchorName === 0) {
            const height = document.getElementById(anchorName).offsetTop - 40; // 计算需要向上移动的距离
            window.scrollTo({
                top: height,
                behavior: 'smooth'
            });
        }
    };
    const banner = useRef()
    const activeref = useRef()
    const { search } = useLocation()
    useEffect(() => {

        window.onscroll = function () {
            // 获取当前页面的滚动条纵坐标位置 （依次为火狐谷歌、safari、IE678）
            let scrollT = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            // 滚动超过banner后nav吸顶
            if (scrollT >= banner.current.clientHeight + banner.current.offsetTop) {
                activeref.current?.classList.add('active');
            } else {
                activeref.current?.classList.remove('active');
            }
            // 滚动到对应点，对应nav高亮
            for (let i = 0; i < tab.length; i++) {

                if (scrollT >= document.getElementById(`item${i}`).offsetTop - 40) {
                    document.getElementById(`link${i}`).classList.add('active');
                    for (let j = 0; j < tab.length; j++) {
                        if (i !== j) {
                            document.getElementById(`link${j}`).classList.remove('active');
                        }
                    }
                }

            }
        }
        // 其他页面跳转尽量判断是否有锚点
        if (search.split('?')[1]) {
            scrollToAnchor(`item${search.split('?')[1]}`)
        }
    }, [tab.length, search]);

    return (<>
        <IntroHeader />
        <div className="mobile-content" ref={activeref}>
            <div className="banner visual" ref={banner}>
                <div className="content">
                    <p className="big">数字资产管理系统</p>
                    <div className="cont">通过对数字资产上传、登记、入库、共享、授权、注销等全生命周期的规范化管理，建立数字资产管理应用的规范化流程。建立开放、共享、安全的数字资产库，满足数字资产在陈列展览、教育、学术研究、文创、版权授权等业务领域的广泛应用。</div>
                    <div className="btnbox">
                        {/* <div className="btn btn1">
                            观看演示
                        </div> */}
                        {/* <div className=" btn btn2">
                            免费体验
                        </div> */}
                    </div>

                </div>
            </div>
            <div className="tab" >
                <ul>
                    {tab.map((item, index) => {
                        return (
                            <li key={index} id={`link${index}`} onClick={() => {
                                scrollToAnchor(`item${index}`)
                            }}>
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="people" id='item0'>
                <p className="title" >用户群体</p>
                <p className="txt">本产品适用于对图片、视频、模型等数字类资产有管理和利用需求的公共文化场馆、教育科研机构等企事业单位，面向数字资产责任部门及相关工作人员提供资产管理服务，面向其他部门人员提供全面的数字资产检索和下载服务。</p>
            </div>
            <div className="section2" id='item1' >
                <p className="title" >功能组成</p>
                <div className="functionof">
                    <div className="visua-function">
                        <div className={showsec ? "shade" : "shade hide"}>
                            <div className="close" onClick={() => { setShowsec(null) }} />
                            <div className="des">
                                <i className={`v${showsec?.index + 1}`} />
                                <p className='tip'>{showsec?.title}</p>
                                <p className="des">
                                    {showsec?.txt}
                                </p>
                            </div>
                        </div>
                        {
                            sec2.map((item, index) => {
                                return (
                                    <div className='item' key={index} onClick={() => {
                                        setShowsec(item)
                                    }}>
                                        <i className={`v${index + 1}`} />
                                        {item.title}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="section3" id='item2'>
                <p className="title">产品特点</p>
                {
                    sec3.map((item, index) => {
                        return (
                            // <div className={cls("item", { active: down === index })} key={index} onClick={() => {
                            <div className={cls("item")} key={index}>
                                <div className="box">
                                    <div className="l">
                                        <i className={`icon${index + 5}`} />
                                        {item.title}
                                    </div>
                                    <div className="down" />
                                </div>
                                <div className="text">
                                    {item.txt}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            <div className="price" id='item3'>
                <p className="title">产品价格</p>
                <div className="num">
                    8800
                    <span>元/年</span>
                </div>
            </div>
            <div className="update" id='item4'>
                <div className="updatebox">
                    更新历史
                    <i className="jian" />
                    <span style={{ marginLeft: 10 }}>2021年9月26日正式发布</span>
                </div>
            </div>
        </div>
        <IntroFooter />
    </>
    )
}
