/**
 * @file  试用详情
 * @create tianmu 2021/5/7
 * @update tianmu 2021/5/7
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { queryTrialByIdForCustomer } from '../../service';
import { dateFormat, searchStrToObj } from '../../utils';
import { useHistory, useLocation } from 'react-router';
import './index.scss';
import TrialStatusIcon1 from '../../assets/notice/1.png';
import TrialStatusIcon2 from '../../assets/notice/2.png';
import TrialStatusIcon3 from '../../assets/notice/3.png';
import TrialStatusIcon4 from '../../assets/notice/11.png';

const TIME_FORMAT = 'YYYY-mm-dd HH:MM:SS';
export const TrialDetail = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [trialDetail, setTrialDetail] = useState(null);
    const o = useMemo(() => searchStrToObj(location.search), [location.search]);
    const fetchTrialInfo = useCallback(async (id) => {
        const res1 = await queryTrialByIdForCustomer(id);
        const d = res1.data.data;
        const detail =
            Array.isArray(d.trialDetailList) &&
            d.trialDetailList.length > 0 &&
            d.trialDetailList[0];
        const prodInfo = detail && JSON.parse(detail?.prodInfo);
        setTrialDetail({
            approveStatus: d?.approveStatus,
            approveOpinion: d?.approveOpinion,
            initProgress: d?.initProgress,
            applyTime: dateFormat(TIME_FORMAT, d?.applyTime),
            approveTime: dateFormat(TIME_FORMAT, d?.approveTime),
            effectDate: detail?.effectDate,
            expireDate: detail?.expireDate,
            productName: prodInfo?.productName,
            description: prodInfo?.description
        });
    }, []);

    const fetchData = useCallback(() => {
        // trialId 是试用列表里的查看详情过来的
        // businessId 是从试用消息点击过来的
        const id = o?.trialId || o?.businessId;
        fetchTrialInfo(id);
    }, [fetchTrialInfo, o]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            <div className="page-header">
                <div className="title">试用详情</div>
            </div>
            <Layout className="page-content order-detail">
                <div className="order-detail-info">
                    <div className="notice-img-icon">
                        {trialDetail?.approveStatus === 1 ? ( // 审批中
                            <img src={TrialStatusIcon1} alt="" />
                        ) : trialDetail?.approveStatus === 2 ? ( // 审批通过
                            trialDetail?.initProgress === 2 ? ( // 初始化成功
                                <img src={TrialStatusIcon2} alt="" />
                            ) : (
                                <img src={TrialStatusIcon1} alt="" />
                            )
                        ) : trialDetail?.approveStatus === 3 ? ( // 已拒绝
                            <img src={TrialStatusIcon3} alt="" />
                        ) : trialDetail?.approveStatus === 4 ? ( // 已撤销
                            <img src={TrialStatusIcon4} alt="" />
                        ) : null}
                    </div>
                    <h3
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            marginBottom: 10,
                            paddingBottom: 10,
                            borderBottom: '1px solid #ccc'
                        }}
                    >
                        试用详情
                        {/* {o?.trialId && ( */}
                            <span
                                onClick={() => {
                                    history.goBack();
                                }}
                                style={{
                                    float: 'right',
                                    color: 'rgb(86, 192, 150)',
                                    cursor: 'pointer',
                                    fontSize: '14px'
                                }}
                            >
                                &lt; 返回
                            </span>
                        {/* )} */}
                    </h3>
                    <Row gutter={[0, 20]}>
                        <Col style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }} span={2}>
                            试用产品：
                        </Col>
                        <Col style={{ color: 'rgb(86, 192, 150)', fontWeight: 'bold' }} span={20}>
                            {trialDetail?.productName}
                        </Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>申请时间：</Col>
                        <Col span={18}>{trialDetail?.applyTime}</Col>
                    </Row>

                    <Row gutter={[0, 20]}>
                        <Col span={2}>产品简介：</Col>
                        <Col span={18}>{trialDetail?.description}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>生效日期：</Col>
                        <Col span={18}>{trialDetail?.effectDate}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>失效日期：</Col>
                        <Col span={18}>{trialDetail?.expireDate}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>审批时间：</Col>
                        <Col span={18}>{trialDetail?.approveTime}</Col>
                    </Row>
                    <Row gutter={[0, 20]}>
                        <Col span={2}>审批意见：</Col>
                        <Col span={18}>{trialDetail?.approveOpinion}</Col>
                    </Row>
                </div>
                {/* <h3
                     style={{
                         fontSize: 20,
                         fontWeight: 'bold',
                         marginBottom: 10,
                         paddingBottom: 10,
                         borderBottom: '1px solid #ccc'
                     }}
                 >
                     发票详情
                 </h3>

                 {Array.isArray(invoiceList) && invoiceList.length > 0 && (
                     <div className="order-invoice-item">
                         <div className="notice-img-icon">
                             {invoiceList[0].status === 0 && <img src={InvoiceStatusIcon0} alt="" />}
                             {invoiceList[0].status === 1 && <img src={InvoiceStatusIcon1} alt="" />}
                             {invoiceList[0].status === 2 && <img src={InvoiceStatusIcon2} alt="" />}
                             {invoiceList[0].status === 3 && <img src={InvoiceStatusIcon3} alt="" />}
                         </div>

                         <Row gutter={[0, 20]}>
                             <Col span={2}>发票抬头：</Col>
                             <Col span={22}>{invoiceList[0].invoiceTitle}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>金额：</Col>
                             <Col span={22}>￥{invoiceList[0].invoiceCost}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>备注说明：</Col>
                             <Col span={22}>{invoiceList[0].remark}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>公司税号：</Col>
                             <Col span={22}>{invoiceList[0].dutyParagraph}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>公司注册电话：</Col>
                             <Col span={22}>{invoiceList[0].companyPhone}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>公司地址：</Col>
                             <Col span={22}>{invoiceList[0].companyAddress}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>开户行：</Col>
                             <Col span={22}>{invoiceList[0].accountBank}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>收件人：</Col>
                             <Col span={22}>{invoiceList[0].accountNumber}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>收件人电话：</Col>
                             <Col span={22}>{invoiceList[0].receiver}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>寄件地址：</Col>
                             <Col span={22}>{invoiceList[0].receiverPhone}</Col>
                         </Row>
                         <Row gutter={[0, 20]}>
                             <Col span={2}>公司注册电话：</Col>
                             <Col span={22}>{invoiceList[0].receiverAddress}</Col>
                         </Row>
                         {invoiceList[0].approveOpinion && (
                             <Row gutter={[0, 20]}>
                                 <Col span={2}>审批意见：</Col>
                                 <Col span={22}>{invoiceList[0].approveOpinion}</Col>
                             </Row>
                         )}
                         {invoiceList[0].approveTime && (
                             <Row gutter={[0, 20]}>
                                 <Col span={2}>审批时间：</Col>
                                 <Col span={22}>{invoiceList[0].approveTime}</Col>
                             </Row>
                         )}

                     </div>
                 )} */}
            </Layout>
        </>
    );
};
