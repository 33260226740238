/**
 * @file   页面侧边栏布局模块
 * @create tianmu 2020/9/28
 * @update tianmu 2020/10/20 更新注释
 */

import React from 'react';
import { Layout } from 'antd';
import { CustomMenu } from '../components/CustomMenu/CustomMenu.jsx';

const { Sider } = Layout;

// 侧边栏组件
export const AppAside = (props) => {
    let { menu } = props;
    return (
        <Sider className="layout-sider">
            <CustomMenu menu={menu} />
        </Sider>
    );
};
