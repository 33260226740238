/**
 * @file   意见反馈
 * @create macaiqi 2021/07/07
 * @update
 */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import idcard from 'idcard';
import * as Yup from 'yup';
// import { validator } from '../../../../utils/platform';
// import { useLocation } from 'react-router-dom';
// import { http } from '../../service/http';
import './style.scss';
import Layer from './Layer';
export default function Feedback({ setShowfrom, showfrom }) {
    const [personalInfo, setPersonalInfo] = useState({});
    const [resMessage, setResMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (showfrom) {
            document.body.parentNode.style.overflow = "hidden"
        } else {
            document.body.parentNode.style.overflow = "unset"
        }
    }, [showfrom])
    const validator = () => {
        return {
            phoneNumRegex: /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[01356789]\d{2}|4(?:0\d|1[0-2]|9\d))|9[189]\d{2}|6[567]\d{2}|4[579]\d{2})\d{6}$/, // 中国大陆手机号匹配
            userNameRegex: /^[a-zA-Z_][a-zA-Z0-9_]{1,17}$/, // 用户名
            pwdRegex: /^[\w_]{6,16}$/, // 6-16位  字母数字下划线
            fullNameRegex: /^[\u4e00-\u9fa5]{2,5}(?:·[\u4e00-\u9fa5]+)?$/, // 汉字姓名正则
            contentRegex: /^(<\s*\/?\s*[a-zA-z_]([^>]*?["][^"]*["])*[^>"]*>)/g, // 留言内容
            emailRegex: /^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/ // 邮箱
        };
    }
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({

                peopleName: Yup.string()
                    .required('请填写姓名！')
                    .matches(validator().fullNameRegex, '不合法名称!'),
                phoneNumber: Yup.string()
                    .required('请填写手机号！')
                    .matches(validator().phoneNumRegex, '不合法手机号!'),
                email: Yup.string().required('请填写正确邮箱！').matches(validator().emailRegex, '请填写正确邮箱!'),
                itemDescription: Yup.string().required('请填写反馈内容！'),
            }),
        []
    );
    // const { pathname } = useLocation()
    // const filePrefix = process.env.ENV_IMG_PREURL;
    const [val, setVal] = useState()
    const handleReset = (resetForm) => {
        resetForm();
        setPersonalInfo({
            volName: '',
            peopleName: '',
            phoneNumber: '',
            email: '',
            itemDescription: '',
            type: '',
            businessLicense: '',
        });
        setShowfrom(false)
    }
    const toHandleSubmit = (values, resetForm) => {
        setVal(values)
        console.log('values', val, values)
        // imgsec.current.outerHTML = imgsec.current.outerHTML.replace(/(value=\\").+\\"/i, "$1\"");
        // let obj = {
        //   contact: values.peopleName, // 姓名
        //   telephone: values.phoneNumber, // 联系电话
        //   email: values.email, // 电子邮箱，
        //   introduction: values.itemDescription, // 反馈内容

        // }

        // http.post('/japi/sw-cms-xn/api/saveMerchantInfo', obj).then((res) => {
        //   setResMessage(res.data);
        //   if (res.data.code === 200) {
        //     // 成功后清空
        handleReset(resetForm)
        //   }
        //   setSubmitted(true);
        // });
    };

    return (
        <div className={showfrom ? "application-formbox" : 'application-formbox hide'}>
            <div className="application-form">
                <h3>意见反馈</h3>

                <Layer
                    containerStyle={{
                        width: 260,
                        height: 154
                    }}
                    width={500}
                    visible={submitted}
                    onClose={() => {
                        setSubmitted(false);
                    }}
                >
                    {resMessage.code === 200 ? <p>提交成功</p> : <p>提交失败</p>}
                </Layer>
                <Formik
                    enableReinitialize={personalInfo}
                    initialValues={{
                        peopleName: '',
                        phoneNumber: '',
                        email: '',
                        itemDescription: '',

                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        toHandleSubmit(values, resetForm);
                    }}
                >
                    {({ handleSubmit, setFieldValue, resetForm }) => (
                        <Form onSubmit={handleSubmit}>
                            <ul>
                                <li>
                                    <span>
                                        姓名：
                                    </span>
                                    <Field name="peopleName" type="text" />
                                    <span className="tip error">
                                        <ErrorMessage name="peopleName" />
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        联系电话：
                                    </span>
                                    <Field name="phoneNumber" type="text" />
                                    <span className="tip error">
                                        <ErrorMessage name="phoneNumber" />
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        电子邮箱：
                                    </span>
                                    <Field name="email" type="text" />
                                    <span className="tip error">
                                        <ErrorMessage name="email" />
                                    </span>
                                </li>
                                <li>
                                    <span className='top-span'>
                                        反馈内容：
                                    </span>
                                    <Field name="itemDescription" component="textarea" />
                                    <span className="tip error">
                                        <ErrorMessage name="itemDescription" />
                                    </span>
                                </li>

                            </ul>
                            <div className="btnbox">
                                <div className="cancel btnitem" onClick={() => {
                                    handleReset(resetForm)
                                }}>
                                    取消
                                </div>
                                <button className="btn btnitem" type="submit" >
                                    提交
                                </button>
                            </div>


                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
