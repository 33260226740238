/**
 * @file   我的发票模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Button, Input, Table, DatePicker, Form, Select, notification } from 'antd';
import './index.scss';
import { cancelInvoice, queryInvoiceList } from '../../service';
import { searchStrToObj, toFixedNumStr } from '../../utils';

const { RangePicker } = DatePicker;

// 默认页面大小
const PAGE_SIZE = 10;

// 发票类型与数字枚举对应
const invoiceTypeStr = (type) => {
    switch (type) {
        case 1:
            return '个人';
        case 2:
            return '企业单位';
        default:
            return '';
    }
};

// 开票状态与数字枚举对应
const statusStr = (status) => {
    switch (status) {
        case 0:
            return <span className="state-waring">开票中</span>;
        case 1:
            return <span className="state-cancel">已撤销</span>;
        case 2:
            return <span className="state-success">已开票</span>;
        case 3:
            return <span className="state-error">开票失败</span>;
        default:
            return '';
    }
};

// 我的发票页面组件
export const MyBill = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: PAGE_SIZE
    });

    // 获取数据并设置数据源和分页状态
    const fetchData = useCallback(
        (params) => {
            const o = searchStrToObj(location.search);
            setLoading(true);
            let p = params;
            if (o?.orderId) {
                p = {
                    entity: {
                        ...p?.entity,
                        orderId: o.orderId
                    },
                    param: p?.param
                };
            }
            queryInvoiceList(p).then((res) => {
                // console.log(res)
                const listData = res.data.data.records.map((item) => {
                    return {
                        key: item.invoiceId,
                        invoiceCost: toFixedNumStr(item.invoiceCost, 2),
                        invoiceTitle: item.invoiceTitle,
                        dutyParagraph: item.dutyParagraph,
                        invoiceTitleType: item.invoiceTitleType,
                        status: item.status,
                        createTime: item.createTime,
                        approveTime: item.approveTime,
                        remark: item.remark,
                        operations: item,
                    };
                });
                setPagination({
                    current: params.pageNum,
                    pageSize: params.pageSize,
                    total: res.data.data.total
                });
                setDataSource(listData);
                setLoading(false);
            });
        },
        [location]
    );

    useEffect(() => {
        let params = {
            entity: {},
            param: {
                pageNum: 1,
                pageSize: PAGE_SIZE
            }
        };
        fetchData(params);
    }, [location, fetchData]);

    const columns = [
        {
            title: '金额（元）',
            dataIndex: 'invoiceCost'
        },
        {
            title: '发票抬头',
            dataIndex: 'invoiceTitle'
        },
        {
            title: '公司税号',
            dataIndex: 'dutyParagraph'
        },
        {
            title: '发票性质',
            dataIndex: 'invoiceTitleType',
            render: (type) => <span className="state-success">{invoiceTypeStr(type)}</span>
        },
        {
            title: '创建时间',
            dataIndex: 'createTime'
        },
        {
            title: '开票状态',
            dataIndex: 'status',
            render: (status) => <span>{statusStr(status)}</span>
        },
        {
            title: '备注说明',
            dataIndex: 'remark'
        },
        {
            title: '操作',
            dataIndex: 'operations',
            render: (data) => {
                // 发票状态为开票中才可以撤销
                return (<>
                    <Link
                        to={`/MyBill/InvoiceDetail?orderId=${data.orderId}&invoiceId=${data.invoiceId}`}
                        style={{
                            marginRight: 10
                        }}
                    >查看详情</Link>
                    {(data.status === 0) && (
                        <a
                            href="/#"
                            onClick={(e) => {
                                e.preventDefault();
                                cancelInvoice({
                                    invoiceId: data.invoiceId
                                }).then(res => {
                                    if (res.data.code === 200) {
                                        notification.success({
                                            className: 'custom-antd-notification',
                                            message:"撤销成功！",
                                            duration: 5,
                                        });
                                        fetchData({
                                            entity: {},
                                            param: {
                                                pageNum: 1,
                                                pageSize: PAGE_SIZE
                                            }
                                        });
                                    } else {
                                        notification.warn({
                                            className: 'custom-antd-notification',
                                            message: res.data.msg,
                                            duration: 5,
                                        })
                                    }
                                })
                            }}
                        >
                            撤销
                        </a>
                    )}
                </>)
            }
        }
    ];

    const handleTableChange = (pagination) => {
        const values = form.getFieldsValue();
        const timeRange = values.createTimeQuery;
        fetchData({
            entity: {
                ...values,
                createTimeQuery: timeRange && timeRange.map((t) => t.format('YYYY-MM-DD')).join('#')
            },
            param: {
                pageNum: pagination.current,
                pageSize: pagination.pageSize
            }
        });
    };

    return (
        <>
            <div className="page-header">
                <div className="title">我的发票</div>
            </div>
            <Layout className="page-content mybill">
                <div className="table-meta mybill-table-meta">
                    <Form
                        form={form}
                        layout="inline"
                        onFinish={() => handleTableChange({ current: 1, pageSize: PAGE_SIZE })}
                    >
                        <Form.Item
                            name="status"
                        >
                            <Select placeholder="请选择开票状态">
                                <Select.Option key={0} value={0}>
                                    开票中
                                </Select.Option>
                                <Select.Option key={1} value={1}>
                                    已撤销
                                </Select.Option>
                                <Select.Option key={2} value={2}>
                                    已开票
                                </Select.Option>
                                <Select.Option key={3} value={3}>
                                    开票失败
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="invoiceTitle">
                            <Input placeholder="请输入发票抬头" />
                        </Form.Item>
                        <Form.Item name="dutyParagraph">
                            <Input placeholder="请输入纳税人识别号" />
                        </Form.Item>
                        <Form.Item name="createTimeQuery">
                            <RangePicker />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            查询
                        </Button>
                        <Button
                            onClick={() => {
                                form.resetFields();
                                let params = {
                                    entity: {},
                                    param: {
                                        pageNum: 1,
                                        pageSize: PAGE_SIZE
                                    }
                                };
                                fetchData(params);
                            }}
                        >
                            重置
                        </Button>
                    </Form>
                </div>

                <Table
                    columns={columns}
                    dataSource={dataSource}
                    bordered
                    pagination={{
                        ...pagination,
                        // showSizeChanger: true,
                        showTotal: (total) => {
                            return `共 ${total} 条数据`;
                        }
                    }}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </Layout>
        </>
    );
};
