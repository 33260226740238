/**
 * @file   绑定设置模块
 * @create tianmu 2021/3/3
 * @update tianmu 2021/3/3
 */

import React, {useState,useCallback,useEffect, useRef} from 'react'
import { Input, Form, Button, notification, Checkbox, List, Modal } from 'antd';
import { UserOutlined, MobileOutlined, CheckCircleFilled } from '@ant-design/icons';
import Wechat from '../../../assets/wechat-binding.png'
import Alipay from '../../../assets/alipay-binding.png'
import Dingding from '../../../assets/dingding-binding.png'
import Weibo from '../../../assets/weibo-binding.png'
import { querySmsVCode, queryUserInfo, unbindWechat, wechatBindList } from '../../../service'
import { validator } from '../../../utils';
import './index.scss'
// const WECHAT_BIND_APPID = process.env.ENV_WECHAT_BIND_APPID;
// const WECHAT_BIND_ADDR = process.env.ENV_WECHAT_BIND_ADDR;
const WECHAT_BIND_APPID = window.WECHAT_BIND_APPID;
const WECHAT_BIND_ADDR = window.WECHAT_BIND_ADDR;

const avatarStyle = {
    width: 60,
    height: 60,
}
// 发送cd时间
const TIME_LIMIT = 60;

/**
 * @description 绑定设置组件
 */
export const Binding = () => {

    const [showAuthModal, setShowAuthModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSending, setSending] = useState(false);
    // const [isAuth, setAuth] = useState(false);
    const [time, setTime] = useState(TIME_LIMIT);
    const ref = useRef(null);
    const [form] = Form.useForm();

    const fetchUserInfo = useCallback(() => {
        queryUserInfo().then(res => {
            const user = res?.data?.data;
            form.setFieldsValue({
                phone: user.customerPhone,
            })
        })
    }, [form]);

    useEffect(() => {
        fetchUserInfo();
    }, [fetchUserInfo]);

    // fetch微信绑定状态
    const [bindWechat, setBindWechat] = useState(null);
    const fetchBindWechat = useCallback(() => {
        wechatBindList().then((res) => {
            if (res.data.code === 200) {
                const lst = res.data.data?.openIdList;
                setBindWechat(
                    Array.isArray(lst) && lst.filter(l => l.type === 1).length > 0 ?
                    lst.filter(l => l.type === 1)[0] :
                    null
                )
            }
        })
    }, [])

    const list = [
        {
            title: '微信绑定',
            description: bindWechat ? `已绑定微信账号${bindWechat.nickname ? '：'+bindWechat.nickname : ''}`: '当前尚未绑定微信帐号',
            avatar: <img style={avatarStyle} src={Wechat} alt="" />
        },
        // {
        //     title: '支付宝绑定',
        //     description: '当前尚未绑定支付宝帐号',
        //     avatar: <img style={avatarStyle}  src={Alipay} alt="" />
        // },
        // {
        //     title: '钉钉绑定',
        //     description: '当前尚未绑定钉钉帐号',
        //     avatar: <img style={avatarStyle}  src={Dingding} alt="" />
        // },
        // {
        //     title: '微博绑定',
        //     description: '当前尚未绑定微博帐号',
        //     avatar: <img style={avatarStyle} src={Weibo} alt="" />
        // },

    ]

    useEffect(() => {
        fetchBindWechat()
    }, [fetchBindWechat])

    // 获取手机验证码
    const query = useCallback(async (phone) => {
        // 验证微信登录的验证码
        const res = await querySmsVCode(phone, 1);
        // 根据返回的错误代码显示错误信息
        if (res.data.code === 200) {
            notification.success({
                className: 'custom-antd-notification',
                message: '验证码发送成功，请注意查收！',
                duration: 5
            });
            return true;
        } else {
            notification.warn({
                className: 'custom-antd-notification',
                message: res.data.msg,
                duration: 5
            });
            return false;
        }
    }, []);

    // 处理点击获取验证码按钮事件
    const handleClick = useCallback(
        async (phone) => {
            if (isSending) return;
            setSending(true);
            const success = await query(phone);
            // 如果请求验证码不成功直接返回
            if (!success) {
                setSending(false);
                return;
            }
            ref.current = setInterval(() => {
                setTime((time) => {
                    const left = time - 1;
                    // 点击验证码之后TIME_LIMIT秒
                    if (left === 0) {
                        setTime(TIME_LIMIT);
                        window.clearInterval(ref.current);
                        setSending(left > 0);
                    }
                    return left;
                });
            }, 1000);
        },
        [query, isSending]
    );

    // 表单提交成功
    const onFinish = async (values) => {
        setLoading(true);
        const { phone, svCode } = values;
        unbindWechat(phone, svCode, bindWechat.appOpenidId).then(res => {
            if (res.data.code === 200) {
                notification.success({
                    className: 'custom-antd-notification',
                    message: "解除绑定成功！",
                    duration: 5,
                })
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            } else {
                notification.warn({
                    className: 'custom-antd-notification',
                    message: res.data.msg,
                    duration: 5,
                })
            }
        })

        setLoading(false);
    };
    // 表单提交失败
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

  return (
    <div
        className="page-content"
        style={{
            paddingTop: 20,
        }}
    >

        <List
            // header={<h1 style={{fontSize: 18}}>帐号绑定</h1>}
            itemLayout="horizontal"
            dataSource={list}
            renderItem={item => (
                <List.Item
                    actions={[<span key="list-edit">
                        {bindWechat ? (
                            <a href="#/" onClick={(ev) => {
                                ev.preventDefault();
                                // unbindWechat(bindWechat.appOpenidId).then(res => {
                                //     if (res.data.code === 200) {
                                //         notification.success({
                                //             className: 'custom-antd-notification',
                                //             message: "解除绑定成功！",
                                //             duration: 5,
                                //         })
                                //         setTimeout(() => {
                                //             window.location.reload();
                                //         }, 200);
                                //     } else {
                                //         notification.warn({
                                //             className: 'custom-antd-notification',
                                //             message: res.data.msg,
                                //             duration: 5,
                                //         })
                                //     }
                                // })

                                // TODO
                                setShowAuthModal(true);
                            }}>解除绑定</a>
                        ) : (
                            <a href={`
                                https://open.weixin.qq.com/connect/qrconnect?appid=${
                                    WECHAT_BIND_APPID
                                }&redirect_uri=${
                                    encodeURIComponent(WECHAT_BIND_ADDR)
                                }&response_type=code&scope=snsapi_login&state=123#wechat_redirect
                            `}>立即绑定</a>
                        )}
                    </span>]}
                >
                    <List.Item.Meta
                        avatar={item.avatar}
                        title={item.title}
                        description={item.description}
                    />
                </List.Item>
            )}
        />
        <Modal
            title="安全验证"
            centered
            width={450}
            visible={showAuthModal}
            onCancel={() => { setShowAuthModal(false) }}
            maskClosable={true}
            keyboard={false}
            className="binding-auth-modal"
            footer={null}
        >
            <div>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            required: true,
                            pattern: validator().phoneNumRegex,
                            message: '请输入正确的手机号!'
                        }
                    ]}
                    wrapperCol={{ offset: 4, span: 16 }}
                >
                    <Input
                        placeholder="手机号"
                        size="large"
                        disabled
                        prefix={<UserOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Form.Item
                        name="svCode"
                        rules={[
                            {
                                required: true,
                                message: '验证码不能为空!'
                            },
                            {
                                len: 6,
                                message: '验证码长度为6位！'
                            }
                        ]}
                        noStyle
                    >
                        <Input
                            placeholder="验证码"
                            size="large"
                            className="svcodeInput"
                            prefix={<MobileOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>
                    <Button
                        size="large"
                        type="primary"
                        className={`svcodeBtn ${isSending
                            // || !isAuth
                            ? '' : 'active'
                        }`}
                        disabled={isSending
                            // || !isAuth
                        }
                        style={isSending ? null : {
                            // background: '#00a2fe',
                            border: 'none',
                        }}
                        onClick={() => {
                            console.log('click');
                            const phone = form.getFieldValue('phone');
                            // 手机号不为空时处理验证码获取事件
                            if (phone) {
                                handleClick(phone);
                            } else {
                                notification.info({
                                    className: 'custom-antd-notification',
                                    message: '请先输入手机号！',
                                    duration: 5
                                });
                            }
                        }}
                    >
                        {isSending ? `${time}秒后重新发送` : '发送验证码'}
                    </Button>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <Button
                        type="primary"
                        block
                        size="large"
                        htmlType="submit"
                        loading={loading}
                    >
                        确定
                    </Button>
                </Form.Item>
            </Form>
            </div>
        </Modal>

    </div>

  );
};
